import React from 'react'
import './Loader.css'
import constant from '../../constant'

const loader=constant.IMAGES_LOADER.LOGO

export default function Loader() {
    return (
       <div className=''>
       <img width={`80vw`}  src={loader} alt="Loader png" className="loader-image" />
       </div>
    )
}
 