import React, { useState } from 'react';
import PersonalDetails from '../component/SellerRegistration/PersonalDetails';
import BusinessDetails from '../component/SellerRegistration/BusinessDetails';
import AccountDetails from '../component/SellerRegistration/AccountDetails';
import WhyEkaiv from '../component/SellerRegistration/WhyEkaiv';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { alignProperty } from '@mui/material/styles/cssUtils';

const steps = ['PersonalDetails', 'BusinessDetails', 'AccountDetails'];

function SellerRegistration() {
  const [step, setStep] = useState(0); // Track the current step of the registration process
  const [allData, setAllData] = useState({});
  const [skipped, setSkipped] = useState(new Set());


  const handleNextStep = () => {
    // Logic to move to the next step
    if (step < steps.length - 1) {
      setStep(step + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePreviousStep = () => {
    // Logic to move to the previous step
    if (step > 0) {
      setStep(step - 1);

      window.scrollTo(0, 0);
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleReset = () => {
    setStep(0);
  };

  const getStepComponent = () => {
    switch (step) {
      case 0:
        return <PersonalDetails onNextStep={handleNextStep} setAllData={setAllData} allData={allData} />;
      case 1:
        return (
          <BusinessDetails
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
            setAllData={setAllData}
            allData={allData}

          />
        );
      case 2:
        return <AccountDetails onPreviousStep={handlePreviousStep} setAllData={setAllData} allData={allData} />;
      default:
        return <WhyEkaiv />;
    }
  };

  return (
    <>
      <Box sx={{
        width: '90%', margin: 'auto', marginTop: 5,
        '@media (min-width: 600px)': {
          width: '70%',
        },
      }}>

        <Stepper activeStep={step} sx={{
          flexDirection: 'row',
          '@media (max-width:500px)': {
            flexDirection: 'column',
            alignItems: 'start',
            paddingX: '34%'

          },
          '@media (max-width:400px)': {
            flexDirection: 'column',
            alignItems: 'start',
            paddingX: '20%'

          },
        }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption" sx={{ lineHeight: '1', }}>
                </Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} sx={{
                  '@media (max-width:500px)': {
                    margin: '2px',

                  }
                }}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      {getStepComponent()}

      {step === 3 && <WhyEkaiv />}
    </>
  );
}

export default SellerRegistration;
