import { useState, useEffect } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL, config } from "../../config/config";
import { useParams } from "react-router-dom";
import Loader from "../common/Loader";
import { toast } from "react-hot-toast";

// const Loader = () => (
//   <div className="loader-container">
//     <div className="loader"></div>
//   </div>
// );
import { IoShareSocialSharp } from "react-icons/io5";
import ShareModal from "../Wishlist/ShareModal";
import { FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share";
import { FaCopy } from "react-icons/fa";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const SellerProfile = ({ isSuperAdmin }) => {
  const productsPerPage = 5;
  const loggedInUser = useSelector((state) => state.user); // Get the logged-in user from the Redux state
  const isAdmin = isSuperAdmin || (loggedInUser && loggedInUser.isAdmin);
  const { vendorId } = useParams();
  const [name, setName] = useState("");
  const [Businessname, setBusinessname] = useState("");
  const [about, setAbout] = useState("");
  const [ShopAvatar, setShopAvatar] = useState("");
  const [showFullText, setShowFullText] = useState(false);

  const [vendorProducts, setVendorProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [vendorName, setVendorName] = useState("Vendor");
  const [currentPage, setCurrentPage] = useState(1);

  // Define the total number of products per page

  useEffect(() => {
    fetch(`${API_URL}/api/vendor/getSpecificVendorById/${vendorId}`)
      .then((response) => response.json())
      .then((response) => {
        setName(response.vendor.name);
        setVendorName(response.vendor.Businessname);
        setShopAvatar(
          response.vendor.url
            ? response.vendor.url
            : "https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
        );
        setAbout(response.vendor.about);
        setBusinessname(response.vendor.Businessname);
      })
      .catch((error) => {
        console.error("error fetching data", error);
      });
  }, [vendorId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Use the vendorId obtained from the URL
        const response = await axios.get(
          `${API_URL}/api/vendor/${vendorId}/verifiedproducts`
        );
        setVendorProducts(response.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch data when the component mounts or when the vendorId changes
    fetchData();
  }, [vendorId, currentPage]);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage the visibility of the share modal

  // Update the useEffect hook for fetching data to include the productsPerPage constant
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);

  //       const response = await axios.get(
  //         `${API_URL}/api/vendor/${vendorId}/verifiedproducts?page=${currentPage}&limit=${productsPerPage}`
  //       );
  //       setVendorProducts(response.data);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // Fetch data when the component mounts, or when the vendorId or currentPage changes
  //   fetchData();
  // }, [vendorId, currentPage, productsPerPage]);

  // Function to handle sharing options
  const handleShareOptionClick = (option) => {
    const sellerProfileUrl = `https://theekaiv.in/seller-profile/${vendorId}`; // Construct the seller profile URL

    switch (option.label) {
      case "WhatsApp":
        // Share on WhatsApp
        return window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            vendorName + " " + sellerProfileUrl
          )}`,
          "_blank"
        );
      case "Facebook":
        // Share on Facebook
        return window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            vendorName + " " + sellerProfileUrl
          )}`,
          "_blank"
        );
      case "Twitter":
        // Share on Twitter
        return window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            vendorName + " " + sellerProfileUrl
          )}`,
          "_blank"
        );
      case "Copy Link":
        // Copy to Clipboard
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = sellerProfileUrl;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        toast.success("Link copied to clipboard");
        return;
      default:
        return;
    }
  };

  // Array of share options
  const shareOptions = [
    {
      icon: <WhatsappIcon size={32} round />,
      label: "WhatsApp",
      onClick: () => handleShareOptionClick("WhatsApp"),
    },
    {
      icon: <FacebookIcon size={32} round />,
      label: "Facebook",
      onClick: () => handleShareOptionClick("Facebook"),
    },
    {
      icon: <TwitterIcon size={32} round />,
      label: "Twitter",
      onClick: () => handleShareOptionClick("Twitter"),
    },
    {
      icon: <FaCopy />,
      label: "Copy Link",
      onClick: () => handleShareOptionClick("Copy Link"),
    },
  ];

  // Other component logic...

  // Function to handle opening the share modal
  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(vendorProducts.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    vendorProducts.length
  );

  // Filter the products to display only the ones for the current page
  const currentProducts = vendorProducts.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="w-full p-10 md:p-10 flex flex-wrap gap-2 justify-evenly">
        {/* Image and verified button */}
        {ShopAvatar && about ? (
          <>
            <div className="flex flex-col gap-5 lg:w-[30%]  w-full justify-center items-center">
              {/* Image */}
              <div className=" max-w-max lg:w-full w-full  md:w-1/2 h-auto object-contain shadow-md">
                <img src={ShopAvatar} alt="ShopAvatar Image" />
              </div>
              <div className="flex flex-row justify-between items-center gap-2">
                <>
                  {/* Your component JSX */}
                  <button
                    className="w-10 h-10 p-2 bg-purple-200 hover:bg-opacity-80 text-black rounded-lg text-xl flex items-center justify-center shadow-md hover:scale-105"
                    onClick={handleShareClick}
                  >
                    <IoShareSocialSharp />
                  </button>

                  {/* Render the share modal */}
                  {isModalOpen && (
                    <ShareModal onClose={() => setIsModalOpen(false)}>
                      {/* Render share options */}
                      {shareOptions.map((option) => (
                        <button
                          key={option.label}
                          className="flex flex-col items-center p-4 rounded-md bg-gray-100 hover:bg-gray-200 z-[1]"
                          onClick={() => {
                            handleShareOptionClick(option); // Call the onClick function of the selected share option
                            setIsModalOpen(false); // Close the modal after clicking
                          }}
                        >
                          {option.icon}
                          <span className="mt-2">{option.label}</span>
                        </button>
                      ))}
                    </ShareModal>
                  )}
                </>
                {/* Verified button */}
                <div className="p-2 bg-white max-w-max flex justify-center shadow-lg text-green-500 gap-2 font-extrabold rounded-md text-center item-center border-2 border-green-500">
                  <FontAwesomeIcon icon={faCheckCircle} className="my-auto " />
                  The Ekaiv Certified
                </div>
              </div>
            </div>

            <div className="lg:w-[60%] md:w-full w-full flex flex-wrap   justify-center lg:gap-0 md:gap-10 sm:gap-10 max-sm:gap-10">
              {/* About */}
              <div className="flex flex-col gap-4 lg:w-3/4 md:w-full sm:w-full max-sm:w-full p-5">
                <div className=" text-xl font-semibold text-[#8A58DC]">
                  {Businessname}
                </div>
                <div className=" break-words  text-sm text-black text-justify ">
                  {about}
                </div>
              </div>

              {/* Badges */}
              <div className="flex lg:flex-col md:flex-row gap-2  items-center justify-center lg:w-1/4 md:w-full sm:w-full max-sm:w-full sm:flex-row max-sm:flex-row">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FBadges%2FBurn%20Blue.png?alt=media&token=2b0df88d-19fb-4b4b-b819-5fa59df9d3ef"
                  alt="blue"
                  className="w-20  "
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FBadges%2FBurn%20Orange.png?alt=media&token=84d2d422-73f5-4c40-a844-8a3a933f0ccc"
                  alt="orange"
                  className="w-20 "
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets%2FBadges%2FBurn%20Purple.png?alt=media&token=94c95aac-acbc-479a-a4d5-4e75a3468676"
                  alt="orange"
                  className="w-20 "
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>

      {/* Check if there are products available */}
      {vendorProducts.length > 0 && (
        <>
          <div className="w-full pt-10 pl-10 pr-10 text-[#880081] text-2xl">
            <div className="border-b-2 border-b-[#880081] w-max">
              Vendor Products
            </div>
          </div>
          <div className="cards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 sm:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12 p-5 sm:p-6 lg:p-8 xl:p-10 max-w-full max-sm:max-w-screen-sm">
            {/* Render product cards */}
            {currentProducts.map((product, index) => (
              <ProductCard
                data={product}
                key={index}
                showWishlistAndCartButtons={true}
              />
            ))}
          </div>

          {/* Custom pagination */}
          <div className="flex justify-center mt-4 mb-4">
            {/* Left button */}
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button hover:bg-[#b788ca]  rounded-3xl"
            >
              <IoChevronBack />
            </button>

            {/* Page buttons */}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination-button  ${currentPage === index + 1 ? "active" : ""
                  }`}
              >
                {index + 1}
              </button>
            ))}

            {/* Right button */}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button hover:bg-[#b788ca] rounded-3xl"
            >
              <IoChevronForward />
            </button>
          </div>

          {/* CSS for the custom pagination */}
          <style>
            {`
            .pagination-button {
              margin: 0 0.5rem;
              padding: 0.5rem 1rem;
              border: 1px solid #ccc;
              cursor: pointer;
            }

            .pagination-button.active {
              background-color: #ccc;
            }
          `}
          </style>
        </>
      )}
    </>
  );
};

export default SellerProfile;
