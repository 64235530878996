import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { BiStoreAlt } from 'react-icons/bi';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import EditProduct from '../AddProduct/EditProduct';
import constant from '../../../constant';

const LOGO=constant.IMAGES_LOGO.LOGO;


const VendorMenuBar = ({ activeTab, handleTabClick }) => {
  const renderTabContent = (tabKey, label, icon) => {
    const isActive = activeTab === tabKey;



    return (
      <div

        className={`flex flex-col justify-center items-center h-[60px] min[310px]:h-[100px] sm:h-[100px] w-full border-b-2 border-gray-300 sm:hover:bg-gradient-to-r from-[#F16080] to-[#5156E9] sm:hover:text-white ${isActive ? 'active-tab sm:bg-gradient-to-r from-[#F16080] to-[#5156E9] text-white' : 'inactive-tab text-[#A9A9A9] sm:text-black'
          }`}

        onClick={() => handleTabClick(tabKey)}

      >

        {icon}


        <div className="flex flex-row">
          <span className="text-[10px] sm:text-base text-bold ">{label}</span>
          <span className="hidden sm:inline"><IoIosArrowForward className="pt-1 m-1" /></span>
        </div>
      </div>
    );
  };

  //mobile view



  return (
    <>
      <div className="hidden sm:flex sm:flex-col">
        <div className="w-full flex flex-col  sm:w-48 sticky sm:top-0 bg-white  ">
          {/* ... */}
          <Link to="/">
            <img src={LOGO} alt="Logo" className=" w-24 h-auto sm:w-auto sm:h-24 sm:ml-2 p-3" />
          </Link>

          <div className="w-full sticky cursor-pointer flex flex-row sm:flex-col">
            {renderTabContent('dashboard', 'Dashboard', <HomeIcon />)}
            {renderTabContent('store', 'Your Store', <BiStoreAlt className="justify-center items-center text-[22px] sm:text-base" />)}
            {renderTabContent('product', 'Manage Products', <ShoppingCartIcon className="justify-center items-center" />)}
            {renderTabContent('account', 'Account Settings', <ManageAccountsSharpIcon />)}
            {renderTabContent('report', 'Report', <AutoStoriesIcon />)}
            {renderTabContent('help', 'Need Help?', <SupportAgentIcon />)}

          </div>
        </div>
      </div>

      {/* mobile view */}

      <div >
        <div className="w-full sm:hidden flex flex-col  sm:w-48 sticky sm:top-0 bg-white  ">
          {/* ... */}
          <Link to="/">
            <img src={LOGO} alt="Logo" className=" w-24 h-auto sm:w-auto sm:h-24 sm:ml-2 p-3" />
          </Link>
        </div>
      </div>
      <div className='sm:hidden bg-white fixed bottom-0 w-full flex flex-col justify-around'>

        <div className=" w-full px-2 sticky cursor-pointer  flex flex-row sm:flex-col bg-gradient-to-r from-[#F16080] to-[#5156E9]  rounded-t-lg">
          {renderTabContent('dashboard', 'Dashboard', <HomeIcon className='justify-center items-center ' style={{ fontSize: "18px" }} />)}
          {renderTabContent('store', 'Store', <BiStoreAlt className=" justify-center items-center  " style={{ fontSize: "18px" }} />)}
          {renderTabContent('product', 'Products', <ShoppingCartIcon className=" justify-center items-center " style={{ fontSize: "18px" }} />)}
          {renderTabContent('account', 'Account', <ManageAccountsSharpIcon className='' style={{ fontSize: "18px" }} />)}
          {renderTabContent('report', 'Report', <AutoStoriesIcon className='' style={{ fontSize: "18px" }} />)}
          {renderTabContent('help', 'Help', <SupportAgentIcon className='justify-center items-center ' style={{ fontSize: "18px" }} />)}

        </div>
      </div>
    </>
  );
};

export default VendorMenuBar;