import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";

// // Get user from localStorage
// const saveUser =  localStorage.getItem('token') ? JSON.parse(atob(localStorage.getItem('token').split(".")[1])): null;
const token = localStorage.getItem('token');
let saveUser = null;
 if(token){
    const tokenParts = token.split(".");
    if (tokenParts.length < 2) {
      console.error("Invalid token format");
      // Handle the case where the token is not properly formatted
    } else {
      const t = tokenParts[1];
  
      try {
        saveUser = t ? JSON.parse(atob(t)) : null;
      } catch (error) {
        console.error("Error decoding token:", error);
        // Handle the decoding error
      }
    }
 }

const initialState = {
    auth: saveUser? saveUser : null,
    user :  null,
    cartsData:null,
    allOrdersData:null,
    isError : false,
    isLoading : false,
    isSuccess: false,
    message : "",
} 

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      reset2: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      },
      resetMessage: (state) => {
        state.message = "";
      },    
      
    },
    extraReducers : (builder) => {
        builder
        // .addCase(logout.fulfilled , (state, action)=>{
        //     state.user = null
        //     state.isLoading = false
        //     state.isSuccess = true
        //     state.isError = false
        //     state.message=action.payload.message
        // })
        .addCase(signup.pending , (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(signup.fulfilled , (state , action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(signup.rejected , (state , action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(getUser.pending , (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(getUser.fulfilled , (state , action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.isError = false
        })
        .addCase(getUser.rejected , (state , action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateUser.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(updateUser.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(updateUser.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(addAddress.pending,(state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(addAddress.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(addAddress.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteAddress.pending,(state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(deleteAddress.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(deleteAddress.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(editAddress.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(editAddress.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(editAddress.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(changePassword.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(changePassword.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(changePassword.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(createVendor.pending , (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(createVendor.fulfilled , (state , action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(createVendor.rejected , (state , action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
            // state.user = null
        })
        .addCase(addToCart.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(addToCart.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(addToCart.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateToCart.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(updateToCart.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(updateToCart.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteFromCart.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(deleteFromCart.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(deleteFromCart.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getDataFromCart.pending, (state,action)=>{
            state.isLoading = true
            state.isSuccess = false
            // state.message = action.payload.message
            state.isError = false
        })
        .addCase(getDataFromCart.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.cartsData = action.payload
            // state.message = action.payload.message
            state.isError = false
        })
        .addCase(addToWishlist.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(addToWishlist.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(addToWishlist.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteFromWishlist.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(deleteFromWishlist.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.user
            state.message = action.payload.message
            state.isError = false
        })
        .addCase(deleteFromWishlist.rejected, (state, action)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getAllOrders.pending, (state)=>{
            state.isLoading = true
            state.isError = false
            state.isSuccess = false
        })
        .addCase(getAllOrders.fulfilled, (state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.allOrdersData = action.payload
            state.isError = false
        })
        .addCase(getAllOrders.rejected, (state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
        })   

    }
})


export const signup = createAsyncThunk('/signup' , async(userData , thunkAPI)=>{

    try {
        return await authService.signup(userData) 
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }

})

export const login = createAsyncThunk('/signin' , async(userData , thunkAPI)=>{

    try {
        const resp=await authService.login(userData)
        return resp
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }

})

export const createVendor = createAsyncThunk('/createVendor/:id' , async(vendorData , thunkAPI)=>{

    try {
        const resp=await authService.createVendor(vendorData)
        return resp
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }

})




export const logout = createAsyncThunk("/signout" , async(thunkAPI)=>{
   
   try {
    return await authService.logout()
} catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message) 
}
})


export const getUser = createAsyncThunk("/getUser", async (thunkAPI) => {

    try {
        return await authService.getUser()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      
        if(error.response.data.status===404){
            localStorage.clear();
        }
      
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateUser =createAsyncThunk("/updateUser/:id" , async(newUserData, thunkAPI)=>{
   
    try {
     const data= await authService.updateUser(newUserData);
     return data;
    } catch (error) {
     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
     return thunkAPI.rejectWithValue(message) 
    }
})

export const addAddress=createAsyncThunk('/addAddress/', async( address , thunkAPI ) =>{
    try {
        const data= await authService.addAddress(address);
       
        return data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

export const deleteAddress=createAsyncThunk('/addAddress/:id/:addressId', async(id, thunkAPI)=>{
    try {
        const data= await authService.deleteAddress(id);
      
        return data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

export const editAddress=createAsyncThunk('/editAddress/:addressId', async(editedData, thunkAPI)=>{
    try {
        const data= await authService.editAddress(editedData);
     
        return data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})


export const changePassword=createAsyncThunk('/changePassword/:id', async(passwordData, thunkAPI)=>{
    try {
        const data= await authService.changePassword(passwordData);
     
        return data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

export const addToCart = createAsyncThunk('/addToCart/:id/:pId', async (newData, thunkAPI) => {
  try {
    const { id, pId, quantity,size,customizationMessage, customPay,customPrice,customizationId } = newData; // Extract quantity from newData
    const response = await authService.addToCart({ id, pId, quantity,size ,customizationMessage, customPay,customPrice,customizationId }); // Pass the quantity to your service function

    return response; // You can customize the response data as needed
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateToCart = createAsyncThunk('/updateCart/:pId', async (newData, thunkAPI) => {
    try {
      const { pId, quantity } = newData; // Extract quantity from newData
      const response = await authService.updateCart({ pId, quantity }); // Pass the quantity to your service function
  
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  

  export const deleteFromCart = createAsyncThunk('/deleteCartItem/:id/:pId', async (newData, thunkAPI) => {
    try {
      
      const response = await authService.deleteFromCart(newData);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const getDataFromCart = createAsyncThunk('/getCartItem/:id/:pId', async (newData, thunkAPI) => {
    try {
      
      const response = await authService.getDataFromCart(newData);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  
  


  export const addToWishlist = createAsyncThunk('/addToWishlist/:id/:pId', async (newData, thunkAPI) => {
    try {
      const response = await authService.addToWishlist(newData);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
      
    }
  });

  export const deleteFromWishlist = createAsyncThunk('/deleteWishlistItem/:id/:pId', async (newData, thunkAPI) => {
    try {
      const response = await authService.deleteFromWishlist(newData);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const getAllOrders = createAsyncThunk('/getAllOrders/', async (thunkAPI) => {
    try {
      const response = await authService.getAllOrders();
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

  export const forgotPasswordWithEmail = createAsyncThunk('/auth/forgotPasswordEmail/', async (data,thunkAPI) => {
    try {
      const response = await authService.forgotPasswordWithEmail(data);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  export const forgotPasswordWithPhone = createAsyncThunk('/auth/forgotPasswordPhone/', async (data,thunkAPI) => {
    try {
      const response = await authService.forgotPasswordWithPhone(data);
    
      return response; // You can customize the response data as needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });

export const {reset2, resetMessage} = authSlice.actions
export default authSlice.reducer
