import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import {
  getAllProducts,
} from "../../Redux/features/product/productsSlice";
import {API_URL} from '../../config/config';


const Footer = () => {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".footer-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);


  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const dispatch = useDispatch();

  const handleCategorySelect = (subcategory) => {

    
    if (subcategory === selectedCategory) {
      setSelectedCategory('');
    } else {
      setSelectedCategory(subcategory);

      // Replace the API URL with your actual endpoint
      const apiUrl = `${API_URL}/api/Products?selectedCategory=${subcategory}`;
     

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Handle the data as needed
         
        })
        .catch((error) => {
          // Handle errors
          console.error('Error fetching data:', error);
        });

      dispatch(getAllProducts({ selectedCategory: subcategory })).then(() => {
        // After the dispatch is completed, use navigate to redirect to the product page
        navigate(`/products/`);
      });
    }
  };



  useEffect(() => {
    // Replace the API URL with your actual endpoint
    fetch(`${API_URL}/api/admin-profile/getCategories`)
      .then((response) => response.json())
      .then((data) => {
    
        setCategoriesData(data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error fetching categories:', error);
      });
  }, []);



  return (
    <div className=" text-white" style={{
      background: 'rgb(255,107,103)',
      background: `linear-gradient(90deg, rgba(255,107,103,0.7) 14%, rgba(231,88,146,0.7) 24%, rgba(208,69,188,0.7) 42%, rgba(149,84,217,0.7) 65%, rgba(102,104,231,0.7) 84%, rgba(27,136,254,0.7) 100%)`
    }}>
      <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:px-8 px-5 py-16 lg:text-center">
        <div className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <br />

          <p>Where Creativity Meets Convenience <br />
            Your Destination for Unique Finds.
          </p>
          <div className="flex items-center mt-[15px] text-white ">

            <AiFillFacebook size={25} className="cursor-pointer hover:text-[#000000]" />
            <a href="https://twitter.com/TheEkaiv" target="_blank" rel="noopener noreferrer">
              <AiOutlineTwitter
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer", }}
                className="cursor-pointer hover:text-[#000000]"
              />
            </a>
            <a href="https://www.instagram.com/the_ekaiv/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
                className="cursor-pointer hover:text-[#000000]"
              />
            </a>
            <a href="https://www.youtube.com/@TheEkaiv" target="_blank" rel="noopener noreferrer">
              <AiFillYoutube
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
                className="cursor-pointer hover:text-[#000000]"
              />
            </a>
          </div>
        </div>

        <div className="text-center lg:text-start">
          <h1 className="mb-1 font-semibold">Company</h1>
          <div className=" flex flex-col">
            <Link
              to="/about-us"
              className="text-white hover:text-gray-900 duration-300 text-sm cursor-pointer leading-6 footer-link"
            >
              About Us
            </Link>
            <Link
              to="/carrer-page"
              className="text-white hover:text-gray-900 duration-300 text-sm cursor-pointer leading-6 footer-link"
            >
              Careers
            </Link>
            <Link

              to="/our-blog"

              className="text-white hover:text-gray-900 duration-300 text-sm cursor-pointer leading-6 footer-link"
            >
              Our Blog
            </Link>
            <Link
              to="/reviews"
              className="text-white hover:text-gray-900 duration-300 text-sm cursor-pointer leading-6 footer-link"
            >
              Reviews
            </Link>

          </div>
        </div>
        <ul className="text-center sm:text-start">

          <h1 className="mb-1 font-semibold">Support</h1>
          <div
            className=" flex flex-col "
          >
            <Link to='/faq' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">FAQ's</Link>
            
            
            <Link to='/contact-us' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Contact Us</Link>

            <Link to='/live-chat' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Live Chat</Link>

             <Link to='/become-seller' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Become A Seller</Link>       
                   

          </div>
        </ul>

        <div className="text-center lg:text-start text-white">
          <h1 className="mb-1 font-semibold">User Policy</h1>
          <div
            className=" flex flex-col "
          >
           
            <Link to='/terms-and-condition' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Terms & Conditions</Link>
            <Link to='/vendor-account-policy' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Vendor Account Policy</Link>
            <Link to='/shipping-policy' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Shipping Policy</Link>
            <Link to='/return-policy' className="text-white-700 hover:text-gray-900 duration-300
                   text-sm cursor-pointer leading-6 footer-link">Return Policy</Link>

           
          </div>
        </div>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2  gap-10
         text-center pt-2 text-white text-sm pb-8 w-full "
      >
        <div className="flex justify-start items-start px-10"><span>© 2023 GraphMind Technologies Pvt. Ltd. All rights reserved.</span></div>
        <div className=" w-full flex items-center justify-center md:items-end md:justify-end px-10">
          <Link to='/privacy-policy' className="footer-link "><span >Privacy Policy</span></Link>
        </div>
        {/* <div className="sm:block flex items-center justify-center w-full">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div> */}
      </div>
    </div>
  );
};

export default Footer;