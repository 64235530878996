import React,{useState, useRef} from 'react'
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {API_URL} from '../../config/config';

import constant from '../../constant';


const ZAIP=constant.IMAGES_IMAGES.ASSISTANCE.COMMUNITY_WELFARE.ZAIP;
const CAIT=constant.IMAGES_IMAGES.ASSISTANCE.COMMUNITY_WELFARE.CAIT;

function CommunityEngagement() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        businessType:'',
      });
    
      //email.js

  const formcommunity = useRef();
  
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }

    try {
      // Make an API call using axios
      const response = await axios.post(`${API_URL}/api/email/sendLegalAdvisoryEmail}`, formData);

      // Handle the API response as needed
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          location: '',
          businessType: '',
        });
      } else {
        // Handle unsuccessful response
        toast.error('Failed to submit form');
      }
    } catch (error) {
      // Handle API call error
      console.error('Error during API call:', error);
      toast.error('Failed to submit form');
    }
    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };
     
  return (
    <>
    <div className=' bg-gray-100 p-2 md:p-10'>
   
   <div className=' w-full flex justify-center items-center'>
   <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Community & Welfare</h2>
   </div>
    <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
        
        <div className=' flex flex-col justify-center items-center'>
          <div className=' bg-white rounded-2xl p-5 w-[100%] md:w-[70%]'>
      
      {/* <p className="text-gray-600">
        Information about the CA goes here. Contact us for assistance.
      </p> */}
      <form ref={formcommunity} onSubmit={sendEmail} className="mt-4 flex flex-col">
        <div className="mb-4">
          
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Name"
          />
        </div>
        <div className="mb-4">
          
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Email"
          /></div>
          <div className="mb-4">
          <input
            type="tel"
            id="phone"
            name="phone"
            pattern="[0-9]*"
                  maxLength={10}
            value={formData.phone}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericInput = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
              setFormData({ ...formData, phone: numericInput });
              setPhoneError(numericInput.length === 10 ? '' : 'Please enter a 10-digit phone number');
            }}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Phone"
          /></div>
          <div className="mb-4">
          <input
            type="location"
            id="location"
            name="location"
            value={formData.location}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Location"
          />
        </div>

        <div className="mb-4">
          
          <textarea
            id="businessType"
            name="businessType"
            value={formData.businessType}
            onChange={(e) => setFormData({ ...formData, businessType: e.target.value })}
            className={`form-textarea bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-2xl focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            rows="2"
            placeholder="Business Type & Details"
          ></textarea>
        </div>
        
        <button
          type="submit"
          className="px-4 py-2 bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 border-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
          disabled={buttonDisabled}
        >
          Join Us
        </button>
      </form>
      </div>
      {/* Add any other UI elements and functionality here */}
    </div>
        <div className='  md:order-2 py-20 md:py-0 px-2 md:px-10 lg:px-16'>
          <div>
          <p className=' p-2 text-justify '>
          Join our vibrant community and become part of a network that propels your business forward. Stay updated on local events, collaborate with fellow vendors, and tap into a supportive ecosystem that fosters growth. Together, we build a community that goes beyond transactions, creating lasting connections and opportunities.
          </p>
          </div>
          <div className=' flex flex-col sm:flex-row justify-center items-center w-full pt-10 '>
            <img src={ZAIP} alt="zaip" className=' h-32 w-32 p-2' />
            <img src={CAIT} alt="CAIT" className=' h-32 w-32 p-2'/>
          </div>
          

        </div>


</div>

    </div>
    
    </>
  )
}

export default CommunityEngagement
