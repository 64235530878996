import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Privacy() {
    
    useEffect(() => {
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        const links = document.querySelectorAll(".footer-link");
        links.forEach((link) => {
          link.addEventListener("click", scrollToTop);
        });
    
        return () => {
          // Remove event listeners when the component unmounts
          links.forEach((link) => {
            link.removeEventListener("click", scrollToTop);
          });
        };
      }, []);


    return (
        <div className='flex justify-center items-center bg-gray-100 text-justify '>
            <div className='w-[85%] pt-10 text-justify'>
                <div>
                    

                    <div className='text-center w-full font-bold text-xl p-5'>
                        Privacy Policy 
                    </div>
                    <div className=' break-words'>
                        <p>
                        At The Ekaiv, we prioritize your trust and are committed to maintaining the highest standards for secure transactions and customer information privacy. Please take a moment to review our information gathering and dissemination practices outlined in the following statement.
                        </p>
                        <br />
                        <p>
                        Note: Our privacy policy is subject to change without notice. To stay informed of any changes, please periodically review this policy. By accessing or using the Ekaiv website (https://www.theekaiv.com), you agree to be bound by the terms and conditions of this Privacy Policy. If you disagree, please refrain from using our website. Your use of the website implies your express consent to our use and disclosure of your personal information in line with this Privacy Policy. This Privacy Policy is an integral part of and subject to the Terms of Use.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>1. Collection of Personally Identifiable Information and other Information:</span><br />
                        When utilizing our website, we collect and store the personal information you provide to offer you a safe, efficient, and customized experience. This information helps us provide services and features tailored to your needs. You can browse the website anonymously, but certain services may require you to provide information. We may track information about your behavior on the website to better understand and serve our users. We use data collection devices, such as cookies, to analyze web page flow, measure promotional effectiveness, and enhance trust and safety.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>2. Use of Demographic/Profile Data/Your Information</span><br />
                        We use personal information to provide requested services, resolve disputes, troubleshoot problems, and enhance overall user experience. We may use demographic and profile data for internal analysis and to tailor your website experience. Your IP address is used for server diagnostics and to gather broad demographic information.
                       </p>
                        <br />
                        <p>
                        <span className=' font-bold'>3. Sharing of Personal Information:</span><br />
                        We may share personal information with corporate entities and affiliates. Such sharing may result in marketing unless you explicitly opt-out. Disclosure to third parties may occur to provide access to services, comply with legal obligations, enforce agreements, or prevent fraudulent activities. We may also share/sell personal information during mergers, acquisitions, or business restructuring, with the acquiring entity bound by this privacy policy.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>4. Links to Other Sites/Apps:</span><br />
                        Our website may link to other sites/apps collecting personally identifiable information. The Ekaiv is not responsible for the privacy practices or content of linked websites/apps.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>5. Security Precautions:</span><br />
                        The Ekaiv employs stringent security measures to protect information under our control from loss, misuse, and alteration. Secure servers are used for account information changes and access.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>6. Choice/Opt-Out:</span><br />
                        Users have the option to opt-out of non-essential communications. To remove your contact information from our lists and newsletters, please visit the unsubscribe link.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>7. Advertisements on the Website:</span><br />
                        Third-party advertising companies may serve ads on our website, using information about your visits for personalized advertising.
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>8. Your Consent:</span><br />
                        By using the website or providing information, you consent to the collection and use of disclosed information according to this Privacy Policy. Any changes to this policy will be posted on this page.      
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>9. Grievance Officer:</span><br />
                        In compliance with the Information Technology Act 2000, our Grievance Officer details are as follows:<br />
                        Anil Raghuwanshi <br />
                        GraphMind Technologies Pvt. Ltd. <br />
                        219, Jawahar Nagar <br />
                        Indore, 452012 <br />
                        services@theekaiv.in <br />
                        Time: Mon - Fri 10:00 - 19:00 Hrs <br />
                        </p>
                        <br />
                        <p>
                        <span className=' font-bold'>10. Questions:</span><br />
                        For any questions regarding this statement, please contact us.
                        Last updated date: 02/11/2023
                        </p>
                        <br />
                        {/* <p>
                        By continuing to use The Ekaiv App and Website, you explicitly agree to the terms outlined in this Privacy Policy. If any part of this policy requires clarification or if you have specific concerns, please contact us for further assistance.
                        </p>
                        <br />
                        <p>
                        Thank you for being a part of The Ekaiv community, and we value your trust in our platform!
                        </p>
                        <br/> */}
                        <p>
                        To visit our Terms and Conditions of use <Link to='/terms-and-condition' className=' footer-link text-indigo-500'>click here</Link>
                        <br />
                        <Link to='/contact-us'  className=' text-indigo-500 footer-link'>Contact us</Link> here.
                        <br />
                        <br/>

                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Privacy
