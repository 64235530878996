import React, { useState, useRef } from 'react'
import { toast } from 'react-hot-toast';

import axios from 'axios';
import {API_URL} from '../../config/config';


import constant from '../../constant';


const EMAIL_LOGO=constant.IMAGES_IMAGES.ASSISTANCE.WEB_APP_SOFTWARE_DEVELOPMENT.EMAIL_LOGO;
const PERCEPTIONS=constant.IMAGES_IMAGES.ASSISTANCE.WEB_APP_SOFTWARE_DEVELOPMENT.PERCEPTIONS;

function SAWdev() {
  const form = useRef();
  // const [showToast, setShowToast] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }

    const emailData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      requirement: formData.requirement,
      businessSpecifications: formData.businessSpecifications,
    };

    try {
   
      const response = await axios.post(`${API_URL}/api/email/sendSAWWebEmail`, emailData);
    
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirement: '',
          businessSpecifications: '',
        });
      } else {
        toast.error(response.data.message || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Internal Server Error');
    }
    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  } 
  


  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessSpecifications: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));

    
  };


  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name', // Placeholder text
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email', // Placeholder text
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone', // Placeholder text
    },

    {
      name: 'requirement',
      label: 'Requirement',
      type: 'text',
      placeholder: 'Requirements', // Placeholder text
    },
    {
      name: 'businessSpecifications',
      label: 'businessSpecifications',
      type: 'text',
      placeholder: 'Business Specifications before connecting', // Placeholder text
    },
    
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };
  return (
    <>
      <div className=' bg-gray-100 p-2 md:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Web / App / Software Development</h2>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
          <div className=' flex justify-center items-center'>
            <div className=' w-[100%] md:w-[70%]'>
              <form ref={form} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full md:w-1/2' : 'w-full'}`}>
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder}
                        className={`form-input px-4 py-2  ${field.name === 'requirement' ? 'rounded-2xl' : 'rounded-full'} shadow-sm mt-1 w-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                        maxLength={field.name === 'phone' ? 10 : undefined}
                        required
                      />
                    </div>
                  ))}
                </div>
                <button
                  type="submit"
                  className="mt-4 px-4 py-2 w-1/2 flex justify-center items-center bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
                  disabled={buttonDisabled}
                >
                  Submit 
                </button>
              </form>



              {/* Add any other UI elements and functionality here*/}
            </div>
          </div>

          <div className='  '>
            {/* <p className=' bg-rose-200 rounded-lg p-2 text-center '>
      Tell us about your unique requirements, and our team will guide you through the entire process of website, application, or software development. We focus on creating a seamless online presence for your business, ensuring that your digital platforms are not just functional but tailored to meet the specific needs of your growing enterprise.
      </p> */}

            <div class="container mx-auto min-[936px]:px-4 py-8 max-w-md">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-1 min-[868px]:gap-6">

                <div class=" bg-white rounded-lg p-2 shadow-lg flex justify-center items-center ">
                  <img src={PERCEPTIONS} alt="Perceptions Technologies" className=' object-contain w-[55%]' />
                </div>


                <div class=" bg-white rounded-lg text-center px-4 py-5 shadow-lg ">
                  {/* <h1 className=' font-bold text-lg'>Perceptions Technologies</h1> */}
                  <p className='pt-2 text-xs text-justify'>Tell us about your unique requirements, and our team will guide you through the entire process of website, application, or software development.</p>

                  {/* <p className="flex items-center justify-center">
  <span className="mr-2"><FaLocationDot /></span>
  Indore
</p> */}

                </div>


                <div class=" bg-white rounded-lg py-5 shadow-lg ">
                  {/* <h1 className="flex flex-col items-center text-xs">
  <FaPhoneSquareAlt size={18}/>
 <span className='pt-2'> +91 9620864378</span>
</h1> */}
                  <p class="text-xs px-4  text-justify">
                    We focus on creating a seamless online presence for your business, ensuring that your digital platforms are not just functional but tailored to meet the specific needs of your growing enterprise.
                  </p>
                </div>


                <div class=" bg-white rounded-lg px-1 py-4 shadow-lg flex justify-center items-center ">
                  <p className="flex flex-col items-center text-center text-xs ">
                    {/* <IoIosMail size={22} /> */}
                    <img src={EMAIL_LOGO} alt="Email Logo" className=' h-14 w-14' />
                    <span className='break-words'>services@ perceptionstechnologies.com</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default SAWdev
