// paymentHandler.js

import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL, config } from '../../config/config';

const handleOrderAndPayment = async (productsDetails, addressFormData, userDetails, setLoading) => {
    try {
    
        // Make an API call to your server to initiate PhonePe payment
        const response = await axios.post(`${API_URL}/api/payment/phonepay-payment`, {
            cartItems: productsDetails,
            shippingAddress: addressFormData,
            userDetails: userDetails,

        }, config);

    

        if (response && response.data && response.data.redirectUrl) {
            window.open(response.data.redirectUrl, '_blank');
        }
        

    } catch (error) {
        setLoading(false);
        console.error("Error during payment checkout:", error);
        if (error.response) {
            toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
        }
    }
};

export { handleOrderAndPayment };
