import React, { useState, useEffect } from 'react';

const Modal = ({ comment, onClose }) => {
    // Add event listener to prevent scrolling when modal is open
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        // Remove the event listener when the modal is unmounted
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-60 p-4">
            <div className="bg-white p-4 rounded-lg text-center relative sm:flex">
                <div className="flex-shrink-0 w-1/3 mx-auto">
                    <img
                        className="max-w-full h-auto"
                        src={comment.images[0]}
                        alt={` for ${comment.customerName}`}
                    />
                </div>
                <div className="flex-grow p-4 text-left">
                    <button
                        className="absolute top-0 right-0 p-2 text-gray-600 hover:text-red-600"
                        onClick={onClose}
                    >
                        &#x2716;{/* Close button (cross symbol) */}
                    </button>
                    <h1 className="text-2xl font-bold mb-2">{comment.customerName}</h1>
                    <p className="text-gray-600 mb-2">{comment.PurchaseDate}</p>
                    <h1 className={`text-white rounded-md p-1 w-fit ${comment.rating >= 4
                        ? 'bg-green-500'
                        : comment.rating === 3 || comment.rating === 2
                            ? 'bg-yellow-500'
                            : 'bg-red-500'
                        }`}
                    >
                        {comment.rating} <span role="img" aria-label="star">&#9733;</span>
                    </h1>
                    <p className="py-4">{comment.commentText}</p>
                </div>
            </div>
        </div>
    );
};

const ProductsComments = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);

    const openModal = (comment) => {
        setSelectedComment(comment);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedComment(null);
        setModalVisible(false);
    };

    // Replace this with actual data fetched from the backend
    const commentsFromBackend = [
        // Your comment objects from the backend should go here
    ];

    return (
        <div className="border-t-2 mx-auto">
            <div className="comment-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {commentsFromBackend.map((comment) => (
                    <div key={comment.id} className="p-5">
                        {/* Rating given by customer */}
                        <h1 className={`px-3 w-fit text-white ${comment.rating >= 4
                            ? 'bg-green-500'
                            : comment.rating === 3 || comment.rating === 2
                                ? 'bg-yellow-500'
                                : 'bg-red-500'
                            }`}>
                            {comment.rating} <span role="img" aria-label="star">&#9733;</span>
                        </h1>

                        {/* Comment */}
                        <p className="py-4">{comment.commentText}</p>

                        {/* Images if provided */}
                        {comment.images.length > 0 && (
                            <div className="py-2">
                                <img
                                    className="w-[100px] py-2 cursor-pointer"
                                    src={comment.images[0]}
                                    alt={` for ${comment.customerName}`}
                                    onClick={() => openModal(comment)} // Open modal on image click
                                />
                            </div>
                        )}

                        {/* Name of customer */}
                        <h3 className="mb-6 border-b-2 py-2">{comment.customerName} {comment.PurchaseDate}</h3>
                    </div>
                ))}
            </div>

            {/* Render the modal when it's visible */}
            {modalVisible && (
                <Modal comment={selectedComment} onClose={closeModal} />
            )}
        </div>
    );
};

export default ProductsComments;
