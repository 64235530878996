import React from 'react'
import { ImCross } from "react-icons/im";

function Cancel() {
  return (
    <div className='flex justify-center items-center h-screen bg-gray-100'>
        <div className='  md:w-[50%]'>
            <div className=''>
            <h1 className='text-lg md:text-2xl font-bold px-10 py-5 text-center'>Payment Unsuccessful</h1>
            {/* <p className='text-base md:text-xl font-bold px-10 text-center'></p> */}
            <div className='flex justify-center items-center py-5'>
                <span className=' bg-red-500 animate-bounce rounded-full  h-14 w-14 flex justify-center items-center '><ImCross  className=' text-white' size={20} /></span>
            
            </div>

            </div>

        </div>
      
    </div>
  )
}

export default Cancel
