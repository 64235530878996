import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddressCard from './AddressCard';
import AddAddressForm from './AddAddressForm';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import constant from "../../constant"

const ROBOT_ADDRESS=constant.IMAGES_IMAGES.USER_PROFILE.ROBOT_ADDRESS;
const YourAddresses = () => {
    
    const {user}=useSelector((state)=>state.auth);
    const [openAddressForm, openAddAddressForm]=useState(false);
    const [addressId, setAddressId]=useState(null);
   
  return (
    <>
    <Box sx={{width:"100%", padding:"5px"}}>
        {( !(user && user.address.length>0) && !openAddressForm)? (
        <Box sx={{width:"100%",display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center", gap:"5px"}}>
            <img src={ROBOT_ADDRESS} width='30%' height='30%'/>
            <Typography
            sx={{
                fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            lineHeight: '25px',
            color:"#4C4C4C"
            }}>No Address found in your account!</Typography>
            <Typography
            sx={{
                fontSize: '26px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            lineHeight: '25px',
            color:"#767575"
            }}>Add a Delivery Address</Typography>

        </Box>
        ):
        (!openAddressForm&& <AddressCard openAddAddressForm={openAddAddressForm}  setAddressId={setAddressId}/>)}
        {openAddressForm?<AddAddressForm  openAddAddressForm={openAddAddressForm} addressId={addressId} setAddressId={setAddressId}/>:
        (<Button
             variant="contained"
            size="small"
            onClick={()=>{openAddAddressForm(true)}}
            sx={{
              color: "#ffffff",
              borderRadius: "6px",
              borderColor:"#8A58DC",
              backgroundColor:"#8A58DC",
              marginTop:"30px",
              height: '36px',
              width: '150.023px',
              "&:hover": {
                borderColor: "#8A58DC !important",
                backgroundColor:"#8A58DC !important"
              },
              "&:active": {
                borderColor: "#8A58DC !important",
                backgroundColor:"#8A58DC !important"
              },
            }}
          >
            Add Address
          </Button>)}

    </Box>
    </>
  )
}

export default YourAddresses