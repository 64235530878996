import React, { lazy, Suspense } from 'react';
import Hero from "../component/Home/Hero"
import PopularProducts from "../component/Home/PopularProducts"
import FeaturedProduct from "../component/Home/FeaturedProduct"
import BrandingTag from '../component/Home/BrandingTag'
import Instagram from '../component/Home/Instagram'
import PromotinalVedio from '../component/Home/PromotinalVedio'
import FestiveCards from '../component/Home/FestiveCards'
const Categories = lazy(() => import('../component/Home/Categories'));

const HomePage = () => {
  return (
    <div className='w-[100%]'>
      <Hero />
      <FestiveCards/>
      <Suspense fallback={<div>Loading...</div>}>
        <Categories />
      </Suspense>
      
      <PopularProducts />
      <FeaturedProduct />
      {/* <PromotinalVedio/> */}
      <BrandingTag />
      <Instagram />
    </div>

  )
}

export default HomePage
