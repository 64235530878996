
import React, { useEffect, useState } from "react";
import OrderCards from "./OrderCards";

import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../Redux/features/auth/authSlice";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";


const YourOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
const productsPerPage = 3;
  const [selectedFilter, setSelectedFilter] = useState('NewOrders');
  const users=useSelector((state)=>state.auth);
  const LoggedInUserId = users &&  users.user && users.user._id
  const {user, allOrdersData}=useSelector((state)=>state.auth);
  const [orders, setOrders]=useState([]);
  const dispatch=useDispatch();
  

  useEffect(()=>{
    if(users.user){
    
      dispatch(getAllOrders())
    }
  }, [users.user])
 

  const filteredOrders = allOrdersData?.filter((order) => {
    const isLoggedInUserOrder = LoggedInUserId === order.userId;
    switch (selectedFilter) {
      case 'NewOrders':
        return isLoggedInUserOrder &&  order.orderStatus === 'NewOrders' && order.paymentStatus === 'completed';
      case 'InProcess':
        return isLoggedInUserOrder &&  order.orderStatus === 'InProcess' && order.paymentStatus === 'completed';
      case 'InTransit':
        return isLoggedInUserOrder &&  order.orderStatus === 'InTransit' && order.paymentStatus === 'completed';
      case 'Completed':
        return isLoggedInUserOrder &&  order.orderStatus === 'Completed' &&order.paymentStatus === 'completed';
      default:
        return true; // Return all orders if no specific filter matches
    }
  });

  
  // Calculate the total number of pages
  const totalPages = Math.ceil(allOrdersData?.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    allOrdersData?.length
  );

  // Filter the products to display only the ones for the current page
  const currentProducts = allOrdersData?.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };



  return (
    <div className="min-[500px]:p-2 h-max w-full flex-col cols-1">
      <div className="flex flex-row text-[9px] min-[500px]:text-xs sm:text-base justify-start items-start gap-1 min-[500px]:gap-5 mb-4">
        {/* <Button variant='text' sx={{ color: "#8A58DC" }} onClick={() => setSelectedFilter('all')}>Show All</Button> */}
        <button
      className={`rounded-md  py-1 px-2 ${
        selectedFilter === 'NewOrders' ? 'bg-[#8A58DC] text-white' : 'text-[#8A58DC]'
      }`}
      onClick={() => setSelectedFilter('NewOrders')}
    >
      New Order
    </button>
        <button
        className={`rounded-md  py-1 px-2 ${
          selectedFilter === 'InProcess' ? 'bg-[#8A58DC] text-white' : 'text-[#8A58DC]'
        }`}
        onClick={() => setSelectedFilter('InProcess')}
      >
        In Process
      </button>

      <button
        className={`rounded-md  py-1 px-2 ${
          selectedFilter === 'InTransit' ? 'bg-[#8A58DC] text-white' : 'text-[#8A58DC]'
        }`}
        onClick={() => setSelectedFilter('InTransit')}
      >
        Transit
      </button>

      <button
        className={`rounded-md  py-1 px-2 ${
          selectedFilter === 'Completed' ? 'bg-[#8A58DC] text-white' : 'text-[#8A58DC]'
        }`}
        onClick={() => setSelectedFilter('Completed')}
      >
        Delivered Orders
      </button>
       
      </div>
      {filteredOrders?.map((order, index) => (
        <>
        <OrderCards Key={index} productsDetails={order.productsDetails} key={order.id} orderStatus={order.orderStatus} createdAt={order.createdAt}  userAddress={order.shippingAddress}/>
        </>))}



    </div>
  );
};

export default YourOrders;