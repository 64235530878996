import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import AddressCard from './AddressCard';
import { addAddress, editAddress, getDataFromCart } from '../../Redux/features/auth/authSlice'
import BillingForm from './BillingForm';
import toast from 'react-hot-toast';
import ConstantData from '../../constant'
import { handleOrderAndPayment } from './handlePayment';
import { Link, useNavigate } from 'react-router-dom';

const Checkout = () => {
    const users = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isAgreed, setIsAgreed] = useState(true);


    /////////////// Order data's:-////////////////////////////

    // shipping address Data & their functionalities:-
    const [shippingAddress, setShippingAddress] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [isEditAddress, setIsEditAddress] = useState(false)
    const [isChange, setIsChange] = useState(false)
    const initialAddressData = {
        fullname: '',
        phoneNumber: '',
        flat: '',
        area: '',
        landmark: '',
        city: '',
        state: 'Madhya Pradesh',
        pinCode: '',
        country: 'India',
    }
    const [addressFormData, setAddressFormData] = useState(initialAddressData);

    // If edit address set form data to default That edit address values
    useEffect(() => {


        (users?.user?.address.length > 0 && shippingAddress) ? setAddressFormData({
            country: 'India',
            fullname: shippingAddress.fullname,
            phoneNumber: shippingAddress.phoneNumber,
            pinCode: shippingAddress.pinCode,
            flat: shippingAddress.flat,
            area: shippingAddress.area,
            landmark: shippingAddress.landmark,
            city: shippingAddress.city,
            state: shippingAddress.state
        }) : (
            isChange && setAddressFormData(initialAddressData)
        )

    }, [isEditAddress, shippingAddress])

    // Select address from address card
    const handleAddressSelect = (addressData) => {
        setShippingAddress(addressData);
        setIsEditAddress(false) //deselct edit
        setIsAgreed(false); // Deselect the checkbox
    };

    // Select handleEdit Address card
    const handleEditAddressSelect = (addressData) => {
        setShippingAddress(addressData);
        setIsEditAddress(true) // select to edit address
        setIsAgreed(true); // select the checkbox
    };

    // Change address selection / change new shipping address
    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
        // if (e.target.checked) {
        setAddressFormData(initialAddressData)

        setShippingAddress(null); // Reset selectedAddress to null when the checkbox is checked
        // }
    };

    //Handle address form input change
    const handleInputChange = (e) => {
        setIsChange(true)
        const { name, value } = e.target;

        let formattedValue = '';
        if (name === 'phoneNumber') {
            // Allow only digits and limit to a maximum length of 10
            formattedValue = value.replace(/\D/g, '').slice(0, 10);

            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        } else if (name === 'pinCode') {
            // Allow only numeric characters and limit to a maximum length of 6
            formattedValue = value.replace(/\D/g, '').slice(0, 6);
            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        }
        else if (['flat', 'landmark', 'area'].includes(name)) {
            // Limit to a maximum length of 40 for specific fields
            formattedValue = value.slice(0, 80);
            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        }
        else if (name === 'fullname') {
            // Limit to a maximum length of 40 for specific fields
            formattedValue = value.slice(0, 50);
            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        }
        else if (name === 'city') {
            // Limit to a maximum length of 40 for specific fields
            formattedValue = value.slice(0, 20);
            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        } else {
            formattedValue = value;
            setAddressFormData({
                ...addressFormData,
                [name]: formattedValue,
            });
        }

        // Clear the corresponding error when the user starts typing
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };

    // Validation function to check if address form fields are filled out
    const validateFormData = (data) => {
        const errors = {};
        for (const key in data) {
            if (data[key] === '' || data[key] === 'Select Country' || data[key] === 'Select State') {
                // Capitalize the first letter of the field name
                const fieldName = key.charAt(0).toUpperCase() + key.slice(1);
                errors[key] = `${fieldName} is required`;
            }
        }

        return errors;
    };

    // Save Edit & add address form data
    const handleSubmit = (e) => {
        e.preventDefault();

        if (addressFormData.phoneNumber.length !== 10) {
            toast.error("Please provide a valid phone number.")
            return;
        }
        if (addressFormData.pinCode.length !== 6) {
            toast.error("Please provide a valid pin code.")
            return;
        }
        const errors = validateFormData(addressFormData);
        if (Object.keys(errors).length === 0) {
            setFormErrors({});
            if (isEditAddress && shippingAddress) {
                // Edit shipping address
                dispatch(editAddress({ addressData: { ...addressFormData }, addressId: shippingAddress?._id })).then((resp) => {
                    if (resp.error) {
                        toast.error(resp.payload)
                    } else {
                        toast.success(resp.payload.message)
                        setAddressFormData(initialAddressData)
                        setShippingAddress(null);
                        setIsEditAddress(false)
                        setIsAgreed(false)
                    }
                })
            } else {
                // Add shippong address
                dispatch(addAddress({ ...addressFormData })).then((resp) => {
                    if (resp.error) {
                        toast.error(resp.payload)
                    } else {
                        toast.success(resp.payload.message)
                        setAddressFormData(initialAddressData)
                        setShippingAddress(null);
                        setIsEditAddress(false)
                        setIsAgreed(false)
                    }
                })
            }

        } else {
            setFormErrors(errors);
        }
    };

    // Close Address Form
    const closeForm = () => {
        setShippingAddress(null);
        setIsEditAddress(false) // select to edit address
        setIsAgreed(false); // select the checkbox
    }



    // Header images
    useEffect(() => {
        // Trigger the animation by updating the style after a short delay
        const timer = setTimeout(() => {
            if (imageRef.current) {
                imageRef.current.style.transform = 'translateX(0)';
            }
        }, 100); // Adjust the delay as needed

        return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }, []);



    // Cart product Order Data & their functionalities:-
    const [cartItems, setCartItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [stock, setStock] = useState(null);

    // To get all cart Items
    useEffect(() => {
        if (users.cartsData && users.cartsData.length >= 0) {
            setCartItems(users.cartsData);

            let total = 0;

            users.cartsData.forEach((item) => {
                const price = item.customPay && item.customPay === true ? item.customPrice : item.product.theEkaivDiscountPrice;
                total += price * item.quantity;
            });

            setSubTotal(total);
        }
    }, [users.cartsData]);


    useEffect(() => {
        const fetchCartItems = async () => {
            if (users?.user?.cartsItems?.length > 0) {
                try {
                    dispatch(getDataFromCart(users.user._id))
                    if (users.cartsData) {
                        setCartItems(users.cartsData)
                    }
                } catch (error) {
                    console.error(error);
                    toast('Something went wrong')
                }
            }
        };
        fetchCartItems();
    }, [users.user]);



    //   Make a productDetails for order:-
    const [productsDetails, setProductsDetails] = useState(null)
    useEffect(() => {
        if (cartItems?.length > 0) {
            const updatedProductDetails = [];
            for (const cartItem of cartItems) {
                const productDetails = {
                    productId: cartItem.product._id,
                    quantity: cartItem.quantity,
                    customization: cartItem.customization,
                    size: cartItem.size,
                    price: cartItem.product.theEkaivDiscountPrice,
                };

                updatedProductDetails.push(productDetails);
            }

            setProductsDetails(updatedProductDetails);
        }
    }, [cartItems]);


    //Get savings on each product
    function calculateSavingsPercentage(theEkaivPrice, theEkaivDiscountPrice) {
        const savings = theEkaivPrice - theEkaivDiscountPrice;
        const percentage = (savings / theEkaivPrice) * 100;
        return percentage.toFixed(2);
    }

    // Calculate total savings
    const calculateOverallSavings = () => {
        let overallSavings = 0;

        cartItems.forEach((product) => {
            // Skip items with customPay
            if (!product.customPay) {
                const savings = (product.product.theEkaivPrice - product.product.theEkaivDiscountPrice) * product.quantity;
                overallSavings += savings;
            }
        });

        return overallSavings;
    };


    // Shipping Charges and additional charges on product
    // const shippingCharges = ConstantData.data.SHIPPING_CHARGES;
    const additionalCharges = ConstantData.data.ADDITIONAL_CHARGES;
    const overallSavings = calculateOverallSavings();
    const overallSavingsPercentage = ((overallSavings / subTotal) * 100).toFixed(2);

    //Calculate total amount
    const calculateTotal = () => {
        const overallSavings = calculateOverallSavings();
        // const totalBeforeTax = subTotal - overallSavings;
        const total = additionalCharges + subTotal;
        return total;
    };
    const total = calculateTotal();


    // Go for payment
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [timer, setTimer] = useState(null);

    const handlePayment = async () => {
        // Check for shipping address
        if (!shippingAddress || !addressFormData) {
            toast.error("Please select shipping address.");
            return;
        }




        // Check for products
        if (!productsDetails) {
            toast.error("Cart is empty.")
            return;
        }

        // Set loading till payment not success
        if (!paymentLoading) {
           
            setPaymentLoading(true);
      
            const timeout = setTimeout(() => {
                setPaymentLoading(false);
              clearTimeout(timeout);
            }, 10000); 
      
            setTimer(timeout);
          }
          setButtonClicked(true);
        try {
            const userDetails = {
                fullname: users?.user?.fullname,
                email: users?.user?.email,
                contact: users?.user?.phone
            }
            // call function for payment
            await handleOrderAndPayment(productsDetails, addressFormData, userDetails, setLoading)

        } catch (error) {
            setLoading(false);
            toast.error(error);
        } finally {
           

            setLoading(false);
            setPaymentLoading(false);
            window.location.reload();
            

        }
       
    }

    

    return (
        <>
            {/* Header */}
            {/* image and text div */}
            {loading && <div className='w-full h-screen flex justify-center items-start'><Loader /></div>}
            {!loading && <><div className='justify-center items-center w-full flex flex-col' >

                <img
                    className='h-[300px]'
                    ref={imageRef}
                    style={{
                        transform: 'translateX(-100%)',
                        transition: 'transform 1s ease-in-out',
                    }}
                    src="https://img.freepik.com/premium-vector/hand-drawn-man-with-shopping-cart-flat-style_1375-17854.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
                    alt=""
                />
                <p className='font-bold text-3xl p-2 text-[#8459C7] animate-bounce' >Ready to Checkout...</p>
                <p className='font-semibold text-xl p-2'>Fill up the details correctly in order to get your desired product on time.</p>
            </div>

                {/* Body */}
                <div className='flex md:flex-row max-md:flex-col-reverse min-[420px]:p-5 gap-4 justify-between'>
                    <div className=' md:w-5/12 h-max p-5'>
                        <h1 className='font-bold mb-4 text-2xl'>Billing Details</h1>
                        {/* Address Card */}
                        <div
                            className={`flex p-2 flex-row gap-2 w-full mb-4 overflow-x-scroll no-scrollbar ${users?.user?.address?.length > 1 ? 'with-box-shadow' : ''
                                }`}
                        >
                            {users?.user?.address?.map((address, index) => (
                                <div key={index}>
                                    <AddressCard
                                        address={address}
                                        onSelect={() => handleAddressSelect(address)}
                                        onEditSelect={() => handleEditAddressSelect(address)}
                                        selected={address === shippingAddress}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Checkbox to change shiping address to new address */}
                        <div className="mt-4 flex items-center">
                            <input
                                type="checkbox"
                                id="agreeCheckbox"
                                checked={isAgreed}
                                onChange={handleCheckboxChange}
                                className="mr-2 mb-2"
                            />
                            <label htmlFor="agreeCheckbox" className='text-md mb-2'>Ship to a different address?</label>
                        </div>

                        {/* Form to add new shipping address */}
                        {isAgreed && (
                            <BillingForm
                                formData={addressFormData}
                                formErrors={formErrors}
                                handleInputChange={handleInputChange}
                                handleSubmit={handleSubmit}
                                closeForm={closeForm}
                                isAgreed={isAgreed}
                            />
                        )}

                        {/* Go for Payment */}
                        <div>
                            <span>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our</span>
                            <Link
                                to="/privacy-policy"
                                onClick={() => {
                                    navigate('/privacy-policy');
                                    window.scrollTo(0, 0);
                                }}
                                className='text-red-500'> privacy policy.</Link>
                        </div>

                        <button
                           
                            onClick={handlePayment}
                            // onClick={handleAddAddress}
                           
                            className={`mt-4 ${buttonClicked ? 'bg-[#F16080] hover:bg-[#FF6FAA]' : 'bg-[#8A58DC] '} text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline`}
                            disabled={paymentLoading}   
                        >
                            {paymentLoading ? 'Processing Payment...' : 'Process to payment'}
                        </button>

                    </div>


                    {/* Cart Product Details */}
                    <div className=' md:w-[55%] min-[420px]:p-5 h-max'>

                        <h2 className='font-bold text-2xl px-5 sm:px-0'>Your Cart</h2>
                        {/* laptop view */}
                        <div className='hidden sm:block border text-sm h-full max-h-[420px] p-5 rounded-md mt-5 font-bold overflow-y-auto scrollbar-hide'>
                            <div className='flex flex-row   max-[924px]:text-[10px] text-xs lg:text-sm   justify-between items-center text-center p-2'>
                                <h1 className='w-3/6 '>ITEMS</h1>
                                <h1 className='w-1/6 '>QUANTITY</h1>
                                <h1 className='w-1/6 '>SAVINGS</h1>
                                <h1 className='w-1/6 text-right'>PRICE</h1>
                            </div>
                            <div>
                                {cartItems.map((product, index) => (
                                    <div key={product.id} className='flex justify-between items-center mb-2 border rounded-md gap-2 p-2'>
                                        <div>
                                            <img
                                                src={product.product.images[0]}
                                                alt={product.product.imagesAlt}
                                                className="h-24 w-24  object-contain"
                                            />
                                        </div>


                                        <div className='w-5/6 flex justify-between items-center p-2'>
                                            <div className='flex flex-col w-2/6'>
                                                <span className='w-1/4'>{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 27)}...` : product.product.productName}</span>
                                                {stock === 0 ? (
                                                    <p className='text-red-500 font-bold'>OUT OF STOCK</p>
                                                ) : null}

                                            </div>
                                            <span className='w-1/5  text-center'>{product.quantity}</span>

                                            {!product.customPay && (
                                                <span className='w-1/5 text-center text-green-500'>
                                                    {calculateSavingsPercentage(product.product.theEkaivPrice, product.product.theEkaivDiscountPrice)}% OFF
                                                    <br />
                                                    <strike className="text-black opacity-30">₹{product.product.theEkaivPrice.toFixed(2)}</strike>
                                                </span>
                                            )}




                                            <span className='w-1/5 text-right'>₹{product.customPay ? product.customPrice.toFixed(2) : product.product.theEkaivDiscountPrice.toFixed(2)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* mobile view */}
                        <div className='block sm:hidden border text-sm h-full max-h-[420px] p-5 rounded-md mt-5 font-bold overflow-y-auto scrollbar-hide'>

                            <div>
                                {cartItems.map((product, index) => (
                                    <div key={product.id} className='flex justify-between items-center mb-2 border rounded-md gap-2 p-2'>

                                        <div>
                                            <h1 className=' text-xs text-gray-500'>ITEMS:</h1>
                                            <img
                                                src={product.product.images[0]}
                                                alt={product.product.imagesAlt}
                                                className="h-24 w-24  object-contain"
                                            />
                                        </div>


                                        <div className='w-5/6 flex flex-col sm:flex-row justify-between items-center p-2'>
                                            <div className='flex flex-col w-full'>
                                                <span className='w-full '>{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 27)}...` : product.product.productName}</span>
                                                {stock === 0 ? (
                                                    <p className='text-red-500 font-bold'>OUT OF STOCK</p>
                                                ) : null}

                                            </div>
                                            <span className='w-full gap-1 flex'><h1 className=' text-[10px] text-gray-400'>QUANTITY: </h1> {product.quantity}</span>

                                            {!product.customPay && (
                                                <span className='w-full gap-1  flex text-green-500'>
                                                    <h1 className=' text-[10px] text-gray-400'>SAVINGS: </h1>
                                                    {calculateSavingsPercentage(product.product.theEkaivPrice, product.product.theEkaivDiscountPrice)}% OFF
                                                    <br />
                                                    <strike className="text-black opacity-30">₹{product.product.theEkaivPrice.toFixed(2)}</strike>
                                                </span>
                                            )}



                                            <span className='w-full gap-1 flex'>
                                                <h1 className=' text-[10px] text-gray-400'>PRICE: </h1>
                                                ₹{product.customPay ? product.customPrice.toFixed(2) : product.product.theEkaivDiscountPrice.toFixed(2)}
                                            </span>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* end */}

                        <hr className='mt-4 w-full border-dashed text-gray-500 text-bold p-2' />
                        <div className='mt-4 flex flex-row justify-between items-center p-2 mr-5 font-semibold'>
                            <div className='flex flex-col mb-4 text-start'>
                                <span>SUBTOTAL</span>
                                <span>TOTAL SAVINGS</span>
                                <span>SHIPPING CHARGES</span>

                            </div>

                            <div className='flex flex-col mb-4 text-end'>
                                <span>₹{subTotal}</span>
                                <span>₹{overallSavings}</span>
                                <span>FREE SHIPPING</span>
                            </div>
                        </div>

                        <hr className='w-full text-black opacity-60 font-bold p-2 mb-2 border-dashed' />
                        <div className='flex justify-between items-center font-bold p-2'>
                            <span>TOTAL</span>
                            <span className=' mr-5'>₹{total}</span>
                        </div>
                        <hr className='w-full text-black opacity-60 font-bold p-2 mt-2 border-dashed' />


                    </div>

                </div></>}
        </>
    )
}

export default Checkout