import axios from 'axios'

import { API_URL , config } from '../../../config/config';


const getVendor = async(data)=>{
try {
    const response = await axios.get(`${API_URL}/api/vendor/getVendor/${data.vendorId}`, config )
    if(response.data){
     localStorage.setItem('vendor' , JSON.stringify(response.data.vendor));
    }
    return response.data
}
 catch (error) {
    // Handle error appropriately (e.g., log it, show a user-friendly message)
    console.error('Error in getVendor:', error);
    throw error; // Rethrow the error to be handled at a higher level if needed
  }
}




const updateVendorPersonal = async (newUserData) => {
    try {
      
      const response = await axios.put(`${API_URL}/api/vendor/updateVendor/Personal/${newUserData.vendorId}` , config , newUserData);
      
      if(response.data){
        localStorage.setItem('vendor' , JSON.stringify(response.data));
       }
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('Error in updateVendorPersonal:', error);
      throw error;
    }
  };
  const updateVendorBusiness = async (newUserData) => {
    try {
      
      const response = await axios.put(`${API_URL}/api/vendor/updateVendor/Business/${newUserData.vendorId}` , config , newUserData);
      
      if(response.data){
        localStorage.setItem('vendor' , JSON.stringify(response.data));
       }
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('Error in updateVendorBusiness:', error);
   
      throw error;
    }
  };
  
  const updateVendorAccount = async (newUserData) => {
    try {
      
      const response = await axios.put(`${API_URL}/api/vendor/updateVendor/Account/${newUserData.vendorId}`, config , newUserData);
      
      if(response.data){
        localStorage.setItem('vendor' , JSON.stringify(response.data));
       }
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('Error in updateVendorAccount:', error);
      throw error;
    }
  };

  const deleteProduct = async (data) => {
    try {
      const response = await axios.delete(`${API_URL}/api/vendor/${data.vendorId}/products/${data.productId}` , config , data);
      
      if(response.data){
        localStorage.setItem('vendor' , JSON.stringify(response.data));
       }
      
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('Error in deleteProduct:', error);
      throw error;
    }
  };

const vendorService = {
    getVendor,
    updateVendorPersonal,
    updateVendorBusiness,
    updateVendorAccount,
    deleteProduct,
}

export default vendorService;