import React, { useState, useEffect } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { GiScaleMail, GiDoubleNecklace, GiBedLamp, GiWaterBottle } from "react-icons/gi";
import { TbCategory } from "react-icons/tb";
import { IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { API_URL, config } from "../../../config/config";

const VendorVerify = ({ savedMobileNumber }) => {

    const params = useParams();
    // const [selectAll, setSelectAll] = useState(false);
    const [vendors, setVendors] = useState(null)
    const [notVerifiedReasons, setNotVerifiedReasons] = useState("");
    const navigate = useNavigate();
    // personal details

    const [fullName, setFullName] = useState("");
    const [mobileNumber, setMobileNumber] = useState(savedMobileNumber || "");
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [data, setData] = useState(null)
    const [fieldsEmpty, setFieldsEmpty] = useState(false);

    // business details 
    const [selectedTypeOfBusiness, setSelectedTypeOfBusiness] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [category, setCategory] = useState("");
    const [showInfo, setShowInfo] = useState({});
    const [categoriesEmpty, setcategoriesEmpty] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [businessAddressLine1, setBusinessAddressLine1] = useState("");
    const [businessAddressLine2, setBusinessAddressLine2] = useState("");
    const [landmark, setLandmark] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [documentsUploaded, setDocumentsUploaded] = useState({
        businessRegistration: false,
        businessAddressProof: false,
        foodLicenseProof: false,
    });
    const [businessRegistration, setbusinessRegistration] = useState(null)
    const [foodLicenseProof, setfoodLicenseProof] = useState(null)
    const [businessAddressProof, setbusinessAddressProof] = useState(null)
    const [typeOfBusiness, setselectedTypeOfBusiness] = useState([]);

    const [typeOfBusinessEmpty, settypeOfBusinessEmpty] = useState(false);
    // const [businessRegistrationUploaded, setBusinessRegistrationUploaded] = useState(false);
    // const [businessAddressProofUploaded, setBusinessAddressProofUploaded] = useState(false);
    // const [foodLicenseProofUploaded, setFoodLicenseProofUploaded] = useState(false);
    const categories = [
        { id: 'FASHION', icon: <GiScaleMail className="text-[50px]" />, name: 'FASHION' },
        { id: 'ACCESSORIES', icon: <GiDoubleNecklace className="text-[50px]" />, name: 'ACCESSORIES' },
        { id: 'HOME DECOR', icon: <GiBedLamp className="text-[50px]" />, name: 'HOME DECOR' },
        { id: 'FOOD', icon: <GiWaterBottle className="text-[50px]" />, name: 'FOOD' },
        { id: 'OTHER', icon: <TbCategory className="text-[50px]" />, name: 'OTHER' },
        // Add more categories as needed
    ];
    const handlebusinessRegistration = (event) => {
        const file = event.target.files[0];
        TransformPdf(file, setbusinessRegistration);
    };

    const handlefoodLicenseProof = (event) => {
        const file = event.target.files[0];
        TransformPdf(file, setfoodLicenseProof);
    };

    const handlebusinessAddressProof = (event) => {
        const file = event.target.files[0];
        TransformPdf(file, setbusinessAddressProof);
    };

    // Add a new function to transform PDF files
    const TransformPdf = (pdfFile, setPdfState) => {
        const reader = new FileReader();
        reader.readAsDataURL(pdfFile);
        reader.onload = () => {
            setPdfState(reader.result);
        };
    };
    const [about, setAbout] = useState("");

    // Account Details

    // State variables for input field values and error messages
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [branch, setBranch] = useState("");
    const [bankCity, setBankCity] = useState("");
    const [bankState, setBankState] = useState("");
    const [cifNumber, setCifNumber] = useState("");
    const [bankPassbookUploaded, setBankPassbookUploaded] = useState(false);


    const toggleInfo = (category) => {
        if (selectedCategory === category) {
            // Clicked on the same category, close it
            setSelectedCategory(null);
        } else {
            // Clicked on a different category, open it
            setSelectedCategory(category);
            setShowInfo({ [category]: true }); // Open the info for the new category
        }
    };

    useEffect(() => {
        if (vendors) {
            setFullName(vendors.fullName);
            setMobileNumber(vendors.mobileNumber);
            setAlternateMobileNumber(vendors.alternateMobileNumber);
            setEmail(vendors.email);
            setDateOfBirth(vendors.dateOfBirth);
            setGender(vendors.gender);
        }
    }, [params, vendors])

    useEffect(() => {
        const fetchVendorDetails = async () => {
            try {
                if (!params) {
                    console.error('vendor or vendorId is undefined');
                    return;
                }
                const response = await axios.get(`${API_URL}/api/vendor/getVendor/${params.itemId}`, config);
                setData(response.data.vendor);

            } catch (error) {
                console.error('Error fetching vendor details:', error);
            }
        };

        fetchVendorDetails();
    }, [params]);


    const handleVerify = async () => {
        try {
            const response = await axios.put(`${API_URL}/api/admin-profile/vendors/${params.itemId}/verify`);
            toast.success("Vendor Verified sucessfully")

        } catch (error) {
            if (error.response) {
                toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
            }
            console.error('Error verifying vendor:', error);
        }
        window.scrollTo(0, 0);
        navigate(-1);
    };

    const handleReject = async () => {
        if (!notVerifiedReasons) {
            // If the "Not Verified Reasons" field is not filled, show a toast message and return
            toast.error("Please provide a reason for rejection");
            return;
        }

        try {
            // Proceed with rejection if the field is filled
            const response = await axios.put(`${API_URL}/api/admin-profile/vendors/${params.itemId}/reject`, {
                reasons: notVerifiedReasons,
            });

            toast.success("Vendor Rejected successfully");
            // Handle rejection response as needed
        } catch (error) {
            toast.error("Error Rejecting Vendor");
            console.error('Error rejecting vendor:', error);
        }

        // Navigate back after handling rejection
        window.scrollTo(0, 0);
        navigate(-1);
    };



    return (
        <>
            <div className='lg:flex flex-row justify-center items-start'>

                <div className="felx flex-col">
                    {/* Personal Details */}

                    <div className='flex md:flex-row items-start justify-evenly gap-10 p-10 sm:flex-col flex-col'>
                        {/* left div */}
                        <div className="font-bold md:sticky top-10 lg:w-full">
                            <h1>Personal Details</h1>
                        </div>
                        <form action="">
                            <div className="flex flex-col gap-7 mb-4 md:w-[500px]">
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Full Name</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.name}
                                            onChange={() => setFullName(!data?.PersonalDetails?.name)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Enter Your Name *"
                                            value={data?.PersonalDetails?.name}
                                            onChange={(e) => setFullName(e.target.value)}
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                        />
                                        {data?.PersonalDetails?.name && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Phone Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.phoneNumber}
                                            onChange={() => setMobileNumber(!data?.PersonalDetails?.phoneNumber)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Enter Mobile Number *"
                                            value={data?.PersonalDetails?.phoneNumber}
                                            maxLength={10}
                                            className={`w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] ${data?.PersonalDetails?.phoneNumber === savedMobileNumber ? 'bg-gray-300 cursor-not-allowed' : ''
                                                }`}
                                        />
                                        {data?.PersonalDetails?.phoneNumber && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Alternate Phone Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.alternateNumber}
                                            onChange={() => setAlternateMobileNumber(!data?.PersonalDetails?.alternateNumber)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Enter Alternate Mobile Number *"
                                            value={data?.PersonalDetails?.alternateNumber}
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                        />
                                        {data?.PersonalDetails?.alternateNumber && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Email</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.email}
                                            onChange={() => setEmail(!data?.PersonalDetails?.email)}
                                        />
                                        <input
                                            type="email"
                                            value={data?.PersonalDetails?.email}
                                            placeholder="Email Id *"
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                        />
                                        {data?.PersonalDetails?.email && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Date Of Birth</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.dateOfBirth}
                                            onChange={() => setDateOfBirth(!data?.PersonalDetails?.dateOfBirth)}
                                        />
                                        <input
                                            type="date"
                                            placeholder="Date of Birth *"
                                            value={data?.PersonalDetails?.dateOfBirth ? new Date(data?.PersonalDetails?.dateOfBirth).toISOString().split('T')[0] : ''}
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            onChange={(e) => setDateOfBirth(e.target.value)}
                                        />

                                        {data?.PersonalDetails?.dateOfBirth && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Gender</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.PersonalDetails?.gender}
                                            onChange={() => setGender(!data?.PersonalDetails?.gender)}
                                        />
                                        <input
                                            type="text"
                                            value={data?.PersonalDetails?.gender}
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                        />
                                        {data?.PersonalDetails?.gender && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                    {/* Business Details */}
                    <div className="flex md:flex-row items-start justify-evenly gap-10 p-10 sm:flex-col flex-col">
                        {/* left div */}
                        <div className="font-bold md:sticky top-10 lg:w-full">
                            <h1>Business Details</h1>
                        </div>
                        <form action="">
                            <div className="flex flex-col gap-7 mb-4 md:w-[500px]">
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Business Name</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.Businessname}
                                            onChange={() => setBusinessName(!data?.BussinessDetails?.Businessname)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Enter Business Name *"
                                            className="w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.Businessname}
                                        />
                                        {data?.BussinessDetails?.Businessname && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Business Email</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessEmail}
                                            onChange={() => setBusinessEmail(!data?.BussinessDetails?.BusinessEmail)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Email Id (Business) *"
                                            className="flex p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                                            value={data?.BussinessDetails?.BusinessEmail}
                                        />
                                        {data?.BussinessDetails?.BusinessEmail && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Business Address Line 1</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.addressLine1}
                                            onChange={() => setBusinessAddressLine1(!data?.BussinessDetails?.BusinessAddress?.addressLine1)}
                                        />

                                        <input
                                            type="text"
                                            placeholder="Business Address Line1 *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.addressLine1}
                                        />
                                        {data?.BussinessDetails?.BusinessAddress?.addressLine1 && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Business Address Line 2</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.addressLine2}
                                            onChange={() => setBusinessAddressLine2(!data?.BussinessDetails?.BusinessAddress?.addressLine2)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Business Address Line2 *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.addressLine2}
                                        />
                                        {data?.BussinessDetails?.BusinessAddress?.addressLine2 && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Landmark</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.landmark}
                                            onChange={() => setLandmark(!data?.BussinessDetails?.BusinessAddress?.landmark)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Landmark *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.landmark}
                                        /> {data?.BussinessDetails?.BusinessAddress?.landmark && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">City</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.city}
                                            onChange={() => setCity(!data?.BussinessDetails?.BusinessAddress?.city)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="City *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.city}
                                        />
                                        {data?.BussinessDetails?.BusinessAddress?.city && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">State</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.state}
                                            onChange={() => setState(!data?.BussinessDetails?.BusinessAddress?.state)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="State *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.state}
                                        />
                                        {data?.BussinessDetails?.BusinessAddress?.state && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Pincode</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.BusinessAddress?.pincode}
                                            onChange={() => setPincode(!data?.BussinessDetails?.BusinessAddress?.pincode)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Pincode *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.BussinessDetails?.BusinessAddress?.pincode}
                                        />
                                        {data?.BussinessDetails?.BusinessAddress?.pincode && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Pan Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.panNumber}
                                            onChange={() => setPanNumber(!data?.BussinessDetails?.panNumber)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="PAN Number *"
                                            className="p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                                            value={data?.BussinessDetails?.panNumber}
                                        />
                                        {data?.BussinessDetails?.panNumber && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">GST Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.gstNumber}
                                            onChange={() => setGstNumber(!data?.BussinessDetails?.gstNumber)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="GST Number *"
                                            className="p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                                            value={data?.BussinessDetails?.gstNumber}
                                        />
                                        {data?.BussinessDetails?.gstNumber && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>


                            {/* ------------CATAGORIES------------ */}
                            <div className="flex flex-col gap-2">
                                <div>
                                    <label className="font-semibold flex-wrap">Category</label>
                                </div>
                                <div className="flex flex-row gap-2 justify-between items-center w-full">
                                    <div className="font-bold mb-4 flex flex-row">
                                        <input
                                            type="checkbox"
                                            checked={data?.BussinessDetails?.typeOfBusiness}
                                            onChange={() => setCity(!data?.BussinessDetails?.typeOfBusiness)}
                                        />
                                        <div className="flex flex-wrap ml-4 gap-2 mt-4">
                                            {data?.BussinessDetails?.typeOfBusiness?.map((category, index) => (
                                                <span key={category} className={`bg-gray-200 rounded-md p-2 ${index > 2 ? 'mt-2' : ''} ${index > 5 ? 'hidden' : ''}`}>
                                                    {category.toUpperCase()}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        {data?.BussinessDetails?.typeOfBusiness && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>


                            {/* //NOTE - Document */}

                            <div className="flex flex-col md:flex-row gap-x-6 md:items-center mb-4 justify-between">
                                <input
                                    type="checkbox"
                                    checked={data?.BussinessDetails?.businessRegistration}
                                    onChange={() => setbusinessRegistration(!data?.BussinessDetails?.businessRegistration)}
                                />
                                <label className="font-semibold md:w-48">Business Registration</label>
                                <div className="flex items-center">

                                    <Link to={data?.BussinessDetails?.businessRegistration} target="_blank" rel="noopener noreferrer">
                                        <button type="button" onClick={() => {
                                            window.open(data?.BussinessDetails?.businessRegistration, '_blank');
                                        }} className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                                    </Link>

                                </div>
                                {data?.BussinessDetails?.businessRegistration && (
                                    <label className="text-green-400 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>


                            <div className="flex flex-col md:flex-row gap-x-6 md:items-center mb-4 justify-between">
                                <input
                                    type="checkbox"
                                    checked={data?.BussinessDetails?.businessAddressProof}
                                    onChange={() => setbusinessAddressProof(!data?.BussinessDetails?.businessAddressProof)}
                                />
                                <label className="font-semibold md:w-48">Business Address</label>
                                <div className="flex items-center">
                                    <Link to={data?.BussinessDetails?.businessAddressProof} target="_blank" rel="noopener noreferrer">
                                        <button type="button" onClick={() => {
                                            window.open(data?.BussinessDetails?.businessAddressProof, '_blank');
                                        }} className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                                    </Link>
                                </div>
                                {data?.BussinessDetails?.businessAddressProof && (
                                    <label className="text-green-400 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>

                            {data?.BussinessDetails?.typeOfBusiness.includes("homemade-food") && (
                                <div className="flex flex-col md:flex-row gap-x-6 md:items-center mb-4 justify-between">
                                    <input
                                        type="checkbox"
                                        checked={data?.BussinessDetails?.foodLicenseProof}
                                        onChange={() => setfoodLicenseProof(!data?.BussinessDetails?.foodLicenseProof)}
                                    />
                                    <label className="font-semibold md:w-48">Food License</label>
                                    <div className="flex items-center">

                                        <Link to={data?.BussinessDetails?.foodLicenseProof} target="_blank" rel="noopener noreferrer">
                                            <button type="button" onClick={() => {
                                                window.open(data?.BussinessDetails?.foodLicenseProof, '_blank');
                                            }} className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                                        </Link>

                                    </div>
                                    {data?.BussinessDetails?.foodLicenseProof && (
                                        <label className="text-green-400 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            )}

                            <div className="flex flex-col gap-2 mt-2">
                                <div>
                                    <label className="font-semibold flex-wrap">About</label>
                                </div>
                                <div className="flex flex-row gap-2 justify-center items-center w-full">
                                    <input
                                        type="checkbox"
                                        checked={data?.BussinessDetails?.about}
                                        onChange={() => setAbout(!data?.BussinessDetails?.about)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="About *"
                                        className="p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC] w-full"
                                        value={data?.BussinessDetails?.about}
                                    />
                                    {data?.BussinessDetails?.about && (
                                        <label className="text-green-400 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>

                        </form >
                    </div >

                    {/* Account Details */}
                    < div className="flex flex-col md:flex-row items-start justify-evenly gap-10 p-10 sm:flex-col" >
                        <div className="font-bold md:sticky top-10 lg:w-full">
                            <h1>Account Details</h1>
                        </div>
                        {/* left div */}
                        <form onSubmit="">
                            <div className="flex flex-col gap-7 mb-10 md:w-[500px]">
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Account Holder Name</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.AccountHolderName}
                                            onChange={() => setAccountHolderName(!data?.AccountDetais?.AccountHolderName)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Account Holder Name *"
                                            className="w-full h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC] "
                                            value={data?.AccountDetais?.AccountHolderName}
                                        />
                                        {data?.AccountDetais?.AccountHolderName && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Account Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.AccountNumber}
                                            onChange={() => setAccountNumber(!data?.AccountDetais?.AccountNumber)}
                                        />

                                        <input
                                            type="text"
                                            placeholder="Account Number *"
                                            className="w-full h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.AccountNumber}
                                        />
                                        {data?.AccountDetais?.AccountNumber && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">IFSC Code</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.IFSCCode}
                                            onChange={() => setIfscCode(!data?.AccountDetais?.IFSCCode)}
                                        />

                                        <input
                                            type="text"
                                            placeholder="IFSC Code *"
                                            className="w-full h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.IFSCCode}
                                        />
                                        {data?.AccountDetais?.IFSCCode && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">Branch</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.Branch}
                                            onChange={() => setBranch(!data?.AccountDetais?.Branch)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Branch *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.Branch}
                                        />
                                        {data?.AccountDetais?.CIFNumber && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">CIF Number</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.CIFNumber}
                                            onChange={() => setCifNumber(!data?.AccountDetais?.CIFNumber)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="CIF Number *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.CIFNumber}
                                        />
                                        {data?.AccountDetais?.CIFNumber && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">City</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.City}
                                            onChange={() => setBankCity(!data?.AccountDetais?.City)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="City *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.City}
                                        />
                                        {data?.AccountDetais?.City && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div>
                                        <label className="font-semibold flex-wrap">State</label>
                                    </div>
                                    <div className="flex flex-row gap-2 justify-center items-center w-full">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.State}
                                            onChange={() => setBankState(!data?.AccountDetais?.State)}
                                        />
                                        <input
                                            type="text"
                                            placeholder="State *"
                                            className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                                            value={data?.AccountDetais?.State}
                                        />
                                        {data?.AccountDetais?.State && (
                                            <label className="text-green-400 ml-2 justify-start items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="flex flex-col md:flex-row gap-x-6 md:items-center mb-4 justify-between">
                                        <input
                                            type="checkbox"
                                            checked={data?.AccountDetais?.otherBusinessDocument}
                                            onChange={() => setBankPassbookUploaded(!data?.AccountDetais?.otherBusinessDocument)}
                                        />
                                        <label className="font-semibold md:w-48">Bank Document</label>
                                        <div className="flex items-center">
                                            <Link to={data?.AccountDetais?.otherBusinessDocument} target="_blank" rel="noopener noreferrer">
                                                <button type="button" onClick={() => {
                                                    window.open(data?.AccountDetais?.otherBusinessDocument, '_blank');
                                                }} className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                                            </Link>


                                        </div>
                                        {data?.AccountDetais?.otherBusinessDocument && (
                                            <label className="text-green-400 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}
                                    </div>
                                </div>


                            </div>

                        </form>
                    </div >
                    <div className="w-11/12 flex-col ml-10 justify-center items-center">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="myTextArea">
                            Not Verified Reasons :
                        </label>
                        <textarea
                            className="shadow appearance-none w-full py-2 px-3 border rounded-xl focus:outline-none focus:border-[#8A58DC] text-gray-700 leading-tight focus:shadow-outline"
                            id="myTextArea"
                            rows="4"
                            placeholder="Enter your text here"
                            value={notVerifiedReasons}
                            onChange={(e) => setNotVerifiedReasons(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="flex flex-row gap-2 ml-10 p-5">
                        <button className="w-full md:w-28 h-9 rounded-lg bg-[#8A58DC] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                            onClick={handleVerify}>
                            Verify
                        </button>
                        <button className="w-full md:w-28 h-9 rounded-lg bg-red-500 text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide"
                            onClick={handleReject}>
                            Reject
                        </button>
                    </div>

                </div >


                {/* right div */}
                < div className='flex p-5 lg:w-1/2 md:sticky top-32 ' >
                    <img className=" mx-auto" src="https://img.freepik.com/premium-vector/onlin-ebusiness-partnership-two-male-cartoon-characters-businessmen-shaking-hands-making-business-deal-business-management-remotely-flat-vector-illustration_241107-1243.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" alt="business-management" />
                </div >
            </div >
        </>
    )
}

export default VendorVerify;