import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import VendorAnalytics from './VendorAnalytics';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL, config } from '../../../config/config';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const ManageVendors = () => {
    const productsPerPage = 10;
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedItems, setSelectedItems] = useState({});
    const [data, setData] = useState([]);
    const [active, setActive] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    useEffect(() => {
        // Function to fetch products from the server`
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin-profile/getAllvendors/superAdmin?page=${page}`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };

        // Call the fetchVendors function
        fetchVendors();
    }, []);



    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / productsPerPage);

    // Calculate the index of the first and last product to display on the current page
    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = Math.min(
        startIndex + productsPerPage,
        data.length
    );

    // Filter the products to display only the ones for the current page
    const currentProducts = data.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="p-4 ">
                <VendorAnalytics />
                <div className="flex flex-col h-screen">
                    <h2 className="text-lg md:text-xl lg:text-2xl self-start">Product</h2> {/* Align the heading to the start */}
                    {active !== 'NewProductDetailsAdd' && (
                        <div className="mt-5 overflow-x-auto">
                            <table className="w-full md:w-[100%] border rounded-lg bg-white ">
                                <thead>
                                    <tr className="flex font-poppins font-medium p-2 justify-between">
                                        <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Business Name</th>
                                        <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Vendor Name</th>
                                        <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Location</th>
                                        <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Status</th>
                                        <th className="w-2/12 text-md md:text-lg lg:text-xl font-semibold">Action</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-gray-100">
                                    {data.slice(startIndex, endIndex)?.map((item) => (
                                        <tr key={item._id} className="flex font-poppins font-medium p-2 justify-between">
                                            <td className="w-2/12 justify-center items-center">
                                                <div className="flex flex-col text-[#4779CC]">
                                                    <div className="">{item.BussinessDetails?.Businessname}</div>
                                                </div>
                                            </td>
                                            <td className="w-2/12 text-center">{item.PersonalDetails?.name}</td>
                                            <td className="w-2/12 text-center">{item.BussinessDetails?.BusinessAddress?.city}</td>

                                            <td className={`w-2/12 text-center ${item.status === 'Verified' ? 'text-green-500' : (item.status === 'Rejected' ? 'text-red-500' : 'text-orange-500')}`}>{item.status}</td>

                                            <td className="w-2/12 justify-center items-center">
                                                <Link to={`/vendor-verify/${item._id}`}>
                                                    <button
                                                        className={`w-full md:w-28 h-9 justify-center items-center rounded-lg bg-[#8A58DC] text-white font-poppins text-lg md:text-base lg:text-lg font-semibold tracking-wide`}
                                                    >
                                                        {item.status === 'Verified' ? 'Edit' : 'Verify'}
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                        </div>
                    )}
                </div>
                {/* Custom pagination */}
                <div className="flex justify-center mb-4" >
                    {/* Left button */}
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button hover:bg-[#b788ca]  rounded-3xl"
                    >
                        <IoChevronBack />
                    </button>

                    {/* Page buttons */}
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`pagination-button  ${currentPage === index + 1 ? "active" : ""
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}

                    {/* Right button */}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button hover:bg-[#b788ca] rounded-3xl"
                    >
                        <IoChevronForward />
                    </button>
                </div>

                {/* CSS for the custom pagination */}
                <style>
                    {`
            .pagination-button {
              margin: 0 0.5rem;
              padding: 0.5rem 1rem;
              border: 1px solid #ccc;
              cursor: pointer;
            }

            .pagination-button.active {
              background-color: #ccc;
            }
          `}
                </style>
            </div>
        </>
    );
};

export default ManageVendors;
