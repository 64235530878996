import React, { useState } from 'react';
import ComingSoon from './ComingSoon';
const CareerPage = () => {
  
  const jobListings = [
    {
      id: 1,
      position: 'Frontend Developer',
      department: 'Engineering',
      location: 'Remote',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
    },
    {
      id: 2,
      position: 'Marketing Specialist',
      department: 'Marketing',
      location: 'Office Location',
      description: 'Pellentesque habitant morbi tristique senectus et netus et...',
    },
  ];

  const [formData, setFormData] = useState({
    jobId: null,
    name: '',
    email: '',
    resume: '',
    personalDetails: {
      address: '',
      phone: '',
    },
    academicDetails: {
      university: '',
      degree: '',
    },
    professionalDetails: {
      experience: '',
      skills: '',
    },
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJobApplyClick = (jobId) => {
    setFormData((prevData) => ({
      ...prevData,
      jobId,
    }));
    setCurrentStep(1);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStep(1); // Reset the form step when closing the modal
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={handlePersonalDetailsSubmit} className="max-w-md mx-auto">
            {/* Step 1: Personal Details */}
            <div className="mb-4">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="personalDetails.address"
                value={formData.personalDetails.address}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="personalDetails.phone"
                value={formData.personalDetails.phone}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600"
            >
              Next
            </button>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleAcademicDetailsSubmit} className="max-w-md mx-auto">
            {/* Step 2: Academic Details */}
            <div className="mb-4">
              <label htmlFor="university" className="block text-sm font-medium text-gray-700">
                University
              </label>
              <input
                type="text"
                id="university"
                name="academicDetails.university"
                value={formData.academicDetails.university}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="degree" className="block text-sm font-medium text-gray-700">
                Degree
              </label>
              <input
                type="text"
                id="degree"
                name="academicDetails.degree"
                value={formData.academicDetails.degree}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600"
            >
              Next
            </button>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleFormSubmit} className="max-w-md mx-auto">
            {/* Step 3: Professional Details */}
            <div className="mb-4">
              <label htmlFor="experience" className="block text-sm font-medium text-gray-700">
                Experience
              </label>
              <input
                type="text"
                id="experience"
                name="professionalDetails.experience"
                value={formData.professionalDetails.experience}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="skills" className="block text-sm font-medium text-gray-700">
                Skills
              </label>
              <input
                type="text"
                id="skills"
                name="professionalDetails.skills"
                value={formData.professionalDetails.skills}
                onChange={handleInputChange}
                className="mt-1 p-3 w-full border rounded-md focus:outline-none focus:ring focus:border-teal-500"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600"
            >
              Submit Application
            </button>
          </form>
        );
      default:
        return null;
    }
  };

  const handlePersonalDetailsSubmit = (e) => {
    e.preventDefault();
    setCurrentStep(2);
  };

  const handleAcademicDetailsSubmit = (e) => {
    e.preventDefault();
    setCurrentStep(3);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
   
    // Clear form data after submission (you may want to update this based on your actual requirements)
    setFormData({
      jobId: null,
      name: '',
      email: '',
      resume: '',
      personalDetails: {
        address: '',
        phone: '',
      },
      academicDetails: {
        university: '',
        degree: '',
      },
      professionalDetails: {
        experience: '',
        skills: '',
      },
    });
    closeModal(); // Close the modal after submission
  };

  return (<>


    <div className="min-h-screen text-white">
    <ComingSoon/>
      {/* <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Join Our Team</h1>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {jobListings.map((job) => (
            <div key={job.id} className="bg-white text-gray-800 p-6 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-2">{job.position}</h3>
              <p className="text-gray-600 mb-2">
                {job.department} | {job.location}
              </p>
              <p className="text-gray-800 mb-4">{job.description}</p>
              <button
                onClick={() => handleJobApplyClick(job.id)}
                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600"
              >
                Apply Now
              </button>
            </div>
          ))}
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-md shadow-md relative z-10">
              <button
                onClick={closeModal}
                className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
              >
                Close
              </button>
              {renderFormStep()}
            </div>
          </div>
        )}
      </div> */}
    </div> 
    </>
  );
};

export default CareerPage;