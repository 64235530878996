import React from 'react'

const Report = () => {
    return (
        <>
            <div className='p-2 mt-4 w-96 flex flex-col justify-center items-center gap-10 '>
                <img
                    src="https://img.freepik.com/premium-vector/illustration-vector-graphic-cartoon-character-company-activity-report_516790-1825.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
                    alt=""
                />
                <p className='font-bold text-3xl p-2 text-[#8459C7] animate-bounce' >Coming Soon...</p>
            </div>
        </>
    )
}

export default Report