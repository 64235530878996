import React, { useEffect, useState } from 'react';
import { IoMdHeart, IoMdCart, IoMdPerson } from "react-icons/io";
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import CartCard from '../CartCard';
import { toast } from 'react-hot-toast';
import { API_URL, config } from '../../config/config';
import constant from '../../constant';



const SearchBar = () => {
  const users = useSelector((state) => state.auth);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [wishlistItemCount, setWishlistItemCount] = useState(0);
  const [showCartSideBar, setShowCartSideBar] = useState(false)
  const [cartNotificationCount, setCartNotificationCount] = useState(0);
  const [wishlistNotificationCount, setWishlistNotificationCount] = useState(0);
  const token = JSON.parse(localStorage.getItem('token'));
  const Logo = constant.IMAGES_LOGO.LOGO

  useEffect(() => {
    if (users && users.user && users.user.cartsItems) {
      setCartItemCount(users.user.cartsItems.length)
    }
  }, [users])

  useEffect(() => {
    if (users && users.user && users.user.wishlistItems) {
      setWishlistItemCount(users.user.wishlistItems.length)
    }
  }, [users])

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const users = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = () => {
    // Implement your login logic here, set isLoggedIn to true after successful login
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Implement your logout logic here, set isLoggedIn to false after successful logout
    setIsLoggedIn(false);
  };

  const setCartItems = () => {
    // Define your logic for setting cart items here
  };
  const searchProductsAPI = async (term) => {
    try {
      const response = await fetch(`${API_URL}/api/products/searchproducts?searchTerm=${searchTerm}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },


      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  };





  // const handleSearchSubmit = async (event) => {
  //   event.preventDefault();

  //   dispatch(getAllProducts({ searchTerm })).then((searchedProducts) => {
  //     console.log("searchTerm",searchTerm)
  //     console.log("Searched Products:", searchedProducts);
  //     // After the dispatch is completed, use navigate to redirect to the product page
  //     navigate(`/products/`);
  //   });


  // };



  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/products/?searchTerm=${encodeURIComponent(searchTerm)}`);
  };


  const handleCartClick = () => {
    if (!users.user) {
      toast.error('Please login to view your Cart');
    } else {
      setShowCartSideBar(!showCartSideBar);
    }
  };
  const handleWishlistClick = () => {
    if (!users.user) {
      toast.error('Please login to view your Wishlist');
    } else {
      navigate("/wishlist");
    }
  };


  useEffect(() => {
    const fetchCartItems = async () => {
      if (users && users.user && users.user.cartsItems && users.user.cartsItems.length > 0) {
        try {

          const cartItemsProducts = await axios.get(`${API_URL}/api/user/getCart/${users.user._id}`, {
            headers: {
              "Content-type": "application/json",
              "Authorization": token,
            },
          });
          if (cartItemsProducts) {
            setCartItems(cartItemsProducts.data);
            // Update the cart notification count based on the number of items in the cart
            setCartNotificationCount(cartItemsProducts.data.length);
          }
        } catch (error) {
          console.error(error);
          toast('Something went wrong');
        }
      }
    };

    fetchCartItems();
  }, [users]);


  useEffect(() => {
    const fetchWishlistItems = async () => {
      if (users && users.user && users.user.wishlistItems && users.user.wishlistItems.length > 0) {
        try {
          const wishlistItemsProducts = await axios.get(`${API_URL}/api/user/getWishlist/${users.user._id}`, {
            headers: {
              "Content-type": "application/json",
              "Authorization": token,
            },
          });
          if (wishlistItemsProducts) {
            setCartItems(wishlistItemsProducts.data);
            // Update the cart notification count based on the number of items in the cart
            setWishlistNotificationCount(wishlistItemsProducts.data.length);
          }
        } catch (error) {
          console.error(error);
          toast('Something went wrong');
        }
      }
    };

    fetchWishlistItems();
  }, [users]);

  return (
    <>
      <div className="w-full flex flex-row justify-between md:p-3 sm:p-3 md:pl-6 md:pr-6 sm:pl:3 sm:pr-3 max-sm:p-3">
        {/* <div className=" flex-1 flex items-center justify-center"> */}
        {/* {LOGO} */}
        <Link to="/">
          <img src={Logo} alt="Logo" className="md:w-[90px] md:h-[40px] sm:w-[90px] sm:h-[40px] max-sm:w-[90px] max-sm:h-[40px]" />
        </Link>


        {/* {searchBar} */}
        <form onSubmit={handleSearchSubmit} className="hidden md:flex items-center justify-start md:w-[-webkit-fill-available] px-4 md:px-8 sm:md:w-[350px] xs:md:w-[300px]" >
          <div className="flex justify-center items-center border w-[100%] rounded-md border-none">
            <div className="flex items-center justify-center p-2 bg-[#F7FAFC] rounded-l-md border border-r-0 border-[#F16080]">
              <Search className="h-6 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                handleSearchSubmit(e)
              }
              }
              placeholder="Search for Products..."
              className="md:flex px-4 py-2 w-[-webkit-fill-available] bg-[#F7FAFC] rounded-l-none rounded-r-1 border border-l-0 border-[#F16080] outline-none"
            />
            <button
              type="submit"

              className="px-4 py-2 w-[max-content] border border-[#F16080] bg-[#F16080] text-white rounded-r-md hover:bg-[#8A58DC] hover:border-[#8A58DC] cursor-pointer"
            >
              Search
            </button>
          </div>
        </form>






        {/* {icons} */}
        <div className=" flex flex-row items-center justify-end md:gap-3 sm:gap-2 max-sm:gap-1 relative  w-[fit-content]">

          {(users.user !== null) ? (
            // Show the Person logo and a logout button if the user is logged in
            <>
              <Link to={'/user-profile'}>
                <IoMdPerson
                  className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer"
                  size={27}
                  onClick={handleLogout}
                />
              </Link>

            </>
          ) : (
            // Show the Login button if the user is not logged in
            <Link to={'/signin'} style={{ cursor: "pointer" }} className='hover:bg-[#F16080] cursor-pointer text-gray-100 rounded-lg bg-[#8A58DC]  font-semibold'>
              <button
                className="text-gray-100 bg-[#8A58DC] p-1 pl-3 pr-3 rounded-md font-light hover:bg-[#F16080] cursor-pointer"
              >
                Login
              </button>
            </Link>
          )}

          {/* Add icons for wishlist, cart, and profile */}

          <div className="relative inline-block">
            <IoMdHeart className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer" onClick={handleWishlistClick} size={27} />
            {wishlistItemCount > 0 && (
              <span className="absolute top-0 right-0 w-4 h-4 flex items-center justify-center text-sm text-white bg-red-500 rounded-full">
                {wishlistNotificationCount}
              </span>
            )}
          </div>


          <div className="relative inline-block" onClick={handleCartClick}>
            <IoMdCart className="text-[#8A58DC] hover:text-[#F16080] cursor-pointer" size={27} />
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0  w-4 h-4 flex items-center justify-center text-sm text-white bg-red-500 rounded-full">
                {cartNotificationCount}
              </span>
            )}
          </div>



        </div>
      </div>
      {/* after 655px */}
      <div className='p-2'>
        <form className="md:hidden  flex items-center justify-start md:w-[-webkit-fill-available] px-4 md:px-8 sm:md:w-[350px] xs:md:w-[300px]" onSubmit={handleSearchSubmit}>
          <div className="flex justify-center items-center border w-[100%] rounded-md border-none">
            <div className="flex items-center justify-center p-2 bg-[#F7FAFC] rounded-l-md border border-r-0 border-[#F16080]">
              <Search className="h-6 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                handleSearchSubmit(e)
              }}
              placeholder="Search"
              className="md:flex px-4 py-2 w-[90%] bg-[#F7FAFC] rounded-l-none rounded-r-1 border border-l-0 border-[#F16080] outline-none"
            />
            <button
              type="submit"
              onClick={handleSearchSubmit}
              className="px-2 py-2 border  border-[#F16080] bg-[#F16080] text-white rounded-r-md hover:bg-[#8A58DC] hover:border-[#8A58DC] cursor-pointer"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      {
        showCartSideBar && <CartCard setShowCartSideBar={setShowCartSideBar} showCartSideBar />
      }
    </>
  )
}

export default SearchBar;
