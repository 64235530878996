import React, { useState, useEffect } from 'react'
import { IoShareSocialSharp } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { Rating } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFromWishlist } from '../../Redux/features/auth/authSlice';
import { toast } from 'react-hot-toast';
import { FaCopy } from "react-icons/fa";
import { FacebookIcon, WhatsappIcon, TwitterIcon } from 'react-share';
import ShareModal from "./ShareModal";
import { addToCart } from '../../Redux/features/auth/authSlice';
import { API_URL, config } from '../../config/config';


const WishlistProductCard = ({ data }) => {
    const [ratings, setRatings] = useState(0);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vendorName, setVendorName] = useState("");

    const handleShareClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (data && data.vendorId) {
            fetch(`${API_URL}/api/vendor/getSpecificVendorById/${data.vendorId}`, config)
                .then((response) => response.json())
                .then((response) => {
                    if (response.vendor) {
                        setVendorName(response.vendor.Businessname);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching vendor data", error);
                });
        }
    }, [data.vendorId]);

    const handleShareOptionClick = (option) => {

        const productUrl = `${API_URL}/SpecificProductPage/${data._id}`;
        switch (option.label) {
            case 'WhatsApp':
                // Share on WhatsApp
                return window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(data.productName + ' ' + productUrl)}`, '_blank');
            case 'Facebook':
                // Share on Facebook
                return window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}`, '_blank');
            case 'Twitter':
                // Share on Twitter
                return window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(productUrl)}&text=${encodeURIComponent(data.productName)}`, '_blank');
            case 'Copy Link':
                // Copy to Clipboard
                const dummy = document.createElement('input');
                document.body.appendChild(dummy);
                dummy.value = productUrl;
                dummy.select();
                document.execCommand('copy');
                document.body.removeChild(dummy);
                toast.success('Link copied to clipboard');
                return;
            default:
                return;
        }
    };

    const shareOptions = [
        { icon: <WhatsappIcon size={32} round />, label: 'WhatsApp', onClick: () => handleShareOptionClick('WhatsApp') },
        { icon: <FacebookIcon size={32} round />, label: 'Facebook', onClick: () => handleShareOptionClick('Facebook') },
        { icon: <TwitterIcon size={32} round />, label: 'Twitter', onClick: () => handleShareOptionClick('Twitter') },
        { icon: <FaCopy />, label: 'Copy Link', onClick: () => handleShareOptionClick('Copy Link') },
    ];
    const dispatch = useDispatch();
    const users = useSelector((state) => state.auth);
    const [isWishlist, setIsWishlist] = useState(false);

    const handleRemoveFromWishlist = () => {
        const newData = {
            id: users && users.user && users.user._id,
            pId: data._id,
        };


        dispatch(deleteFromWishlist(newData))
            .then((result) => {
                if (deleteFromWishlist.fulfilled.match(result)) {
                    setIsWishlist(false);
                    toast.success('Item removed from your wishlist');
                } else {
                    console.error('Error removing product from the wishlist:', result.payload);
                }
            });
    };

    const addCart = () => {


        if (data.stock === 0) {
            toast.error('This product is currently out of the stock');
            return;
        }

        if (data.selectedCategory === 'clothing' ||
            data.selectedCategory === 'Men' ||
            data.selectedCategory === 'kids' ||
            data.selectedCategory === 'Boy' ||
            data.selectedCategory === 'Girl' ||
            data.selectedCategory === 'Women') {
            toast.error('Please select a size before adding to cart');
            return;
        }

        const newData = {
            id: users && users.user && users.user._id,
            pId: data._id,
        }
        dispatch(addToCart(newData))
            .then((result) => {
                if (addToCart.fulfilled.match(result)) {
                    // Product added to the cart successfully, handle the success case
                    toast.success('Item added to cart successfully');

                } else {
                    // Handle the error case
                    toast.error(result.payload);
                }
            });
    };
    const BuyNow = () => {


        if (data.stock === 0) {
            toast.error('This product is currently out of the stock');
            return;
        }

        if (data.selectedCategory === 'clothing' ||
            data.selectedCategory === 'Men' ||
            data.selectedCategory === 'kids' ||
            data.selectedCategory === 'Boy' ||
            data.selectedCategory === 'Girl' ||
            data.selectedCategory === 'Women') {
            toast.error('Please select a size before buying the product');
            return;
        }

        const newData = {
            id: users && users.user && users.user._id,
            pId: data._id,
        }
        dispatch(addToCart(newData))
            .then((result) => {
                if (addToCart.fulfilled.match(result)) {
                    // Product added to the cart successfully, handle the success case
                    navigate("/checkout");

                } else {
                    // Handle the error case
                    toast.error(result.payload);
                }
            });
    };

    return (
        <>

            <div className="sm:flex w-full hover:scale-105 border rounded-2xl shadow-md ">


                {/* Product Image */}
                <div className="w-52 h-52 cursor-pointer mx-auto">
                    <Link

                        to={{
                            pathname: `/SpecificProductsPage/${data._id}`,
                            state: { productId: data._id },
                        }}
                    >
                        <img
                            src={data.images && data.images.length > 0 ? data.images[0] : 'https://placehold.it/300x300'} // You can replace 'https://placehold.it/300x300' with your placeholder image URL
                            alt="product Image"
                            className="w-full h-full hover:scale-105 p-5 rounded-l-md object-contain"
                        />


                    </Link>
                </div>

                {/* Product Details */}

                <div className="flex flex-col p-4 md:w-96">
                    <Link

                        to={{
                            pathname: `/SpecificProductsPage/${data._id}`,
                            state: { productId: data._id },
                        }}
                    >

                        <h2 className="text-lg font-semibold cursor-pointer">
                            {data.productName && data.productName.length > 30 ? data.productName.slice(0, 40) + '...' : data.productName}
                        </h2>
                        <h2 className="text-[#880081] font-bold cursor-pointer">

                            {vendorName}
                        </h2>
                        <div className="flex items-center space-x-2 mt-5 cursor-pointer">
                            <Rating
                                name="simple-controlled"
                                readOnly
                                value={data.ratings}
                                onChange={(event, newRating) => {
                                    setRatings(newRating);
                                }}
                            />
                        </div>
                        <div className="text-gray-600 mt-5 flex">
                            <h5 className="font-bold text-[18px] text-[#333] font-Roboto">
                                ₹{data.theEkaivDiscountPrice}
                            </h5>
                            <h4 className="font-[300] text-[16px] text-[gray] pl-2 pt-3 mt-[-5px] line-through">
                                ₹{data.theEkaivPrice}
                            </h4>
                        </div>
                    </Link>
                </div>



                <div className=" min-[440px]:flex sm:flex-col sm:pt-10 sm:space-y-2 p-4 items-center justify-between ">
                    <div className='flex p-[1.25rem] sm:flex-col gap-2 max-[440px]:mb-3 max-[440px]:justify-between '>
                        {data.stock === 0 ? (
                            <p className='text-red-500 p-2 w-full  text-sm'>Out of the stock</p>
                        ) : (
                            <>
                                <Link onClick={BuyNow}>
                                    <button className="p-2 w-full bg-[#8A58DC] text-white hover:bg-opacity-80 rounded-md text-sm hover:scale-105">Buy Now</button>
                                </Link>

                                <button onClick={addCart} className="p-2 md:w-full bg-[#8A58DC] text-white hover:bg-opacity-80 rounded-md text-sm hover:scale-105">Add to Cart</button>
                            </>
                        )}
                    </div>
                    <div className="flex gap-5 justify-between ">
                        <button className="w-10 h-10 p-2 bg-purple-200 hover:bg-opacity-80 text-black rounded-lg text-xl flex items-center justify-center shadow-md hover:scale-105"
                            onClick={handleShareClick}>
                            <IoShareSocialSharp />

                        </button>
                        <button
                            className="w-10 h-10 p-2 bg-purple-200 text-black hover:bg-opacity-80 rounded-lg text-xl flex items-center justify-center shadow-md hover:scale-105"
                            onClick={() => handleRemoveFromWishlist()}
                        >
                            <RiDeleteBinLine />
                        </button>
                    </div>
                </div>

            </div >
            {isModalOpen && (
                <ShareModal onClose={handleModalClose}>
                    {shareOptions.map((option) => (
                        <button
                            key={option.label}
                            className="flex flex-col items-center p-4 rounded-md bg-gray-100 hover:bg-gray-200 z-[1]"
                            onClick={() => {
                                handleShareOptionClick(option);
                                handleModalClose();
                            }}
                        >
                            {option.icon}
                            <span className="mt-2">{option.label}</span>
                        </button>
                    ))}
                </ShareModal>
            )
            }

        </>
    );
};

export default WishlistProductCard;