import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {API_URL , config} from '../../config/config';

const OrderCards = ({ orderStatus,userAddress, productsDetails,createdAt }) => {
  const navigate=useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  const [products, setProducts] = useState([]);
  const productsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
       
  
        if (productsDetails.length > 0) {
          // Fetch product details using the API endpoint
          const productDetailsPromises = productsDetails.map((products) =>
            axios.get(`${API_URL}/api/products/getProduct/${products.productId}`)
          );
  
          const productDetailsData = await axios.all(productDetailsPromises);
  
          // Extract and set product details
          const formattedProducts = productDetailsData.map((response) => {
            // Find the corresponding product details based on productId
            const productDetail = productsDetails.find(
              (detail) => detail.productId == response.data.product._id
            );
            // Combine product details and formatted product data
            return {
              ...response.data,
              quantity: productDetail ? productDetail.quantity : 0,
              price: productDetail ? productDetail.price : 0,
            };
          });
         
  
          setProducts(formattedProducts);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
        // Handle error if necessary
      }
    };
  
    fetchProductDetails();
  }, [productsDetails]);
  

   

  return (
    <>
      {
        products.map(( product)=>{
          return <div
        className={`w-full shadow-md mb-4 rounded-md ${isExpanded ? 'h-auto' : 'h-48'}`}
        onClick={handleCardClick}
      >
        <div className='flex flex-col sm:flex-row cursor-pointer w-full h-auto '>
          <div className='flex flex-row'>
          <div className='' onClick={()=>navigate(`/SpecificProductsPage/${product?.product?._id}`)}>
          <img
            className='h-20 sm:h-48 w-20 sm:w-48 p-2 object-contain'
            src={product?.product?.images[0]}
            alt=""
          />

          </div>
          <div className='p-2 sm:hidden flex flex-col text-start text-gray-400'>
              <h1 className='font-semibold text-base text-black'>{product?.product?.productName}</h1>
              <p className='text-[10px]'>Color: gray</p>
              <p className='text-[10px]'>Size: xl</p>
              <p className='text-[10px]'>02-10-2023</p>
              <p className='text-[10px]'>{product?.price} Rs/-</p>
              {orderStatus!== 'NewOrders' ? (orderStatus==='Completed' ? (
                <p className='text-green-500'>Delivered</p>
              ) : (
                <p className='text-orange-500'>Processing...</p>
              )): <></>}
            </div>

          </div>

          <div className='flex flex-col min-[500px]:flex-row justify-between items-center w-full h-full p-5'>
            <div className='p-2 hidden sm:flex flex-col text-start text-gray-400'>
              <h1 className='font-semibold text-lg text-black'>{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 35)}...` : product.product.productName}</h1>
              {product.product.selectedCategory!='homemade-food' && <><p>Color: gray</p>
              <p>Size: xl</p></>}
            </div>
            <div className='hidden sm:block'>
              <p>{new Date(createdAt).toDateString()}</p>
              {product?.price} Rs/-
              {orderStatus!== 'NewOrders' ? (orderStatus==='Completed' ? (
                <p className='text-green-500'>Delivered</p>
              ) : (
                <p className='text-orange-500'>Processing...</p>
              )): <></>}
            </div>
            {
              orderStatus!=='NewOrders' && <div className='flex-col items-center p-2'>
              {orderStatus!=='Completed'  ? (
                <div className="flex justify-center items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-green-500"></div>
                  <p className='text-black font-semibold break-words'>Delivered On Aug 27</p>
                </div>
              ) : (

                 <div className="flex justify-center items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-orange-500"></div>
                  <p className='text-black font-semibold break-words'>Expected Delivery Date</p>
                </div>
              )}
              <p className='font-medium break-words text-xs'>
                {orderStatus==='Completed'
                  ? 'Your item has been delivered'
                  : 'Your item will be delivered on 20th Dec 2023'}
              </p>
            </div>
            }
          </div>

        </div>
        {isExpanded && (
          <div className='flex flex-col min-[500px]:flex-row p-4 justify-between'>
            <div className='text-left'>
              <h1 className='font-semibold text-black'>Delivery Address:</h1>
              <p>{`${userAddress.flat}, ${userAddress.area}, ${userAddress.landmark}, ${userAddress.city}, ${userAddress.state}, ${userAddress.pinCode}, ${userAddress.country}`}</p>
              
              <h1 className='font-semibold text-black'>Contact Info:</h1>
              <p className="text-md font-bold">{userAddress.fullname}</p>
              <p>Mob. No.: {userAddress.phoneNumber}</p>
            </div>
            <div className='min-[500px]:text-right'>
              <p className='font-semibold text-black'>Order No. <span className='font-light text-gray-400'>7958642</span></p>
              <p className='font-semibold text-black'>Tracking Number: <span className='font-light text-gray-400'>IE45UI555</span> </p>
              <p className='font-semibold text-black'>Quantity: <span className='font-light text-gray-400'>{product?.quantity}</span></p>
            </div>
          </div>
        )}
      </div>
        })
           
      }
       
    </>
  );
}

export default OrderCards;

