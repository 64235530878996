import React, { useState, useEffect } from 'react';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordWithEmail, forgotPasswordWithPhone } from '../../Redux/features/auth/authSlice';
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {API_URL} from '../../config/config';

const ForgotPassword = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [resendTimer, setResendTimer] = useState(300);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState('');
  const [otpError, setOtpError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [resetHash, setResetHash] = useState('')

  const sendOTP = () => {

    if (!emailOrPhone) {
      setEmailOrPhoneError('Please enter your email or phone.');
      setShowOTP(false);
    } else {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(emailOrPhone).toLowerCase())) {
        dispatch(forgotPasswordWithEmail({ email: emailOrPhone })).then((response) => {
          if (response.error) {
         
            toast.error(response.payload)
            // setSuccessMessage('')
            // setErrorMessage(response.payload)
            // dispatch(resetMessage())
          } else {

            toast.success(response.payload.message)
            setResetHash(response.payload.fullHash)
            setEmailOrPhoneError('');
            setShowOTP(true);
            // Start the timer when OTP is sent
            startResendTimer();
            // setErrorMessage('')
            // setSuccessMessage(response.payload.message)
            // window.location.href = '/'
            // dispatch(resetMessage())
          }
        })
      } else if (emailOrPhone.match(/^\d+$/)) {
        dispatch(forgotPasswordWithPhone({ phone: emailOrPhone })).then((response) => {
          if (response.error) {

          
            toast.error(response.payload)
         
            // setSuccessMessage('')
            // setErrorMessage(response.payload)
            // dispatch(resetMessage())
          } else {
            setEmailOrPhoneError('');
            toast.success(response.payload)
            setShowOTP(true);
            // Start the timer when OTP is sent
            startResendTimer();
            // setErrorMessage('')
            // setSuccessMessage(response.payload.message)
            // window.location.href = '/'
            // dispatch(resetMessage())
          }
        })

      } else {
        setEmailOrPhoneError('Please enter your valid email or phone.');
      }
    }
  };
  const verifyOTP = async () => {
    await axios.post(`${API_URL}/api/auth/verify-reset-password-otp`, { phone: emailOrPhone, otp: otpValue, hash: resetHash }).then(response => {
      // setErrorMessage('')
  
      localStorage.setItem('resetPasswordToken', response.data.resetPasswordToken);
      navigate('/reset-password');
      // setSuccessMessage(response.data)
      // setShowOtpInput(false)
    })
      .catch(error => {
        // setSuccessMessage('')
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        // setErrorMessage(message)
   
      })
  };

  const handleOTPChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setOtpValue(numericValue);

    // Show the "Continue" button when OTP is entered
    if (numericValue.length === 6) {
      setOtpError('');
      setShowContinueButton(true);
    } else {
      setOtpError('Please enter a 6-digit OTP.');
      setShowContinueButton(false);
    }
  };

  const startResendTimer = () => {
    // Start a 1-minute 30-second timer
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          // When the timer reaches zero, clear the interval
          clearInterval(timerInterval);
          return 0;
        }
      });
    }, 1000);
  };

  const resetTimer = () => {
    // Reset the timer to 1 minute and 30 seconds when "Resend OTP" is clicked
    setResendTimer(90);
  };

  useEffect(() => {
    if (showOTP) {
      startResendTimer();
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(resendTimer);
    };
  }, [showOTP]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scroll-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <>
      <div className='h-full w-auto bg-[#f7fafc]'>
        <div className="inset-0 flex items-center justify-center z-50 p-10 sm:p-20">
          <div className="bg-white rounded-lg p-8 shadow-lg max-w-4xl w-full">

            <div className="flex flex-col mr-5 md:flex-row">
              <div className="md:w-1/2">
                <img
                  src="https://img.freepik.com/free-vector/reset-password-concept-illustration_114360-7876.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
                  alt="Image1"
                  className="h-auto w-full mx-auto md:ml-0 md:mr-8 mb-4 md:mb-0"
                />
              </div>
              <div className="md:w-1/2">
                <h2 className="text-3xl font-bold mb-4">Forgot Password</h2>

                <label htmlFor="email" className="block mt-4 text-gray-700 mb-2">
                  Email or Phone
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={emailOrPhone}
                  onChange={(e) => {
                    const lowercasedValue = e.target.value.toLowerCase(); // Convert input value to lowercase
                    setEmailOrPhone(lowercasedValue);
                    // Enable the "Send OTP" button when emailOrPhone is not empty
                    setEmailOrPhoneError('');
                  }}
                  className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1]"
                  placeholder="test1@gmail.com"
                />
                {emailOrPhoneError && <p className="text-red-500 text-sm">{emailOrPhoneError}</p>
                }

                {!showOTP ? (
                  <button
                    className="flex row gap-2 text-white bg-[#8A58DC] font-medium rounded-full text-sm px-2 min-[320px]:px-5 py-1 min-[320px]:py-2.5 text-center mt-4 cursor-pointer justify-center hover-bg-[#ac7ff4]"
                    // disabled={!emailOrPhone}
                    onClick={sendOTP}
                  >
                    Send OTP <AiOutlineSwapRight className='text-xl justify-center items-center' />
                  </button>
                ) : (
                  <div className="flex flex-col mt-2">
                    <label htmlFor="email" className="block text-gray-700 mb-2">
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      value={otpValue}
                      maxLength={6}
                      onChange={handleOTPChange}
                      className="w-full px-4 py-2 border-none outline-none rounded-md mb-2 bg-[#FFEDF1]"
                      placeholder="Enter OTP *"
                    />
                    {otpError && <p className="text-red-500 text-sm">{otpError}</p>}

                    <div className="flex flex-col mb-2">
                      <p>
                        Didn't receive OTP?{" "}
                        {resendTimer > 0 ? (
                          <span className='text-gray-400'>
                            Resend ({Math.floor(resendTimer / 60)}:
                            {String(resendTimer % 60).padStart(2, "0")} )
                          </span>
                        ) : (
                          <div

                            className="text-[#8A58DC] cursor-pointer"
                            onClick={() => {
                              resetTimer();
                              sendOTP();
                            }}
                          >
                            Resend OTP
                          </div>
                        )}
                      </p>
                    </div>
                    <button
                      className="bg-[#8A58DC] scroll-link text-white mt-4 font-medium rounded-full text-sm px-5 py-2.5 text-center justify-center hover-bg-[#ac7ff4]"
                      disabled={!showContinueButton}
                      onClick={verifyOTP
                        // () => {
                        //   // Use navigate to go to the next page
                        //   // if (showContinueButton) {
                        //   //   navigate('/reset-password');
                        //   // }
                        // }
                      }
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;



{/* <Link to="/reset-password"> */ }
