import React from 'react'

import { Link } from 'react-router-dom';


import { IoIosLock } from "react-icons/io";



import constant from '../constant';

const LOGO=constant.IMAGES_LOGO.LOGO;
const EKAIVESELLING=constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.EKAIVESELLING;
const FAKEPAN=constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.FAKEPAN;
const CAPITAL=constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.CAPITAL;
const SEAMSTRESS=constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.SEAMSTRESS;
const PRIVACY=constant.IMAGES_IMAGES.BECOME_A_SELLER.STARTSELLING.PRIVACY;
const RIBBON=constant.IMAGES_IMAGES.BECOME_A_SELLER.RIBBON;



function EkaiveSelling() {
    const activeComponent = 'BusinessConsultants';


    return (
        <div className=' p-3 md:p-5 '>
            {/* header Start */}

            <div className=' grid grid-cols-1 sm:grid-cols-2 p-5'>
                <div className='py-5 px-2 sm:p-5 flex flex-col justify-center items-center'>
                    <div className=' pt-10'>

                        <h1 className='text-4xl font-bold'>Becoming A Seller</h1>
                        <p className='text-sm pt-4 text-justify'>
                            Embark on a rewarding journey with The Ekaiv, seize the opportunity to become a seller and showcase your unique handcrafted products across the nation. Join us, and let's create something extraordinary together!
                        </p><br />
                    </div>
                    <div className=''>

                        <h1 className='text-2xl text-center md:text-left font-bold pt-2'>Three Easy Steps of Account Creation:</h1>

                        <p className='pl-5 pt-3 text-sm'>
                            <span className=' font-bold'>Step 1:</span> Add basic Business and Personal Details.<br />
                            <span className=' font-bold'>Step 2:</span> Add your Business related Documents (GST  no., PAN no. And other required documents as per the category of sale).<br />
                            <span className=' font-bold'>Step 3:</span> Add your Account details on which you want your payments to be made.<br />
                        </p>
                        <p className='text-sm pt-2'> And after the approval of your details, we’ll send you WhatsApp message and then you can start uploading your products.</p>

                        <h1 className='text-lg font-bold pt-2'>How to Get Your The Ekaiv Vendor ID:</h1>
                        <p className='pt-2 pl-5 text-sm'>
                            •	Enter your GST number for GST categories.<br />
                            •	For non-GST categories, provide your PAN number.

                        </p>


                    </div>
                </div>
                <div className=' flex justify-center items-center'>
                    <img className=" w-full mx-auto" src={EKAIVESELLING} alt="Seller" />

                </div>

            </div>
            {/* header end */}

            {/* start selling start */}
            <div className=' grid grid-cols-1 md:grid-cols-3 py-20'>
                <div className=' flex justify-center items-center'>
                    <img className=" w-[90%] mx-auto " src={FAKEPAN} alt="fakePan" />

                </div>
                <div className=' flex flex-col justify-center items-center px-5'>
                    <h1 className='text-2xl font-bold text-center'>How To Start Selling</h1>
                    <p className='pt-2 sm:pt-10'>
                        <span className=' font-bold'> 1.	Create an Account:</span>
                        <br />
                        <p className=' text-sm pl-5'>
                            •	All you need is your GST, PAN Card, and an active bank account.<br />
                            •	For non-GST products, register with your PAN details.<br />
                        </p>

                        <span className=' font-bold'> 2.	List Your Product:</span><br />
                        <p className=' text-sm pl-5 '>
                            •	Complete the product listing, providing detailed product and brand information.<br />
                        </p>
                        <span className=' font-bold'>3.	Track Your Business:</span><br />
                        <p className=' text-sm pl-5 '>
                            •	Easily monitor orders, sales growth, and payment statements on our certified dashboard and app.<br />
                            •	Once verified as an The Ekaiv vendor, your payments are securely deposited into your bank account.<br />
                        </p>

                    </p>
                </div>
                <div className=' flex justify-center items-center'>
                    <img className=" w-full mx-auto" src={CAPITAL} alt="capital" />

                </div>

            </div>
            {/* start selling end */}






            {/* why choose ekaiv start */}
            <div>
                <img src={RIBBON} alt="ribbon" className='w-full py-20' />
            </div>
            <div className=' grid grid-cols-1 gap-5 sm:grid-cols-2 px-5 py-10 gap-y-20 '>
                <div className='order-2 sm:order-1 flex justify-center items-center sm:px-10'>
                    <img src={LOGO} alt="logo" />
                </div>
                <div className='order-1 sm:order-2 flex justify-center items-center '>


                    <div className=' flex flex-col'>
                        <h1 className='text-3xl font-bold text-center md:text-left'>Why "The Ekaiv" ?</h1>
                        <p className='pt-4 sm:pr-5 text-justify text-sm'>
                            <span className=' font-bold text-base'>Increased Visibility:</span> We make your products visible to a vast consumer base.<br />
                            <span className=' font-bold text-base text-blue-700 hover:text-purple-700'> <Link to="/catalogue">Make Your Own Identity:</Link> </span> Our Catelogue page gives your business it's own identity.<br />
                            <span className=' font-bold text-base'>Secure Daily Payments:</span> Payments are safely deposited directly into your account after the immediate delivery of the product, even for pay-on-delivery orders.<br />
                            <span className=' font-bold text-base text-blue-700 hover:text-purple-700'><Link to="/assistance#abc">Expert Support:</Link></span> Gain support from our experts in areas such as business growth and tax-related queries.<br />
                            <span className=' font-bold text-base'>Efficient Product Delivery:</span> We take care of delivering your products, ensuring a hassle-free experience for you.<br />
                        </p>
                        <Link to ='/selling-page'><button className=' w-[140px] absolute h-[46px] bg-pink-500 text-white rounded-[8px] shadow-md mt-4'> Seller</button></Link>
                    </div>
                    <div>

                        {/* steps were written here */}
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-300 my-4 mt-10"></div>

            {/* why choose ekaiv end */}

            {/* privacy policy section start  */}

            <div className=' grid grid-cols-1 sm:grid-cols-2  pt-5 '>
                <div className=' flex flex-col items-center justify-center pt-10 px-5 md:px-14 '>
                    <div>
                    <div>
                        <h1 className='text-3xl font-bold' >Privacy Policy for The Ekaiv:</h1>
                        <p className=' text-justify text-sm pt-4'>Welcome to The Ekaiv! Our privacy policy ensures the utmost security of your personal information. We collect voluntary information like name, email, and phone number. Additionally, we gather data like IP address, browser type, and device information for a personalized experience.</p>

                    </div>
                    <div>
                        <h1 className='text-lg font-bold pt-4 text-center md:text-left'>How We Use Your Information:</h1>
                        <p className=' pl-5 pt-4 text-sm'>
                            •	Provide effective services and improve efficiency.<br />
                            •	Respond to your inquiries, requests, and complaints.<br />
                            •	Send you marketing and promotional material with your consent.<br />
                            •	Monitor and analyze usage patterns and trends.<br />
                            •	Offer job opportunities in case of vacancies.<br />

                        </p>

                    </div>
                    </div>
                </div>
                <div className=' flex justify-center items-center '>
                    <img
                        src={PRIVACY}
                        alt="img1"
                        className=' object-contain h-[90%] w-[90%]'

                    />

                </div>


            </div>
            {/* privacy policy section end */}

            <div className="border-t border-gray-300 my-4 mt-10"></div>

            {/* customization section start */}



            <div className=' py-10  grid grid-cols-1 md:grid-cols-2'>
                <div className=' flex justify-center items-center'>
                   <img src={SEAMSTRESS} alt="Seamstress-bro" className='w-[60%]' />
                </div>
                <div className=' flex flex-col justify-center items-center'>
                <h1 className='text-2xl font-bold text-center lg:text-left px-10 ' >For Vendors Offering Customization</h1>

                    <p className='px-10 py-7 text-sm  text-justify'>
                        1.	Ensure all business details provided are correct, as they will be verified by our CA. <br />
                        2.	By filling the details, you agree to share the agreed-upon margin with "The Ekaiv Team." <br />
                        3.	Upload quality photographs of your products to attract consumers. <br />
                        4.	Clearly specify the level of customization you can offer in your product details. <br />
                        5.	Respond promptly to consumer customization requests received via WhatsApp. <br />
                        6.	The amount added to the product pricing will be considered with "The Ekaiv" margin.<br />
                        7.	Payments will be credited to your account after the product reaches the consumer.<br />
                        8.	Check our "Our Badges" page to understand how to become a highly desirable vendor.<br />
                    </p>
                </div>
            </div>

            {/* customization section end */}
            {/* security start */}
            <div className=' flex justify-center items-center'>
                <div className=' flex items-center justify-center px-5 md:px-14 py-10 w-full lg:w-[50%] bg-gray-100'>
                    <IoIosLock className=' relative text-gray-200 hidden md:block ' size={300} />
                    <div className='w-full lg:w-[50%] p-5 md:p-10 md:absolute'>
                        <div>
                            <h1 className='text-3xl font-bold'>Security of Your Personal Data:</h1>
                            <p className='pt-4 text-sm text-justify'>We employ various security technologies to protect your data. However, it's your responsibility to keep your username and password confidential.</p>

                        </div>
                        <div>
                            <h1 className='text-lg font-bold pt-4' >Your Choices:</h1>
                            <p className='  text-sm'>
                                •	Opt-out of receiving marketing communication.
                                •	Request access, correction, or deletion of your personal information.

                            </p>
                        </div>
                        <div>
                            <h1 className='text-lg font-bold pt-4'>Links to Third-Party Websites:</h1>
                            <p className=' text-sm'>
                                •	Be cautious when providing information on third-party websites linked from ours.
                            </p>
                        </div>
                        <div>
                            <h1 className='text-lg font-bold pt-4'>Changes to Our Privacy Policy:</h1>
                            <p className=' text-sm'>
                                •	We may update our privacy policy to comply with legal requirements. The latest version will be posted.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* security ends */}


            <div className='w-full md:p-5  flex flex-col justify-center items-center mt-10'>
                <div className=' flex flex-col justify-center items-center md:px-2 break-words'>
                    <h1 className='text-3xl text-center font-bold'>Contact Us:</h1>
                    <p className=' text-center pt-5 '>
                        For any questions or concerns about your privacy, <br />
                        contact us at: <span className=' text-blue-700 break-words text-[13px] sm:text-sm'>services@theekaiv.in</span><br />
                        for Financial queries contact us at: <span className=' text-blue-700 text-[13px] sm:text-sm'>support@theekaiv.in</span> <br />
                    </p>

                </div>
                <div>
                    <p className=' font-semibold text-lg text-center pt-10 '>
                        "Join us in creating a valuable and long-term partnership.<br></br> Together, let's make your journey with The Ekaiv extraordinary!"

                    </p>

                </div>


            </div>

        </div>
    )
}

export default EkaiveSelling
