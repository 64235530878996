import React, { useEffect, useState } from "react";
import "./AddAddressForm.css";
import InputBox from "../InputBox";
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, getUser, resetMessage, updateUser } from "../../Redux/features/auth/authSlice";
import { toast, Toaster } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import {API_URL , config}  from "../../config/config";


const inputFields = [
  {
    id: "fullname",
    label: "Full Name",
    name: "fullname",
    autofocus: true,
    autocomplete: "fullname",
  },
  {
    id: "email",
    label: "Email Address",
    name: "email",
    autocomplete: "email",
  },
  {
    id: "mobile",
    label: "Mobile Number",
    name: "phone",
    autocomplete: "mobile",
  },

];
const LoginAndSecurity = () => {
  const dispatch = useDispatch();
  const { user, isError, isSuccess, message } = useSelector((state) => state.auth);
  const [resetHash, setResetHash] = useState('')
  // const [disabled, setDisable]=useState(true);

  const [isUser, setUser] = useState(user || null);
  const [editedField, setEditedField] = useState(null)
  const [isChange, setChange] = useState(false)
  const [otpInput1, setOtpInput1] = useState('');
  const [otpInput2, setOtpInput2] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [showAddEmailPopup, setShowAddEmailPopup] = useState(false)
  const [tempPasswords, setTempPasswords] = useState({});

  const token = JSON.parse(localStorage.getItem('token'));

  const config = {
    headers: {
      "Content-type": "application/json",
      "Authorization": token,
    },
  };
  // const [isOtpVerified, setIsOtpVerified] = useState(false);

  const [details, setDetails] = useState({
    fullname: '',
    email: '',
    phone: '',
  })

  useEffect(()=>{
    if(user){
      setDetails({
        fullname: user?.fullname || '',
        email: user?.email || '',
        phone: user?.phone?.replace('+91', '') || '',
      })
    }
  }, [user])

  const [passwords, setPasswords] = useState({
    prevPassword: "",
    newPassword: "",
    cnewPassword: ""
  })


  const handleSendOTP = async (field) => {
    if (!details.email && !details.phone) {
      toast.error("Please fill all the required fields before sending OTP.");
      return;
    }



    if (field === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(details.email)) {
    
        toast.error("Please enter a valid email address");
        return;
      }

      if(user?.email == null) {
        try {
          const response = await axios.post(`${API_URL}/api/user/add-email-otp`, { email: details.email }, config);
          setResetHash(response.data.fullHash);
          setShowAddEmailPopup(true);
          return toast.success(response.data.message);
        } catch (error) {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return toast.error(message);
        }
      }

      try {
        const response = await axios.put(`${API_URL}/api/user/change-email-otp`, { newEmail: details.email }, config);
        toast.success(response.data.message);
        setResetHash(response.data.fullHash);
        setShowEmailPopup(true);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(message);
      }
    } else if (field === 'phone') {
    
      if (details.phone.length !== 10) {
        toast.error('New Phone number should be of 10 digits');
        return;
      }

      if(`+91${details.phone}`== user?.phone){
        toast.error('New Phone number should be different from current phone number.');
        return;
      }
      await axios.put(`${API_URL}/api/user/change-phone-number-otp`, { newPhone: details.phone}, config)
        .then(response => {
          toast.success(response.data.message);
          setShowMobilePopup(true);
        })
        .catch(error => {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          toast.error(message);
        });
    }
  };

  const handleEdit = (fieldName) => {
    setEditedField(fieldName);
  };

  const handleResendOTP = async (field) => {
    try {
      // Add logic to resend OTP
      // For example, you can dispatch an action to resend OTP or make an API call

      // Simulating a successful resend for demonstration purposes
      // Replace this with your actual logic
      const resendSuccess = true;

      if (resendSuccess) {
        await handleSendOTP(field)
        toast.success('OTP resent successfully!');
      } else {
        toast.error('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      toast.error('Failed to resend OTP. Please try again.');
    }
    // setIsOtpVerified(false);
  };

  const handleSaveUpdates= async (event)=>{
    event.preventDefault();

    if(!details.fullname){
      toast.error("Please fill in all the necessary details.");
      return;
    }

    const data={
      fullname: details?.fullname,
    }

    dispatch(updateUser(data));
    setEditedField(null);


  }


  const handleSave = async (event) => {
    event.preventDefault();

    // Check if any of the required fields is empty
    if (!details.email && !details.phone) {
      toast.error("Please fill in all the necessary details.");
      return;
    }

    if(user?.email == null){
      if (!otpInput1) {
        toast.error("Please enter the OTP before saving 1.");
        return;
      }
      if (otpInput1 && showAddEmailPopup) {
        if (showAddEmailPopup) {
          await axios.put(`${API_URL}/api/user/add-email`, { email: details.email, otp: otpInput1, resetHash: resetHash }, config)
            .then(response => {
              console.log(response,"dwdwdw");
              setShowAddEmailPopup(false);
              setEditedField(null);
              dispatch(getUser());
              return toast.success(response.data.message);
            })
            .catch(error => {
              const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
             return toast.error(message);
            });
            return;
        }

      }
    }

    if (!otpInput1 || !otpInput2) {
      toast.error("Please enter the OTP before saving 2.");
      return;
    }

    if (otpInput1 && otpInput2 && (showEmailPopup || showMobilePopup)) {
      // If OTP is entered, you can proceed with saving the edited data

      // for mobile verification
      if (showMobilePopup) {
        await axios.put(`${API_URL}/api/user/change-phone-number`, { newPhone: details.phone, oldOtp: otpInput1, newOtp: otpInput2 }, config)
          .then(response => {
            toast.success(response.data.message);
            setShowMobilePopup(false);
            setEditedField(null);
            dispatch(getUser());
          })
          .catch(error => {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            toast.error(message);
          });
      }

      // for email verification
      if (showEmailPopup) {
        try {
          const response = await axios.put(`${API_URL}/api/user/change-email`, { newEmail:details.email, oldOtp: otpInput1, newOtp: otpInput2, resethash: resetHash }, config);
          setShowEmailPopup(false);
          setEditedField(null);
          dispatch(getUser());
          toast.success(response.data.message);
        } catch (error) {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          toast.error(message);
        }
      }

      setOtpInput1('');
      setOtpInput2('')
      // dispatch(updateUser({ details, id: user._id }));
      // setEditedField(null); // Reset the edited field
      // setShowEmailPopup(false);
      // setShowMobilePopup(false);
      // toast.success("Changes saved successfully!");
    } else {
      // If OTP fields are empty, show an error message
      toast.error("Please enter the OTP before saving 3.");
    }


    // Check if OTP is verified (uncomment when otp is verified)
    // if (!isOtpVerified) {
    //   toast.error("Please verify OTP before saving.");
    //   return;
    // }

    // const d = { ...details, ['phone']: details['phone'] === user.phone ? '' : details['phone'] }

    // dispatch(updateUser({ d, id: user._id }))
    // setEditedField(null);
    // setShowEmailPopup(false);
    // setShowMobilePopup(false);
    // toast.success("Changes saced sucessfully!")

    // setIsOtpVerified(false); // Reset OTP verification status

  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Allow only digits and limit to a maximum length of 10
      const formattedValue = value.replace(/\D/g, '').slice(0, 10);
      setDetails({
        ...details,
        [name]: formattedValue,
      });
    } else if (name === 'fullname') {
      // Limit to a maximum length of 50 characters
      const trimmedValue = value.slice(0, 50);
      setDetails({
        ...details,
        [name]: trimmedValue,
      });
    } else if (name === 'email') {
      // Allow any input for email, even if it doesn't match the expected format
      setDetails({
        ...details,
        [name]: value,
      });
    } else {
      setDetails({
        ...details,
        [name]: value,
      });
    }
  };



  const cancelEdit = () => {
    setDetails({

      fullname: (user && user.fullname) || '',
      email: (user && user.email) || '',
      password: '.....',
      phone: user?.phone?.replace('+91', '') || '',
    }
    )
    setEditedField(null);
  }

  const onChangePassword = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    })
  }

  const handleCancelPasswordChange = () => {
    toast.error('changes are not saved')
    setChange(false);
    setTempPasswords({}); // Reset temporary passwords
  };


  useEffect(() => {
    if (isError && message) {
      toast.error(message);
      dispatch(resetMessage());
    }
    if (isSuccess && message) {
      toast.success(message);
      dispatch(resetMessage());
    }
  }, [message, isError, isSuccess])

  const handleChangePassword = () => {
    setChange(!isChange);

    dispatch(changePassword({ id: user._id, ...passwords }))
  }


  const handleOtpInputChange1 = (e) => {
    // Allow only digits in the first OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setOtpInput1(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };

  const handleOtpInputChange2 = (e) => {
    // Allow only digits in the second OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setOtpInput2(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };





  return (
    <div className="mt-4 space-y-4">
      {inputFields.map((element, index) => (
        <div key={index} className="relative flex flex-col justify-start items-start">
          {editedField === element.name ? (
            <>
              <InputBox {...element} value={details[element.name]} changeInput={changeInput} />
              <div className="mt-2 flex">
                {element.name === 'phone' || element.name === 'email' ? (
                  <button
                    className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                    onClick={() => handleSendOTP(element.name)}
                  >
                    Send OTP
                  </button>
                ) : (
                  <button
                    className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                    onClick={handleSaveUpdates}
                  >
                    Save
                  </button>
                )}
                <button
                  className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                  onClick={cancelEdit}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <InputBox {...element} value={details[element.name]} disabled={true} />
              <button
                className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                onClick={() => handleEdit(element.name)}
              >
                Edit
              </button>
            </>
          )}
        </div>
      ))}

      {showEmailPopup && (
        // Email popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg flex flex-col">

            <div className="flex justify-end">
              <button className="text-[#8A58DC]"
                onClick={() => setShowEmailPopup(false)}>
                <RxCross2 />
              </button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Email</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Email'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />
              <input
                type="text"
                value={otpInput2}
                onChange={handleOtpInputChange2}
                placeholder="Enter OTP for New Email"
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <button
                className='flex text-[#8A58DC] justify-start w-auto'
                onClick={()=>handleResendOTP('email')}>
                Resend OTP
              </button>


              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleSave}
              >
                Submit OTP
              </button>
            </div>
          </div>

        </div>
      )}


      {showAddEmailPopup && (
        // Email popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg flex flex-col">

            <div className="flex justify-end">
              <button className="text-[#8A58DC]"
                onClick={() => showAddEmailPopup(false)}>
                <RxCross2 />
              </button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Email</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Email'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <button
                className='flex text-[#8A58DC] justify-start w-auto'
                onClick={()=>handleResendOTP('email')}>
                Resend OTP
              </button>


              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleSave}
              >
                Submit OTP
              </button>
            </div>
          </div>

        </div>
      )}

      {showMobilePopup && (
        // Mobile popup content
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-5 w-[350px] rounded-md shadow-lg">

            <div className="flex justify-end">
              <button className="text-[#8A58DC]"
                onClick={() => setShowMobilePopup(false)}>
                <RxCross2 />
              </button>
            </div>
            <div className='flex justify-start p-2'>
              <label className="font-semibold md:w-48">Verify Mobile Number</label>
            </div>
            <div className="flex flex-col gap-y-4">
              <input
                type="text"
                value={otpInput1}
                onChange={handleOtpInputChange1}
                placeholder={'Enter OTP for Current Number'}
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />
              <input
                type="text"
                value={otpInput2}
                onChange={handleOtpInputChange2}
                placeholder="Enter OTP for New Number"
                className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
              />

              <button
                className='flex text-[#8A58DC] justify-start w-auto'
                onClick={()=>handleResendOTP('phone')}>
                Resend OTP
              </button>


              <button
                className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                onClick={handleSave}
              >
                Submit OTP
              </button>
            </div>

          </div>
        </div>

      )}


      <div className="mt-4 flex flex-col justify-start items-start">
        {!isChange && (
          <>
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Change Password
            </label>
            <input
              id="change-password"
              label="Confirm New Password"
              name="new-password"
              autoComplete="new-password"
              type="password"
              maxLength={16}
              minLength={8}
              disabled={true}
              value="***********"
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
          </>
        )}
        {isChange && (
          <>
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Previous Password
            </label>
            <input
              id="prevPassword"
              label="Previous Password"
              name="prevPassword"
              autoComplete="prevPassword"
              type="password"
              maxLength={16}
              minLength={8}
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
            <label className='text-gray-700 text-sm font-bold mb-2'>
              New Password
            </label>
            <input
              id="newPassword"
              label="New Password"
              name="newPassword"
              maxLength={16}
              minLength={8}
              autoComplete="newPassword"
              type="password"
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
            <label className='text-gray-700 text-sm font-bold mb-2'>
              Confirm New Password
            </label>
            <input
              id="cnewPassword"
              label="Confirm New Password"
              name="cnewPassword"
              autoComplete="cnewPassword"
              maxLength={16}
              minLength={8}
              type="password"
              onChange={onChangePassword}
              className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2'
              required
            />
          </>
        )}
        <div className="flex mt-2">
          {!isChange && (
            <button
              className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC]"
              onClick={() => setChange(!isChange)}
            >
              Change
            </button>
          )}
          {isChange && (
            <>
              <button
                className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                onClick={handleCancelPasswordChange}
              >
                Cancel
              </button>

              <button
                className="bg-purple-600 text-white px-2 py-1 rounded-md hover:bg-[#F16080] active:bg-[#8A58DC] ml-2"
                onClick={handleChangePassword}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>

      {/* <Toaster /> */}
    </div>

  );
};

export default LoginAndSecurity;
