
// import React, { useState, useRef, useEffect } from "react";
// import { IoIosArrowRoundBack } from 'react-icons/io';
// import { TbCircleFilled } from 'react-icons/tb';
// import { IoSend } from 'react-icons/io5';

// const VendorChatBot = () => {
//   const [messages, setMessages] = useState([]);
//   const [inputMessage, setInputMessage] = useState("");
//   const chatContainerRef = useRef(null);

//   const handleInputChange = (e) => {
//     setInputMessage(e.target.value);
//   };

//   const handleSendMessage = () => {
//     if (inputMessage.trim() !== "") {
//       const newMessage = {
//         text: inputMessage,
//         user: "user",
//       };

//       setMessages((prevMessages) => [...prevMessages, newMessage]);
//       setInputMessage("");

//       // Simulate a bot response after a short delay (replace with actual bot logic)
//       setTimeout(() => {
//         const botResponse = {
//           text: "This is a bot response.",
//           user: "bot",
//         };

//         setMessages((prevMessages) => [...prevMessages, botResponse]);
        
//         // Scroll to the bottom of the chat container when new messages are added
//         if (chatContainerRef.current) {
//           chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//       }, 1000);
//     }
//   };

  
//   useEffect(() => {
//     // Scroll to the bottom of the chat container when new messages are added
//     if (chatContainerRef.current) {
//       chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//     }
//   }, [messages]);
  
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSendMessage();
//     }
//   };
//   return (
//     <>
//       <div className="px-20 py-4 h-60">
//         <div className=" bg-white border h-[580px]">
//           {/* chatbot header */}
//           <div className="flex items-center border-b-2 w-1/4 p-4">
//             <IoIosArrowRoundBack className="text-3xl mr-4" />
//             <div>
//               <h2 className="text-blue-500 text-xl font-bold">The Ekaiv Chat Bot</h2>
//               <div className="flex items-center gap-x-1 text-green-600">
//                 <TbCircleFilled className="text-[8px]" />
//                 <h2> Online</h2>
//               </div>
//             </div>
//           </div>
//           {/* Chat Section */}`
//           <div
//             ref={chatContainerRef}
//             className="px-6 py-4 h-96 overflow-y-scroll flex flex-col gap-y-2"
//           >
//             {messages.map((message, index) => (
//               <div
//                 key={index}
//                 className={`w-[40%] p-4  ${
//                   message.user === "user"
//                     ? "bg-[#7065E2] text-white self-end text-right rounded-b-full rounded-l-full w-fit"
//                     : "bg-[#EEEEEE] text-black rounded-t-full rounded-r-full  w-fit"
//                 }`}
//               >
//                 {message.text}
//               </div>
//             ))}
//           </div>
//           {/* Chat Send Section */}
//           <div className="mx-auto w-11/12 bottom-0">
//             <div className="relative">
//               <input
//                 type="text"
//                 id="message"
//                 name="message"
//                 placeholder="Write your message"
//                 className="w-full px-10 font-bold py-4 rounded-3xl shadow-md shadow-black outline-none"
//                 value={inputMessage}
//                 onChange={handleInputChange}
//                 onKeyPress={handleKeyPress}
//               />
//               <button onClick={handleSendMessage}>
//                 <IoSend className="text-2xl absolute right-4 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer" />
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VendorChatBot;
import React from 'react'
import ComingSoon from '../../../pages/ComingSoon'

function  VendorChatBot() {
  return (
	<div className='h-full'>
	  <ComingSoon/>
	</div>
  )
}

export default  VendorChatBot;

