import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../common/Loader';

const VendorCard2 = ({ vendor }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const ShopAvatarImage = vendor?.ShopAvatar?.url;
  const businessName = vendor && vendor.BussinessDetails && vendor.BussinessDetails.Businessname;
  const state = vendor && vendor.BussinessDetails && vendor.BussinessDetails.BusinessAddress && vendor.BussinessDetails.BusinessAddress.state

  const handleImageLoad = () => {
    setImageLoading(false);
  };
  // const typeOfBusiness = vendor && vendor.BussinessDetails && vendor.BussinessDetails.typeOfBusiness
  return (
    <>
      {/* <section
      className="bg-gray-900 h-screen flex justify-center items-center gap-x-16 text-white"
    > */}
      <div
        className="w-[380px] h-[200px] flex justify-center item-center bg-transparent cursor-pointer group perspective max-sm:w-[280px] max-sm:h-[150px]"
      >
        <div
          className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000"
        >
          <div className="absolute backface-hidden border-2 w-full h-full">
          {ShopAvatarImage ? (
           <img
           src={ShopAvatarImage}
           alt="ShopAvatar Image"
           className='w-full h-full'
           onLoad={handleImageLoad}
         />
        ) : (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
            alt="Default Image"
            className='w-full h-full'
          />
        )}
          </div>
          {imageLoading && ShopAvatarImage && (
            <div className="absolute w-full h-full flex items-center justify-center bg-white">
             
            </div>
          )}
          
          <div className="absolute my-rotate-y-180 backface-hidden w-full h-full overflow-hidden" >
            <div
              className="text-center flex w-full flex-col items-center justify-center h-full text-gray-800" style={{ backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2FBecomeASeller%2FCatalogue%20gradient.png?alt=media&token=32ce33e8-7e85-482d-9737-2b55a3c5f49c") ' , backgroundSize: 'cover' }}>              <div className='absolute -bottom-40 delay-500 duration-1000 group-hover:bottom-10 pl-2 pr-2 max-sm:-bottom-10 max-sm:group-hover:bottom-5 '>
                <h1 className=" text-xl font-semibold break-words">{businessName}</h1>
                <p className="my-2 text-white">{state?.toUpperCase()}</p>
                <Link to={`/seller-profile/${vendor._id}`}>
                  <button
                    className="bg-[#F16080] p-1 px-4 mt-2 font-sm text-white rounded-sm   scale-0 group-hover:scale-125"
                  >
                    See More...
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </section> */}
    </>
  )
}

export default VendorCard2