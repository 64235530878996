import React, { useState, useCallback, useEffect } from 'react';
import DropBox from '../../VendorProfile/AddProduct/Dropbox';
import axios from 'axios'; // Import Axios
import { Tooltip } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { API_URL, config } from '../../../config/config';
import { useParams } from 'react-router-dom';
import Products from '../../../pages/Products/Products';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const ProductVerify = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [notVerifiedReasons, setNotVerifiedReasons] = useState("");
    const [products, setProducts] = useState(null)
    const [images, setImages] = useState([]);
    const [productName, setProductName] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [material, setMaterial] = useState('');
    const [internalMaterial, setInternalMaterial] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [dimensions, setDimensions] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [modelSize, setModelSize] = useState('');
    const [sizeChartImage, setSizeChartImage] = useState(null);
    const [stock, setStock] = useState('');
    const [processingTime, setProcessingTime] = useState('');
    const [selfLife, setSelfLife] = useState('');
    const [imageUpload, setImageUpload] = useState(false);
    const [VideoUpload, setVideoUpload] = useState(false);

    const [theEkaivPrice, setTheEkaivPrice] = useState('');
    const [theEkaivDiscountPrice, setTheEkaivDiscountPrice] = useState('');

    const handleOriginalPriceChange = (event) => {
        // Allow only digits and limit to 10 digits
        const value = event.target.value.replace(/\D/g, '').slice(0, 10);
        setTheEkaivPrice(value);
    };

    const handleDiscountedPriceChange = (event) => {
        // Allow only digits and limit to 10 digits
        const value = event.target.value.replace(/\D/g, '').slice(0, 10);
        setTheEkaivDiscountPrice(value);
    };


    useEffect(() => {
        const fetchProductDetails = async () => {


            try {
                if (!params) {
                    console.error('Product or productId is undefined');
                    return;
                }

                const response = await axios.get(
                    `${API_URL}/api/products/getProduct/${params.itemId}`
                );

                const productDetails = response.data;
                setProducts(productDetails.product);


            } catch (error) {
                if (error.response) {
                    toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
                }
                console.error('Error fetching product details:', error);
            }
        };

        fetchProductDetails();
    }, [params]);

    const handleVideoChange = (event) => {
        const videoFile = event.target.files[0];

        // Check if the file size is within the allowed limit (50 MB)
        const maxSize = 50 * 1024 * 1024; // 50 MB in bytes
        if (videoFile && videoFile.size > maxSize) {
            alert('File size exceeds the maximum limit (50 MB). Please choose a smaller file.');
            return;
        }

        setSelectedVideo(videoFile);
    };




    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file, index) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    setImages((prevState) => [
                        ...prevState,
                        { id: index, src: e.target.result },
                    ]);
                };
                reader.readAsDataURL(file);
            }
        });
    }, []);


    const handleSizeChange = (event) => {
        const size = event.target.value;
        setSelectedSizes((prevSelectedSizes) => {
            if (prevSelectedSizes.includes(size)) {
                return prevSelectedSizes.filter((s) => s !== size);
            } else {
                return [...prevSelectedSizes, size];
            }
        });
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     const submitData = {
    //         productName,
    //         selectedCategory,
    //         type,
    //         description,
    //         material,
    //         internalMaterial,
    //         selectedSizes: selectedSizes.join(','),
    //         dimensions,
    //         originalPrice,
    //         discountPrice,
    //         modelSize,
    //         processingTime,
    //         selectedVideo,
    //         sizeChartImage,
    //         stock,
    //         // Add a verification flag or any other data you want to send for verification
    //         verified: true, // Modify this based on your verification logic
    //     };

    //     try {
    //         // Make a POST request to update the verification status in the backend
    //         const response = await axios.post(
    //             `${API_URL}/api/products/products/${products._id}/verify`,
    //             submitData
    //         );
    //      

    //         // Log the response from the server
    //         // You can redirect the user or perform any other necessary actions upon successful submission
    //     } catch (error) {
    //         console.error("Error submitting the form:", error);
    //     }
    // };








    useEffect(() => {
        if (products) {
            setProductName(products.productName);
            setDescription(products.description);
            setMaterial(products.material);
            setSelectedCategory(products.selectedCategory);
            setProcessingTime(products.processingTime);
            setDimensions(products.dimensions);
            setOriginalPrice(products.originalPrice);
            setDiscountPrice(products.discountPrice)
            setImages(products.images[0])
            // setSelectedVideo(products.video)
            setStock(products.stock);
            setSelfLife(products.selfLife);
            setInternalMaterial(products.internalMaterial);
            setTheEkaivPrice(products.theEkaivPrice)
            setTheEkaivDiscountPrice(products.theEkaivDiscountPrice)
        }
    }, [params, products])


    const handleVerify = async () => {
        try {
            const ekaivPrices = {
                theEkaivPrice,
                theEkaivDiscountPrice
            }
            const response = await axios.put(
                `${API_URL}/api/admin-profile/products/${products._id}/verify`, ekaivPrices
            );
            toast.success("Product Verified sucessfully")

            // Handle verification response as needed
        } catch (error) {
            if (error.response) {
                toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
            }
            console.error('Error verifying product:', error);
        }
        window.scrollTo(0, 0);
        navigate(-1);
    };

    const handleReject = async () => {
        if (!notVerifiedReasons) {
            // If the "Not Verified Reasons" field is not filled, show a toast message and return
            toast.error("Please provide a reason for rejection");
            return;
        }


        try {
            const response = await axios.put(
                `${API_URL}/api/admin-profile/products/${products._id}/reject`, {
                reason: notVerifiedReasons,
            }
            );
            toast.success("Product Rejected Sucessfully.");
            // Handle rejection response as needed
        } catch (error) {
            if (error.response) {
                toast.error(` ${(error.response && error.response.data && error.response.data.message) || error.message || error.toString()}`);
            }
            console.error('Error rejecting product:', error);
        }

        window.scrollTo(0, 0);
        navigate(-1);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const submitData = {
            productName,
            selectedCategory,
            type,
            description,
            material,
            internalMaterial,
            selectedSizes: selectedSizes.join(','),
            dimensions,
            originalPrice,
            discountPrice,
            modelSize,
            processingTime,
            selectedVideo,
            sizeChartImage,
            stock,
        };

        try {
            const response = await axios.post(
                `${API_URL}/api/admin-profile/products/${products._id}/verify`,
                submitData
            );

            // Handle submission response as needed
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (

        <div className='container mx-auto p-4 mb-2'>
            <form onSubmit={handleSubmit} className='max-w-full mx-auto bg-[#F5f6f9] shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 '>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className='left w-full flex-wrap flex flex-col px-2 mb-4'>
                        <div className='flex gap-2 '>
                            <label htmlFor='productName' className="text-sm font-semibold">Product Name</label>
                            <div>
                                <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 180 characters when entering the product name.</h1>} >
                                    <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <input
                                type="checkbox"
                                checked={productName}
                                onChange={() => setProductName(!productName)}
                            />
                            <input
                                type='text'
                                placeholder='Add name of your product'
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            {productName && (
                                <label className="text-green-400 flex justify-center items-center">
                                    Verified
                                </label>
                            )}

                        </div>

                        <div className='flex flex-col mt-4'>
                            <label htmlFor='category' className="text-sm font-semibold">Choose a category</label>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={selectedCategory}
                                    onChange={() => setSelectedCategory(!selectedCategory)}
                                />
                                <select
                                    id='category'
                                    name='category'
                                    value={selectedCategory}
                                    onChange={(e) => { setSelectedCategory(e.target.value) }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                >
                                    <option value='clothing'>Clothing</option>
                                    <option value='home-decor'>Home Decor</option>
                                    <option value='accessories'>Accessories</option>
                                    <option value='homemade-food'>Homemade Food</option>
                                </select>
                                {selectedCategory && (
                                    <label className="text-green-400 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col mt-4'>

                            {selectedCategory === 'clothing' ? <>
                                <label htmlFor='type' className="text-sm font-semibold">
                                    Gender</label>

                                <div className='flex flex-row mt-2 gap-2'>

                                    <input
                                        type="checkbox"
                                        checked={type}
                                        onChange={() => setType(!type)}
                                    />
                                    <select id='type' name='type' value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    >

                                        <option value='Men'>Men</option>
                                        <option value='Women'>Women</option>
                                        <option value='Boy'>Boy</option>
                                        <option value='Girl'>Girl</option>

                                    </select>
                                    {type && (
                                        <label className="text-green-400 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </> : <></>

                            }


                        </div>
                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='description' className="text-sm font-semibold">Product Specification</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 1000 characters when entering the product description.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='gap-2'>
                                <input
                                    type="checkbox"
                                    checked={description}
                                    onChange={() => setDescription(!description)}
                                />
                                {description && (
                                    <label className="text-green-400 ml-2">
                                        Verified
                                    </label>
                                )}
                                <textarea
                                    id='description'
                                    name='description'
                                    rows='8'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                >
                                </textarea>
                            </div>

                        </div>

                        <div className='flex flex-col mt-4'>
                            <label htmlFor='material' className="text-sm font-semibold">

                                {selectedCategory === 'homemade-food' ? 'Ingredients' : (selectedCategory === 'clothing' ? 'Fabric' : 'Material')}
                            </label>
                            <div className='flex flex-row gap-2'>

                                <input
                                    type="checkbox"
                                    checked={material}
                                    onChange={() => setMaterial(!material)}
                                />
                                <input
                                    type='text'
                                    placeholder={
                                        selectedCategory === 'homemade-food'
                                            ? 'Add ingredients for homemade food'
                                            : (selectedCategory === 'clothing' ? 'Fabric' : 'Material')
                                    }
                                    value={material}
                                    onChange={(e) => setMaterial(e.target.value)}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {material && (
                                    <label className="text-green-400 ml-2 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>
                        </div>

                        {selectedCategory === 'clothing' ? (
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='internalMaterial' className="text-sm font-semibold">Inner Fabric</label>
                                <div className='flex flex-row gap-2'>

                                    <input
                                        type="checkbox"
                                        checked={internalMaterial}
                                        onChange={() => setInternalMaterial(!internalMaterial)}
                                    />

                                    <input
                                        type='text'
                                        placeholder='Internal Material'
                                        value={internalMaterial}
                                        onChange={(e) => setInternalMaterial(e.target.value)}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {internalMaterial && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        ) : null}


                        <div className='flex flex-col mt-4'>
                            <div className='flex gap-2'>
                                <label htmlFor='processing-time' className="text-sm font-semibold">Processing Time</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16, }}>Enter the number of days required for processing the order.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>

                            </div>
                            <div className='flex flex-row gap-2'>
                                <input
                                    type="checkbox"
                                    checked={processingTime}
                                    onChange={() => setProcessingTime(!processingTime)}
                                />

                                <input
                                    type='number'
                                    placeholder='Add processing time for the item'
                                    value={processingTime}
                                    onChange={(e) => setProcessingTime(e.target.value)}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {processingTime && (
                                    <label className="text-green-400 ml-2 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}

                            </div>
                        </div>

                    </div>
                    <div className='right flex flex-col w-full flex-wrap px-2 mb-4 mt-2'>
                        <div className='flex gap-2'>
                            <label className="text-sm font-semibold">Product Images</label>
                            <div>
                                <Tooltip title={<h1 style={{ fontSize: 16, }}>You need to add minimum 1 and maximum 5 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Ensure that the product showcases all the details.</h1>} >
                                    <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="flex flex-col flex-wrap mt-2 w-full lg:w-full md:w-1/3">
                            <div className='flex flex-row item items-center gap-2 mt-2'>
                                <input
                                    type="checkbox"
                                    checked={imageUpload}
                                    onChange={() => setImageUpload(!imageUpload)}
                                />

                                {products?.images?.map((image, index) => (
                                    <div key={index}>
                                        <Link to={image} target="_blank" rel="noopener noreferrer">
                                            <img src={image} alt={`Image ${index}`} className='w-16 h-16' />
                                        </Link>
                                    </div>
                                ))}
                                {imageUpload && (
                                    <label className="text-green-400 ml-2 flex">
                                        Verified
                                    </label>
                                )}

                                {/* {data?.BussinessDetails?.foodLicenseProof && (
<label className="text-green-400 flex justify-center items-center">
    Verified
</label>
)} */}
                            </div>
                            {/* <DropBox onDrop={onDrop} className="mt-2 border rounded p-4 justify-center items-center" /> */}
                            {/* <div className='flex flex-row  flex-wrap'>
                                {images.map((file, index) => (
                                    <div key={index} className="w-16 h-16 p-2 border border-gray-300">
                                        <img src={file.src} alt={`Product  ${index}`} className="w-12 h-12 object-cover" />
                                    </div>
                                ))}
                            </div> */}

                        </div>


                        {/* <div className='mt-4'>
                            <label htmlFor="video" className="text-sm font-semibold">
                                Product Video
                            </label>
                            <div className='flex flex-row gap-2 mt-2'>

                                <input
                                    type="checkbox"
                                    checked={VideoUpload}
                                    onChange={() => setVideoUpload(!VideoUpload)}
                                />
                                <input
                                    type="file"
                                    id="video"
                                    name="video"
                                    accept="video/*"
                                    onChange={handleVideoChange}
                                    max="52428800"
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {VideoUpload && (
                                    <label className="text-green-400 ml-2 flex justify-center items-center">
                                        Verified
                                    </label>
                                )}
                            </div>

                        </div> */}

                        <div className="w-full lg:w-full md:w-1/3 px-2 mb-4 mt-4">
                            {selectedCategory === "clothing" ? <>
                                <div className="mt-2">

                                    <div className="flex gap-4 flex-wrap">
                                        {type === 'Boy' || type === 'Girl' ? (
                                            <div className="flex-col flex mt-2 ">
                                                <h3 className="text-sm font-semibold ">Select Years:</h3>
                                                <div className=''>
                                                    {['0-1', '1-3', '3-5', '5-7', '7-9', '9-11', '11-13'].map((size, index) => (
                                                        <label key={index} className="inline-flex items-center mt-2">
                                                            <input
                                                                type="checkbox"
                                                                value={size}
                                                                checked={selectedSizes.includes(size)}
                                                                onChange={handleSizeChange}
                                                                className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                            />
                                                            <span className="ml-2 mr-4">{size}</span>
                                                        </label>
                                                    ))}

                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex gap-4 mt-2 items-center">
                                                <h3 className="text-sm font-semibold">Select Sizes:</h3>

                                                {['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'].map((size, index) => (
                                                    <label key={index} className="inline-flex items-center mt-2">
                                                        <input
                                                            type="checkbox"
                                                            value={size}
                                                            checked={selectedSizes.includes(size)}
                                                            onChange={handleSizeChange}
                                                            className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                        />
                                                        <span className="ml-2 mr-4">{size}</span>

                                                    </label>
                                                ))}


                                            </div>
                                        )}



                                    </div>
                                </div>
                                <div className="mt-2 flex gap-2">

                                </div> </> : <></>}


                        </div>
                        <div className="w-full md:w-1/2 lg:w-full px-2 mb-4 mt-2">
                            {selectedCategory === 'clothing' ? (
                                <>
                                    <label className="text-sm font-semibold" htmlFor='size-chart'>Size Chart Image</label>
                                    <div className='flex flex-row gap-2 mt-2 justify-start items-center'>
                                        <input
                                            type="checkbox"
                                            checked={sizeChartImage}
                                            onChange={() => setSizeChartImage(!sizeChartImage)}
                                        />
                                        <input type='file'
                                            className="mt-4 border rounded p-4 "
                                            onChange={(e) => setSizeChartImage(e.target.files[0])}
                                        />
                                        {sizeChartImage && (
                                            <label className="text-green-400 ml-2 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>

                                    <label htmlFor='model-size' className="text-sm font-semibold mt-4">Model Size</label>
                                    <div className='flex flex-row gap-2 mt-2 justify-start items-center'>
                                        <input
                                            type="checkbox"
                                            checked={modelSize}
                                            onChange={() => setModelSize(!modelSize)}
                                        />
                                        <input
                                            type='text'
                                            placeholder='Add size of the model'
                                            value={modelSize}
                                            onChange={(e) => setModelSize(e.target.value)}
                                            className="mt-4 px-3 py-2 border w-full rounded-md focus:outline-none focus:border-[#8A58DC] "
                                        />
                                        {modelSize && (
                                            <label className="text-green-400 ml-2 flex justify-center items-center">
                                                Verified
                                            </label>
                                        )}

                                    </div>
                                </>
                            ) : (
                                selectedCategory !== 'homemade-food' ? (
                                    <div className=" px-2 mb-2 mt-4">
                                        <label htmlFor='dimensions' className="text-sm font-semibold">Dimensions</label>
                                        <div className='flex flex-row gap-2 mt-2 w-full     '>

                                            <input
                                                type="checkbox"
                                                checked={dimensions}
                                                onChange={() => setDimensions(!dimensions)}
                                            />
                                            <input
                                                type='number'
                                                placeholder="Add product dimensions (e.g., 10x12x5 )"
                                                value={dimensions}
                                                onChange={(e) => setDimensions(e.target.value)}
                                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                            />
                                            {dimensions && (
                                                <label className="text-green-400 ml-2 flex justify-center items-center">
                                                    Verified
                                                </label>
                                            )}
                                        </div>

                                    </div>

                                ) : null

                            )}
                        </div>


                        <div className="flex lg:flex-row max-lg:flex-col gap-4 mt-2">
                            <div>
                                <label htmlFor='original-price' className="text-sm font-semibold">Original Price</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={originalPrice}
                                        onChange={() => setOriginalPrice(!originalPrice)}
                                    />

                                    <input
                                        type='number'
                                        placeholder='Add original price of the product'
                                        value={originalPrice}
                                        onChange={(e) => {
                                            let newValue = parseInt(e.target.value, 10);
                                            if (isNaN(newValue) || newValue < 0) {
                                                newValue = 0;
                                            }
                                            setOriginalPrice(newValue);
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {originalPrice && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div>
                                <label htmlFor='discount-price' className="text-sm font-semibold mt-2">Discounted Price</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={discountPrice}
                                        onChange={() => setDiscountPrice(!discountPrice)}
                                    />
                                    <input
                                        type='number'
                                        placeholder='Add discount price of the product'
                                        value={discountPrice}
                                        onChange={(e) => {
                                            let newValue = parseFloat(e.target.value);
                                            if (isNaN(newValue) || newValue < 0) {
                                                newValue = 0;
                                            }
                                            setDiscountPrice(newValue);
                                        }}
                                        className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                    />
                                    {discountPrice && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex lg:flex-row max-lg:flex-col gap-4 mt-4 mb-4">
                            {/* Ekaiv prizes */}
                            <div>
                                <label htmlFor='ekaivorigina-price' className="text-sm font-semibold mt-2">Ekaiv Original Price</label>
                                <input
                                    type='text'
                                    placeholder='Add Ekaiv original price of the product'
                                    value={theEkaivPrice}
                                    onChange={handleOriginalPriceChange}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                            </div>
                            <div>
                                <label htmlFor='ekaivdiscount-price' className="text-sm font-semibold mt-2">Ekaiv Discounted Price</label>
                                <input
                                    type='text'
                                    placeholder='Add Ekaiv discount price of the product'
                                    value={theEkaivDiscountPrice}
                                    onChange={handleDiscountedPriceChange}
                                    className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                />
                            </div>
                        </div>

                        {selectedCategory !== 'homemade-food' ? (
                            <div className='flex flex-col mt-4'>
                                <label htmlFor='expiry-date' className="text-sm font-semibold">Stock</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={stock}
                                        onChange={() => setStock(!stock)}
                                    />
                                    <input
                                        type='number'
                                        placeholder='stock of product'
                                        value={stock}
                                        onChange={(e) => {
                                            let newValue = parseInt(e.target.value, 10);
                                            if (isNaN(newValue) || newValue < 0) {
                                                newValue = 0;
                                            }
                                            setStock(newValue);
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {stock && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        ) : <></>}



                        {selectedCategory === 'homemade-food' && (
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='expiry-date' className="text-sm font-semibold">Self Life</label>
                                <div className='flex flex-row gap-2 mt-2'>
                                    <input
                                        type="checkbox"
                                        checked={selfLife}
                                        onChange={() => setSelfLife(!selfLife)}
                                    />
                                    <input
                                        type='text'
                                        placeholder='Add expiry date of the item'
                                        value={selfLife}
                                        onChange={(e) => setSelfLife(e.target.value)}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {selfLife && (
                                        <label className="text-green-400 ml-2 flex justify-center items-center">
                                            Verified
                                        </label>
                                    )}
                                </div>
                            </div>
                        )}


                    </div>
                </div>
                <div className="w-full flex-col justify-center items-center">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="myTextArea">
                        Not Verified Reasons :
                    </label>
                    <textarea
                        className="shadow appearance-none w-full py-2 px-3 border rounded-xl focus:outline-none focus:border-[#8A58DC] text-gray-700 leading-tight focus:shadow-outline"
                        id="myTextArea"
                        rows="4"
                        placeholder="Enter your text here"
                        value={notVerifiedReasons}
                        onChange={(e) => setNotVerifiedReasons(e.target.value)}
                    ></textarea>
                </div>


                <div className="w-full mt-4 flex gap-5">
                    <button type='submit' className="bg-custom-purple hover:bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleVerify}>Verify</button>
                    <button
                        type='button'
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleReject} >
                        Reject
                    </button>
                </div>

            </form>
        </div>
    );
}

export default ProductVerify;

