import React from 'react'

const LiveChat = () => {

  return (
    <>
      <h1 className='animate-pulse text-[#8A58DC] overflow-hidden whitespace-nowrap text-center text-3xl p-5 font-bold'>Coming Soon...</h1>
      <div className='flex justify-center items-center'>
        <img
          className='h-[500px]'
          src="https://img.freepik.com/free-vector/chatbot-artificial-intelligence-abstract-concept-illustration_335657-3723.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" alt="" />

      </div>
    </>

  )
}

export default LiveChat