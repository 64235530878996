import React, { useState, useEffect } from 'react';
import constant from '../../constant';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loader from '../common/Loader';
const POLYGON=constant.IMAGES_ERRORCRVES.POLYGON;

function Success() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for demonstration purposes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className='bg-[#F3F3F3] p-8 '>
        <div className='flex justify-center items-center rounded-md bg-white'>
          <div className='grid grid-cols-1'>
            <div className='text-2xl text-center sm:text-5xl md:text-8xl px-10 pb-10 pt-32 text-700 text-[#E9E9E9] '>
              Thank You!
            </div>
            <div><img src={POLYGON} alt='polygon' /></div>
            <div>
              <h1 className='text-center font-bold text-lg pt-5'>Your Payment is Successful</h1>
              <p className='text-center text-sm pt-2'>
                Thank you for your payment. You will receive a receipt on your registered email.
              </p>
                <Link to='/' >
              <span className='flex justify-center items-center pt-5 pb-28'>

                <button className='flex items-center space-x-2 text-[5px] sm:text-xs bg-[#DF5F8C] text-white mt-2 py-1 md:py-2 px-3 md:px-8 rounded-md'>
                  <span>Back to Home</span>
                  <FaArrowRight />
                </button>
              </span>
                </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;
