import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import constant from '../../constant';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../config/config';
import Loader from '../common/Loader';


const HAPPYREPUBLICDAY=constant.IMAGES_IMAGES.ASSISTANCE.DIGITAL_MARKETING_GRAPHIC_DESIGNING.HAPPYREPUBLICDAY;
const DR_NISHANT=constant.IMAGES_IMAGES.ASSISTANCE.DIGITAL_MARKETING_GRAPHIC_DESIGNING.DR_NISHANT;
const ONAM=constant.IMAGES_IMAGES.ASSISTANCE.DIGITAL_MARKETING_GRAPHIC_DESIGNING.ONAM;
const PADWA=constant.IMAGES_IMAGES.ASSISTANCE.DIGITAL_MARKETING_GRAPHIC_DESIGNING.PADWA;

function DmGd() {
  const formdm = useRef();

  const [loadingImages, setLoadingImages] = useState(true);
  const handleImageLoad = () => {
    setLoadingImages(false);
  };

  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const sendEmail = async (e) => {
    e.preventDefault();
  
    // Your form validation logic here...
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }
  
    try {
      const response = await axios.post(`${API_URL}/api/email/sendGraphicDesigningEmail`, formData);
  
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirement: '',
          businessType: '',
          location: '',
        });
      } else {
        toast.error('Failed to submit form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form. Please try again.');
    }
    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };


  const previousWorks = [
    {
      title: 'Creative Event Design',
      description: 'Capturing the essence of people through striking portrait photography. Memorable moments in every frame.',
      imageUrl: HAPPYREPUBLICDAY,
    },
    {
      title: 'Modern Interior Design',
      description: 'Innovative interior designs that transform living spaces. Harmony and style in every room.',
      imageUrl: DR_NISHANT,
    },
    {
      title: 'Abstract Art Photography',
      description: 'Exploring abstract forms and colors in the world of photography. Unveiling artistry in everyday scenes.',
      imageUrl: ONAM,
    },
    {
      title: 'Logo Design',
      description: 'Crafting unique logos that symbolize brands. Graphic design expertise that leaves a lasting impression.',
      imageUrl: PADWA,
    },
    // Add more works with specific details and actual image URLs
  ];

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessType:'',
          location:'',
    // Add more form fields as needed
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 
  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));

   
  };
  


  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name', // Placeholder text
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email', // Placeholder text
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone', // Placeholder text
    },
    {
      name: 'requirement',
      label: 'Requirement Type',
      type: 'text',
      placeholder: 'Your Requirement', // Placeholder text
    },
    {
      name: 'businessType',
      label: 'Business Type',
      type: 'text',
      placeholder: 'Business Type', // Placeholder text
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      placeholder: 'Location', // Placeholder text
    },
    // Add more form fields as needed
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };

  return (
    <>
      <div className='bg-gray-100'>


        <div className=" p-4">
          <h2 className="text-xl min-[510px]:text-2xl text-center font-bold mb-4 text-gray-800">Digital Marketing / Graphic Designing</h2>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {previousWorks.map((work, index) => (
              <div key={index} className=" flex justify-center items-center relative">
                <div className="">
                {loadingImages &&  <div className="absolute inset-0 flex items-center justify-center"><Loader/></div>}
                  <img
                    src={work.imageUrl}
                    alt={work.title}
                    onLoad={handleImageLoad}
                    className="w-[300px] h-[300px]  rounded-md object-contain opacity-100 hover:opacity-100  "
                  />
                  {/* <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 text-white text-center opacity-0 hover:opacity-100 transition-opacity p-4">
                                    <h3 className="text-lg font-bold">{work.title}</h3>
                                    <p className="text-gray-200 ">{work.description}</p>
                                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2 pb-5 mb-5'>


          <div className=' flex flex-col justify-center items-center'>
            <div className=' bg-white rounded-2xl p-5 w-[100%] md:w-[70%]'>
              {/* <p className="text-gray-600 mb-4">
           Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum assumenda a omnis aliquid, voluptatum officiis error consequatur? Voluptatum provident illum officiis totam ipsum exercitationem quis temporibus, fuga eaque, ad laudantium.
        </p> */}
              <form ref={formdm} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? ' w-full md:w-1/2' : 'w-full'}`}>
                      {/* <label htmlFor={field.name} className="block text-gray-700 font-bold">
                        {field.label}:
                      </label> */}
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder} // Placeholder text
                        className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                        maxLength={field.name === 'phone' ? 10 : undefined}
                        required
                      />
                    </div>
                  ))}</div>
                <button
                  type="submit"
                  className="px-4 py-2 bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
                  disabled={buttonDisabled}
                >
                  Submit 
                </button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here*/}
          </div>

          <div className=' flex flex-col justify-center  rounded-lg  order-1 md:order-2 py-20 md:py-5 px-2 md:px-10 lg:px-20'>
            <h1 className=' font-bold text-2xl text-violet-500 sm:first-letter:indent-2'>Contact Us to Get Started ...</h1>
            <p className=' text-sm rounded-lg p-2 text-justify'>
              Transform your marketing strategy with our graphic designing and digital marketing assistance. Our experts will collaborate with you to create visually stunning designs and strategic digital marketing campaigns. Whether it's crafting eye-catching festival posts or developing a comprehensive growth strategy, we're here to amplify your brand's online presence and reach.
            </p>

          </div>
        </div>
      </div>
    </>
  )
}

export default DmGd
