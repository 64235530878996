import React, { useState, useEffect } from 'react';
import { API_URL } from '../../../config/config';


const NotificationDropdown = ({notifications}) => {
  const [seenNotifications, setSeenNotifications] = useState({});
  const [data, setData] = useState([]);

  const [count , setCount] = useState(0);
 
 
  const markAsSeen = (index) => {
    
    setSeenNotifications((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    fetchData();
  };


  
  const fetchData = () => {
    fetch(`${API_URL}/api/vendor/${notifications}/notifications`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((result) => {
        setData(result.notifications);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
   // Check the type of data
  const handleNotificationClick = (index) => {
    markAsSeen(index);
  };
  
  useEffect(() => {
    fetchData(); // Fetch data initially
  }, []);


  
  

  return (

    <div className="absolute bg-white shadow-md top-full right-0 mt-2 h-[195px] overflow-y-auto w-[70vw] sm:w-[30vw] xl:w-[20vw]">
    {data.map((notification, index) => (
      <div
        key={index}
        className={`p-3 border-b hover:bg-gray-400 cursor-pointer transition-colors duration-300`}
        onClick={() => handleNotificationClick(index)}
      >
        <div className="flex items-center">
          {!seenNotifications[index] && (
            <span
              className="w-2 h-2 mr-2 bg-red-500 rounded-full cursor-pointer"
            ></span>
          )}

          {notification.message}
        </div>
      </div>
    ))}
  </div>
  );
};

export default NotificationDropdown;
