import React,{useState, useRef} from 'react'
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import constant from '../../constant';
import axios from 'axios';
import { API_URL } from '../../config/config';
import Loader from '../common/Loader';

function Learning() {
  //email.js

  const TRAINING_DEVELOPMENT=constant.IMAGES_IMAGES.ASSISTANCE.TRAINING_DEVELOPMENT;
  const formlearn = useRef();
  const [phoneError, setPhoneError] = useState('');

  const [imageLoaded, setImageLoaded] = useState(false);


  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
  
    // Your form validation logic here...
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      toast.error('Please enter a valid 10-digit phone number');
      return;
    } else {
      setPhoneError('');
    }
  
    try {
      // Make a POST request to your server endpoint
      const response = await axios.post(`${API_URL}/api/email/sendTraningAndLearningEmail`, formData);
  
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          phone: '',
        });
      } else {
        toast.error('Failed to submit form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form. Please try again.');
    }
    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        
      });
    
      const handleFormSubmit = (e) => {
        e.preventDefault();
        // Implement logic to handle form submission with the formData state.
         // Example: Log form data to the console
      };

  return (
    <>
    <div className=' bg-gray-100 p-2 sm:p-10'>
   
   <div className=' w-full flex flex-col justify-center items-center'>
   <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Training & Development</h2>
   <p className=' rounded-lg py-5 px-7 text-justify text-sm'>
          Enhance your entrepreneurial skills through our communication and learning programs. Whether you're looking to improve your language proficiency, master the art of effective communication, or learn the ropes of selling on The Ekaiv, our resources are designed to empower you with the knowledge and skills needed for success in the dynamic world of business.
          </p>
   </div>
    <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
    <div className=' flex flex-col justify-center items-center' >
        <div className="bg-white rounded-2xl p-5 w-[100%] md:w-[70%]">
      {/* <p className="text-gray-600">
        Information about the CA goes here. Contact us for assistance.
      </p> */}
      <form ref={formlearn} onSubmit={sendEmail} className="mt-4 flex flex-col">
        <div className="mb-4">
          
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Name"
          />
        </div>
        <div className="mb-4">
          
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            pattern="[0-9]*"
                  maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericInput = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
                    setFormData({ ...formData, phone: numericInput });
                    setPhoneError(numericInput.length === 10 ? '' : 'Please enter a 10-digit phone number');
                  }}
            
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Phone"
          />
        </div>
        
        <button
          type="submit"
          className="px-4 py-2 bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
          disabled={buttonDisabled}
        >
          Get Started
        </button>
      </form>
      </div>
      {/* Add any other UI elements and functionality here */}
    </div>
        <div className='  flex justify-center items-center py-20 md:py-0 px-10 lg:px-20'>

          <img
      src={TRAINING_DEVELOPMENT}
      alt="Banner"
      onLoad={() => setImageLoaded(true)}
    />
    {!imageLoaded && (
      <div className='flex items-center justify-center md:h-[610px] sm:h[400px] h-[210px]'>
        <Loader />
      </div>
    )}
        </div>



      </div>
    </div>
    
    </>
  )
}

export default Learning
