import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { BiSort } from 'react-icons/bi';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {API_URL,  config} from '../../../config/config';

// Create a custom Modal component
function StoreStatusModal({ isActive, onClose, onStatusChange }) {
  const vendor = useSelector((state) => state.vendor);
  const vendorId = vendor && vendor.vendor && vendor.vendor._id;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleConfirm = async () => {
    if (isActive === false && (!startDate || !endDate)) {
      return toast.error("Please provide both start and end date");
    }
    // Check if the end date is greater than or equal to the start date
  if (isActive === false && new Date(endDate) < new Date(startDate)) {
    return toast.error("End date should be greater than or equal to start date");
  }
    try {
      const response = await axios.put(`${API_URL}/api/vendor/${vendorId}/store-status` , {
        startDate,
        endDate,
        ...(isActive ? { isActive: true } : { isActive: false }),
      });
      onStatusChange(startDate, endDate, isActive);
      onClose();
      toast.success('Status changed successfully!');
      if (response.status >= 200 && response.status < 300) {

        window.location.reload()
      }
    } catch (error) {
      console.error("Error updating store status:", error);
      toast.error('Failed to change the status. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-x-0 top-20 z-50 flex items-center justify-center">
      <div className="modal-content p-6 bg-white rounded-lg shadow-md max-w-md w-full relative">
        <span className="modal-close absolute top-2 right-2 text-gray-600 cursor-pointer text-2xl" onClick={onClose}>&times;</span>
        <h2 className="text-2xl font-semibold mb-4">{isActive ? 'Activate Store' : 'Deactivate Store'}</h2>
        <div className="mb-4">
          {isActive ? null : (
            <>
              <label className="block text-gray-600 text-sm font-medium">Start Date</label>
              <input
                type="date"
                className="form-input block w-full mt-1"
                placeholder="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                min={new Date().toISOString().split('T')[0]}
              />
            </>
          )}
        </div>
        <div className="mb-4">
          {isActive ? null : (
            <>
              <label className="block text-gray-600 text-sm font-medium">End Date</label>
              <input
                type="date"
                className="form-input block w-full mt-1"
                placeholder="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                min={new Date().toISOString().split('T')[0]}
              />
            </>
          )}
        </div>
        <button
          onClick={handleConfirm}
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full w-full"
        >
          {isActive ? 'Activate' : 'Deactivate'}
        </button>
      </div>
    </div>
  );
}

export default function VendorSales() {
  const [productSoldCount, setProductSoldCount] = useState(0);
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;

  // Use local storage to retrieve the initial state or default to the API-provided initial status
  // const [isActive, setIsActive] = useState(() => {
  //   const savedStatus = JSON.parse(localStorage.getItem('isActive'));
  //   return savedStatus !== null ? savedStatus : initialStatus;
  // });

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (vendor.vendor?.activeStore && vendor.vendor?.activeStore?.isActive !== isActive) {
      setIsActive(vendor.vendor.activeStore.isActive);
    }
  }, [vendor.vendor?.activeStore, isActive]);
  
  


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchNewOrdersCount = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/order/getAllOrders`, config);
        const orders = response.data;
        const { isActive: storeIsActive } = response.data;
        setIsActive(storeIsActive);

        const vendorOrders = orders.filter(order => order.productDetails.vendorId === loggedInVendorId);

        const soldOrders = vendorOrders.filter(order => order.orderStatus === 'Completed');
        setProductSoldCount(soldOrders.length);

        const relevantStatuses = ['NewOrders', 'Completed', 'InTransit', 'InProcess'];
        const newOrders = vendorOrders.filter(order => relevantStatuses.includes(order.orderStatus));
        setNewOrdersCount(newOrders.length);
      } catch (error) {
        console.error('Error fetching new orders:', error);
      }
    };

    fetchNewOrdersCount();
  }, [loggedInVendorId]);

  useEffect(() => {
    if (vendor.vendor?.activeStore && vendor.vendor?.activeStore?.isActive !== isActive) {
      setIsActive(vendor.vendor.activeStore.isActive);
    }
  }, [vendor.vendor?.activeStore, isActive, ]);
  

  const handleToggleClick = () => {
    toggleActive();
    setShowModal(true);
  };

  const handleStatusChange = (startDate, endDate, newStatus) => {
    setIsActive(newStatus);
    localStorage.setItem('isActive', JSON.stringify(newStatus));
  };

  const toggleActive = async () => {
    try {
      const response = await axios.put(`${API_URL}/api/vendor/${vendor?.vendor?._id}/store-status` ,  {
        isActive: !isActive,
      });
      const { startDate, endDate, isActive: updatedStatus } = response.data;
      handleStatusChange(startDate, endDate, updatedStatus);
      
    } catch (error) {
      console.error("Error updating store status:", error);
      // toast.error('Failed to change the status. Please try again later.');
    }
  };

  return (
    <>
      <div className=''>
        {showModal && (
          <StoreStatusModal
            isActive={!isActive}
            onClose={() => setShowModal(false)}
            onStatusChange={handleStatusChange}
          />
        )}
        <h1 className="m-5 ml-5 text-2xl">Sales</h1>
        <div className="flex flex-col md:flex-row gap-5 p-5">
          {/* Card 1 */}
          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-full md:w-1/3 rounded-xl"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >
            <div>
              <ManageAccountsIcon className='m-2' />
              <div className="flex justify-center items-center flex-col mb-5">
                <div className="p-4">
                  <p className="text-2xl font-bold text-[#737587]"> {productSoldCount}</p>
                </div>
                <p className="font-bold text-2xl">Product Sold</p>
              </div>
            </div>
          </Card>

          {/* Card 2 */}
          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-full md:w-1/3 rounded-xl"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >
            <div>
              <BiSort className="text-2xl m-2" />
              <div className="flex justify-center items-center flex-col">
                <div className="p-4">
                  <p className="text-2xl font-bold text-[#737587]"> {newOrdersCount}</p>
                </div>
                <p className="font-bold text-2xl">Total Orders</p>
              </div>
            </div>
          </Card>

          {/* Card 3 */}
          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-full md:w-1/3 rounded-xl"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >
            <div className="flex flex-col justify-center items-center mt-7">
              <div className="p-4">
                <p className="text-2xl font-bold text-[#737587]">Store Status</p>
              </div>
              <button
                className={`flex items-center space-x-4 border rounded-full p-1 mb-2`}
                onClick={handleToggleClick}
                style={{ borderColor: '#0DA06A' }}
              >
                Active Store {isActive ? (
                  <ToggleOnIcon style={{ color: '#0DA06A' }} />
                ) : (
                  <ToggleOffIcon style={{ color: 'gray' }} />
                )}
              </button>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}