import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

function ShippingPolicy() {

    useEffect(() => {
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        const links = document.querySelectorAll(".footer-link");
        links.forEach((link) => {
          link.addEventListener("click", scrollToTop);
        });
    
        return () => {
          // Remove event listeners when the component unmounts
          links.forEach((link) => {
            link.removeEventListener("click", scrollToTop);
          });
        };
      }, []);

      const email = 'services@theekaiv.in';

  const handleClick = () => {
    window.open(`mailto:${email}`);
  };


  return (
    <div className=' flex justify-center items-center bg-gray-100 text-justify'>
        <div className='w-[85%] pt-10 text-justify'>
            <div>
                <div className='text-center w-full font-bold text-xl p-5'>
                Shipping Policy
                </div>

                <div className=' py-5'>
                    <p>
                    At The Ekaiv, we understand the excitement of receiving your handcrafted treasures, and we strive to make your shipping experience seamless. Here's what you need to know about our shipping policy:
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>Free Shipping Nationwide</h1>
                    <p>
                    Enjoy free shipping across India on all orders with any bill value. Shop to your heart's content, and leave the shipping worries to us!
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>Fast and Reliable Delivery</h1>
                    <p>
                    The Ekaiv ensures that your meticulously crafted products reach you swiftly and in excellent condition. All orders are dispatched within 3-5 working days. For domestic orders within India, please allow an additional 2-10 working days for your handpicked items to arrive at your doorstep.
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>International Shipping</h1>
                    <p>
                    Please be informed that we do not offer international shipping at this time. We appreciate your interest and support, and we are working diligently to expand our shipping capabilities to reach customers worldwide in the near future.
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>Order Processing Details</h1>
                    <p>
                    While we strive to maintain our delivery commitments, rare circumstances beyond our control may cause delays. Products with separate shipping charges will be clearly indicated in the product details.
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>Important Points</h1>
                    <p>
                    1. Currently, we do not offer COD<br/>
                    2. International shipping is not available at the moment, but we appreciate your interest. We aim to introduce international shipping soon.<br/>
                    3. In addition to email notifications, you'll also receive WhatsApp notifications. For bulk orders, customization options are available, and shipping may be charged extra based on weight.<br/>
                    4. Shipping is free for all orders within India, regardless of the purchase amount.<br/>
                    5. Prices on the payment page are final and inclusive of all charges and taxes (<Link to="/terms-and-condition" onClick={()=>{ window.scrollTo(0,0)}} className=' text-blue-500'>Terms and Conditions</Link> apply).<br/>
                    </p>
                </div>

                <div className=' py-5'>
                    <h1 className=' font-bold'>CONTACT US</h1>
                    <p>
                    For any questions or comments (including all inquiries unrelated to copyright infringement) regarding the Ekaiv platform,<br/> please contact us at <span className=' text-blue-400 cursor-pointer' onClick={handleClick}>{email}</span> .
                    </p>
                </div>

                <div className=' py-5'>
                    <p className=' text-center font-bold'>Happy Shopping at The Ekaiv!</p>
                </div>

            </div>
        </div> 
    </div>
  )
}

export default ShippingPolicy