import React from 'react'

function ReturnPolicy() {
    const refundPolicyData = [
        {
            content:'At The Ekaiv, we are dedicated to providing you with a delightful and hassle-free shopping experience. Our Return and Refund Policy is crafted to ensure your satisfaction and address any concerns you may have. Kindly review the following details:'
        },
        {
            title: 'Unpacking Video Refund:',
            content: 'We understand that sometimes you may receive a product that doesn\'t meet your expectations. To facilitate a smooth resolution, if you have a proper video showcasing the unpacking and breakdown of the product, we offer a refund. This helps us identify and address any legitimate issues you may have encountered.'
        },
        {
            title: 'Non-Generated Order Refund:',
            content: 'In the rare instance where your payment is processed, but the order is not generated within 5 working days, rest assured a refund will be promptly initiated. We value your time and aim to rectify such situations promptly.'
        },
        {
            title: 'Made-to-Order Products:',
            content: 'Many of our products are meticulously crafted on order to ensure freshness and quality. We encourage you to check product dimensions (for home decor and accessories), ingredients (for food items), and size charts (for clothing) before making a purchase. This ensures that the product aligns with your expectations.'
        },
        {
            title: 'Vendor Authenticity:',
            content: 'We take pride in onboarding authentic vendors who possess the necessary licenses for their respective businesses. This not only guarantees the quality of the products you purchase but also reflects our commitment to fostering genuine and legitimate business practices.'
        },
        {
            title: 'No General Return Policy:',
            content: 'Given the nature of our made-to-order products, we do not offer a general return policy. Each product is crafted specifically for your order, ensuring freshness and uniqueness. However, we are committed to addressing any legitimate concerns through the Unpacking Video Refund option.'
        }
    ];

    return (
        <div className='flex justify-center items-center bg-gray-100 text-justify'>
            <div className='w-[85%] pt-10 text-justify'>
                <div>
                    <div className='text-center w-full font-bold text-xl p-5'>
                        Return and Refund Policy
                    </div>

                    {refundPolicyData.map((section, index) => (
                        <div key={index} className='py-5'>
                            <h1 className='font-bold'>{section.title}</h1>
                            <p>{section.content}</p>
                        </div>
                    ))}

                    <div className='py-5'>
                        <p>We want your shopping experience at The Ekaiv to be seamless and enjoyable. If you have any questions or concerns, our customer support team is ready to assist you. Your satisfaction is our utmost priority.</p>
                    </div>

                    <div className='py-5'>
                        <p className='text-center font-bold'>Thank you for choosing The Ekaiv for your curated and unique shopping needs!</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReturnPolicy
