import { Button, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import constant from "../../constant";

const Hero = () => {
   const [currentImage, setCurrentImage] = useState(0);
   const images = [
     constant.IMAGES_IMAGES.HOME.HOMEPAGE1,
     constant.IMAGES_IMAGES.HOME.HOMEPAGE2,
     constant.IMAGES_IMAGES.HOME.HOMEPAGE_GREEN,
     constant.IMAGES_IMAGES.HOME.HOMEPAGE_KIDS,
     
 
   ]
 
   useEffect(() => {
     const timer = setTimeout(() => {
       setCurrentImage((prevImage) => (prevImage + 1) % images.length);
     }, 3000); // Change image every 1 seconds
 
     return () => clearTimeout(timer);
   }, [currentImage, images.length]);
 
   return (
     <>
       <div className="w-full h-[20vh] min-[200px]:h-[30vh] min-[500px]:h-[50vh] sm:h-[81vh] bg-white   relative cursor-pointer overflow-hidden">
         {/* //////////////////Image Slider//////////////////////////////////// */}
         {images.map((image, index) => (
           <img
           key={index}
           src={image}
           alt={`Image ${index}`}
           className={`absolute w-full h-full object-cover transition-opacity duration-500 ${index === currentImage ? "opacity-100" : "opacity-0"}`}
         />
         ))}
         {/* <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2">
           {images.map((_, index) => (
             <span
               key={index}
               className={`inline-block w-3 h-3 rounded-full mx-1 ${index === currentImage ? "bg-[#8A58DC]" : "bg-gray-300"
                 }`}
             ></span>
           ))}
         </div> */}
 
         {/* Mid Box */}
         {/* <div className="w-full h-full absolute flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center items-center">
           <div className="bg-slate-100/70  p-5 pt-10 pb-8  rounded-lg  flex justify-center items-center flex-col">
             <h6 className=" text-[#880081]  md:text-lg sm:text-sm max-sm:text-xs font-semibold">NAMASTE! WELCOME TO THE EKAIV</h6>
             <div className="text-[#111] md:text-xl sm:text-lg max-sm:text-sm font-bold">Right Place to Affordable & Creative Products</div>
 
             <Link to="/products"><Button variant="contained" sx={{ backgroundColor: "#880081", marginTop: "10px", '&:hover': { backgroundColor: "#ffffff", color: "#F16080", outline: "1px solid #F16080" } }}>Shop Now</Button></Link>
           </div>
         </div> */}
       </div>
     </>
   )
 }

export default Hero