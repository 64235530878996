import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import axios from 'axios';
import { API_URL } from '../../../config/config';


export default function VendorAnalytics() {
    const [data, setData] = useState({
        vendors: [],
        products: []
    });
    const [vendor, setVendor] = useState([]);
    // api for vendors
    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin-profile/getAllVendors/superAdmin`);
                setData(prevData => ({ ...prevData, vendors: response.data }));
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin-profile/getAllProducts/superAdmin`);
                setData(prevData => ({ ...prevData, products: response.data }));
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchVendors();
        fetchProducts();
    }, []);


    // Filter vendors based on status
    const pendingVendors = data.vendors.filter(vendor => vendor.status === 'Pending');
    const verifiedVendors = data.vendors.filter(vendor => vendor.status === 'Verified');
    const rejectedVendors = data.vendors.filter(vendor => vendor.status === 'Rejected');


    // Filter products based on status
    const verifiedProducts = data.products.filter(product => product.status === 'Verified');
    const pendingProducts = data.products.filter(product => product.status === 'Pending');
    const rejectedProducts = data.products.filter(product => product.status === 'Rejected');


    return (
        <div className='p-5 flex flex-col w-full'>
            < h1 className="mt-3 mb-3 ml-6" > Sales</h1 >
            <div className="grid grid-cols-3 gap-4 w-full">
                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col mb-4">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{data?.vendors?.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Total Vendors</p>
                        </div>
                    </div>
                </Card>

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >

                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{verifiedVendors.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Verified Vendors</p>
                        </div>
                    </div>
                </Card>

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">  {pendingVendors.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Pending Vendors</p>
                        </div>
                    </div>
                </Card>
                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">  {rejectedVendors.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Rejected Vendors</p>
                        </div>
                    </div>
                </Card>

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col mb-4">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{data.products.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Total Products</p>
                        </div>
                    </div>
                </Card>

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >

                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{verifiedProducts.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Verified Products</p>
                        </div>
                    </div>
                </Card>

                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{pendingProducts.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Pending Products</p>
                        </div>
                    </div>
                </Card>
                <Card
                    variant="outlined"
                    orientation="horizontal"
                    className="w-full rounded-xl cursor-pointer"
                    sx={{
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
                        borderRadius: '1rem', // Rounded corners
                        '&:hover': {
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                            borderColor: 'neutral.outlinedHoverBorder',
                        },
                    }}
                >
                    <div>
                        <AccountBoxIcon className='m-2 text-green-800' />
                        <div className="flex justify-center items-center flex-col">
                            <div className="p-4">
                                <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{rejectedProducts.length}</p>
                            </div>
                            <p className="font-bold text-2xl">Rejected Products</p>
                        </div>
                    </div>
                </Card>

            </div >
        </div >
    );
}
