import React from 'react'
import {Link} from 'react-router-dom'
import sellerimg from "../../constant"

const sell=sellerimg.IMAGES_IMAGES.BECOME_A_SELLER.SELLING_PAGE.EKAIV




const StartingSelling = () => {
  return (
    <div className='  flex max-lg:flex-col lg:flex-row gap-2 px-12'>
    <div className='  w-full pl-10 sm:flex md:flex flex-col justify-center sm:mb-0 mb-10'>
      <p className='sm:text-5xl text-xl mb-4 font-bold '> 
      Start your Seller Journey
      </p>
      <p className='sm:text-lg  text-gray-700 text-base mb-4'>
      Join The Ekaiv
      </p>
     
     <Link to ='/become-seller'> <button className='mb-3 sm:w-[180px]  sm:text-xl  text-sm cursor-pointer text-white font-bold shadow-md hover:scale-[1.025] hover:shadow-xl rounded-full sm:px-6 sm:py-3 px-3 py-2 bg-purple-700'>
        Start Selling
      </button>
      </Link>

     
      
    </div>
    <div className='  w-full  sm:m-0'>
      <img src={sell} alt="About" className=' p-5' /> 
    </div>
  </div>
  )
}

export default StartingSelling
