import React from 'react';
import BlogsCard from '../component/OurBlog/BlogsCard';
import Popular from '../component/OurBlog/Popular';
import CommingSoon from './ComingSoon'
const OurBlog = () => {
  // Array to represent the number of times to render the Popular component
  const popularArray = [1, 2, 3, 4, 5];

  return (
    <>
      <CommingSoon/>
    </>
    // <div>
    //   <div className='pt-10 pb-5'>
    //     {/* gradient */}
    //     <div className='bg-gradient-to-r from-[#F16080] to-[#5156E9] h-[300px] flex justify-evenly items-center  ml-16 rounded-l-2xl ' >

    //       {/* content */}
    //       <div className='flex gap-52 justify-between items-center '>
    //         <div className='flex flex-col text-white gap-10'>
    //           <h1 className='text-3xl font-bold'>THE EKAIV BLOGS</h1>
    //           <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. <br/> Aliquam nam veniam, illum repellat molestiae eligendi suscipit </p>
    //         </div>
    //         <div className='w-[300px]  scale-x-[-1]'>
    //           <img src="https://s3-alpha-sig.figma.com/img/b18e/ad84/1615f34dceb0e5b962a06bd6601e5b6d?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ggu58hv-Lk3UNX-Oow5svOe9aXiwMZb52XmzGQTllu~iOZw7JrbCwg1zcT-qV9O6KYG3eO2Jzi~crRHl5bf0CNnBE7ebUshvw8EAzwN7ht8jbYIpEeLlGcjJ71coBtERUaO9zQtjmJcB0tqCyDWJZk2MZS8vKmEm07kb8iZHvRprtNuZNTSTsJleAYKJBoTLuVsNq4h1JhfVhnjpK-CaVBUH8bKVngW3nvWvTXTTMbUVqKq~rLI7IpYH526XrDW1xJ52PsxX104EEdHOyb3gUyAhQ0BCjaNg-JwLf6mrj6adxYFu9xQWEOuvkIG6cqf96rPJdZTXBLPcdnRY2sW~eA__" alt="" />
    //         </div>
    //       </div>
    //     </div>

    //     {/* main */}
    //     <div className='flex flex-row'>
    //       <div className=' p-8'>
    //         {/* new Blogs */}
    //         <div className=' m-8'>
    //           <h1 className=' text-3xl '>New Blogs</h1>
    //         </div>
    //         <div className='flex flex-col gap-4 m-6'>
    //           <BlogsCard />
    //           <BlogsCard />
    //         </div>
    //       </div>

    //       <div className=' p-8 m-8'>
    //         <h1 className='text-3xl '>Popular</h1>
    //         <div className=' mt-5'>
    //           {/* Use map to render Popular component five times */}
    //           {popularArray.map((_, index) => (
    //             <Popular key={index} />
    //           ))}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>


  );
};

export default OurBlog;
