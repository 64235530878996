import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import constant from '../../constant';
import axios from 'axios';
import { API_URL } from '../../config/config';
const BUSINESS_Registrations=constant.IMAGES_IMAGES.ASSISTANCE.BUSINESS_Registrations;

const Registrations = () => {
  //email.js

  const formregister = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    }

    try {
        const response = await axios.post(`${API_URL}/api/email/sendBusinessRegistrationsEmail`, formData);

        console.log(response);
        if (response && response.data && response.data.success) {
            toast.success('Form submitted successfully');
            setFormData({
                name: '',
                email: '',
                phone: '',
                businessType: '',
                messageOrFAQ: 'message',
                details: '',
            });
        } else {
            toast.error('Failed to submit form. Please try again.');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to submit form. Please try again.');
    }

    setButtonDisabled(true);
    setTimeout(() => {
        setButtonDisabled(false);
    }, 2000);
};

  // Define initial form data with fields
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    details: '',
    messageOrFAQ: '',
    businessType: '',
    
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));

    
  };

  

  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name', // Placeholder text
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      pattern: '^[^\s@]+@[^\s@]+\.[^\s@]+$',
      placeholder: 'Email', // Placeholder text
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone', // Placeholder text
    },
    {
      name: 'businessType',
      label: 'businessType',
      type: 'text',
      placeholder: 'Business Type', // Placeholder text
    },
    {
      name: 'details',
      label: 'Business Details & Licenses',
      type: 'text',
      placeholder: 'Business Details & Licenses', // Placeholder text
    },
    {
      name: 'messageOrFAQ',
      label: 'Choose Message or FAQ',
      type: 'radio',
      options: [
        { value: 'message', label: 'Message' },
        { value: 'faq', label: 'FAQ' },
      ],
    },
    

    // Add more form fields as needed
  ];
  const handleMessageOrFAQChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      message: '',
      // Reset message when toggling between message/FAQ
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };


  return (
    <>
      <div className='bg-gray-100 p-1  sm:p-10'>

        <div className=' w-full flex flex-col justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold py-4 mb-4  text-gray-800">Bussiness Registrations</h2>
          <p className='text-sm rounded-lg sm:py-5 px-2 sm:px-7  text-justify'>
                Simplify the often-complex process of business registration with our assistance services. We provide guidance and support to help you obtain the necessary licenses tailored to your specific product categories. Our aim is to streamline the registration process, ensuring that you can focus on what you do best – growing your business.
              </p>
        </div>
        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
           
          <div className=' flex flex-col justify-center items-center' >
          
            <div className=' bg-white rounded-2xl p-5 w-[100%] md:w-[70%]'>
              {/* <p className="text-gray-600">Provide your registration details:</p> */}
              <form ref={formregister} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? ' w-full sm:w-1/2' : 'w-full'}`}>
                      {/* <label htmlFor={field.name} className="block text-gray-700 font-bold">
                      {field.label}:
                       </label> */}
                      {field.type !== 'radio' ? (
                        <input
                          type={field.type}
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                          placeholder={field.placeholder}
                          className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                          maxLength={field.name === 'phone' ? 10 : undefined}
                          required
                        />
                      ) : (
                        <div>
                          {field.options.map((option, optionIndex) => (
                            <label className="inline-flex items-center px-2" key={optionIndex}>
                              <input
                                type="radio"
                                className="form-radio h-5 w-5 px-2 text-blue-600"
                                name={field.name}
                                value={option.value}
                                checked={formData[field.name] === option.value}
                                onChange={(e) => {
                                  handleMessageOrFAQChange(e);
                                  handleInputChange(e);
                                }}
                              />
                              <span className="ml-2">{option.label}</span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {formData.messageOrFAQ === 'message' && (
                    <div className="mb-4 w-full px-4 ">

                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your message..."
                        className="border border-gray-300 bg-gray-100 shadow-lg p-2 rounded-2xl   w-full"
                      ></textarea>
                    </div>
                  )}
                  {formData.messageOrFAQ === 'faq' && (
                    <div className="mb-4 w-full px-4">

                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your FAQ..."
                        className="border border-gray-300 bg-gray-100 shadow-lg p-2 rounded-2xl w-full"
                      ></textarea>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="px-4 py-2 w-full rounded-full bg-violet-500 text-white  hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
                  disabled={buttonDisabled}
                >
                  Submit Registration
                </button>
              </form>
              {/* Add any other UI elements and functionality here */}
            </div>

                    </div>
            <div className=' '>
            {/* <h1 className=' text-lg font-bold'>Reach Out To Us!</h1> */}
            <div className=' bg-white  rounded-2xl px-5 pt-5 '>
                      
            <div className=' flex flex-col'>
              <div className='flex flex-col sm:flex-row'>
              <img src={BUSINESS_Registrations} alt="rachnaErankar" className='mx-5 h-28 w-28 rounded-full' />
              <p className=' pt-5 sm:pt-14 px-2 sm:px-5 text-2xl font-bold'>Ranjana Erankar</p>
              </div>
              <div className=' flex flex-col p-2 sm:p-5'>
              <p className='text-sm text-justify'>Ranjana Erankar, with an extensive 18-year history in Business Registration, exemplifies a company that has stood the test of time. Demonstrating unwavering commitment, our company continuously endeavors to enhance and purify its services. We specialize in facilitating various types of loans, including Personal, Home, Business, Car, Mortgage, OD, BT, and TOP UP. Through partnerships with 50 banks and financial institutions, we streamline documentation processes and also offer comprehensive Insurance policies. For all your loan requirements, Ranjana Erankar is your trusted point of contact, ensuring seamless and efficient service.</p>
              </div>
              
            </div>
          
            </div>
            </div>
          
        </div>
       
      </div>
    </>
  );
};

export default Registrations;
