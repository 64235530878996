import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {API_URL} from '../../config/config';

const BusinessConsultants = () => {
  
  
  //email.js 
  const formconsultant = useRef();

  //form fields
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    consultationType: '',
    businessType: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);  
  
  const sendEmail = (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }

    const emailData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      consultationType: formData.consultationType,
      businessType: formData.businessType,
    };

    // Make a POST request to your server API
    axios.post(`${API_URL}/api/email/sendBusinessConsultantsEmail`, emailData)
      .then((response) => {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          consultationType: '',
          businessType: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again later.');
      });
      setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };


  //handling form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));

    
  };


  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));



    
  };
  

  // array of form fields 
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name', 
      required: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email',
      errorMessage: "Invalid email addres" ,
      pattern: '^[^\s@]+@[^\s@]+\.[^\s@]+$',
      required: true, 
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      errorMessage: 'Invalid phone number (10 digits)',
      placeholder: 'Phone',
      
      required: true,
    },
    {
      name: 'consultationType',
      label: 'Consultation Type',
      type: 'text',
      placeholder: 'Consultation Message', 
      required: true,
    },
    {
      name: 'businessType',
      label: 'Business Type',
      type: 'text',
      placeholder: 'Business Type', 
      required: true,
    },
    
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };

  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Business Consultation</h2>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>


          <div className="flex flex-col justify-center items-center">
            <div className='bg-white rounded-2xl p-5 w-[100%] sm:w-[70%]'>

              {/* <p className="text-gray-600 mb-4">
                Our team of experienced business consultants is here to help you with your business needs.
                Contact us for expert advice and guidance on various aspects of your business.
            </p> */}
              <form ref={formconsultant} onSubmit={sendEmail} className="mt-4 flex flex-col">
                <div className="flex flex-wrap -mx-4">
                  {formFields.map((field, index) => (
                    <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full lg:w-1/2' : 'w-full'}`}>
                      {/* <label htmlFor={field.name} className="block text-gray-700 font-bold">
                      {field.label}:
                    </label> */}
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                        placeholder={field.placeholder} // Placeholder text
                        className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                        required={field.required}
                        maxLength={field.name === 'phone' ? 10 : undefined}
                      />
                    </div>
                  ))}
                </div>
                <button
                  type="submit"
                  className="px-4 py-2 w-full rounded-full bg-violet-500 text-white  hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
                  disabled={buttonDisabled}
                >
                  Submit
                </button>
              </form>
            </div>
            {/* Add any other UI elements and functionality here*/}
          </div>

          <div className=' flex flex-col justify-center items-center rounded-lg  order-1 md:order-2 py-20 md:py-0 px-2 sm:px-10 lg:px-20'>
            <h1 className=' text-3xl text-violet-500 font-bold '>Get In Touch</h1>
            <p className=' rounded-lg p-2 text-sm text-justify'>
              Engage with experienced business professionals who offer personalized consultations. Whether you're seeking insights for business growth, strategic planning, or contemplating a new venture, our consultants are here to provide valuable perspectives and actionable guidance based on their extensive industry experience.
            </p>

          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessConsultants;
