import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {API_URL} from '../../config/config';

const ZoomedImage = ({ src }) => {
  const [showZoomed, setShowZoomed] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    setShowZoomed(!src.includes('youtube.com'));
    setCursorPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const handleMouseLeave = () => {
    setShowZoomed(false);
  };

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  return (
    <div
      className='relative w-full aspect-square justify-center items-center overflow-hidden'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {src.includes('youtube.com') ? (
        <div className='video-container flex justify-center items-center'>
          <iframe
            width="560"
            height="315"
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <img src={src} alt="" className='w-full p-4 h-full object-contain' />
      )}
      {showZoomed && !src.includes('youtube.com') && (
        <div
          className='absolute top-0 left-0 w-full h-full overflow-hidden '
          style={{
            transformOrigin: `${cursorPosition.x}px ${cursorPosition.y}px`,
            transform: 'scale(3)',
          }}
          onWheel={(e) => {
            e.preventDefault();
            const container = e.currentTarget;
            container.scrollTop += e.deltaY;
          }}
        >
          <img src={src} alt="" className='w-full h-full object-contain' />
        </div>
      )}
    </div>
  );
};

function ProductImage() {
  const { productId } = useParams();
  const [images, setImages] = useState([]);
  const [activeImg, setActiveImage] = useState(null);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/Products/getProduct/${productId}`);

      if (response.data) {
        const productData = response.data.product;
        setImages(productData.images);
        setActiveImage(productData.images[0]);

      } else {
        console.error('Product data not found');
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  return (
    <>
      <style>
        {`
          @media (max-width: 767px) {
              .small-screen-layout {
                  flex-direction: column;
              }
              .small-screen-zoom {
                  order: 1;
              }
          }

          @media (min-width: 768px) {
              .large-screen-layout {
                  flex-direction: row;
              }
              .large-screen-zoom {
                  order: 0;
              }
          }
        `}
      </style>

      <div className="flex small-screen-layout large-screen-layout w-full gap-1 justify-center items-center p-5 border rounded-sm lg:sticky top-0 overflow-hidden">
        <div className="flex small-screen-zoom large-screen-zoom sm:flex-row md:flex-col gap-1 h-full overflow-x-scroll no-scrollbar">
          {images.map((img, index) => (
            <div className='w-24 h-24 mr-5' key={index}>
              <img
                className={`w-24 h-24 cursor-pointer object-contain ${activeImg === img ? 'border border-[#8A58DC]' : ''}`}
                src={img}
                onClick={() => setActiveImage(img)}
              />
            </div>
          ))}
        </div>
        {activeImg && <ZoomedImage src={activeImg} />}
      </div>
    </>
  );
}

export default ProductImage;
