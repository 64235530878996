import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Fade from "@mui/material/Fade";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from '../../config/config';

import {
  getAllProducts,
} from "../../Redux/features/product/productsSlice";
import axios from "axios";

const Navbar = () => {
  const [language, setLanguage] = useState("en");
  const [openMenu, setOpenMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isAllCategories, setIsAllCategories] = useState(false);
  const [openAllCategories, setOpenAllCategories] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const users = useSelector((state) => state.auth);
  const [showKidsSubcategories, setShowKidsSubcategories] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [hoverLink, setHoverLink] = useState("");

  const [isHovered, setIsHovered] = useState('');
  const navRef = useRef(null);


  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleCategoriesClick = (event) => {
    setActiveLink("All Categories");
    setAnchorEl(event.currentTarget);
  };
  const handleCategoriesClose = () => {
    setAnchorEl(null);
  };

  const [anchor, setAnchor] = React.useState(null);
  const open2 = Boolean(anchor);

  const handleSupportClick = (event) => {
    setActiveLink("Support");
    setAnchor(event.currentTarget);
  };
  const handleSupportClose = () => {
    setAnchor(null);
    setActiveLink("Support"); // Set activeLink to "Support" when the support dropdown is closed
  };

  const handleLinkHover = (link) => {
    setHoverLink(link);
  };


  const openMenuItems = () => {
    setOpenMenu(!openMenu);
  };

  let Links = [
    { name: "Home", link: "/" },
    { name: "Catalogue", link: "/catalogue" },
    { name: "Products", link: "/products" },
    { name: "Assistance", link: "/assistance" },
  ];

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');

  const [categoryExpanded, setCategoryExpanded] = useState(false);

  const toggleCategoryExpansion = () => {
    setCategoryExpanded(!categoryExpanded);
  };

  const toggleKidsSubcategories = () => {
    setShowKidsSubcategories(!showKidsSubcategories);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedCategory(subcategory);
    setCategoryExpanded(false); // Close the category expansion
  };

  const dispatch = useDispatch();

  ///////navigate the selected category to product page///////////////
  const [categoriesData, setCategoriesData] = useState([]);

  const handleCategorySelect = (category) => {
    setTimeout(() => {
      setSelectedCategory(category);
      navigate(`/products/?category=${category}`);
    }, 1000)
    handleCategoriesClose(true);

  };
  //////////////////////////////////////////////////////////////////

  // const handleCategorySelect = async (subcategory) => {

  //   setOpenMenu(!openMenu);

  //   if (subcategory === selectedCategory) {
  //     setSelectedCategory('');
  //   } else {
  //     setSelectedCategory(subcategory);

  //     console.log("sub", subcategory)

  //   // console.log("send", subcategory);

  //     await axios.post(`${API_URL}/api/products/searchproducts?category=${subcategory}`,'')
  //       .then((response) => { 
  //         console.log('Fetched resp:', response);
  //         return response
  //         })
  //         .then((data) => {
  //           console.log('Fetched data:', data);

  //           navigate(`/products/`);
  //         })
  //       .catch((error) => {
  //         // Handle errors
  //         console.error('Error fetching data:', error);
  //       });



  //   }
  //   handleCategoriesClose();
  // };



  useEffect(() => {
    // Replace the API URL with your actual endpoint
    fetch(`${API_URL}/api/admin-profile/getCategories`)
      .then((response) => response.json())
      .then((data) => {

        setCategoriesData(data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error fetching categories:', error);
      });
  }, []);


  const handleLinkClick = (link) => {
    setActiveLink(link);
    // ... (any other logic you want to perform when a link is clicked)
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={navRef}>
        <Box
          sx={{
            position: "sticky",  // Add this line
            top: 0,             // Add this line
            zIndex: 20,       // Add this line
            width: "100%",
            height: "50px",
            padding: "10px 5px",
            fontSize: { lg: "1.1rem", md: "1rem", sm: "1rem" },
            color: "#ffffff",
          }}
          className={`flex shadow-gray-400 shadow-md items-center ${screenSize.width <= 900 ? "justify-end" : "justify-start"
            } bg-gradient-to-r from-[#F16080] to-[#5156E9]`}
        >
          {screenSize.width <= 900 ? (
            !openMenu && (
              <MenuIcon
                fontSize="large"
                onClick={openMenuItems}
                style={{
                  cursor: "pointer",
                  transitionDelay: "2s",
                  position: "absolute",
                  left: "10px",
                }}
              />
            )
          ) : (
            <></>
          )}
          <Box
            className={`flex flex-row gap-5 items-center w-[100%] justify-start`}
            style={{ display: screenSize.width <= 900 ? "none" : "flex" }}
          >
            <Box
              className={`font-light cursor-pointer pl-2 flex items-center gap-1 `}
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleCategoriesClick}
            >
              <MenuIcon /> All Categories
            </Box>
            <div className="flex flex-1 items-center justify-center gap-[1rem]">
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCategoriesClose}
                TransitionComponent={Fade}
                sx={{
                  "&": { marginTop: "12px" },
                  borderRadius: "0 0 1px 1px !important",
                }}
              >
                <>
                  {categoriesData?.map((category) => {
                    // Capitalize the first letter of each word and replace hyphens with space
                    const categoryName = category.name
                      .split("-")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ");

                    // Special handling for "Home Deocr"
                    const formattedCategoryName =
                      categoryName === "Home Deocr" ? "Home Decor" : categoryName;

                    return (
                      <div className="max-w-[150px] w-[max-content] p-2" key={category._id}>
                        <li
                          className={`cursor-pointer text-start whitespace-nowrap relative group transition-all duration-300 pb-2 text-gray-800 ${selectedCategory === category.name ? "font-bold" : ""}`}
                          onMouseEnter={() => setIsHovered(category.name)}
                          onMouseLeave={() => setIsHovered('')}
                          onClick={() => handleCategorySelect(category.name)}

                        >
                          {formattedCategoryName}
                          <div
                            className={`absolute inset-x-0 bottom-0 h-0.5 bg-gray-800 transition-all duration-500 transform origin-bottom ${isHovered === category.name ? "scale-x-100" : "scale-x-0"
                              }`}
                          />
                        </li>
                      </div>
                    );
                  })}


                </>
              </Menu>

              {Links.map((navItem) => (
                <Link to={navItem.link} key={navItem.name} onClick={() => handleLinkClick(navItem.link)}>
                  <Box
                    key={navItem.name}
                    className={`font-light transition-colors cursor-pointer duration-400 delay-75 hover:text-white relative inline-block ${activeLink === navItem.link || hoverLink === navItem.link ? "text-white" : ""}`}
                    onMouseEnter={() => handleLinkHover(navItem.link)}
                    onMouseLeave={() => handleLinkHover("")}
                  >
                    {navItem.name}
                    {(activeLink === navItem.link || hoverLink === navItem.link) && (
                      <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white mt-1"></div>
                    )}
                  </Box>
                </Link>
              ))}




              <Box
                className={`font-light cursor-pointer hover:text-white relative`}
                id="support-button"
                aria-controls={open2 ? "support-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleSupportClick}
                onMouseEnter={() => handleLinkHover("Support")}
                onMouseLeave={() => handleLinkHover("")}
              >
                Support
                {open2 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                {(hoverLink === "Support" || open2) && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-[#fff]"></div>
                )}
              </Box>

              <Menu
                id="support-menu"
                MenuListProps={{
                  "aria-labelledby": "support-button",
                }}
                anchorEl={anchor}
                open={open2}
                onClose={handleSupportClose}
                TransitionComponent={Fade}
                sx={{
                  "&": { marginTop: "12px" },
                  borderRadius: "0 0 1px 1px !important",
                }}
              >

                <Link
                  to="/faq"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>
                    FAQ's
                  </MenuItem>
                </Link>

                <Link
                  to="/contact-us"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>
                    Contact Us
                  </MenuItem>
                </Link>
                <Link
                  to="/live-chat"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={handleSupportClose}>
                    Live Chat
                  </MenuItem>
                </Link>
              </Menu>

              {users.user && users.user.role === "vendor" ? (
                <Link to="/vendor-profile">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "#880081",
                      boxShadow: "none",
                      borderRadius: "5px",
                      textTransform: "capitalize",
                      padding: "4px 10px 4px 10px",
                      "&:hover": {
                        color: "#F16080",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Go to Seller Dashboard <ChevronRightIcon />
                  </Button>
                </Link>
              ) : (
                users.user && users.user.role === 'admin' ? (
                  <Link to="/admin-profile">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        color: "#880081",
                        boxShadow: "none",
                        borderRadius: "5px",
                        textTransform: "capitalize",
                        padding: "4px 10px 4px 10px",
                        "&:hover": {
                          color: "#F16080",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      Go to admin Dashboard <ChevronRightIcon />
                    </Button>
                  </Link>
                ) : <Link to="/become-seller">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "#880081",
                      boxShadow: "none",
                      borderRadius: "5px",
                      textTransform: "capitalize",
                      padding: "4px 10px 4px 10px",
                      "&:hover": {
                        color: "#F16080",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Become a Seller <ChevronRightIcon />
                  </Button>
                </Link>
              )}
            </div>
          </Box>

          {/* z` */}
        </Box >

        {/* SideNavigation */}

        {
          (screenSize.width <= 900) ?
            <div className={`w-[250px] fixed h-full overflow-y-scroll top-0 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] z-50  bg-[#fdfdfd] transform transition-transform duration-300 ease-in-out ${openMenu ? 'translate-x-0' : '-translate-x-[400px]'}`} >
              <div className={`w-full h-full flex flex-col relative justify-start items-center pt-20`}>
                <CloseIcon onClick={openMenuItems} sx={{ position: "absolute", cursor: 'pointer', right: '20px', top: '20px', color: "#222" }} fontSize="large" />
                <ul className="text-[#222] flex flex-col gap-5 font-light text-lg">
                  <li onClick={() => setOpenAllCategories(!openAllCategories)}>All Categories {openAllCategories ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}</li>
                  <div className={`${openAllCategories ? 'flex flex-col gap-3 justify-center items-center' : 'hidden'}`}>
                    <ul className="flex flex-col gap-3 pl-5 ">
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081]" onClick={() => handleCategorySelect("clothing")}>Clothing</li>
                      {/* <div
                      className={`hover:bg-gray-100 cursor-pointer flex flex-row justify-between items-center hover:scale-105 text-start ml-2 ${selectedCategory === "Kids" ? "font-bold" : ""}`}
                      onClick={() => toggleKidsSubcategories()}
                    >
                      Kids

                      <IoIosArrowDown
                        className={`transform text-black ${showKidsSubcategories
                          ? "rotate-clockwise rotate"
                          : "rotate-counterclockwise rotate"
                          }`} />

                    </div> */}

                      {/* {showKidsSubcategories && (
                      <div className="ml-4">
                        <div
                          className={`hover:bg-gray-100 cursor-pointer hover:scale-105 text-start ml-2 ${selectedCategory === "Boys" ? "font-bold" : ""}`}
                          onClick={() => handleCategorySelect("Boys")}
                        >
                          Boys
                        </div>
                        <div
                          className={`hover:bg-gray-100 cursor-pointer hover:scale-105 text-start ml-2 ${selectedCategory === "Girls" ? "font-bold" : ""}`}
                          onClick={() => handleCategorySelect("Girls")}
                        >
                          Girls
                        </div>
                      </div>
                    )}
                    <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081]" onClick={() => handleCategorySelect("Men")}>Men</li> */}
                      <li className={`cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081] ${selectedCategory === "Accessories" ? "font-bold text-[#880081]" : ""
                        }`} onClick={() => handleCategorySelect("accessories")}>Accessories</li>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081]" onClick={() => handleCategorySelect("home-decor")}>Home Decor</li>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081]" onClick={() => handleCategorySelect("homemade-food")}>Homemade Food</li>
                      <li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#880081]" onClick={() => handleCategorySelect("others")}>Others</li>
                    </ul>
                  </div>
                  {Links.map((e, index) => {
                    return <Link to={e.link} key={index} onClick={openMenuItems}><li className="transition-colors duration-400 delay-75 hover:text-[#222]">{e.name}</li></Link>
                  })}
                  <li onClick={() => setOpenSupport(!openSupport)}>Support {openSupport ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}</li>
                  <div className={`${openSupport ? 'flex flex-col gap-3 justify-center items-center' : 'hidden'}`}>
                    <ul className="flex flex-col gap-3 pl-5 ">
                      <Link to='/faq' onClick={openMenuItems}><li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222] ">FAQ's</li></Link>
                      <Link to='/contact-us' onClick={openMenuItems}><li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222]">Contact Us</li></Link>
                      <Link to='/live-chat' onClick={openMenuItems}><li className=" cursor-pointer transition-colors duration-400 delay-75 hover:text-[#222]">Live Chat</li></Link>

                    </ul>
                  </div>
                  {users.user && users.user.role === "vendor" ? (
                    <Link to="/vendor-profile" onClick={openMenuItems}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "white",
                          color: "#880081",
                          boxShadow: "none",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                          padding: "4px 10px 4px 10px",
                          "&:hover": {
                            color: "#F16080",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        Go to Seller Dashboard <ChevronRightIcon />
                      </Button>
                    </Link>
                  ) : (
                    users.user && users.user.role === 'admin' ? (
                      <Link to="/admin-profile" onClick={openMenuItems}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "white",
                            color: "#880081",
                            boxShadow: "none",
                            borderRadius: "5px",
                            textTransform: "capitalize",
                            padding: "4px 10px 4px 10px",
                            "&:hover": {
                              color: "#F16080",
                              backgroundColor: "white",
                            },
                          }}
                        >
                          Go to admin Dashboard <ChevronRightIcon />
                        </Button>
                      </Link>
                    ) : <Link to="/become-seller" onClick={openMenuItems} >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "white",
                          color: "#880081",
                          boxShadow: "none",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                          padding: "4px 10px 4px 10px",
                          "&:hover": {
                            color: "#F16080",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        Become a Seller <ChevronRightIcon />
                      </Button>
                    </Link>
                  )}
                </ul>
              </div>
            </div>
            : <></>
        }
      </div>
    </>
  );
};

export default Navbar;
