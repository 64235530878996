import React, { useState, useRef } from 'react';

import { toast } from 'react-hot-toast';
import constant from '../../constant';
import axios from 'axios';
import {API_URL} from '../../config/config';

const LEGAL_ADVISORY=constant.IMAGES_IMAGES.ASSISTANCE.LEGAL_ADVISORY;

const Legal = () => {
  //email.js

  const formlegal = useRef();
  
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
   

    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }
    try {
      // Make an API call using axios
      const response = await axios.post(`${API_URL}/api/email/sendLegalAdvisoryEmail}`, formData);

      // Handle the API response as needed
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          query: '',
        });
      } else {
        // Handle unsuccessful response
        toast.error('Failed to submit form');
      }
    } catch (error) {
      // Handle API call error
      console.error('Error during API call:', error);
      toast.error('Failed to submit form');
    }
    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

    //form data

    const initialFormData = {
        name: '',
        email: '',
        phone:'',
        message: '',
        query: '',
        // Add more form fields as needed
      };
    
      const [formData, setFormData] = useState(initialFormData);
    
      // const handleInputChange = (e) => {
      //   const { name, value } = e.target;
      //   setFormData({
      //     ...formData,
      //     [name]: value,
      //   });
      // };
    
      
    
      // Define an array of form fields for rendering
      const formFields = [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          placeholder: 'Name', // Placeholder text
        },
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          placeholder: 'Phone', // Placeholder text
        },
        {
          name: 'phone',
          label: 'Phone',
          type: 'tel',
          placeholder: 'Phone', // Placeholder text
        },
        {
            name: 'message',
            label: 'Business Type & Details:',
            type: 'text',
            placeholder: 'Business Type & Details:', // Placeholder text
          },
          {
            name: 'query',
            label: 'Advisory Message',
            type: 'text',
            placeholder: 'Advisory Message:', // Placeholder text
          },
        // Add more form fields as needed
      ];
 
  return (
    <>
    <div className=' bg-gray-100 p-2 sm:p-10'>
   
   <div className=' w-full flex flex-col justify-center items-center'>
   <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">Legal Advisory</h2>
   <p className='text-sm rounded-lg py-5 px-7  text-justify'>
   Our network of Advocates and Lawyers is committed to assisting you in navigating the legal landscape of business. From contract reviews to dispute resolution, our legal experts are ready to ensure that your business operations remain smooth and legally sound. Say goodbye to unexpected legal hurdles with the assurance that you have a team of professionals supporting your journey.

   </p>
   </div>
    
    <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>
        
        <div className=' flex flex-col justify-center items-center'>
        <div className=' bg-white rounded-2xl p-5 w-[100%] md:w-[70%]'>
      {/* <p className="text-gray-600">
       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas, mollitia.
      </p> */}


      <form ref={formlegal} onSubmit={sendEmail} className="mt-4 flex flex-col">
        <div className="mb-4">
          {/* <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Your Name:
          </label> */}
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Name"
          />
        </div>
        <div className="mb-4">
          {/* <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Your Name:
          </label> */}
          <input
            type="text"
            id="phone"
            name="phone"
            pattern="[0-9]*"
                  maxLength={10}
            value={formData.phone}
            onChange={(e) => {
              const inputValue = e.target.value;
              const numericInput = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
              setFormData({ ...formData, phone: numericInput });
              setPhoneError(numericInput.length === 10 ? '' : 'Please enter a 10-digit phone number');
            }}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Phone"
          />
        </div>
        <div className="mb-4">
          
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            required
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            className={`form-textarea bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-2xl focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            rows="2"
            placeholder="Business Type & Details:"
          ></textarea>
        </div>
        <div className="mb-4">
          
          <textarea
            id="query"
            name="query"
            value={formData.query}
            onChange={(e) => setFormData({ ...formData, query: e.target.value })}
            className={`form-textarea bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-2xl focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
            placeholder="Advisory Message"
          ></textarea>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-violet-500 rounded-full text-white  hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
          disabled={buttonDisabled}
        >
          Book an Appointment
        </button>
      </form>
      </div>
      {/* Add any other UI elements and functionality here */}
    </div>

    
        
        
         
        
            {/* <h1 className=' text-lg font-bold'>Reach Out To Us!</h1> */}
            <div className=' bg-white  rounded-2xl px-2 sm:px-5 pt-5 '>
            
              
           
            <div className=' flex flex-col'>
              <div className='flex md:flex-row flex-col'>
              <img src={LEGAL_ADVISORY} alt="" className='mx-5 h-28 w-28 rounded-full' />
              <p className=' pt-14 px-5 text-2xl font-bold'>Priya Nair Pakhare</p>
              </div>
              <div className=' flex flex-col p-5'>
              <p className='text-sm text-justify'>
              Priya Nair Pakhare, a highly skilled lawyer leading a legal firm in Navi Mumbai, specializes in a range of legal areas with qualifications in B.L.S., LLB., D.C.L., & LLM. Registered with the Bar Council of Maharashtra & Goa, she adeptly manages criminal, divorce, company, civil, and cybercrime cases in Mumbai and its suburbs. Her diverse educational background enriches her grasp of legal intricacies. In addition to her legal practice, Priya is an impassioned social activist, speaker, and educator, actively contributing to initiatives and serving as a Visiting Faculty for Law students at MCT (Manjara Charitable Trust College of Law, Navi Mumbai). Notably, her impactful work extends across India.
              </p>
              </div>
              
            </div>
          
       

</div>
      </div>

    </div>
    
    </>
  );
};

export default Legal;
