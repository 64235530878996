import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { BiStoreAlt } from 'react-icons/bi';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { FaBoxOpen } from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import constant from '../../../constant';

const LOGO = constant.IMAGES_LOGO.LOGO;
const AdminMenuBar = ({ activeTab, handleTabClick }) => {

    const renderTabContent = (tabKey, label, icon) => {
        const isActive = activeTab === tabKey;

        return (
            <div
                className={`flex flex-col justify-center items-center h-24 border-b-2 border-gray-300 hover:bg-gradient-to-r from-[#F16080] to-[#5156E9] hover:text-white ${isActive ? 'active-tab bg-gradient-to-r from-[#F16080] to-[#5156E9] text-white' : 'inactive-tab'
                    }`}
                onClick={() => handleTabClick(tabKey)}
            >
                {icon}
                <div className="flex flex-row">
                    {label}
                    <IoIosArrowForward className="pt-1 m-1" />
                </div>
            </div>
        );
    };

    return (
        <div className="flex">
            <div className="w-48 h-full bg-white overflow-y-auto scrollbar-hide">
                <Link to="/">
                    <img src={LOGO} alt="Logo" className="w-16 h-auto sm:w-auto sm:h-30 ml-2 p-3" />
                </Link>

                <div className="cursor-pointer">
                    {renderTabContent('dashboard', 'Dashboard', <HomeIcon />)}
                    {renderTabContent('vendors', 'Manage Vendors', <BiStoreAlt className="justify-center items-center" />)}
                    {renderTabContent('products', 'Manage Products', <ShoppingCartIcon className="justify-center items-center" />)}
                    {renderTabContent('setting', 'Account Setting', <ManageAccountsSharpIcon />)}
                    {renderTabContent('banner', 'Festival Banner', <ManageAccountsSharpIcon />)}
                    {/* {renderTabContent('createcategory', 'Create Category', <BiSolidCategory />)} */}
                    {renderTabContent('ourorders', 'Our Orders', <FaBoxOpen />)}
                    {renderTabContent('report', 'Report', <NoteAltIcon />)}
                    {renderTabContent('accountingsheet', 'Accounting Sheet',<FaBoxOpen />)}
                    {renderTabContent('logout', 'Logout', <LogoutIcon />)}

                </div>
            </div>


        </div>
    );
};

export default AdminMenuBar;