// CreateCategory.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material'
import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import ImagePreview from './ImagePreview'; // Adjust the path based on your project structure

const CreateCategory = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    selectedCategoryId: '',
    name: '',
    imageUrl: '',
    subcategories: [{ name: '', imageUrl: '' }],
  });
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Make an API request to fetch categories
        const response = await axios.get(`${API_URL}/api/admin-profile/getCategories`);

        // Assuming the response data is an array of categories
        const categoriesFromAPI = response.data;

        setCategories(categoriesFromAPI);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryNameChange = (e) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      name: category.selectedCategoryId ? e.target.value : e.target.value,
    }));
  };

  useEffect(() => {
    const selectedCategory = categories.find((cat) => cat._id === category.selectedCategoryId);

    if (selectedCategory) {
      setCategory((prevCategory) => ({
        ...prevCategory,
        name: selectedCategory.name,
      }));
    }
  }, [category.selectedCategoryId, categories]);

  const handleChange = (index, field, value) => {
    const newSubcategories = [...category.subcategories];
    newSubcategories[index][field] = value;

    setCategory({
      ...category,
      subcategories: newSubcategories,
    });
  };

  const handleAddSubcategory = () => {
    setCategory({
      ...category,
      subcategories: [...category.subcategories, { name: '', imageUrl: '' }],
    });
  };

  const handleRemoveSubcategory = (index) => {
    const newSubcategories = [...category.subcategories];
    newSubcategories.splice(index, 1);

    setCategory({
      ...category,
      subcategories: newSubcategories,
    });
  };
  const handleDeleteCategory = async () => {
    try {
      if (!category.selectedCategoryId) {
        toast.error('Please select a category to delete.');
        return;
      }

      setLoading(true);
      const response = await axios.delete(`${API_URL}/api/admin-profile/deleteCategory/${category.selectedCategoryId}`);
     
      toast.success('Category deleted successfully!');
      setCategory({
        selectedCategoryId: '',
        name: '',
        imageUrl: '',
        subcategories: [{ name: '', imageUrl: '' }],
      });
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to delete the category. Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  const handleSubmit = async () => {
    if (!category.name.trim()) {
      toast.error('Category Name is required.');
      return;
    }

    if (!category.selectedCategoryId && !category.imageUrl.trim()) {
      toast.error('Image URL is required for the Category Name.');
      return;
    }

    for (const subcat of category.subcategories) {
      if (subcat.name.trim() && !subcat.imageUrl.trim()) {
        toast.error('Image URL is required for Subcategory Name.');
        return;
      }
    }

    // Show confirmation modal before submitting
    setShowConfirmationModal(true);
  };

  const handleConfirmSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/api/admin-profile/createCategory`, category);
     
      toast.success('Category created successfully!');
      setCategory({
        selectedCategoryId: '',
        name: '',
        imageUrl: '',
        subcategories: [{ name: '', imageUrl: '' }],
      });
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to create the category. Please try again later.');
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelSubmit = () => {
    // User cancelled submission, close confirmation modal
    setShowConfirmationModal(false);
  };

  const handleResetForm = () => {
    // Clear all fields and start over
    setCategory({
      selectedCategoryId: '',
      name: '',
      imageUrl: '',
      subcategories: [{ name: '', imageUrl: '' }],
    });
  };

  return (
    <div className="container mx-auto p-10 mt-5 bg-white rounded-md max-w-3xl">
      <div className='flex justify-between'>
        <h1 className="text-2xl font-semibold mb-4">Create Category</h1>
        <button type="button" onClick={handleResetForm} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
          Reset
        </button>
      </div>

      <div className='flex gap-2'>
        <label htmlFor="category" className="block text-sm font-semibold mb-2">
          Select Category:
        </label>
        <div>
          <Tooltip title={<h1 style={{ fontSize: 16 }}>Select category if you want to add a subcategory to an existing Category</h1>}>
            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
          </Tooltip>
        </div>
      </div>

      <select
        id="category"
        name="category"
        value={category.selectedCategoryId}
        onChange={(e) => setCategory({ ...category, selectedCategoryId: e.target.value })}
        className="w-full bg-gray-100 p-2 mb-4 rounded"
      >
        <option value="">-- Select Category --</option>
        {categories.map((existingCategory) => (
          <option key={existingCategory._id} value={existingCategory._id}>
            {existingCategory.name}
          </option>
        ))}
      </select>

      <label htmlFor="name" className="block text-sm font-semibold mb-2">
        Category Name:
      </label>

      <input
        type="text"
        id="name"
        name="name"
        value={category.name}
        onChange={handleCategoryNameChange}
        disabled={!!category.selectedCategoryId}
        className="w-full bg-gray-100 p-2 mb-4 rounded"
        maxLength="50"
      />

      {!category.selectedCategoryId && (
        <>
          <label htmlFor="imageUrl" className="block text-sm font-semibold mb-2">
            Image URL:
          </label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={category.imageUrl}
            onChange={(e) => setCategory({ ...category, imageUrl: e.target.value })}
            className="w-full bg-gray-100 p-2 mb-4 rounded"
            maxLength="255"
          />

          {/* Add Image Preview for Category Image */}
          <ImagePreview imageUrl={category.imageUrl} />
        </>
      )}

      <div>
        <label className="block text-sm font-semibold mb-2">Subcategories:</label>
        {category.subcategories.map((subcat, index) => (
          <div key={index} className="mb-4">
            <label htmlFor={`subcategories-${index}-name`} className="block text-sm font-semibold mb-2">
              Name:
            </label>
            <input
              type="text"
              id={`subcategories-${index}-name`}
              name={`subcategories-${index}-name`}
              value={subcat.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              className="w-full bg-gray-100 p-2 mb-2 rounded"
              maxLength="50"
            />

            <label htmlFor={`subcategories-${index}-imageUrl`} className="block text-sm font-semibold mb-2">
              Image URL:
            </label>
            <input
              type="text"
              id={`subcategories-${index}-imageUrl`}
              name={`subcategories-${index}-imageUrl`}
              value={subcat.imageUrl}
              onChange={(e) => handleChange(index, 'imageUrl', e.target.value)}
              className="w-full bg-gray-100 p-2 mb-4 rounded"
              maxLength="255"
            />

            {/* Added Image Preview for Subcategory Image */}
            <ImagePreview imageUrl={subcat.imageUrl} />

            <button
              type="button"
              onClick={() => handleRemoveSubcategory(index)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Remove Subcategory
            </button>
          </div>
        ))}
      </div>

      <button
        type="button"
        onClick={handleAddSubcategory}
        className="bg-[#F16080] hover:opacity-80 text-white font-bold py-2 px-4 rounded mr-2"
      >
        Add Subcategory
      </button>
      <button
        type="button"
        onClick={handleDeleteCategory}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
      >
        Delete Category
      </button>

      <button type="button" onClick={handleSubmit} className="bg-[#9D5BB7] hover:opacity-80 text-white font-bold py-2 px-4 rounded mr-2">
        Submit
      </button>



      {/* Loader component */}
      {loading && <Loader />}

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md max-w-md">
            <p className="text-lg font-semibold mb-4">Confirm Submission</p>
            <p className="mb-4">Are you sure you want to submit the form?</p>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleCancelSubmit}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleConfirmSubmit}
                className="bg-[#9D5BB7] hover:opacity-80 text-white font-bold py-2 px-4 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <Toaster />
    </div>
  );
};

export default CreateCategory;
