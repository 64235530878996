import React, { useEffect, useState, useRef } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedProducts } from "../../Redux/features/product/productsSlice";
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import Loader from "../common/Loader";

const FeaturedProduct = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Dispatch the async thunk to fetch featured products
    dispatch(getFeaturedProducts())
      .then((response) => {
        const products = response.payload.products; // Ensure the correct structure
        setFeaturedProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dispatch]);



  if (error) {
    return <div>Error fetching featured products: {error.message}</div>;
  }

  // If no featured products are available, hide everything
  if (featuredProducts?.length === 0) {
    return null;
  }


  // Render your featured products using the state (featuredProducts)

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };
  const slideLeft = () => {
    var slider = document.getElementById('featuredslider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById('featuredslider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>
      <div className="w-full p-1 sm:p-5">
        {featuredProducts && featuredProducts?.length > 0 && (
          <>
           <div className="w-full flex justify-center text-center text-[27px] font-600 text-[#343246] mt-4">FEATURED PRODUCTS</div>
        <div className='w-full flex justify-center text-center text-lg  text-[#343246] mt-1 mb-8'>Stand out with our featured wonders!</div>

        {loading ? <>
          <div className="flex justify-center"><Loader /></div></>
          :
          <>
            <div className='relative flex  items-center justify-start group'>
              {/* Left arrow */}
              <div onClick={slideLeft} className="absolute left-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 shadow-2xl md:shadow-2xl lg:shadow-2xl xl:shadow-2xl opacity-80">
                <BsArrowLeftShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }} size={35} />
              </div>
              <div id={'featuredslider'} className="flex flex-nowrap gap-5 overflow-x-auto no-scrollbar scroll-smooth pt-4 pb-4  pl-5 pr-5 space-x-4 ml-10 mr-10">
                {featuredProducts && featuredProducts?.length > 0 && featuredProducts.map((product, index) => (
                  <ProductCard data={product} key={index} />
                ))}
              </div>
              {/* Right arrow */}
              <div onClick={slideRight} className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-80">
                <BsArrowRightShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }} size={35} />
              </div>
            </div>
          </>}
          </>
        )}
       

      </div>
    </>
  );
}

export default FeaturedProduct;