import React, { useState, useEffect } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import NotificationDropdown from './NotificationDropdown';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../config/config';

const VendorNavbar = ({ setActiveTab }) => {

  const vendor = useSelector((state) => state.vendor);
  
  const vendorId = useSelector((state) => state.vendor.vendor?._id);

  const name = vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.Businessname;
  const status = vendor && vendor.vendor && vendor.vendor && vendor.vendor.status;

  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(`${API_URL}/api/vendor/${vendorId}/notifications`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(result => {
        setData(result.notifications);
        
      })
      .catch(error => {
      
      });
  }, []);

  const notificationCount = data ? data.length : 0;

  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };


  const notifications = []

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (showNotifications) {
        const notificationIconElement = document.querySelector('.notification-icon');
        if (notificationIconElement && !notificationIconElement.contains(event.target)) {
          setShowNotifications(false);
        }
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showNotifications]);


  const formatDateString = (date) => {
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div className='flex flex-row items-center  py-2 md:px-10 px-4 md:h-24 h-20 bg-white border'>
      <div className="flex flex-row w-[70%] sm:w-[50%]  font-poppins text-sm lg:text-2xl font-medium lg::leading-9 lg:tracking-normal text-left">
        <div className=' justify-center w-[40%] sm:w-full break-words bg-pink items-start  text-xs sm:text-lg lg:text-2xl px-1 sm:px-5'>
          {name}
        </div>

        {/* <div className='justify-center items-center text-center px-1 sm:px-5'>
         {!isAvailableToday && (
          <span className="text-red-500 text-xs sm:text-base lg:text-lg font-bold">
            You are not available from{' '}
            {vendor?.vendor?.activeStore?.startDate && formatDateString(new Date(vendor.vendor.activeStore.startDate))}{' '}
            to{' '}
            {vendor?.vendor?.activeStore?.endDate && formatDateString(new Date(vendor.vendor.activeStore.endDate))}.
          </span>
        )}
         </div> */}

      </div>

      <div className="flex w-[30%] sm:w-[50%] justify-end items-center gap-5">
        <p className=' sm:flex justify-end items-end text-center sm:text-lg lg:text-2xl px-1 sm:px-5'>
          Status <span className='hidden sm:block'>:</span> <span className={`text-center ml-2 ${status === 'Verified' ? 'text-green-500' : (status === 'Rejected' ? 'text-red-500' : 'text-orange-500')}`}>{status}</span>
        </p>

        <div className="relative inline-block felx items-center">
          <span className={`w-2 h-2  bg-gray-500 rounded-full ${notificationCount > 0 ? 'hidden' : ''}`}></span>
          <NotificationsNoneIcon
            className={`w-6 h-6 mr-2  cursor-pointer notification-icon ${showNotifications ? 'text-gray-500' : ''}`}
            onClick={toggleNotifications}
          />
          {notificationCount > 0 && (
            <span className="absolute top-0 right-1 w-4 h-4 flex items-center justify-center text-xs text-white bg-red-500 rounded-full">
              {notificationCount}
            </span>
          )}
          {showNotifications && <NotificationDropdown notifications={vendorId} />}
        </div>

        <AccountCircleRoundedIcon onClick={() => setActiveTab('account')} />

      </div>
    </div>
  );
}

export default VendorNavbar;
