import React, { useEffect, useState } from 'react';
import VendorMenuBar from '../component/VendorProfile/Dashboard/VendorMenuBar';
import VendorNavbar from '../component/VendorProfile/Dashboard/VendorNavbar';
import VendorSales from '../component/VendorProfile/Dashboard/VendorSales';
import VendorStore from '../component/VendorProfile/YourStore/Store'
import NewOrders from '../component/VendorProfile/Dashboard/NewOrders'
import DeliveredOrders from '../component/VendorProfile/Dashboard/DeliveredOrders'
import InProcessOrders from '../component/VendorProfile/Dashboard/InProcessOrders'
import SalesStore from '../component/VendorProfile/YourStore/SalesStore'
import ManageProduct from '../component/VendorProfile/AddProduct/ManageProduct';
import Help from '../component/VendorProfile/NeedHelp/help';
import Report from '../component/VendorProfile/Report/Report';
import AccountDetailsEdit from '../component/VendorProfile/AccounSettings/AccountDetailsEdit'
import TransitOrders from '../component/VendorProfile/Dashboard/TransitOrders'
import { useDispatch, useSelector } from 'react-redux'
import { getVendor } from '../Redux/features/vendor/vendorSlices';
import { Helmet } from 'react-helmet';
import NotVerifiedMessage from '../pages/NotVerifiedMessage';

function Vendor() {

  const users = useSelector((state) => state.auth);
  const vendor = useSelector((state) => state.vendor);
  const vendorStatus = vendor && vendor.vendor && vendor.vendor.status;

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('dashboard');
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const [activeOrders, setActiveOrders] = useState('newOrders');

  const handleOrders = (tabName) => {
    setActiveOrders(tabName);
  }

  useEffect(() => {

    if (users && users.user) {

      dispatch(getVendor({ vendorId: users.user.vendorDetails }))
    }
  }, [users])

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-gray-100">
        <Helmet>
          <title>Vendor</title>
        </Helmet>

        <VendorMenuBar activeTab={activeTab} handleTabClick={handleTabClick} />

        <div className='flex flex-col w-full'>

          <div>
            <VendorNavbar setActiveTab={setActiveTab} />
          </div>
          <div className='p-1 bg-gray-100' >
            {activeTab === 'dashboard' && (
              <>
                {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <>
                    <div className='p-1 bg-gray-100' >
                      <VendorSales />
                    </div>
                    <div className="flex ml-5 space-x-2 min[450px]:space-x-4 p-1 ">
                      <button
                        onClick={() => handleOrders('newOrders')}
                        className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'newOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                          }`}
                      >
                        New Orders
                      </button>
                      <button
                        onClick={() => handleOrders('inProcessOrders')}
                        className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'inProcessOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                          }`}
                      >
                        In Process
                      </button>
                      <button
                        onClick={() => handleOrders('transitOrders')}
                        className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'transitOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                          }`}
                      >
                        Transit
                      </button>
                      <button
                        onClick={() => handleOrders('deliveredOrders')}
                        className={`py-2 px-2 min[450px]:px-4 text-xs min[450px]:text-base rounded ${activeOrders === 'deliveredOrders' ? 'bg-[#8A58DC] text-white' : 'bg-gray-200 text-gray-800'
                          }`}
                      >
                        Delivered Orders
                      </button>

                    </div>

                    {activeOrders === 'newOrders' && <NewOrders />}
                    {activeOrders === 'inProcessOrders' && <InProcessOrders />}
                    {activeOrders === 'transitOrders' && <TransitOrders />}
                    {activeOrders === 'deliveredOrders' && <DeliveredOrders />}
                  </>
                )}
              </>
            )}
          </div>
          <div className='bg-gray-100'>
            {activeTab === 'store' && (
              <>
                 {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <>
                    <div className='bg-gray-100'>
                      <SalesStore />
                    </div>
                    <VendorStore />
                  </>
                )}
              </>
            )}
          </div>

          <div className='bg-gray-100'>
            {activeTab === 'product' && (
              <>
                {vendorStatus === 'Pending' || vendorStatus === 'Rejected' ? (
                  <NotVerifiedMessage />
                ) : (
                  <ManageProduct />
                )}
              </>
            )}
          </div>

          <div className=' bg-gray-100 ' >
            {activeTab === 'account' && (
              <>
                <AccountDetailsEdit />
              </>
            )}
          </div>
          <div className=' bg-gray-100' >
            {activeTab === 'report' && (
              <>
                <Report />
              </>
            )}
          </div>
          <div className=' bg-gray-100' >
            {activeTab === 'help' && (
              <>
                <Help />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendor;
