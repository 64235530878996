import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import VendorPolicyPopup from "./VendorPolicy";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from "react-redux";
import { createVendor, resetMessage } from "../../Redux/features/auth/authSlice";
import { toast, Toaster } from "react-hot-toast";
import Loader from "../common/Loader";


const AccountDetails = ({ onPreviousStep, setAllData, allData }) => {
  const dispatch = useDispatch()
  const navigate=useNavigate()
  const users = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false);
  // State variables for input field values and error messages
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [Branch, setBranch] = useState("");
  const [CIFNumber, setCIFNumber] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");

  // State variables for error messages
  const [AccountHolderNameError, setAccountHolderNameError] = useState("");
  const [AccountNumberError, setAccountNumberError] = useState("");
  const [IFSCCodeError, setIFSCCodeError] = useState("");
  const [BranchError, setBranchError] = useState("");
  const [CIFNumberError, setCIFNumberError] = useState("");
  const [CityError, setCityError] = useState("");
  const [StateError, setStateError] = useState("");
  const [otherBusinessDocumenterror, setotherBusinessDocumenterror] = useState("")
  const [images, setImages] = useState([]);
  const [otherBusinessDocument, setOtherBusinessDocument] = useState(null)


  const toggleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };


  const isValidAccountNumber = (number) => {
    // Regular expression for validating digits only
    const digitRegex = /^\d+$/;
    return digitRegex.test(number);
  };

  const isValidIFSCCode = (code) => {
    // Regular expression for IFSC code validation
    const ifscRegex = /^[A-Z]{4}[0-9A-Za-z]{7}$/;
    return ifscRegex.test(code);
  };

  const isValidCIFNumber = (number) => {
    // Regular expression for validating numbers only
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(number);
  };

  const handleShowPolicyPopup = () => {
    // Check if all fields are filled before showing the policy popup
    if (!isPdf(otherBusinessDocument)){
      toast.error("Please upload a PDF files for otherBusiness Document");
      return;
    }
    if (
      AccountHolderName &&
      isValidAccountNumber(AccountNumber) &&
      isValidIFSCCode(IFSCCode) &&
      Branch &&
      isValidCIFNumber(CIFNumber) &&
      City &&
      State && otherBusinessDocument
    ) {

      setAllData({
        ...allData,
        AccountHolderName: AccountHolderName,
        AccountNumber: AccountNumber,
        IFSCCode: IFSCCode,
        CIFNumber: CIFNumber,
        Branch: Branch,
        City: City,
        State: State,
        otherBusinessDocument: otherBusinessDocument
      })
      setShowPolicyPopup(true);
    } else {
      // Set error messages for fields with invalid formats
      if (!AccountHolderName) setAccountHolderNameError("This field is required");
      if (!isValidAccountNumber(AccountNumber)) setAccountNumberError("Please enter a valid account number.");
      if (!isValidIFSCCode(IFSCCode)) setIFSCCodeError("Please enter a valid IFSC code.");
      if (!Branch) setBranchError("This field is required");
      if (!isValidCIFNumber(CIFNumber)) setCIFNumberError("Please enter a valid CIF number.");
      if (!City) setCityError("This field is required");
      if (!State) setStateError("This field is required");
      if (!otherBusinessDocument) setotherBusinessDocumenterror("This field is required");
    }
  };


  const fieldsToCheck = [
    { value: AccountHolderName, errorState: setAccountHolderNameError, fieldName: "Account Holder Name" },
    { value: AccountNumber, errorState: setAccountNumberError, fieldName: "Account Number", validator: isValidAccountNumber },
    { value: IFSCCode, errorState: setIFSCCodeError, fieldName: "IFSC Code", validator: isValidIFSCCode },
    { value: Branch, errorState: setBranchError, fieldName: "Branch" },
    { value: CIFNumber, errorState: setCIFNumberError, fieldName: "CIF Number", validator: isValidCIFNumber },
    { value: City, errorState: setCityError, fieldName: "City" },
    { value: State, errorState: setStateError, fieldName: "State" },
  ];

  // fieldsToCheck.forEach(({ value, errorState, fieldName, validator }) => {
  //   if (!value || (validator && !validator(value))) {
  //     errorState(`Please enter a valid ${fieldName}.`);
  //     toast.error(`Please fill in a valid ${fieldName}.`);
  //   }
  // });

  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);


  const handleClosePolicyPopup = () => {
    // e.preventDefault()
    setShowPolicyPopup(false);
  };

  const handleAgreePolicy = () => {
    // Add your form submission logic here
    // e.preventDefault()

    setLoading(true);

    dispatch(createVendor({ id: users.user._id, ...allData })).then((response) => {
      setLoading(false); 
   
      if (response.error) {
        toast.error(response.payload)
        dispatch(resetMessage())
      } else {
        toast.success('Vendor created successfully')
        navigate('/');
      }
    })
    handleClosePolicyPopup();

  };


  const TransformPdf = (pdfFile, setPdfState) => {
    const reader = new FileReader();
    reader.readAsDataURL(pdfFile);
    reader.onload = () => {
      setPdfState(reader.result);
    };
  };
  const checkAndTransformPdf = (pdfFile, setPdfState, event) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
  
    if (pdfFile.size > maxSize) {
      toast.error("File size should be less than 1MB");
      event.target.value = null;
      return;
    }
    if (!isPdfFile(pdfFile)) {
      toast.error("Please upload a PDF files for business registration documents");
      event.target.value = null;
      return;
    }
    TransformPdf(pdfFile, setPdfState);
  };


  const handleotherBusinessDocument = (event) => {
    const file = event.target.files[0];
    checkAndTransformPdf(file, setOtherBusinessDocument, event);
  };

  const isPdfFile = (file) => {
    return file && file.type === "application/pdf";
  };
  const isPdf = (file) => {
    return file && file.startsWith("data:application/pdf");
  };
  

  return (
    <>

      <div className="flex md:flex-row items-start justify-evenly gap-5 xl:gap-10 p-10 flex-col">
        <div className="font-bold w-[100%]    md:w-[25%] text-center md:sticky top-10">
          <h1>Account Details</h1>
        </div>
        {/* left div */}
        <form onSubmit={(e) => e.preventDefault()} className=" w-full md:w-[41%]">
          <div className="flex flex-col gap-7 mb-4 ">
            <div className="flex flex-col w-full">
              <input
                type="text"
                placeholder="Account Holder Name *"
                className="w-full  h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC] "
                required
                maxLength={30}
                value={AccountHolderName}
                onChange={(e) => {
                  const inputValue = e.target.value.toUpperCase();
                  // Allow only alphabets
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                  setAccountHolderName(sanitizedValue);
                  setAccountHolderNameError(""); // Clear the error message
                }}
              />
              {AccountHolderNameError && (
                <p className="text-red-500">{AccountHolderNameError}</p>
              )}
            </div>
            <div className="flex flex-col">

              <input
                type="text"
                placeholder="Account Number *"
                className="w-full h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC]"
                required
                value={AccountNumber}
                minLength={9}
                maxLength={18}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setAccountNumber(inputValue);

                  // Check if the entered value is valid (contains only digits)
                  if (isValidAccountNumber(inputValue) || inputValue === "") {
                    setAccountNumberError("");
                  } else {
                    setAccountNumberError("Please enter only 11-18 digits for the account number.");
                  }
                }}
              />
              {AccountNumberError && (
                <p className="text-red-500">{AccountNumberError}</p>
              )}
            </div>
            <div className="flex flex-col">

              <input
                type="text"
                placeholder="IFSC Code *"
                className="w-full h-12 border rounded-xl p-2 focus:outline-none focus:border-[#8A58DC]"
                required
                value={IFSCCode}
                maxLength={11}
                onChange={(e) => {
                  const inputValue = e.target.value.toUpperCase();
                  setIFSCCode(inputValue);
                  // Check if the entered value is a valid IFSC code
                  if (isValidIFSCCode(inputValue) || inputValue === "") {
                    setIFSCCodeError("");
                  } else {
                    setIFSCCodeError("Please enter a valid IFSC code (E.g.,  SBIN0005943).");
                  }
                }}
              />
              {IFSCCodeError && (
                <p className="text-red-500">{IFSCCodeError}</p>
              )}
            </div>

            <div className="flex flex-col">
              <input
                type="text"
                placeholder="Branch *"
                className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                required
                value={Branch}
                maxLength={20}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow only alphabets
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 20);
                  setBranch(sanitizedValue);
                  setBranchError(""); // Clear the error message
                }}
              />
              {BranchError && (
                <p className="text-red-500">{BranchError}</p>
              )}
            </div>

            <div className="flex flex-col">
              <input
                type="text"
                placeholder="CIF Number *"
                className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                required
                value={CIFNumber}
                maxLength={11}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setCIFNumber(inputValue);

                  // Check if the entered value is valid (contains only numbers)
                  if (isValidCIFNumber(inputValue) || inputValue === "") {
                    setCIFNumberError("");
                  } else {
                    setCIFNumberError("Please enter your 11 digit CIF Number.");
                  }
                }}
              />
              {CIFNumberError && (
                <p className="text-red-500">{CIFNumberError}</p>
              )}
            </div>

            <div className="flex flex-col">
              <input
                type="text"
                placeholder="City *"
                className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                required
                value={City}
                maxLength={30}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow only alphabets
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                  setCity(sanitizedValue);
                  setCityError(""); // Clear the error message
                }}
              />
              {CityError && (
                <p className="text-red-500">{CityError}</p>
              )}
            </div>

            <div className="flex flex-col">
              <input
                type="text"
                placeholder="State *"
                className="flex w-full p-2 h-12 border rounded-xl focus:outline-none focus:border-[#8A58DC]"
                required
                value={State}
                maxLength={30}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Allow only alphabets
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                  setState(sanitizedValue);
                  setStateError(""); // Clear the error message
                }}

              />
              {StateError && (
                <p className="text-red-500">{StateError}</p>
              )}
            </div>

            <div className="mt-4">
              <div className="flex flex-row">
                <p className="mt-3 mb-2 font-bold">Other Account Documents</p>
                <Tooltip
                  title="Please upload front page of Passbook (Upload PDF less than 1 MB).">
                  <IconButton>
                    <InfoIcon className="text-[#8A58DC] top-4 cursor-pointer" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className=" w-full">
                <input
                  type='file'
                  className="mt-2 w-full border rounded p-4 "
                  id="productimageUpload"
                  name="productimageUpload"
                  accept=".pdf"
                  onChange={handleotherBusinessDocument}
                  ></input>
                  {
                    otherBusinessDocumenterror && (
                      <p className="text-red-500">{setotherBusinessDocumenterror}</p>
                    )
                  }
  
                </div>
            </div>

            <div className="flex flex-row gap-5 p-5">

              <Link
                onClick={onPreviousStep}
                className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
              >
                <span className="absolute inset-0 flex items-center text-4xl justify-center w-full h-full text-white duration-300 translate-x-full bg-[#8A58DC] group-hover:translate-x-0 ease">
                  <IoIosArrowRoundBack />
                </span>
                <span className="absolute flex items-center justify-center w-full h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-x-full ease">
                  Previous
                </span>
                <span className="relative invisible">Previous</span>
              </Link>




              {/* Step 2: Show the policy pop-up on "Submit" click */}
              <button
                onClick={handleShowPolicyPopup}
                className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-[#8A58DC] rounded-full shadow-md group"
              >
                <span className="absolute inset-0 flex items-center text-4xl justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#8A58DC] group-hover:translate-x-0 ease">
                  <IoIosArrowRoundForward />
                </span>
                <span className="absolute flex items-center justify-center w-full h-full text-[#8A58DC] font-bold transition-all duration-300 transform group-hover:translate-x-full ease">
                  Submit
                </span>
                <span className="relative invisible">Submit</span>
              </button>
              {showPolicyPopup && (
                <VendorPolicyPopup onClose={handleClosePolicyPopup} onAgree={handleAgreePolicy} />
              )}
            </div>
          </div>
        </form>
        {loading &&
         <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50  bg-[#00000040]">
          <Loader />
        </div> 
        }


        {/* right part */}
        <div className='flex p-5 md:w-[500px] md:sticky top-32'>
          <img src="https://img.freepik.com/premium-vector/onlin-ebusiness-partnership-two-male-cartoon-characters-businessmen-shaking-hands-making-business-deal-business-management-remotely-flat-vector-illustration_241107-1243.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" alt="" />
        </div>
      </div>

    </>
  );
};

export default AccountDetails;