import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import constant from '../../constant';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../config/config';
import Loader from '../common/Loader';

const IMAGE1=constant.IMAGES_IMAGES.ASSISTANCE.PHOTOGRAPHY.IMAGE1;
const IMAGE2=constant.IMAGES_IMAGES.ASSISTANCE.PHOTOGRAPHY.IMAGE2;
const IMAGE3=constant.IMAGES_IMAGES.ASSISTANCE.PHOTOGRAPHY.IMAGE3;
const IMAGE4=constant.IMAGES_IMAGES.ASSISTANCE.PHOTOGRAPHY.IMAGE4;

const DesignsNPhotography = () => {

  const formdp = useRef();
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
  
    // Your form validation logic here...
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }
  
    try {
      const response = await axios.post(`${API_URL}/api/email/sendDesignNPhotographyEmail`, formData);
  
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          requirement: '',
          businessType: '',
          location: '',
        });
      } else {
        toast.error('Failed to submit form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form. Please try again.');
    }
    
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  };

  const previousWorks = [
    {
       imageUrl: IMAGE1,
    },
    {
       imageUrl: IMAGE2,
    },
    {
     imageUrl: IMAGE3,
    },
    {
       imageUrl: IMAGE4,
    },
  ];
  const [loadingImages, setLoadingImages] = useState(true);

  const handleImageLoad = () => {
    setLoadingImages(false);
  };


  //form data

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    requirement: '',
    businessType:'',
          location:'',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  const handlePhoneInputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 10); // Limit to 10 characters

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: inputValue,
    }));

    
  };
  
  // Define an array of form fields for rendering
  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Name', // Placeholder text
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Email',
      pattern: '^[^\s@]+@[^\s@]+\.[^\s@]+$',
      // Placeholder text
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      placeholder: 'Phone', // Placeholder text
    },
    {
      name: 'requirement',
      label: 'Requirement',
      type: 'text',
      placeholder: 'Your Requirements', // Placeholder text
    },
    {
      name: 'businessType',
      label: 'Business Type',
      type: 'text',
      placeholder: 'Business Type', // Placeholder text
    },
    {
      name: 'location',
      label: 'Location',
      type: 'text',
      placeholder: 'Location', // Placeholder text
    },
    // Add more form fields as needed
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement logic to handle form submission with the formData state.
     // Example: Log form data to the console
  };

  return (
    <div className="bg-gray-100 p-4">
      <h2 className="text-xl min-[510px]:text-2xl text-center font-bold mb-4 text-gray-800">Photography</h2>
      <p className="text-gray-600 text-center">
        Explore our previous works in designs and photography. We take pride in our creative projects.
      </p>
      <div className="mt-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {previousWorks.map((work, index) => (
            <div key={index} className="p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow relative">
             <div className="aspect-w-1 aspect-h-1">
                {loadingImages &&  <div className="absolute inset-0 flex items-center justify-center"><Loader/></div>}
                <img
                  src={work.imageUrl}
                  alt="photography"
                  className={`w-96 h-96 object-cover opacity-${loadingImages ? '0' : '100'} hover:opacity-100 transition-opacity`}
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className=' grid md:grid-cols-2 grid-cols-1 gap-2 p-2'>


        <div className=' flex flex-col justify-center items-center'>
          <div className=' bg-white rounded-2xl p-5 w-[100%] md:w-[70%]'>



            {/* <p className="text-gray-600 mb-4">
            If you liked our work and want to create your own masterpiece, feel free to contact us. We'd love to hear from you!

            </p> */}
            <form ref={formdp} onSubmit={sendEmail} className="mt-4 flex flex-col">
            <div className="flex flex-wrap -mx-4">
              {formFields.map((field, index) => (
                <div key={index} className={`mb-4 px-4 ${field.name === 'email' || field.name === 'phone' ? 'w-full md:w-1/2' : 'w-full'}`}>
                  {/* <label htmlFor={field.name} className="block text-gray-700 font-bold">
                    {field.label}:
                  </label> */}
                  <input
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={field.name === 'phone' ? handlePhoneInputChange : handleInputChange}
                    placeholder={field.placeholder} // Placeholder text
                    className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                    maxLength={field.name === 'phone' ? 10 : undefined}
                    required
                  />
                </div>
              ))}
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
            disabled={buttonDisabled}
          >
            Submit
          </button>
        </form>
        </div>
        {/* Add any other UI elements and functionality here*/}
      </div>

      <div className=' flex flex-col justify-center  rounded-lg  order-1 md:order-2 py-20 md:py-5 px-2 sm:px-10 lg:px-20'>
            <h1 className=' font-bold text-2xl text-violet-500 indent-2'>Reach Out To Us ...</h1>
            <p className=' text-sm rounded-lg p-2 text-justify'>
            Elevate your product presentation with our community of skilled photographers. They specialize in enhancing your product imagery, ensuring that your offerings are visually captivating to potential customers. From professional photoshoots to creative styling, our photographers are dedicated to making your products stand out in the crowded marketplace.
            </p>

          </div>

    </div>
        
            </div >
        
    );
};

export default DesignsNPhotography;
