import React from 'react';
import { Link } from 'react-router-dom';
import { MdVerified } from "react-icons/md";

import constant from "../../constant";

const THE_EKAIV = constant.IMAGES_IMAGES.ASSISTANCE.WEB_APP_SOFTWARE_DEVELOPMENT.EKAIV_INSTA;
const InstaCards = ({ link, alt, content, photo }) => {
  return (
    <article className="flex-shrink-0 h-full mb-4 break-inside p-6 rounded-sm bg-white flex flex-col bg-clip-border border border-spacing-0 shadow-lg">
      <div className="flex pb-6 items-center justify-between">
        <div className="flex">
          <a className="inline-block mr-4" href="#">
            <img className="rounded-full max-w-none w-14 h-14" src={THE_EKAIV} alt="User Avatar" />
          </a>
          <div className="flex flex-col justify-center">
            <div className="flex items-center">
              <Link className="inline-block text-sm font-medium mr-2 my-auto" to={"https://www.instagram.com/the_ekaiv/"} target='_blank'>the_ekaiv</Link>
              <span>
                <MdVerified className="fill-blue-500 w-5 h-5" />
              </span>
            </div>
            {/* <div className="text-slate-500 dark:text-slate-300">
              January 22, 2021
            </div> */}
          </div>
        </div>
      </div>
      <div className="py-4">
        <Link className="flex" to={link} target='_blank'>
          <img className="max-w-full rounded-lg"
            src={photo} alt={alt} />
        </Link>
      </div>
      {/* <p>
        {content}
      </p> */}
    </article>
  );
};

export default InstaCards;
