import React, { useEffect, useState, useRef } from 'react';
import { FaImage } from 'react-icons/fa';
import { Tooltip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { getVendor, updateVendorPersonal, updateVendorBusiness, updateVendorAccount, reset, resetMessage } from '../../../Redux/features/vendor/vendorSlices'; // Update with your actual Redux slice and actions
import { toast } from 'react-hot-toast'
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";
import { API_URL, config } from '../../../config/config';
import { Link } from 'react-router-dom';
const token = JSON.parse(localStorage.getItem('token'));




const AccountDetailsEdit = () => {
  const previousVendorId = useRef(null);
  const vendor = useSelector((state) => state.vendor);
  const ShopAvatar = vendor?.vendor?.ShopAvatar
  const dispatch = useDispatch();
  const vendorId = vendor && vendor.vendor && vendor.vendor._id;
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [mobileEditMode, setMobileEditMode] = useState(false);
  const [altMobileEditMode, setAltMobileEditMode] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState('');
  const [altNewMobileNumber, setAltNewMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpInputVisible, setOtpInputVisible] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otpInput1, setOtpInput1] = useState('');
  const [otpInput2, setOtpInput2] = useState('');
  const [IFSCCodeError, setIFSCCodeError] = useState("");
  const [CIFNumberError, setCIFNumberError] = useState("");
  const [fieldsEmpty, setFieldsEmpty] = useState(false);


  const handleOtpCancelClick = () => {
    // Logic to cancel OTP input
    setEmailEditMode(false);
    setMobileEditMode(false);
    setAltMobileEditMode(false);
    setShowOtpPopup(false);
  };

  const handleOtpSubmit = async () => {

    if (otpInput1.trim() === '' || otpInput2.trim() === '') {
      // Display an error message or take appropriate action
      toast.error('Please enter both OTPs before submitting.');
      return;
    }
    try {
      // Add logic to verify OTP
      // For example, you can dispatch an action to verify OTP or make an API call

      // Simulating a successful OTP verification for demonstration purposes
      // Replace this with your actual logic
      const otpVerificationSuccess = true;

      if (otpVerificationSuccess) {
        toast.success('OTP verified successfully!');

        // Close the edit mode
        setEmailEditMode(false);
        setMobileEditMode(false);
        setAltMobileEditMode(false);

        // Save changes by dispatching the appropriate action
        // For example, you can dispatch the updateVendor action
        dispatch(updateVendorPersonal({
          vendorId,
          name,
          email,
          mobile,
          altMobile,
          dob,
          gender,
        })).then(() => {
          dispatch(getVendor({ vendorId }));

        });
      } else {
        toast.error('Failed to verify OTP. Please try again.');
      }

      // Reset OTP-related state
      setOtpInputVisible(false);
      setOtp('');
      setOtpSent(false);
      setOtpInput1('');
      setOtpInput2('');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('Failed to verify OTP. Please try again.');
    }
  };

  useEffect(() => {
    if (vendor && vendor.vendor) {
      if (vendor.vendor !== previousVendorId.current) {

        dispatch(getVendor({ vendorId }));

        previousVendorId.current = vendor.vendor;
      }
    }
  }, [vendor, vendorId]);

  useEffect(() => {
    if (vendor && vendor.status === 'success') {
      setGeneralEditMode(false);
      setBusinessEditMode(false);
      setAccountEditMode(false);
      dispatch(resetMessage());
    }
  }, [vendor]);

  const handleGeneralSaveClick = () => {

    if (mobileEditMode && !otpSent) {
      toast.error('Please verify your mobile number by entering OTP.');
      return;
    }

    // Check if all required fields are filled
    if (!name || !email || !mobile || !altMobile || !dob || !gender) {
      toast.error('Please fill in all the fields.');
      return;
    }
    setGeneralEditMode(false);
    setMobileEditMode(false);
    dispatch(updateVendorPersonal({
      vendorId,
      name,
      email,
      mobile,
      altMobile,
      dob,
      gender,
    })).then(() => {
      dispatch(getVendor({ vendorId }));
    });
  };




  const [activeTab, setActiveTab] = useState('general');

  const [name, setName] = useState(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.name)
  const [email, setEmail] = useState(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.email);
  const [mobile, setMobile] = useState(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.phoneNumber);
  const [altMobile, setAltMobile] = useState(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.alternateNumber);
  // const formattedDate = new Date(vendor && vendor.vendor &&vendor.vendor.PersonalDetails.dateOfBirth).toISOString().split('T')[0];

  const [dob, setDob] = useState(() => {
    if (vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.dateOfBirth) {
      const rawDate = vendor.vendor.PersonalDetails.dateOfBirth;
      const parsedDate = new Date(rawDate);
      const formattedDate = parsedDate.toISOString().split('T')[0];

      return formattedDate;
    }
    return '';
  });
  const status = (vendor && vendor.vendor && vendor.vendor.status)
  const [gender, setGender] = useState(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.gender);
  const [businessName, setBusinessName] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.Businessname);
  const [businessEmail, setBusinessEmail] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessEmail);
  const [panNumber, setPanNumber] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.panNumber);
  const [addressLine1, setAddressLine1] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.addressLine1);
  const [addressLine2, setAddressLine2] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.addressLine2);
  const [landmark, setLandmark] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.landmark);
  const [city, setCity] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.city);
  const [state, setState] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.state);
  const [pincode, setPincode] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.BusinessAddress.pincode);
  const [gstNumber, setGstNumber] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.gstNumber);
  const [businessRegistration, setBusinessRegistration] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.businessRegistration);
  const [businessAddressProof, setBusinessAddressProof] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.businessAddressProof);
  const [foodLicenseProof, setFoodLicenseProof] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.foodLicenseProof);
  const [about, setAbout] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.about);
  const [typeOfBusiness, setselectedTypeOfBusiness] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.typeOfBusiness || []);
  const initiallySavedCategories = vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.BussinessDetails.typeOfBusiness || [];
  const products = vendor && vendor.vendor && vendor.vendor.products
  const [allowRegistration, setAllowRegistration] = useState(true)
  const [allowAddressProof, setAllowAddressProof] = useState(true)
  const [allowfood, setAllowfood] = useState(true)

  // const [typeOfBusiness, setTypeOfBusiness] = useState([]);
  const [accountHolderName, setAccountHolderName] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.AccountHolderName);
  const [accountNumber, setAccountNumber] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.AccountNumber);
  const [ifscCode, setIfscCode] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.IFSCCode);
  const [branch, setBranch] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.Branch);
  const [cifNumber, setCifNumber] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.CIFNumber);
  const [accCity, setAccCity] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.City);
  const [accState, setAccState] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.State);
  const [otherBusinessDocument, setOtherBusinessDocument] = useState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.otherBusinessDocument);
  const [accountEditMode, setAccountEditMode] = useState(false);
  const [generalEditMode, setGeneralEditMode] = useState(false);
  const [businessEditMode, setBusinessEditMode] = useState(false);
  const [ShopAvatarImage, setShopAvatarImage] = useState(null);
  const [allowOther, setAllowOther] = useState(null)



  useEffect(() => {
    // Retrieve the uploaded image URL from localStorage on component mount
    const storedImageUrl = localStorage.getItem('uploadedImageUrl');
    if (storedImageUrl) {
      setShopAvatarImage(storedImageUrl);
    }
  }, []);

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      if (!imageFile.type.startsWith('image/')) {
        toast.error('Please upload a valid image file.');
        return;
      }
      const reader = new FileReader();

      reader.onloadend = async () => {
        const imageDataUrl = reader.result; // Extracting the base64 data
        const vendorId = vendor.vendor._id;

        try {
          // Send the converted data to the backend as a string
          const response = await axios.post(
            `${API_URL}/api/vendor/uploadAvatar/${vendorId}`,
            { imageDataUrl },
            config
          );

          const { url } = response.data;

          toast.success('Vendor Avatar uploaded successfully!');
          setShopAvatarImage(url);
          event.target.form.reset()
          localStorage.setItem('ShopAvatarImage', url);

        } catch (error) {
          event.target.form.reset()
          toast.error(error.response.data.error)
        }
      };

      reader.readAsDataURL(imageFile);
    }
  };






  // General Details State
  const handleGeneralEditClick = () => setGeneralEditMode(!generalEditMode);


  const handleMobileSendOTP = async () => {
    if (!mobile) {
      toast.error('Mobile Number field is empty.');
      return;
    }

    setOtpInputVisible(true);
    setShowOtpPopup(true);

    try {
      // Call your API to send OTP to the existing mobile number
      const response1 = await axios.post(`${API_URL}`, { mobile });

      if (response1.data.success) {
        toast.success('OTP sent to existing mobile number successfully!');
      } else {
        toast.error('Failed to send OTP to existing mobile number. Please try again.');
      }

      // If the user has entered a new mobile number, send OTP to that number as well
      if (mobileEditMode) {
        const response2 = await axios.post(`${API_URL}`, { newMobileNumber });

        if (response2.data.success) {
          toast.success('OTP sent to the new mobile number successfully!');
        } else {
          toast.error('Failed to send OTP to the new mobile number. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Please try again.');
    }
  };

  const handleAltMobileSendOTP = async () => {
    if (!altMobile) {
      toast.error('Alternate Mobile Number field is empty.');
      return;
    }

    setOtpInputVisible(true);
    setShowOtpPopup(true);

    try {
      // Call your API to send OTP to the existing mobile number
      const response1 = await axios.post(`${API_URL}`, { mobile });

      if (response1.data.success) {
        toast.success('OTP sent to existing mobile number successfully!');
      } else {
        toast.error('Failed to send OTP to existing mobile number. Please try again.');
      }

      if (altMobileEditMode) {
        const response2 = await axios.post(`${API_URL}`, { altNewMobileNumber });

        if (response2.data.success) {
          toast.success('OTP sent to the new mobile number successfully!');
        } else {
          toast.error('Failed to send OTP to the new mobile number. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Please try again.');
    }
  };


  const handleEmailSendOTP = async () => {

    if (!email) {
      toast.error('Email field is empty. Please enter a valid email.');
      return;
    }

    if (!isValidEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setOtpInputVisible(true);
    setShowOtpPopup(true);


    try {
      // Call your API to send OTP to the existing mobile number
      const response1 = await axios.post(`${API_URL}`, { mobile });

      if (response1.data.success) {
        toast.success('OTP sent to existing mobile number successfully!');
      } else {
        toast.error('Failed to send OTP to existing mobile number. Please try again.');
      }

      // If the user has entered a new email, send OTP to that email as well
      if (emailEditMode) {
        const response2 = await axios.post(`${API_URL}`, { newEmail: email });

        if (response2.data.success) {
          toast.success('OTP sent to the new email successfully!');
        } else {
          toast.error('Failed to send OTP to the new email. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Failed to send OTP. Please try again.');
    }
  };

  const handleResendOTP = async () => {
    try {
      // Add logic to resend OTP
      // For example, you can dispatch an action to resend OTP or make an API call

      // Simulating a successful resend for demonstration purposes
      // Replace this with your actual logic
      const resendSuccess = true;

      if (resendSuccess) {
        toast.success('OTP resent successfully!');
      } else {
        toast.error('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      toast.error('Failed to resend OTP. Please try again.');
    }
  };

  const handleMobileInputChange = (e) => {
    // Allow only digits in the mobile number input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setMobile(sanitizedValue);
  };

  const handleAltMobileInputChange = (e) => {
    // Allow only digits in the alternate mobile number input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setAltMobile(sanitizedValue);
  };


  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleOtpInputChange1 = (e) => {
    // Allow only digits in the first OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setOtpInput1(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };

  const handleOtpInputChange2 = (e) => {
    // Allow only digits in the second OTP input
    const sanitizedValue = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
    setOtpInput2(sanitizedValue.slice(0, 6)); // Limit the length to 6 digits
  };


  const handleDateOfBirthChange = (event) => {
    setDob(event.target.value);

  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEmailCancelClick = () => {
    setEmail(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.email)
    setEmailEditMode(false);
    setOtp('');
    setOtpSent(false);
  }

  const handleMobileCancelClick = () => {

    setMobile(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.phoneNumber);
    setMobileEditMode(false);
    setOtpInputVisible(false);
    setOtp('');
    setOtpSent(false);
    // If you want to revert the mobile number to its original value on cancel, uncomment the line below
    // setMobile(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.phoneNumber);
  };

  const handleAltMobileCancelClick = () => {
    // Reset the state to the original values
    setAltMobileEditMode(false);
    setAltMobile(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.alternateNumber);
    setOtpInputVisible(false);
    setOtp('');
    setOtpSent(false);
  };

  const handleGeneralCancelClick = () => {
    setGeneralEditMode(false);

    setName(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.name);

    setDob(() => {
      if (vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.dateOfBirth) {
        const rawDate = vendor.vendor.PersonalDetails.dateOfBirth;
        const parsedDate = new Date(rawDate);
        const formattedDate = parsedDate.toISOString().split('T')[0];
        return formattedDate;
      }
      return '';
    });
    setGender(vendor && vendor.vendor && vendor.vendor.PersonalDetails && vendor.vendor.PersonalDetails.gender);

  };

  // business details
  const handleBusinessEditClick = () => setBusinessEditMode(!businessEditMode);


  useEffect(() => {

    if (products && products.length > 0) {
      try {
        const fetchProductData = async (product) => {
          try {
            const response = await fetch(`${API_URL}/api/Products/productcateogry/${product}`, config);
            const data = await response.json();
           
          } catch (error) {
            console.error("Error fetching data for product:", product, error);
            // Handle errors here
          }
        };

        // Assuming products is an array
        products && products.length > 0 && products.map(async (product, index) => {
          // Check if the product at the given index exists
          if (typeof product !== 'undefined') {
            await fetchProductData(product);
          }
        });

      } catch (error) {
        console.error("Error fetching data:", error);

      }
    }
  }, [])


  const handleCategoryCheckboxChange = (category) => {
    // Check if the category is initially saved
    const isInitiallySaved = initiallySavedCategories.includes(category);

    // If the category is initially saved, prevent deselection
    if (isInitiallySaved) {
      toast.error("You can't deselect a cateogry")
      return;
    }

    // If the category is not initially saved, update the state as usual
    const updatedCategories = typeOfBusiness.includes(category)
      ? typeOfBusiness.filter((c) => c !== category)
      : [...typeOfBusiness, category];

    setselectedTypeOfBusiness(updatedCategories);
  };



  const isValidBusinessEmail = (email) => {
    // Regular expression for a basic email format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPANNumber = (value) => {
    const panNumberRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
    return panNumberRegex.test(value);
  };


  const isValidGSTNumber = (value) => {
    const gstNumberRegex = /^[0-9]{2}[A-Za-z]{4}[0-9A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{3}$/;
    return gstNumberRegex.test(value);
  };




  const handleBusinessSaveClick = () => {
    // Validate that all fields are filled
    const requiredFields = [
      'vendorId',
      'businessName',
      'panNumber',
      'gstNumber',
      'typeOfBusiness',
      'addressLine1',
      'addressLine2',
      'landmark',
      'city',
      'state',
      'pincode',
      'businessRegistration',
      'businessAddressProof',
      'about'
    ];

    const emptyField = requiredFields.find(field => {
      if (field === 'foodLicenseProof' && typeOfBusiness === 'homemade-food') {
        return !foodLicenseProof;
      }
      return !eval(field);
    });
    if (!about) {
      toast.error("About must be filled");
      return;
    }

    if (emptyField) {
      toast.error(`${emptyField} must be filled`);
      return;
    }
    if (!isPdf(businessRegistration)) {
      toast.error("Please upload a PDF files for business registration documents");
      return;
    }
    if (!isPdf(foodLicenseProof) && typeOfBusiness.includes('homemade-food')) {
      toast.error("Please upload a PDF files for food license documents");
      return;
    }
    if (!isPdf(businessAddressProof)) {
      toast.error("Please upload a PDF files for business address proof documents");
      return;
    }

    if (!allowAddressProof || !allowRegistration || !allowfood) {
      let missingCondition = "";

      if (!allowAddressProof) {
        missingCondition = " Buisness Address Proof";
      } else if (!allowRegistration) {
        missingCondition = "Buisness Registration";
      } else if (!allowfood) {
        missingCondition = "Food License";
      }

      toast.error(`Please provide the document for ${missingCondition} in PDF format`);
      return;
    }







    setBusinessEditMode(false);
    // Dispatch the updateVendor action
    dispatch(updateVendorBusiness({
      // Include the necessary fields for updating
      vendorId,
      businessName,
      businessEmail,
      panNumber,
      typeOfBusiness,
      gstNumber,
      addressLine1,
      addressLine2,
      landmark,
      city,
      state,
      pincode,
      foodLicenseProof,
      businessRegistration,
      businessAddressProof,
      about
    })).then(() => {
      // After the update is successful, fetch the updated vendor data
      dispatch(getVendor({ vendorId }));
      setAllowAddressProof(true)
      setAllowRegistration(true)
      setAllowfood(true)
      toast.success(`Saved successfully`);
      window.location.reload();
    });
  };



  const handleBusinessCancelClick = () => {
    setBusinessEditMode(false);
    toast.error('no fields are changed.')
    // Reset the state to the original values
    setBusinessName(vendor?.vendor?.BussinessDetails?.Businessname || '');
    setBusinessEmail(vendor?.vendor?.BussinessDetails?.BusinessEmail || '');
    setPanNumber(vendor?.vendor?.BussinessDetails?.panNumber || '');
    setAddressLine1(vendor?.vendor?.BussinessDetails?.BusinessAddress?.addressLine1 || '');
    setAddressLine2(vendor?.vendor?.BussinessDetails?.BusinessAddress?.addressLine2 || '');
    setLandmark(vendor?.vendor?.BussinessDetails?.BusinessAddress?.landmark || '');
    setCity(vendor?.vendor?.BussinessDetails?.BusinessAddress?.city || '');
    setState(vendor?.vendor?.BussinessDetails?.BusinessAddress?.state || '');
    setPincode(vendor?.vendor?.BussinessDetails?.BusinessAddress?.pincode || '');
    setGstNumber(vendor?.vendor?.BussinessDetails?.gstNumber || '');
    setselectedTypeOfBusiness(vendor?.vendor?.BussinessDetails?.typeOfBusiness || []);
    setBusinessAddressProof(vendor?.vendor?.BussinessDetails?.businessAddressProof || '');
    setFoodLicenseProof(vendor?.vendor?.BussinessDetails?.foodLicenseProof || '');
    setBusinessRegistration(vendor?.vendor?.BussinessDetails?.businessRegistration || '');
    setAbout(vendor?.vendor?.BussinessDetails?.about || '');
  };




  // Account Details State
  const handleAccountEditClick = () => setAccountEditMode(!accountEditMode);
  const handleAccountSaveClick = () => {
    if (allowOther === false) {
      toast.error("Please provide the documentfor other Buiness document in pdf format")
      return
    }

    // Validate that all fields are filled
    if (!vendorId || !accountHolderName || !accountNumber || !ifscCode || !cifNumber || !branch || !accCity || !accState || !otherBusinessDocument) {
      // Alert the user or handle the validation error in some way
      toast.error("All fields must be filled");
      return; // Do not proceed if any field is empty
    }
    setAccountEditMode(false);



    // Dispatch the updateVendor action
    dispatch(updateVendorAccount({
      // Include the necessary fields for updating
      vendorId,
      accountHolderName,
      accountNumber,
      ifscCode,
      cifNumber,
      branch,
      accCity,
      accState,
      otherBusinessDocument
    })).then(() => {
      // After the update is successful, fetch the updated vendor data
      dispatch(getVendor({ vendorId }));
      setAllowOther(true)
      toast.success(`Saved successfully`);
      window.location.reload();

    });
  };


  const isValidIFSCCode = (code) => {
    // Regular expression for IFSC code validation
    const ifscRegex = /^[A-Z]{4}[0-9A-Za-z]{7}$/;
    return ifscRegex.test(code);
  };

  const isValidCIFNumber = (number) => {
    // Regular expression for validating numbers only
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(number);
  };

  const handleAccountCancelClick = () => {
    setAccountEditMode(false);
    // Reset the state to the original values
    setAccountHolderName(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.AccountHolderName);
    setAccountNumber(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.AccountNumber);
    setIfscCode(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.IFSCCode);
    setBranch(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.Branch);
    setCifNumber(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.CIFNumber);
    setAccCity(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.City);
    setAccState(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.State);
    setOtherBusinessDocument(vendor && vendor.vendor && vendor.vendor.BussinessDetails && vendor.vendor.AccountDetais && vendor.vendor.AccountDetais.otherBusinessDocument);
  };


  //NOTE - Document Upload
  const handleBusinessRegistration = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      setAllowRegistration(true)
      checkAndTransformPdf(file, setBusinessRegistration, event);
    } else {
      setAllowRegistration(false);
      toast.error("Please provide a pdf file");
      event.target.value = null;
      return;
    }
  };
  const handleotherBusinessDocument = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setOtherBusinessDocument, event);
      setAllowOther(true)
    } else {
      setAllowOther(false);
      toast.error("Please provide a pdf file");
      event.target.value = null;
      return;
    }
  };

  const handleFoodLicenseProof = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setFoodLicenseProof, event);
      setAllowfood(true)
    } else {
      setAllowfood(false);
      toast.error("Please provide a pdf file");
      event.target.value = null;
      return;
    }
  };

  const handleBusinessAddressProof = (event) => {
    const file = event.target.files[0];
    if (isPdfFile(file)) {
      checkAndTransformPdf(file, setBusinessAddressProof, event);
      setAllowAddressProof(true)
    } else {
      setAllowAddressProof(false);
      toast.error("Please provide a pdf file");
      event.target.value = null;
      return;
    }
  };

  const checkAndTransformPdf = (pdfFile, setPdfState, event) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    if (pdfFile.size > maxSize) {
      toast.error("File size should be less than 1MB");
      event.target.value = null;
      return;
    }
    if (!isPdfFile(pdfFile)) {
      toast.error("Please upload a PDF files for business registration documents");
      event.target.value = null;
      return;
    }
    transformPdf(pdfFile, setPdfState);
  };

  // Add a new function to check if the file is a PDF
  const isPdfFile = (file) => {
    return file && file.type === "application/pdf";
  };
  const isPdf = (file) => {
    return file && file.startsWith("data:application/pdf");
  };
  // Modify the transformPdf function to handle both PDF and URL
  const transformPdf = (file, setPdfState) => {
    if (isPdfFile(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPdfState(reader.result);
      };
    } else {
      toast.error("Please Give a pdf file");
      return;
    }
  };

  return (
    <div className="top break-words mb-32">
      <div className="upperbox flex flex-wrap sm:gap-6 border-b-2 sm:visible max-sm:hidden ">
        <div className=" flex-col md:flex md:md:items-center md:space-x-4 bg-cover relative ml-16">

          {ShopAvatarImage ? (
            <img
              src={ShopAvatarImage}
              alt="Uploaded Image"
              className="w-full object-fill h-[200px] shadow-md mb-2"
            />
          ) : ShopAvatar ? (
            <img
              src={ShopAvatar.url}
              alt="Shop Avatar"
              className="w-full object-fill h-[200px] shadow-md mb-2"
            />
          ) : (
            <div className="flex justify-center items-center">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
                alt={`${name} logo`}
                className="w-full object-fill h-[200px] shadow-md mb-2"
              />
            </div>
          )}
          <div className="md:ml-4  bottom-2 w-full absolute rounded-full flex justify-end items-end ">

            <form className="flex flex-wrap break-word px-1 py-2 font-semibold md:w-48    rounded-full justify-center md:items-center"
              action='/upload' method='POST' enctype="multipart/form-data">
              <Tooltip title="UploadShop Logo">
                <label className="hover:scale-110 transition-all duration-200 flex justify-center md:items-center">
                  <input
                    type="file"
                    name="vendorAvatar"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                  />

                  <div className="p-3 rounded-full bg-black/20 m-auto"><FaImage size={"20px"} color="white" /></div>

                </label>
              </Tooltip>
            </form>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-4xl pl-5">{businessName}</h1>
          <p className="text-gray-500 pl-5 font-semibold md:w-48 text-lg w-full">
            {typeOfBusiness.join(', ').toUpperCase()}
          </p>
          <p className=' pl-5'>
            Status : <span className={status === 'Pending' ? 'text-red-500 font-bold' : status === 'Verified' ? 'text-green-500' : ''}>{status}</span>
          </p>

        </div>


      </div>


      {/* mobile view for image header */}

      <div className="  sm:hidden  flex flex-wrap gap-6  border-b-2 p-2">

        <div className=" flex-col md:flex  bg-cover relative  rounded-lg  ">
          {ShopAvatarImage ? (
            <img
              src={ShopAvatarImage}
              alt="Uploaded Image"
              className="h-40 md:w-48"
            />
          ) : ShopAvatar ? (
            <img
              src={ShopAvatar.url}
              alt="Shop Avatar"
              className="h-40 md:w-48"
            />
          ) : (
            <img
              src="https://firebasestorage.googleapis.com/v0/b/theekaiv-10984.appspot.com/o/Assets2%2FImages%2Fdefault%20Shop%20Avatar%2FDefault%20Shop%20Avatar.jpg?alt=media&token=1f94748a-8ed6-4fd5-ba08-22a0240e7f95"
              alt="Default Image"
              className="h-40 md:w-48"
            />
          )}
          <div className="ml-4 rounded-full w-[90%]  absolute h-10 bottom-1 flex justify-end items-end ">

            <form className="flex flex-wrap break-word   font-semibold  absolute  rounded-full justify-end items-center"
              action='/upload' method='POST' encType="multipart/form-data">
              <Tooltip title="UploadShop Logo">
                <label className="hover:scale-110 transition-all duration-200 flex justify-end items-end">
                  <input
                    type="file"
                    name="vendorAvatar"
                    accept="image/*"
                    className="hidden h-20 w-20"
                    onChange={handleImageUpload}
                  />

                  <div className="p-1  rounded-full bg-black/20 "><FaImage size={"13px"} color="white" />
                  </div>

                </label>
              </Tooltip>
            </form>
          </div>
        </div>



        <div className=" md:ml-12 mt-4 gap-4">
          <h1 className="font-bold text-xl mt-10">{businessName}</h1>
          <p className="text-gray-500 font-semibold md:w-48 text-lg">  {typeOfBusiness.join(', ').toUpperCase()}</p>
          <p >
            Status : <span className={status === 'Pending' ? 'text-red-500 font-bold' : status === 'Verified' ? 'text-green-500' : ''}>{status}</span>
          </p>


        </div>

      </div>



      <div className="tab-switching mt-4 md:mt-12 ml-2 sm:ml-4 md:ml-12">
        <div className="flex gap-1 sm:gap-2">
          <button
            onClick={() => handleTabChange('general')}
            className={`${activeTab === 'general'
              ? 'bg-[#8A58DC] text-white'
              : 'bg-gray-300 text-gray-700'
              } px-1 sm:px-4 md:px-6  py-2 text-md rounded-md `}
          >
            Personal Details
          </button>
          <button
            onClick={() => handleTabChange('business')}
            className={`${activeTab === 'business'
              ? 'bg-[#8A58DC] text-white'
              : 'bg-gray-300 text-gray-700'
              } px-1 sm:px-4 md:px-6 py-2  text-md rounded-md `}
          >
            Business Details
          </button>
          <button
            onClick={() => handleTabChange('account')}
            className={`${activeTab === 'account'
              ? 'bg-[#8A58DC] text-white'
              : 'bg-gray-300 text-gray-700'
              } px-1 sm:px-4 md:px-6 py-2 text-md rounded-md`}
          >
            Account Details
          </button>
        </div>
        <div className="mt-4">
          {/* General Details Tab */}
          {activeTab === 'general' && (
            <div className="flex flex-col space-y-8">
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48"> Name</label>
                {generalEditMode ? (
                  <input
                    type="text"
                    value={name}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setName(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={name}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>


              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48 ">Email Address</label>
                {emailEditMode ? (
                  <div className="relative flex md:items-center">
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        // textOverflow: email.length > 15 ? 'ellipsis' : 'clip',
                      }}
                    />

                    <button
                      className='text-[#8A58DC] ml-2'
                      onClick={handleEmailSendOTP}
                    >Send OTP
                    </button>

                    {showOtpPopup && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-5 w-[350px] rounded-md shadow-lg">

                          <div className="flex justify-end">
                            <button className="text-[#8A58DC]" onClick={handleOtpCancelClick}>
                              <RxCross2 />
                            </button>
                          </div>
                          <div className='flex justify-start p-2'>
                            <label className="font-semibold md:w-48">Verify Email</label>
                          </div>

                          <div className="flex flex-col gap-y-4">
                            <input
                              type="text"
                              value={otpInput1}
                              onChange={handleOtpInputChange1}
                              placeholder={`Enter OTP${emailEditMode ? ' for Current Email' : ''}`}
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />
                            <input
                              type="text"
                              value={otpInput2}
                              onChange={handleOtpInputChange2}
                              placeholder={`Enter OTP${emailEditMode ? ' for New Email' : ''}`}
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />

                            <button
                              className='flex text-[#8A58DC] justify-start w-auto'
                              onClick={handleResendOTP}>
                              Resend OTP
                            </button>
                            <button
                              className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                              onClick={handleOtpSubmit}
                            >
                              Submit OTP
                            </button>

                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      className='text-[#8A58DC] ml-4'
                      onClick={handleEmailCancelClick}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={email}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />
                    {/* <button
                      className='text-[#8A58DC] ml-4'
                      onClick={() => setEmailEditMode(true)}>
                      Edit
                    </button> */}
                  </div>
                )}

              </div>

              {/* mobile number */}

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Mobile Number</label>
                {mobileEditMode ? (
                  <div className="relative flex md:items-center">
                    <input
                      type="tel"
                      value={mobile}
                      maxLength={10}
                      onInput={handleMobileInputChange}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] md:w-64 w-[9rem] shadow-lg"
                    />

                    <button
                      className='text-[#8A58DC] ml-2'
                      onClick={handleMobileSendOTP}>
                      Send OTP
                    </button>



                    {showOtpPopup && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-5 w-[350px] rounded-md shadow-lg">


                          <div className="flex justify-end">
                            <button className="text-[#8A58DC]" onClick={handleOtpCancelClick}>
                              <RxCross2 />
                            </button>
                          </div>
                          <div className='flex justify-start p-2'>
                            <label className="font-semibold md:w-48">Verify Mobile Number</label>
                          </div>

                          <div className="flex flex-col gap-y-4">
                            <input
                              type="text"
                              value={otpInput1}
                              onChange={handleOtpInputChange1}
                              placeholder={`Enter OTP${mobileEditMode ? ' for Current Number' : ''}`}
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />
                            <input
                              type="text"
                              value={otpInput2}
                              onChange={handleOtpInputChange2}
                              placeholder="Enter OTP for New Number"
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />

                            <button
                              className='flex text-[#8A58DC] justify-start w-auto'
                              onClick={handleResendOTP}>
                              Resend OTP
                            </button>


                            <button
                              className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                              onClick={handleOtpSubmit}
                            >
                              Submit OTP
                            </button>



                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      className='text-[#8A58DC] ml-4'
                      onClick={handleMobileCancelClick}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={mobile}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />
                    {/* <button
                      className='text-[#8A58DC] ml-4'
                      onClick={() => setMobileEditMode(true)}>
                      Edit
                    </button> */}
                  </div>
                )}

              </div>


              {/* alternate mobile number */}
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Alterante Mobile Number</label>
                {altMobileEditMode ? (
                  <div className="relative flex md:items-center">
                    <input
                      type="tel"
                      value={altMobile}
                      maxLength={10}
                      onInput={handleAltMobileInputChange}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] md:w-64 w-[9rem] shadow-lg"

                    />

                    <button
                      className='text-[#8A58DC] ml-2'
                      onClick={handleAltMobileSendOTP}>
                      Send OTP
                    </button>



                    {showOtpPopup && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-5 w-[350px] rounded-md shadow-lg">


                          <div className="flex justify-end">
                            <button className="text-[#8A58DC]" onClick={handleOtpCancelClick}>
                              <RxCross2 />
                            </button>
                          </div>
                          <div className='flex justify-start p-2'>
                            <label className="font-semibold md:w-48">Verify Alternate Mobile Number</label>
                          </div>

                          <div className="flex flex-col gap-y-4">
                            <input
                              type="text"
                              value={otpInput1}
                              onChange={handleOtpInputChange1}
                              placeholder={`Enter OTP${altMobileEditMode ? ' for Current Number' : ''}`}
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />
                            <input
                              type="text"
                              value={otpInput2}
                              onChange={handleOtpInputChange2}
                              placeholder="Enter OTP for New Number"
                              className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC]"
                            />

                            <button
                              className='flex text-[#8A58DC] justify-start w-auto'
                              onClick={handleResendOTP}>
                              Resend OTP
                            </button>


                            <button
                              className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                              onClick={handleOtpSubmit}
                            >
                              Submit OTP
                            </button>

                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      className='text-[#8A58DC] ml-4'
                      onClick={handleAltMobileCancelClick}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="tel"
                      value={`91${altMobile}`}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />
                    {/* <button
                      className='text-[#8A58DC] ml-4'
                      onClick={() => setAltMobileEditMode(true)}>
                      Edit
                    </button> */}
                  </div>
                )}

              </div>

              {/* -------------------- */}


              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">

                <label className="font-semibold md:w-48">DOB</label>
                {generalEditMode ? (
                  <input
                    type="date"
                    value={dob instanceof Date ? dob.toISOString().split('T')[0] : dob}
                    onChange={handleDateOfBirthChange}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    max={`${new Date().getFullYear()}-12-31`}
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="date"
                      value={dob instanceof Date ? dob.toISOString().split('T')[0] : dob}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48">Gender</label>

                {generalEditMode ? (
                  <select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="w-full p-2 h-12 border rounded-xl focus:outline-none cursor-pointer focus:border-[#8A58DC]"
                    required
                  >
                    <option value="" disabled>
                      Select Gender *
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                ) : (
                  <div className="flex md:items-center p-2">
                    <select
                      value={gender}
                      className="w-[80%] md:w-full p-2 h-12 border rounded-xl focus:outline-none cursor-pointer focus:border-[#8A58DC]"
                      required
                    >
                      <option value="" disabled >
                        Select Gender *
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>

                  </div>
                )}
              </div>
              {/* <div className='flex flex-row gap-4'>
                {generalEditMode ? (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralSaveClick}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralEditClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {generalEditMode && (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleGeneralCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}

              </div> */}
            </div>
          )}

          {/* Business Deatils Tab */}
          {activeTab === 'business' && (
            <div className="flex flex-col space-y-8">
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48">Business Name</label>
                {businessEditMode ? (
                  <input
                    type="text"
                    value={businessName}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setBusinessName(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={businessName}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Business Email Address</label>
                {businessEditMode ? (
                  <input
                    type="text"
                    value={businessEmail}
                    maxLength={50}
                    onChange={(e) => {
                      const inputValue = e.target.value.toLowerCase();
                      setBusinessEmail(inputValue);

                      // Check if the entered value is a valid email
                      if (isValidBusinessEmail(inputValue) || inputValue === "") {
                        setFieldsEmpty(false);
                      } else {
                        setFieldsEmpty(true);
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />

                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={businessEmail}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />
                  </div>
                )}
                {fieldsEmpty && (!businessEmail || !businessEmail.trim()) && (
                  <p className="flex text-red-500 ml-2">
                    Please fill Business Email.
                  </p>
                )}
                {!isValidBusinessEmail(businessEmail) && businessEmail && businessEmail.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please enter a valid email address (E.g., test@example.com).
                  </p>
                )}


              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48">PAN Number</label>
                {businessEditMode ? (
                  <input
                    type="text"
                    value={panNumber}
                    maxLength={10}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      setPanNumber(inputValue);

                      // Check if the entered value is a valid PAN number
                      if (isValidPANNumber(inputValue) || inputValue === "") {
                        setFieldsEmpty(false);
                      } else {
                        setFieldsEmpty(true);
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={panNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                {fieldsEmpty && !panNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the PAN number.
                  </p>
                )}
                {!isValidPANNumber(panNumber) && panNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please enter a valid PAN number (E.g., AAAAA9999A).
                  </p>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">GST Number</label>
                {businessEditMode ? (
                  <input
                    type="text"
                    value={gstNumber}
                    maxLength={15}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase(); // Convert to uppercase

                      setGstNumber(inputValue);
                      // Check if the entered value is a valid GST number
                      if (isValidGSTNumber(inputValue) || inputValue === "") {
                        setFieldsEmpty(false);
                      } else {
                        setFieldsEmpty(true);
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={gstNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                {fieldsEmpty && !gstNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please fill the GST number.
                  </p>
                )}
                {!isValidGSTNumber(gstNumber) && gstNumber.trim() && (
                  <p className="flex text-red-500 ml-2">
                    Please enter a valid GST number (E.g., 06BZAHM6385P6Z2).
                  </p>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Category </label>
                {businessEditMode ? (
                  <div className="flex items-center space-x-4">
                    <input
                      type="checkbox"
                      id="categoryClothing"
                      checked={typeOfBusiness.includes("clothing")}
                      onChange={() => handleCategoryCheckboxChange("clothing")}
                    />
                    <label htmlFor="categoryClothing">Clothing</label>

                    <input
                      type="checkbox"
                      id="categoryAccessories"
                      checked={typeOfBusiness.includes("accessories")}
                      onChange={() => handleCategoryCheckboxChange("accessories")}
                    />
                    <label htmlFor="categoryAccessories">Accessories</label>

                    <input
                      type="checkbox"
                      id="categoryHomeDecor"
                      checked={typeOfBusiness.includes("home-decor")}
                      onChange={() => handleCategoryCheckboxChange("home-decor")}
                    />
                    <label htmlFor="categoryHomeDecor">Home Decor</label>

                    <input
                      type="checkbox"
                      id="categoryFood"
                      checked={typeOfBusiness.includes("homemade-food")}
                      onChange={() => handleCategoryCheckboxChange("homemade-food")}
                    />
                    <label htmlFor="categoryFood">Homemade-Food</label>

                    <input
                      type="checkbox"
                      id="categoryOther"
                      checked={typeOfBusiness.includes("other")}
                      onChange={() => handleCategoryCheckboxChange("other")}
                    />
                    <label htmlFor="categoryOther">Other</label>
                  </div>
                ) : (
                  <div className="flex md:items-center">
                    <div className="flex space-x-2">
                      {typeOfBusiness.map((category) => (
                        <span key={category} className="bg-gray-200 rounded-md p-2">
                          {category.toUpperCase()}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>


              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48"> About </label>
                {businessEditMode ? (
                  <textarea
                    value={about}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const wordCount = inputValue.split(/\s+/).length;

                      if (wordCount <= 120) {
                        setAbout(inputValue);
                      } else {
                        <p>
                          Word limit reached
                        </p>
                      }
                    }}
                    rows={3}
                    cols={50}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <textarea
                      value={about}
                      rows={3}
                      cols={50}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC]shadow-lg"
                    />
                  </div>
                )}
              </div>



              {/* //NOTE - Document */}

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Business Registration</label>
                <div className="flex items-center">
                  {businessEditMode ? (
                    <>
                      <Link to={businessRegistration} target="_blank" rel="noopener noreferrer">
                        <button className="bg-[#8A58DC] text-white rounded-md p-2">View Previous Document</button>
                      </Link>
                      <input className='px-10' type="file" accept=".pdf" onChange={handleBusinessRegistration} />
                      {!businessRegistration && (
                        <p className="flex text-red-500 ml-2">Please upload a food license document.</p>
                      )}
                      {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
                    </>
                  ) : (
                    <Link to={businessRegistration} target="_blank" rel="noopener noreferrer">
                      <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                    </Link>
                  )}
                </div>
              </div>


              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Business Address</label>
                <div className="flex items-center">
                  {businessEditMode ? (
                    <>
                      <Link to={businessAddressProof} target="_blank" rel="noopener noreferrer">
                        <button className="bg-[#8A58DC] text-white rounded-md p-2">View Previous Document</button>
                      </Link>
                      <input className='px-10' type="file" accept=".pdf" onChange={handleBusinessAddressProof} />
                      {!businessAddressProof && (
                        <p className="flex text-red-500 ml-2">Please upload a food license document.</p>
                      )}
                      {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
                    </>
                  ) : (
                    <Link to={businessAddressProof} target="_blank" rel="noopener noreferrer">
                      <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                    </Link>
                  )}             </div>
              </div>



              {typeOfBusiness.includes("homemade-food") && (
                <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                  <label className="font-semibold md:w-48">Food License</label>
                  <div className="flex items-center">
                    {businessEditMode ? (
                      <>
                        <Link to={foodLicenseProof} target="_blank" rel="noopener noreferrer">
                          <button className="bg-[#8A58DC] text-white rounded-md p-2">View Previous Document</button>
                        </Link>
                        <input className='px-10' type="file" accept=".pdf" onChange={handleFoodLicenseProof} />
                        {/* <button className="bg-[#8A58DC] text-white rounded-md p-2 ml-2">Confirm</button> */}
                        {!foodLicenseProof && (
                          <p className="flex text-red-500 ml-2">Please upload a food license document.</p>
                        )}
                      </>
                    ) : (
                      <Link to={foodLicenseProof} target="_blank" rel="noopener noreferrer">
                        <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                      </Link>
                    )}
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center ">
                <label className="font-semibold md:w-48 self-start">Full Address</label>
                {businessEditMode ? (
                  <div className=''>

                    <input
                      type="text"
                      value={addressLine1}
                      maxLength={50}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                      placeholder="Address Line 1"
                    />
                    <input
                      type="text"
                      value={addressLine2}
                      maxLength={50}
                      onChange={(e) => setAddressLine2(e.target.value)}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                      placeholder="Address Line 2"
                    />
                    <input
                      type="text"
                      value={landmark}
                      maxLength={30}
                      onChange={(e) => setLandmark(e.target.value)}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                      placeholder="Landmark"
                    />
                    <input
                      type="text"
                      value={city}
                      maxLength={30}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Allow only alphabets
                        const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                        setCity(sanitizedValue);
                      }}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                      placeholder="City"
                    />
                    <input
                      type="text"
                      value={state}
                      maxLength={30}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Allow only alphabets
                        const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                        setState(sanitizedValue);
                      }}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg mb-2"
                      placeholder="State"
                    />
                    <input
                      type="text"
                      value={pincode}
                      maxLength={6}
                      onChange={(e) => {
                        // Allow only digits and limit the length to 18 characters
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 18);
                        setPincode(sanitizedValue);
                      }}
                      className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="Pincode" />
                  </div>
                ) : (
                  <div classname="grid grid-cols-3 md:items-center justify-center ">

                    <input
                      type="text"
                      value={addressLine1}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="Address Line 1"
                    />
                    <input
                      type="text"
                      value={addressLine2}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="Address Line 2"
                    />
                    <input
                      type="text"
                      value={landmark}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="Landmark"
                    />
                    <input
                      type="text"
                      value={city}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="City"
                    />
                    <input
                      type="text"
                      value={state}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="State"
                    />
                    <input
                      type="text"
                      value={pincode}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                      placeholder="Pincode" />


                  </div>
                )}
              </div>

              {/* <div className='flex flex-row gap-4'>
                {businessEditMode ? (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleBusinessSaveClick}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleBusinessEditClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {businessEditMode && (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleBusinessCancelClick}
                    >
                      Cancel
                    </button>

                  </div>
                )}

              </div> */}

            </div>
          )}

          {/* Account Details Tab */}
          {activeTab === 'account' && (
            <div className="account-details flex flex-col space-y-8 ">


              <div className="flex flex-col md:flex-row gap-x-6 md:items-center mt-10">
                <label className="font-semibold md:w-48">Account Holder Name</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accountHolderName}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccountHolderName(sanitizedValue);
                    }}
                    maxLength={30}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accountHolderName}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Account Number</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => {
                      // Allow only digits and limit the length to 18 characters
                      const sanitizedValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 18);
                      setAccountNumber(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    minLength={9}
                    maxLength={18}
                    pattern="[0-9]*"

                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accountNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">IFSC Code</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={ifscCode}
                    maxLength={11}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      setIfscCode(inputValue);

                      // Check if the entered value is a valid IFSC code
                      if (isValidIFSCCode(inputValue) || inputValue === "") {
                        setIFSCCodeError("");
                      } else {
                        setIFSCCodeError("Please enter a valid IFSC code (E.g.,  SBIN0005943).");
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />


                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={ifscCode}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                <div className='flex flex-col md:flex-row gap-x-6 md:items-center'>
                  {IFSCCodeError && (
                    <p className="text-red-500">{IFSCCodeError}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Branch</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={branch}
                    maxLength={20}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 20);
                      setBranch(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={branch}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">CIF Number</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={cifNumber}
                    maxLength={11}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      setCifNumber(inputValue);

                      // Check if the entered value is valid (contains only numbers)
                      if (isValidCIFNumber(inputValue) || inputValue === "") {
                        setCIFNumberError("");
                      } else {
                        setCIFNumberError("Please enter your 11 digit CIF Number.");
                      }
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={cifNumber}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
                <div className="flex flex-col md:flex-row gap-x-6 md:items-center">

                  {CIFNumberError && (
                    <p className="text-red-500">{CIFNumberError}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">City</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accCity}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccCity(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accCity}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">State</label>
                {accountEditMode ? (
                  <input
                    type="text"
                    value={accState}
                    maxLength={30}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only alphabets
                      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '').slice(0, 30);
                      setAccState(sanitizedValue);
                    }}
                    className="border border-gray-500 rounded-md p-2 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                  />
                ) : (
                  <div className="flex md:items-center">
                    <input
                      type="text"
                      value={accState}
                      readOnly
                      className="border border-gray-300 rounded-md p-2 bg-gray-100 focus:outline-none focus:border-[#8A58DC] w-64 shadow-lg"
                    />

                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-x-6 md:items-center">
                <label className="font-semibold md:w-48">Bank Document</label>
                <div>
                  {accountEditMode ? (
                    <>
                      <Link to={otherBusinessDocument} target="_blank" rel="noopener noreferrer">
                        <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                      </Link>
                      <input className='px-10' type="file" accept=".pdf" onChange={handleotherBusinessDocument} />
                      {/* <button className="bg-green-500 text-white rounded-md p-2 ml-2">Confirm</button> */}
                    </>
                  ) : (
                    <Link to={otherBusinessDocument} target="_blank" rel="noopener noreferrer">
                      <button className="bg-[#8A58DC] text-white rounded-md p-2">View Document</button>
                    </Link>
                  )}
                </div>
              </div>

              {/* <div className='flex flex-row gap-4'>
                {accountEditMode ? (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountSaveClick}
                    >
                      Save
                    </button>

                  </div>
                ) : (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountEditClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {accountEditMode && (
                  <div>
                    <button
                      className="bg-purple-400 hover:bg-purple-600 text-white text-xl px-6 py-2 rounded-lg shadow-lg transition-all duration-200"
                      onClick={handleAccountCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                )}

              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsEdit;