import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { BiSort, BiRupee } from 'react-icons/bi';
import { API_URL, config } from '../../../config/config';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { useTheme } from '@emotion/react';

export default function VendorSales() {
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;

  const [productSoldCount, setProductSoldCount] = useState(0);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [newOrdersCount, setNewOrdersCount] = useState(0);

  useEffect(() => {
    const fetchNewOrdersCount = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/order/getAllOrders`, config);
        const orders = response.data;

        // Filter orders based on vendor ID
        const vendorOrders = orders.filter(order => order.productDetails.vendorId === loggedInVendorId);


        setTotalOrderCount(vendorOrders.length);

        // Calculate product sold count (assuming status 'Completed')
        const soldOrders = vendorOrders.filter(order => order.orderStatus === 'Completed');
        setProductSoldCount(soldOrders.length);

        // Calculate new orders count including 'Completed', 'InTransit', and 'InProcess'
        const relevantStatuses = ['NewOrders', 'Completed', 'InTransit', 'InProcess'];
        const newOrders = vendorOrders.filter(order => relevantStatuses.includes(order.orderStatus));
        setNewOrdersCount(newOrders.length);

        // Calculate total earnings for orders with status 'Completed'
        const completedOrders = vendorOrders.filter(order => order.orderStatus === 'Completed');
        const totalEarnings = completedOrders.reduce((total, order) => total + order.totalAmount, 0);
        setTotalEarnings(totalEarnings);

      } catch (error) {
        console.error('Error fetching new orders:', error);
      }
    };

    fetchNewOrdersCount();
  }, []);



  return (
    <div className=''>
      <h1 className="mt-3 mb-3 text-2xl ml-6">Sales</h1>
      <div className="w-full flex flex-col sm:flex-row gap-y-5 gap-x-2 sm:p-5 py-5">
        <div className='w-full sm:w-2/3 flex flex-row gap-x-2'>

          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl cursor-pointer"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '@media screen and (max-width: 640px)': {
                borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
              },
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >
            <div>
              <div className='flex sm:hidden'>
                <span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
                <p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{productSoldCount}</p>

              </div>
              <span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

              <div className="flex justify-center items-center flex-col sm:mb-4">
                <div className="hidden sm:block sm:p-4">
                  <p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{productSoldCount} </p>
                </div>
                <p className="text-center font-bold text-base sm:text-2xl">Product Sold</p>
              </div>
            </div>
          </Card>



          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-1/2  sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl cursor-pointer"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '@media screen and (max-width: 640px)': {
                borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
              },
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >

            <div>
              <div className='flex sm:hidden'>
                <span><BiSort className="text-xl sm:text-2xl m-1  sm:m-2" /></span>
                <p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{newOrdersCount}</p>

              </div>
              <span className='hidden sm:block'><BiSort className="text-xl sm:text-2xl m-1  sm:m-2" /></span>
              <div className="flex justify-center items-center flex-col">
                <div className=" hidden sm:block sm:p-4 ">
                  <p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{newOrdersCount}</p>
                </div>
                <p className="text-center font-bold text-base sm:text-2xl py-[2px] sm:py-0">Total Orders</p>
              </div>
            </div>
          </Card>
        </div>
        <div className='w-full sm:w-[33%]'>
          <Card
            variant="outlined"
            orientation="horizontal"
            className="w-full  h-1/2 sm:h-full rounded-xl cursor-pointer"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
              borderRadius: '1rem', // Rounded corners
              '@media screen and (max-width: 640px)': {
                borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
              },
              '&:hover': {
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
                borderColor: 'neutral.outlinedHoverBorder',
              },
            }}
          >
            <div>
              <div className='flex sm:hidden'>
                <span><PersonOutlineRoundedIcon className='m-2' /></span>
                <p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-10 mt-2 '>{totalEarnings}</p>

              </div>
              <span className='hidden sm:block'><PersonOutlineRoundedIcon className='m-2' /></span>
              <div className="flex justify-center items-center flex-col">
                <div className="hidden sm:block sm:p-4">
                  <p className="text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">Price: <BiRupee /> {totalEarnings}</p>
                </div>
                <p className="font-bold text-xl sm:text-2xl">Total Earnings</p>
              </div>
            </div>
          </Card>
        </div>

      </div>
    </div>
  );
}
