import React from 'react'

import { FaArrowRight } from "react-icons/fa";
import constant from '../../constant';


const ERROR404=constant.IMAGES_ERRORCRVES.ERROR404;
const CURVES=constant.IMAGES_ERRORCRVES.CURVES;

function Error404() {
    const goBack = () => {
        window.history.back(); // This function navigates back to the previous page in history
      };
    
    return (
        <>
            <div className=' p-2 '>
                <div className=' bg-[#F3F3F3] w-full rounded-md  '>
                    <div className=' relative'>
                        <img src={CURVES} alt="curves" className='relative w-full h-[50%]' />
                    </div>

                    <div className='absolute top-[25%] min-[425px]:top-[30%] sm:top-[40%] md:top-[39%] xl:top-[45%] w-full grid grid-cols-2 py-10 '>

                        <div className=' flex items-start justify-center'>
                            <div>
                                <h1 className=' font-[700] text-[20px] min-[425px]:text-[30px] sm:text-[38px] md:text-[48px] sm:pt-2 md:pt-5'>Ooops...</h1>
                                <h1 className=' text-sm min-[425px]:text-[15px] sm:text-lg md:text-2xl sm:pt-2'>Page not found</h1>
                                <h2 className='pt-1 md:pt-5 text-[5px] min-[425px]:text-[10px] sm:text-xs md:text-sm'>Sorry, We could not find the requested page.<br /> her prenior </h2>
                                <button onClick={goBack} className="flex items-center space-x-2 text-[5px] sm:text-xs bg-[#DF5F8C] text-white mt-2 py-1 md:py-2 px-3 md:px-8 rounded-md">
                                    <span>Go Back</span>
                                    <FaArrowRight />
                                </button>

                                {/* <p>DUNS</p> */}
                            </div>
                        </div>

                        <div className=' '>
                            <img src={ERROR404} alt="error" className=' h-[70%] min-[425px]:h-[70%] sm:h-[80%] md:h-[90%]' />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Error404
