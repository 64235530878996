import React, { useState, useEffect } from "react";
import Instagram from "../component/Home/Instagram";
import BrandingTag from "../component/Home/BrandingTag";
import constant from "../constant";
import PromotinalVedio from "../component/Home/PromotinalVedio";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const SELLERIMAGE = constant.IMAGES_IMAGES.BECOME_A_SELLER.SELLERIMAGE;
const RIBBON = constant.IMAGES_IMAGES.BECOME_A_SELLER.RIBBON

const BecomeASellerPage = () => {
  const { user, auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [redirectValue, setRedirectValue] = useState('');

  useEffect(() => {
    // Check if redirectValue is not an empty string before navigating
    if (redirectValue) {
      navigate(redirectValue);
    }
  }, [redirectValue, auth]);

  const checkRedirect = () => {
    if (auth === null) {
      // User is not logged in, set the redirect value
      setRedirectValue('/signin');
    } else {
      // User is logged in, set the redirect value
      setRedirectValue('/seller-registration');
    }
  };

  //scroll to top

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".footer-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <div className="pt-20">
      <Helmet>
        <title>Become Seller
        </title>
      </Helmet>
      <div className="md:flex gap-20 justify-between p-4">
        <div className="flex flex-col ml-10 gap-4 h-full item-center justify-center sm:mb-0 mb-3 ">
          {/* <div > */}
          <div className="relative ">
            <div className="bg-rose-500 rounded-[100%] w-[100px] h-[100px] opacity-[20%] top-[24px] left-[1px]"></div>
            <Link to={redirectValue}  >
              <button className="w-[160px] absolute h-[46px] bg-pink-500 text-white rounded-[8px] shadow-md top-[30px] sm:top-[40px] left-[35px] sm:left-[70px]" onClick={checkRedirect}>
                Join Us
              </button>
            </Link>
          </div>
          <h1 className="font-bold text-5xl top-[140px] left-[133px]">
            Become a Seller <br />
            on The Ekaiv
          </h1>

          <p className="">Empower your business and reach a world of eager shoppers.</p>

        </div>
        <div>
          <div>

            <img className=" w-full mx-auto" src={SELLERIMAGE} alt="Seller" />
          </div>
        </div>
      </div>


      <div>
        <img src={RIBBON} alt="ribbon" />
      </div>

      <div className="lg:flex sm:mx-10  my-10">
        <div className="lg:w-1/2 gap-7 p-1  bg-white-100 sm:mb-0 pb-16">
          <h2 className="font-bold text-center sm:text-left text-4xl mt-8 mx-auto mb-7 ">Why to choose The Ekaiv</h2>
          <p className=" text-sm">
            Elevate your selling experience by joining our platform. With a vibrant marketplace, unwavering seller support, innovative tools, and a commitment to fairness, we provide the perfect stage for your products to shine. Become a part of our community today and unlock the potential to grow your business like never before.
          </p>
          <Link to='/Ekaiv-selling' onClick={() => {
            window.scrollTo(0, 0)
          }}>
            <button className="mt-5 px-1 sm:px-2 absolute h-[46px] bg-pink-500 text-white rounded-[8px] shadow-md " onClick={checkRedirect}>
              Know More About The Ekaiv Selling
            </button>
          </Link>

        </div>
        <div className="lg:w-1/2  flex flex-col gap-10 sm:p-8 p-4 bg-white-100">
          <div className="min-[340px]:flex sm:flex-row flex-col gap-10 items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/128/10845/10845585.png"
              alt="img1"
              className="w-16 h-16 object-cover rounded-full mx-auto"
            />
            <p className=" text-sm">
              <b>Vibrant Marketplace:</b> Join our dynamic marketplace and tap into a vibrant community of shoppers looking for unique and customized products, providing you with a ready audience eager to discover your offerings.
            </p>
          </div>
          <div className="min-[340px]:flex sm:flex-row flex-col gap-10 items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2544/2544336.png"
              alt="extra3"
              className="w-16 h-16 object-cover rounded-full  mx-auto"
            />
            <p className=" text-sm">
              <b>Seller-Focused Support:</b> We prioritize our sellers' success by offering dedicated support, guidance, and resources to help you grow your business. We're here to assist you every step of the way.
            </p>
          </div>
          <div className="min-[340px]:flex sm:flex-row flex-col gap-10 items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/128/993/993515.png"
              alt="extra3"
              className="w-16 h-16 object-cover rounded-full mx-auto"
            />
            <p className=" text-sm">
              <b>Innovative Tools:</b>Access state-of-the-art seller tools and features designed to simplify inventory management, order processing, and customer engagement, allowing you to focus on what you do best—creating amazing products.
            </p>
          </div>
          <div className="min-[340px]:flex sm:flex-row flex-col gap-10 items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/128/2464/2464131.png"
              alt="extra3"
              className="w-16 h-16 object-cover rounded-full mx-auto"
            />
            <p className=" text-sm">
              <b>Fair and Transparent:</b> We believe in fairness and transparency. Enjoy competitive fees and straightforward policies, ensuring you keep more of your hard-earned profits while selling in a trusted and secure online environment.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col justify-center items-center h-400px p-4">
        <h1 className="text-4xl font-bold">How to sell?</h1>
        <p className="mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          varius enim in eros elementum tristique.
        </p>
        <button className="w-[160px] h-[46px] bg-[#8A58DC] text-white rounded-[8px] mt-4">
          Get started
        </button>
        <div className="mt-4 sm:w-[75%] w-[80%]">
          <PromotinalVedio />
        </div>
      </div> */}

      <div className="md:flex justify-center gap-4  p-4 items-center">
        <div className=" basis-3/5">
          <div className="relative flex flex-col">

            <h1 className="font-bold text-5xl mt-8 ml-8">
              Our Badges
            </h1>
            <p className="ml-8 mt-8 ">
              Empower Your Craft, Begin Your Journey with The Ekaiv.
            </p>
           
           <Link  to='/badges' onClick={()=>{
            window.scrollTo(0,0)
          }}>
            <button className="w-[160px] h-[46px] bg-[#8A58DC] text-white rounded-[8px] mt-4 ml-8">
              Know More
            </button>
           </Link>
          </div>
        </div>
        <div className="basis-1/2">
          <div>
            <img height="500px" width="750px" src={SELLERIMAGE} alt="Seller" />
          </div>
        </div>
      </div>

      <BrandingTag />
      <Instagram />
    </div>
  );
};

export default BecomeASellerPage;
