import React, { useEffect, useState } from 'react';
import ProductCard from '../../ProductCard/ProductCard';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";


const Store = () => {
  const productsPerPage =3 ;
  const vendors = useSelector((state) => state.vendor);
  const vendorId = vendors && vendors.vendor && vendors.vendor._id;
  const [vendorProducts, setVendorProducts] = useState([]);
  const [loading, setLoading] = useState("");
  const [currentStatus, setCurrentStatus] = useState('all'); // 'all', 'verified', 'pending', 'rejected'
  const [currentPage, setCurrentPage] = useState(1);
 
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${API_URL}/api/vendor/${vendorId}/products`, config);


        setVendorProducts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (vendorId) {
      fetchData();
    }
  }, [vendorId, currentPage]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Filter products based on the current status
  const filteredProducts = vendorProducts.filter((product) => {
    if (currentStatus === 'all') {
      return true;
    } else {
      return product.status === currentStatus;
    }
  });

  console.log("hello")
  console.log(filteredProducts)

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Calculate the index of the first and last product to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    filteredProducts.length
  );
 


  return (
    <div className="ml-6">
      <h1 className=" mb-4 font-bold text-xl">Store</h1>
      {loading ? (
        <div className='flex justify-center h-[400px] items-center'>
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex mb-3">
            <button
              className={`min-[400px]:mr-2 px-2  min-[400px]:px-4 py-2 focus:outline-none rounded-md ${currentStatus === 'all' && 'bg-gray-300 font-bold'
                }`}
              onClick={() => setCurrentStatus('all')}
            >
              All
            </button>
            <button
              className={` min-[400px]:mr-2 px-2  min-[400px]:px-4 py-2 focus:outline-none rounded-md ${currentStatus === 'Verified' && 'bg-green-300 font-bold'
                }`}
              onClick={() => setCurrentStatus('Verified')}
            >
              Verified
            </button>
            <button
              className={` min-[400px]:mr-2 px-2  min-[400px]:px-4 py-2 focus:outline-none rounded-md ${currentStatus === 'Pending' && 'bg-yellow-300 font-bold'
                }`}
              onClick={() => setCurrentStatus('Pending')}
            >
              Pending
            </button>
            <button
              className={`px-2  min-[400px]:px-4 py-2 focus:outline-none rounded-md ${currentStatus === 'Rejected' && 'bg-red-300 font-bold'
                }`}
              onClick={() => setCurrentStatus('Rejected')}
            >
              Rejected
            </button>
          </div>
          {filteredProducts.length === 0 ? (
            <div className='flex flex-col justify-center items-center'>
              <h1 className='text-[#8A58DC] overflow-hidden whitespace-nowrap text-center text-3xl p-5 font-bold'>Products Not Available.</h1>
              <img
                className='h-[400px] p-5'
                src="https://img.freepik.com/free-vector/boycott-abstract-concept-vector-illustration-political-program-consumer-activism-collective-behavior-cancel-culture-moral-purchasing-solidarity-action-public-protest-abstract-metaphor_335657-4199.jpg?size=626&ext=jpg&ga=GA1.1.65071757.1704699075&semt=ais" alt="" />
            </div>
          ) : (
            <>
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-6 mb-12 border-0">
                {filteredProducts?.slice(startIndex, endIndex)?.map((product, index) => (
                  <div className='flex flex-col' key={index}>
                    <ProductCard data={product} showWishlistAndCartButtons={false} showStatus={true} />
                  </div>
                ))}
              </div>

                {/* Custom pagination */}
        <div className="flex justify-center mt-4 mb-4">
          {/* Left button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button hover:bg-[#b788ca]  rounded-3xl"
          >
            <IoChevronBack />
          </button>

          {/* Page buttons */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`pagination-button  ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}

          {/* Right button */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button hover:bg-[#b788ca] rounded-3xl"
          >
            <IoChevronForward />
          </button>
        </div>

        {/* CSS for the custom pagination */}
        <style>
          {`
            .pagination-button {
              margin: 0 0.5rem;
              padding: 0.5rem 1rem;
              border: 1px solid #ccc;
              cursor: pointer;
            }

            .pagination-button.active {
              background-color: #ccc;
            }
          `}
        </style>

            </>
          )}

        </>
      )}
    </div>
  );
};

export default Store;