import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // Import Axios
import { FilledInput, Tooltip } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast'
import { API_URL, config } from '../../../config/config';
import Loader from '../../common/Loader';
import imageCompression from 'browser-image-compression';



const NewProductDetailsAdd = () => {
    const users = useSelector((state) => state.auth);
    const userId = users && users.user && users.user._id;
    const vendorId = users && users.user && users.user.vendorDetails;
    const vendor = useSelector((state) => state.vendor);
    const categories = vendor.vendor.BussinessDetails.typeOfBusiness;
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([]);
    const [sizeChartImage, setSizeChartImage] = useState(null);
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [type, setType] = useState('');
    const [kidsCategory, setkidsCategory] = useState('');
    const [customization, setCustomization] = useState('');
    const [material, setMaterial] = useState('');
    const [internalMaterial, setInternalMaterial] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [dimensions, setDimensions] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [stock, setStock] = useState('');
    const [modelSize, setModelSize] = useState('');
    const [processingTime, setProcessingTime] = useState('');
    const [selfLife, setSelfLife] = useState('');
    const [limitOfManufacturing, setLimitOfManufacturing] = useState('');
    const [resetFields, setResetFields] = useState(false);
    // -------------------------------------------------------
    const [productNameError, setProductNameError] = useState(false);
    const [subcategoryError, setSubcategoryError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [kidsCategoryError, setKidsCategoryError] = useState(false);
    const [customizationError, setCustomizationError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [materialError, setMaterialError] = useState(false);
    const [internalmaterialError, setInternalMaterialError] = useState(false);
    const [processingTimeError, setProcessingTimeError] = useState(false);
    const [limitOfManufacturingError, setLimitOfManufacturingTimeError] = useState(false);
    const [productImageError, setProductImageError] = useState(false);
    const [sizeError, setSizeError] = useState(false);
    const [yearError, setYearError] = useState(false);
    const [modelSizeError, setModelSizeError] = useState(false);
    const [sizeChartImageError, setSizeChartImageError] = useState(false);
    const [dimensionsError, setDimensionsError] = useState(false);
    const [originalPriceError, setOriginalPriceError] = useState(false);
    const [discountPriceError, setDiscountPriceError] = useState(false);
    const [stockError, setStockError] = useState(false);
    const [selfLifeError, setSelfLifeError] = useState(false);
    // ******************************************


    useEffect(() => {
        if (resetFields) {
            setProductName('');
            setDescription('');
            setSelectedCategory('');
            setType('');
            setkidsCategory('');
            setMaterial('');
            setCustomization('');
            setInternalMaterial('');
            setSelectedSizes([]);
            setDimensions('');
            setOriginalPrice('');
            setDiscountPrice('');
            setModelSize('');
            setStock('');
            setProcessingTime('');
            setSelfLife('');
            setLimitOfManufacturing('');
            setImages([]);
            setSelectedVideo('');
            setSizeChartImage(null);

            setResetFields(false);
        }
    }, [resetFields]);
    // const [ingredients , setIngredients] = useState('');

    const handleVideoChange = (event) => {
        const videoFile = event.target.files[0];

        // Check if the file size is within the allowed limit (50 MB)
        const maxSize = 50 * 1024 * 1024; // 50 MB in bytes
        if (videoFile && videoFile.size > maxSize) {
            alert('File size exceeds the maximum limit (50 MB). Please choose a smaller file.');
            return;
        }

        TransformVideo(videoFile);

    };

    const TransformVideo = (videoFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(videoFile);
        reader.onload = () => {
            const videoData = reader.result;

            setSelectedVideo(videoData);
        };
    };

    const isImage = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        return allowedTypes.includes(file.type);
    };

    const handleProductImageChange = async (event) => {
        const productImages = event.target.files;
    
        if (productImages.length === 0) {
            toast.error("Please select at least one Image");
            setProductImageError(true);
            return;
        }
        if (productImages.length > 5) {
            toast.error("You can only select up to 5 images.");
            event.target.value = null;
            setProductImageError(true);
            return;
        }
    
        const compressedImages = await Promise.all(Array.from(productImages).map(async (file) => {
            if (isImage(file)) {
                return await compressImage(file);
            } else {
                toast.error("Invalid file type. Please choose only JPEG, PNG, or JPG images.");
                event.target.value = null
                return null;
            }
        }));
    
    
        const validImages = compressedImages.filter((img) => img !== null);
    
        if (validImages.length === productImages.length) {
            TranformProductImages(validImages);
            setProductImageError(false);
        } else {
            toast.error("Please make sure to select only valid image files.");
            setProductImageError(true);
            // Handle invalid files
            // ...
        }
    };

    useEffect(() => {
        setProductImageError(false);
    }, [images]);
    
    const compressImage = async (file) => {
        const maxSizeInBytes = 1024 * 1024 * 0.5; 
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };

        //NOTE - If the provided image is under the maz size than it won't be compressed
        if (file.size <= maxSizeInBytes) {
            return file; 
        }

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('Image compression error:', error);
            return null;
        }
    };
    // Add useEffect to handle the state update
  



    const TranformProductImages = (productImages) => {
        const newImages = [];

        for (let i = 0; i < productImages.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(productImages[i]);
            reader.onload = () => {
                newImages.push(reader.result);
                if (newImages.length === productImages.length) {
                    setImages(newImages);
                }
            };
        }

    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];

        // Check if an image is selected
        if (!file) {
            toast.error("Please select an image");
            return;
        }

        // Function to check if a file is an image
        const isImage = (file) => {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            return allowedTypes.includes(file.type);
        };

        // Function to check if a file is less than 1 MB
        const isSizeValid = (file) => {
            const maxSizeInBytes = 1024 * 1024; // 1 MB
            return file.size <= maxSizeInBytes;
        };

        // Check if the selected file is a valid image and within size limit
        if (isImage(file) && isSizeValid(file)) {
            TransformSizeChartImage(file);
        } else {
            // Handle invalid file
            if (!isImage(file)) {
                toast.error("Invalid file type. Please choose only JPEG, PNG, or JPG images.");
                event.target.value = null
                setSizeChartImage([]);
                return
            } else {
                toast.error("File size exceeds the 1 MB limit. Please choose a smaller file.");
                event.target.value = null
                setSizeChartImage([]);
                return
            }

            // Reset the form and clear the images state

        }
    };


    const TransformSizeChartImage = (sizeChartImageFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(sizeChartImageFile);
        reader.onload = () => {

            const sizeChartImageData = reader.result;

            setSizeChartImage(sizeChartImageData);

            setSizeChartImage(reader.result);

        };
    };


    let categoryValue = '';

    if (selectedCategory === 'clothing') {
        if (type === 'kids') {
            categoryValue = kidsCategory;
        } else {
            categoryValue = type;
        }
    } else {
        categoryValue = selectedCategory;
    }


    const [prevSelectedCategory, setPrevSelectedCategory] = useState(categoryValue);
    // Function to reset selections when category or type changes
    useEffect(() => {
        setSelectedSizes([]); // Reset selected sizes
        setYearError(false); // Reset year error
        setSizeError(false); // Reset size error
    }, [selectedCategory, type]);

    const handleSizeChange = (event) => {
        const size = event.target.value;

        // Rest of the existing logic to update selectedSizes and prevSelectedCategory
        if (categoryValue !== prevSelectedCategory) {
            setSelectedSizes([]);
            setPrevSelectedCategory(categoryValue);
        }

        setSelectedSizes((prevSelectedSizes) => {
            if (prevSelectedSizes.includes(size)) {
                return prevSelectedSizes.filter((s) => s !== size);
            } else {
                return [...prevSelectedSizes, size];
            }
        });
    };


    const handleSubmit = async (event) => {

        event.preventDefault();
        setLoading(true)

        // Validation: Check if the product name is empty
        if (!productName) {
            setProductNameError(true);
            toast.error('Please enter the Product Name.');
            setLoading(false);
            return;
        }
        // Validation: Check if the category and subcategory are selected
        if (!selectedCategory) {
            setSubcategoryError(true);
            toast.error('Please select the required Category.');
            setLoading(false);
            return;
        }

        if (selectedCategory === 'clothing') {
            if (!type) {
                setTypeError(true);
                toast.error('Please select the Type Of Category.');
                setLoading(false);
                return;
            }

            if (type === 'kids' && !kidsCategory) {
                setKidsCategoryError(true);
                toast.error('Please select the Kids Category.');
                setLoading(false);
                return;
            }
        }

        // Validation: Check if customization is selected
        if (!customization) {
            setCustomizationError(true);
            toast.error('Please select whether the product is Customizable.');
            setLoading(false);
            return;
        }

        // Validation: Check if the Product Specification is empty or exceeds the maximum character limit
        if (!description || description.length > 1000) {
            setDescriptionError(true);
            toast.error('Product Specification must not be empty and should not exceed 1000 characters.');
            setLoading(false);
            return;
        }

        // Validation: Check if the material field is empty for 'homemade-food' category
        if (selectedCategory === 'homemade-food' && !material) {
            setMaterialError(true);
            toast.error('Please add Ingredients for Homemade Food.');
            setLoading(false);
            return;
        }

        // Validation: Check if the material field is empty for 'clothing' category
        if (selectedCategory === 'clothing' && !material) {
            setMaterialError(true);
            toast.error('Please enter the Fabric for clothing.');
            setLoading(false);
            return;
        }

        // Validation: Check if the internalMaterial field is empty for 'clothing' category
        if (selectedCategory === 'clothing' && !internalMaterial) {
            setInternalMaterialError(true);
            toast.error('Please enter the Inner Fabric for clothing.');
            setLoading(false);
            return;
        }

        // Additional Material Validation: Check if the material field is empty for specific categories
        if (['accessories', 'home-decor', 'other' /* add other categories here */].includes(selectedCategory) && !material) {
            setMaterialError(true);
            toast.error('Please enter Material details.');
            setLoading(false);
            return;
        }

        // Validation: Check if the Processing time is empty
        if (!processingTime) {
            setProcessingTimeError(true);
            toast.error('Please enter the Processing Time.');
            setLoading(false);
            return;
        }
        // Validation: Check if the limit of manufacturing is empty
        if (!limitOfManufacturing) {
            setLimitOfManufacturingTimeError(true);
            toast.error('Please enter the Limit Of Manufacturing.');
            setLoading(false);
            return;
        }

        // Check if no images are selected
        if (images.length === 0) {
            setProductImageError(true);
            toast.error("Please select at least one Product Image");
            setLoading(false);
            return;
        }

        //  Check if the Size and year is empty
        if (selectedCategory === 'clothing') {
            if (type === 'kids' && selectedSizes.length === 0) {
                setYearError(true);
                toast.error('Please select at least one Year.');
                setLoading(false);
                return;
            } else if (type !== 'kids' && selectedSizes.length === 0) {
                setSizeError(true);
                toast.error('Please select at least one Size.');
                setLoading(false);
                return;
            }
        }


        //  Check if the Size chart is empty
        if (selectedCategory === 'clothing') {
            // Validation: Check if the Size Chart Image is uploaded
            if (!sizeChartImage) {
                setSizeChartImageError(true);
                toast.error('Please upload the Size Chart Image.');
                setLoading(false);
                return;
            }

            // Validation: Check if the Model Size field is empty
            if (!modelSize) {
                setModelSizeError(true);
                toast.error('Please enter the Model Size.');
                setLoading(false);
                return;
            }
        }

        // Validation in your handleSubmit function
        if (['accessories', 'home-decor', 'other'].includes(selectedCategory)) {
            // Validation: Check if the dimensions field is empty
            if (!dimensions) {
                setDimensionsError(true);
                toast.error('Please enter the product dimensions.');
                setLoading(false);
                return;
            } else {
                setDimensionsError(false);
            }
        }
        // Validation: Check if the Original Price is empty
        if (!originalPrice) {
            setOriginalPriceError(true);
            toast.error('Please enter the Original Price.');
            setLoading(false);
            return;
        }
        // Validation: Check if the Discount Price is empty
        if (!discountPrice) {
            setDiscountPriceError(true);
            toast.error('Please enter the Discount Price.');
            setLoading(false);
            return;
        }
        // Validation: Check if the Stock is empty
        if (!stock) {
            setStockError(true);
            toast.error('Please enter the Stock.');
            setLoading(false);
            return;
        }

        // Validation in your handleSubmit function
        if (selectedCategory === 'homemade-food') {
            // Validation: Check if the shelf life is empty
            if (!selfLife) {
                setSelfLifeError(true);
                toast.error('Please enter the shelf life.');
                setLoading(false);
                return;
            } else {
                setSelfLifeError(false);
            }
        }

        let categoryValue = '';

        if (selectedCategory === 'clothing') {
            if (type === 'kids') {
                categoryValue = kidsCategory; // Set category to 'Boy' or 'Girl'
            } else {
                categoryValue = type; // Set category to 'Men' or 'Women'
            }
        } else {
            categoryValue = selectedCategory; // Set category for other categories (e.g., 'Home Decor', 'Homemade Food')
        }



        const submitData = {
            productName,
            description,
            selectedCategory: categoryValue,
            type,
            kidsCategory: kidsCategory,
            material,
            internalMaterial,
            processingTime,
            sizeChartImage,
            selectedSizes: selectedSizes,
            images,
            dimensions,
            originalPrice,
            discountPrice,
            modelSize,
            stock,
            customization,
            selfLife,
            limitOfManufacturing,
        }




        try {
            // Make a POST request to your backend API
            const response = await axios.post(`${API_URL}/api/products/${userId}/${vendorId}`, submitData, {
                //  timeout: 100000,

                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            });

            toast.success('Product created successfully!');
            setLoading(false)


            setProductName('');
            setDescription('');
            setSelectedCategory('');
            setType('');
            setkidsCategory('');
            setCustomization('');
            setMaterial('');
            setInternalMaterial('');
            setSelectedSizes(null);
            setDimensions('');
            setOriginalPrice('');
            setDiscountPrice('');
            setModelSize('');
            setStock('');
            setProcessingTime('');
            setSelfLife('');
            setLimitOfManufacturing('');
            const fileInput = document.getElementById('productimageUpload');
            if (fileInput) {
                fileInput.value = '';  // Clear the value to remove the file name
            }
            const fileInputSize = document.getElementById('imageUpload');
            if (fileInputSize) {
                fileInputSize.value = '';  // Clear the value to remove the file name
            }


            setResetFields(true);
            setProductNameError(false);
            setSubcategoryError(false);
            setTypeError(false);
            setKidsCategoryError(false);
            setCustomizationError(false);
            setDescriptionError(false);
            setMaterialError(false);
            setInternalMaterialError(false);
            setProcessingTimeError(false);
            setLimitOfManufacturingTimeError(false);
            setProductImageError(false);
            setSizeError(false);
            setYearError(false);
            setSizeChartImageError(false);
            setModelSizeError(false);
            setDimensionsError(false);
            setOriginalPriceError(false);
            setDiscountPriceError(false);
            setStockError(false);
            setSelfLifeError(false);
            // **********************************

            // You can redirect the user or perform any other necessary actions upon successful submission
        } catch (error) {
            console.error("Error submitting the form:", error);
            if (error.response) {


                toast.error(` ${error.response.data.message}`);


                setLoading(false)
                return
            } else if (error.request) {

                console.error("No response received. Request details:", error.request);
                setLoading(false)
            } else {

                console.error("Error setting up the request:", error.message);
                setLoading(false)
            }
            toast.error('Failed to create the product. Please try again later.');
            setLoading(false)
        }
    };
    return (
        <>


            <div className='container mx-auto p-2 sm:p-4 mb-2'>
                <form onSubmit={handleSubmit} className='max-w-full mx-auto bg-[#F5f6f9] shadow-md rounded-lg px-2 sm:px-8 pt-6 pb-8 mb-4 '>
                    <div className="grid grid-cols-1  md:grid-cols-2 gap-6">
                        <div className='left w-full flex-wrap flex flex-col   px-2 mb-4'>
                            <div className='flex flex-col mt-2'>
                                <div className='flex gap-2 '>

                                    <label htmlFor='productName' className="text-sm font-semibold">Product Name</label>
                                    <div>
                                        <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 180 characters when entering the product name.</h1>} >
                                            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                        </Tooltip>
                                    </div>

                                </div>

                                <input
                                    type='text'
                                    placeholder='Add name of your product'
                                    value={productName}
                                    maxLength={180}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setProductName(selectedValue);
                                        setProductNameError(false);
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />

                                {productNameError && <p className="text-red-500 text-sm mt-1">Please enter the Product Name.</p>}
                            </div>
                            <div className='flex flex-col mt-2'>
                                <label htmlFor='category' className="text-sm font-semibold">Choose a category</label>
                                <select
                                    id='category'
                                    name='category'
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setSelectedCategory(selectedValue);
                                        setSubcategoryError(false);
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                >
                                    <option value=''>Select a category</option>
                                    {categories?.map((category, index) => (
                                        <option key={index} value={category}>
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </option>
                                    ))}
                                </select>
                                {subcategoryError && <p className="text-red-500 text-sm mt-1">Please select the required Category.</p>}
                            </div>

                            <div className='flex flex-col mt-2'>

                                {selectedCategory === 'clothing' ? <>
                                    <label htmlFor='type' className="text-sm font-semibold">
                                        Type</label>
                                    <select id='type' name='type' value={type}
                                        onChange={(e) => {
                                            setType(e.target.value);
                                            setTypeError(false); // Reset description error on change
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    >
                                        <option value=''>Select a type</option>
                                        <option value='Men'>Men</option>
                                        <option value='Women'>Women</option>
                                        <option value='kids'>Kids</option>

                                    </select>{typeError && <p className="text-red-500 text-sm mt-1">Please select the Type Of Category.</p>} </> : <></>

                                }
                            </div>



                            <div className='flex flex-col mt-2'>
                                {selectedCategory === 'clothing' &&
                                    type === 'kids' ? <>
                                    <label htmlFor='type' className="text-sm font-semibold">Kids Category</label>
                                    <select id='kidsCategory' name='kidsCategory'
                                        value={kidsCategory}
                                        onChange={(e) => {
                                            setkidsCategory(e.target.value);
                                            setKidsCategoryError(false); // Reset description error on change
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full">

                                        <option value=''>Select a kidscategory</option>
                                        <option value='Boy'>Boy</option>
                                        <option value='Girl'>Girl</option>

                                    </select>
                                    {kidsCategoryError && <p className="text-red-500 text-sm mt-1">Please select the Kids Category.</p>}
                                </> : <></>
                                }

                            </div>

                            <div className='flex flex-col mt-4'>
                                <div className='flex gap-2'>
                                    <label htmlFor='customization' className="text-sm font-semibold">Is your product customizable </label>
                                    <div>
                                        <Tooltip title={<h1 style={{ fontSize: 16 }}>If you sell customizable product then select Yes otherwise No</h1>} >
                                            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <select className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    id='customization' name='customization'
                                    value={customization}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setCustomization(selectedValue);
                                        setCustomizationError(false); // Reset customization error on change
                                    }}>
                                    <option value=''>Select Yes or No</option>
                                    <option value='yes'>Yes</option>
                                    <option value='no'>No</option>

                                </select>
                                {customizationError && <p className="text-red-500 text-sm mt-1">Please select whether the product is Customizable.</p>}

                            </div>


                            <div className='flex flex-col mt-2'>
                                <div className='flex gap-2'>
                                    <label htmlFor='description' className="text-sm font-semibold">Product Specification</label>
                                    <div>
                                        <Tooltip title={<h1 style={{ fontSize: 16 }}>Do not exceed 1000 characters when entering the product description.</h1>} >
                                            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                        </Tooltip>
                                    </div>
                                </div>

                                <textarea
                                    id='description'
                                    name='description'
                                    rows='8'
                                    value={description}
                                    maxLength={1000}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setDescriptionError(false); // Reset description error on change
                                    }}
                                    className="w-full mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                ></textarea>
                                {descriptionError && <p className="text-red-500 text-sm mt-1">Product Specification must not be empty and should not exceed 1000 characters.</p>}

                            </div>

                            <div className='flex flex-col mt-2'>
                                <label htmlFor='material' className="text-sm font-semibold">
                                    {selectedCategory === 'homemade-food' ? 'Ingredients' : (selectedCategory === 'clothing' ? 'Fabric' : 'Material')}
                                </label>
                                <input
                                    type='text'
                                    placeholder={
                                        selectedCategory === 'homemade-food'
                                            ? 'Add ingredients for homemade food'
                                            : (selectedCategory === 'clothing' ? 'Fabric' : 'Material')
                                    }
                                    value={material}
                                    maxLength={100}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setMaterial(selectedValue);
                                        setMaterialError(false);
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {(['accessories', 'home-decor', 'other' /* add other categories here */].includes(selectedCategory) && materialError) && (
                                    <p className="text-red-500 text-sm mt-1">Please enter Material Details.</p>
                                )}
                                {selectedCategory === 'homemade-food' && materialError && (
                                    <p className="text-red-500 text-sm mt-1">Please enter Ingredients for Homemade Food.</p>
                                )}
                                {selectedCategory === 'clothing' && materialError && (
                                    <p className="text-red-500 text-sm mt-1">Please enter Fabric Details for clothing.</p>
                                )}
                            </div>
                            {selectedCategory === 'clothing' ? (
                                <div className='flex flex-col mt-2'>
                                    <label htmlFor='internalMaterial' className="text-sm font-semibold">Inner Fabric</label>
                                    <input
                                        type='text'
                                        placeholder='Inner Fabric'
                                        value={internalMaterial}
                                        maxLength={50}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setInternalMaterial(selectedValue);
                                            setInternalMaterialError(false);
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {internalmaterialError && <p className="text-red-500 text-sm mt-1">Please enter Inner Fabric.</p>}
                                </div>

                            ) : null}


                            <div className='flex flex-col mt-2'>
                                <div className='flex gap-2'>
                                    <label htmlFor='processing-time' className="text-sm font-semibold">Processing Time</label>
                                    <div>
                                        <Tooltip title={<h1 style={{ fontSize: 16, }}>Enter the number of days required for processing the order. (enter only digits)</h1>} >
                                            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                        </Tooltip>
                                    </div>

                                </div>

                                <input
                                    type='text'
                                    placeholder='Add processing time for the item'
                                    value={processingTime}
                                    maxLength={3}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const regex = /^[0-9]*$/; // Regular expression to allow only digits

                                        if (regex.test(inputValue)) {
                                            setProcessingTime(inputValue);
                                            setProcessingTimeError(false)
                                        }
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {processingTimeError && <p className="text-red-500 text-sm mt-1">Please enter Processing Time.</p>}

                            </div>

                            <div className='flex flex-col mt-4'>
                                <div className='flex gap-2'>
                                    <label htmlFor='limitOfManufacturing' className="text-sm font-semibold">Limit Of Manufacturing</label>
                                    <div>
                                        <Tooltip title={<h1 style={{ fontSize: 16 }}>The maximum quantity of this product that can be manufactured. (enter only digits)</h1>} >
                                            <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <input
                                    type='text'
                                    placeholder='Limit Of Manufacturing'
                                    value={limitOfManufacturing}
                                    maxLength={20}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const regex = /^[0-9]*$/; // Regular expression to allow only digits

                                        if (regex.test(inputValue)) {
                                            setLimitOfManufacturing(inputValue);
                                            setLimitOfManufacturingTimeError(false);
                                        }
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {limitOfManufacturingError && <p className="text-red-500 text-sm mt-1">Please enter Limit Of Manufacturing.</p>}

                            </div>


                        </div>
                        
                        <div className='right flex flex-col w-full flex-wrap px-2 mb-4 mt-2'>
                            <div className='flex gap-2'>
                                <label className="text-sm font-semibold">Product Images</label>
                                <div>
                                    <Tooltip title={<h1 style={{ fontSize: 16, }}>You need to add minimum 1 and maximum 5 images. Pay attention to the quality of the pictures you add, comply with the background color standards. Pictures must be in certain dimensions. Ensure that the product showcases all the details.</h1>} >
                                        <InfoRoundedIcon fontSize='18' className=" cursor-pointer mb-2" />
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="flex flex-col flex-wrap mt-2 w-full">
                                <div className=' w-full'>
                                    <input
                                        type='file'
                                        className="mt-2 border rounded w-full p-1 lg:p-4 "
                                        id="productimageUpload"
                                        name="productimageUpload"
                                        // accept="image/*"
                                        multiple={true}
                                        onChange={(e) => {
                                            handleProductImageChange(e);
                                            setProductImageError(false);
                                        }}
                                    ></input>
                                    {productImageError && <p className="text-red-500 text-sm mt-1">Please upload the Product Images.</p>}
                                </div>
                                <div className='flex flex-row flex-wrap'>
                                    {images.map((file, index) => (
                                        <div key={index} className="w-16 h-16 p-2 border border-gray-300">
                                            <img src={file}
                                                className="w-12 h-12 object-cover" alt={`Product ${index + 1}`} />
                                        </div>
                                    ))}
                                </div>

                            </div>


                            {/* //NOTE - Do not delete removed for now */}
                            {/* <div className='mt-2'>
                            <label htmlFor="video" className="text-sm font-semibold">
                                Product Video
                            </label>
                            <input
                                type="file"
                                id="videoUpload"
                                name="videoUpload"
                                accept="video/"
                                onChange={handleVideoChange}
                                max="52428800"

                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                            />
                            <div className=''>
                                {selectedVideo ? <>
                                    <video controls width="400" height="300" >
                                        <source src={selectedVideo} type="video/mp4"  />
                                        Your browser does not support the video tag.
                                    </video>
                                </> : <p>Vedio not found</p>}</div>

                        </div> */}


                            <div className="w-full lg:w-full md:w-1/3 px-2 mb-4 mt-3">
                                {selectedCategory === "clothing" ? <>
                                    <div className="mt-2">

                                        <div className="flex gap-4 flex-wrap">
                                            {type === 'kids' ? (
                                                <div className="flex-col flex mt-2 ">
                                                    <h3 className="text-sm font-semibold ">Select Years:</h3>
                                                    <div className=''>
                                                        {['0-1', '1-3', '3-5', '5-7', '7-9', '9-11', '11-13'].map((size, index) => (
                                                            <label key={index} className="inline-flex items-center mt-2">
                                                                <input
                                                                    type="checkbox"
                                                                    value={size}
                                                                    checked={selectedSizes.includes(size)}
                                                                    onChange={(e) => {
                                                                        handleSizeChange(e)
                                                                        setYearError(false);
                                                                    }}
                                                                    className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                                />
                                                                <span className="ml-2 mr-4">{size}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {yearError && <span className="text-red-500 text-sm mt-1">Please select at least one Year.</span>}
                                                </div>
                                            ) : (
                                                <div className="flex flex-col gap-2 mt-2">
                                                    <h3 className="text-sm font-semibold">Select Sizes:</h3>
                                                    <div>
                                                        {['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'].map((size, index) => (
                                                            <label key={index} className="inline-flex items-center mt-2">
                                                                <input
                                                                    type="checkbox"
                                                                    value={size}
                                                                    checked={selectedSizes.includes(size)}
                                                                    onChange={(e) => {
                                                                        handleSizeChange(e)
                                                                        setSizeError(false);
                                                                    }}
                                                                    className="form-checkbox h-4 w-4 bg-custom-purple flex"
                                                                />
                                                                <span className="ml-2 mr-4">{size}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {sizeError && <span className="text-red-500 text-sm mt-1">Please select at least one Size.</span>}
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="mt-2 flex gap-2">

                                    </div> </> : <></>}

                            </div>

                            <div className="w-full md:w-1/2 lg:w-full mb-4 mt-2">
                                {selectedCategory === 'clothing' ? (
                                    <>
                                        <label className="text-sm font-semibold" htmlFor='size-chart'>Size Chart Image</label>
                                        <div>
                                            <input
                                                type='file'
                                                className="mt-2 border rounded p-4"
                                                id="imageUpload"
                                                name="imageUpload"
                                                accept="image/"
                                                onChange={(e) => {
                                                    handleImageChange(e)
                                                    setSizeChartImageError(false);
                                                }}
                                            ></input>
                                            <div className='w-40 h-40'>
                                                {sizeChartImage ? <>
                                                    <img src={sizeChartImage} alt='Size chart Image' className='w-40 h-40'></img>
                                                </> : <p>Image not found</p>}
                                            </div>
                                            {sizeChartImageError && (
                                                <p className='text-red-500 text-sm mt-1 '>Please upload the Size Chart Image.</p>
                                            )}
                                        </div>
                                        <label htmlFor='model-size' className="text-sm font-semibold mt-4">Model Size</label>
                                        <input
                                            type='text'
                                            placeholder='Add size of the model (example. : 4Xl )'
                                            value={modelSize}
                                            maxLength={20}
                                            onChange={(e) => {
                                                setModelSize(e.target.value)
                                                setModelSizeError(false)
                                            }}
                                            className="mt-2 px-3 py-2 border w-full rounded-md focus:outline-none focus:border-[#8A58DC] "
                                        />
                                        {modelSizeError && (
                                            <p className='text-red-500 text-sm mt-1 '>Please enter the Model Size.</p>
                                        )}
                                    </>
                                ) : (
                                    selectedCategory !== 'homemade-food' ? (
                                        <div className="w-full mb-2 mt-2">
                                            <label htmlFor='dimensions' className="text-sm font-semibold">Dimensions</label>
                                            <input
                                                type='text'
                                                maxLength={20}
                                                placeholder="Add product dimensions (e.g., 10x12x5 )"
                                                value={dimensions}
                                                onChange={(e) => {
                                                    setDimensions(e.target.value)
                                                    setDimensionsError(false)
                                                }}
                                                className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full "
                                            />
                                            {dimensionsError && (
                                                <p className="text-red-500 text-sm mt-1">Please enter the Product Dimensions.</p>
                                            )}
                                        </div>
                                    ) : null

                                )}
                            </div>
                            <div className="flex flex-col lg:flex-row gap-4 ">
                                <div>
                                    <label htmlFor='original-price' className="text-sm font-semibold">Original Price</label>
                                    <input
                                        type='text'
                                        placeholder='Add original price of the product'
                                        value={originalPrice}
                                        maxLength={6}
                                        onChange={(e) => {
                                            let newValue = parseInt(e.target.value, 10);
                                            if (isNaN(newValue) || newValue < 0) {
                                                newValue = 0;
                                            }
                                            setOriginalPrice(newValue);
                                            setOriginalPriceError(false);
                                            // Ensure discounted price is always less than or equal to the original price
                                            if (discountPrice > newValue) {
                                                setDiscountPrice(newValue);
                                            }
                                        }}
                                        className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                    />
                                    {originalPriceError && <p className="text-red-500 text-sm mt-1">Please enter the Original Price.</p>}

                                </div>
                                <div>
                                    <label htmlFor='discount-price' className="text-sm font-semibold mt-2">Discounted Price</label>
                                    <input
                                        type='text'
                                        placeholder='Add discount price of the product'
                                        value={discountPrice}
                                        maxLength={6}
                                        onChange={(e) => {
                                            let newValue = parseFloat(e.target.value);
                                            if (isNaN(newValue) || newValue < 0) {
                                                newValue = 0;
                                            }

                                            // Ensure discounted price is always less than or equal to the original price
                                            if (newValue > originalPrice) {
                                                newValue = originalPrice;
                                            }

                                            setDiscountPrice(newValue);
                                            setDiscountPriceError(false);
                                        }}
                                        className="mt-2 px-3 py-2 w-full border rounded-md focus:outline-none focus:border-[#8A58DC]"
                                    />
                                    {discountPriceError && <p className="text-red-500 text-sm mt-1">Please enter the Discounted Price.</p>}

                                </div>
                            </div>


                            <div className='flex flex-col mt-2'>
                                <label htmlFor='stock' className="text-sm font-semibold">Stock</label>
                                <input
                                    type='text'
                                    placeholder='Stock of product'
                                    value={stock}
                                    maxLength={10}
                                    onChange={(e) => {
                                        let newValue = parseInt(e.target.value, 10);
                                        if (isNaN(newValue) || newValue < 0) {
                                            newValue = 0;
                                        }
                                        setStock(newValue);
                                        setStockError(false);
                                    }}
                                    className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                />
                                {stockError && <p className="text-red-500 text-sm mt-1">Please enter the Stock.</p>}

                            </div>

                            {selectedCategory === 'homemade-food' && (
                                <div className='flex flex-col mt-4'>
                                    <label htmlFor='Shelf-life' className="text-sm font-semibold">Shelf Life</label>
                                    <input
                                        type='text'
                                        placeholder='Add shelf life of the item. (in months)'
                                        value={selfLife}
                                        maxLength={30}
                                        onChange={(e) => {
                                            setSelfLife(e.target.value)
                                            setSelfLifeError(false)
                                        }}
                                        className="mt-2 px-3 py-2 border rounded-md focus:outline-none focus:border-[#8A58DC] w-full"
                                    />
                                    {selfLifeError && (<p className="text-red-500 text-sm mt-1">Please enter the Shelf Life.</p>)}
                                </div>
                            )}



                        </div>
                    </div>
                    <div className="w-full mt-4">
                        <button type='submit' className="bg-custom-purple hover:bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >Save Product</button>
                    </div>

                </form >
                <Toaster />
            </div >
            {loading &&
                <div className="fixed inset-0 overflow-hidden w-full h-full flex justify-center items-center z-50  bg-[#00000040]">
                    <Loader />
                </div>
            }

        </>

    );
}

export default NewProductDetailsAdd;
