import React from 'react'

import { Link } from 'react-router-dom';

import BrandingTag from "../component/Home/BrandingTag";
import Instagram from "../component/Home/Instagram";
import { Helmet } from 'react-helmet';
import Aboutusvedio from '../component/Home/Aboutusvedio';
import constant from '../constant';


const ABOUT_US_LOGO=constant.IMAGES_IMAGES.ABOUT_US.ABOUT_US_LOGO;
const ABOUT_US_EARRING=constant.IMAGES_IMAGES.ABOUT_US.EARRING;
const ABOUT_US_IMG=constant.IMAGES_IMAGES.ABOUT_US.ABOUT_US_IMG;
const SHOP=constant.IMAGES_IMAGES.ABOUT_US.SHOP;
const AboutUs = () => {
  return (
    <div className='p-4'>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <div className='w-100vw h-100vh max-w-full'>


        <div className='flex flex-col gap-y-2 mt-6 '>
          <h3 className='text-blue-800 flex justify-center items-center gap-y-5'>Inspiring Creativity, Connecting Creators.</h3>
          <h1 className='text-gray-900 justify-center items-center flex text-[40px]'>Introducing The Ekaiv</h1>
          <p className='text-gray-400 justify-center items-center mb-6 flex text-[14px]  '>At "The Ekaiv", we're the meeting place for a vibrant community of creators , innovators , and discerning <br />
            shoppers. Our platform empowers sellers to showcase their creativity and connect with a global audience,<br />
            while offering customers an ever-expanding world of unique creations and personalized products. <br />
            Whether you're here to share your craft, find inspiring or simply explore endless possibilities, we <br />
            invite you to join our diverse community and be a part of our exciting journey.<br />
          </p>

        </div>

        <div className='flex justify-center items-center'>
          <img width={800} height={800}
            src={ABOUT_US_LOGO} />
        </div>

        <div className='flex flex-col justify-center items-center mt-20 gap-y-5 '>

          <h1 className='text-gray-900 justify-center items-center flex text-[40px]'>
            Unleash Your Imagination<br />
            Shop the Extraordinary</h1>
          <div className='lg:flex  gap-x-28'>
            <div className='sm:flex  gap-x-28 mb-5'>

              <div className='flex flex-col justify-center items-center '>
                {/* <p className='text-gray-900 text-[40px]'>Discover Unique<br /> Creations</p> */}
                <div></div>
                <img className="w-[265px] h-[225px] rounded-2xl" src={ABOUT_US_EARRING} />

                <Link to="/products">
                  <button className='bg-[#8A58DC] rounded-md text-white w-48 ml-8 h-10 flex items-center justify-center gap-2 hover:bg-purple-400'>
                  Start Shopping
                  </button>
                </Link>


              </div>

              <div >
                <p className='text-gray-400 justify-center items-center flex text-[14px] gap-y-10 mt-20'>

                  Experience shopping like never before with <br />
                  'The Ekaiv.' Connect with artisans <br />
                  worldwide on our curated marketplace for<br />
                  unique products. Celebrate craftsmanship <br />
                  and individuality. Start shopping now!</p>
              </div>
            </div>

            <div className=''>
              <img className='w-[300px] h-[320px] rounded-2xl mx-auto'
                src={SHOP}/>
            </div>
          </div>
        </div>


        <div className='md:flex  mt-28 lg:gap-x-60 md:gap-x-20 justify-center items-center gap-y-5'>
          <img className=' md:mx-0 mx-auto md:mb-0 mb-5' src={ABOUT_US_IMG} />
          <div className=''>
            <h3 className="text-gray-900 text-[40px]" >The Ekaiv Unveils</h3>

            <p className='text-gray-400  text-[14px] px-5 gap-y-10 mt-5'>
              Ekaiv Unveils, a canvas of Indian artistry by Perceptions Technologies.
              Our marketplace transcends, curating handmade marvels and fostering a vibrant artisanal community.
              Every creation tells a story, echoing the spirit of Ekaiv—where 'Elevating Your Brand'
              is more than a tagline; it's woven into every thread of our artisanal tapestry.
            </p>
            <div>

            </div>
          </div>
        </div>

        <div>
          <Aboutusvedio />
        </div>

        <div>
          <BrandingTag />
        </div>

        <div>
          <Instagram />
        </div>







      </div>
    </div>
  )
}

export default AboutUs
