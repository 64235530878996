import React from 'react'
import {Link} from 'react-router-dom'
import sellerimg from "../../constant"

const sell=sellerimg.IMAGES_IMAGES.BECOME_A_SELLER.SELLING_PAGE.EKAIV

const StartSelling = () => {
  
  return (
    <div className='  flex max-lg:flex-col lg:flex-row gap-2 sm:px-12 px-8 sm:mt-0 mt-10 '>
    <div className='  w-full sm:pl-10 sm:flex md:flex flex-col justify-center  '>
      <p className='sm:text-5xl text-2xl mb-4 font-bold  '> 
      Join The Ekaiv: Empower Your Artistry, Expand Your Reach
      </p>
      <p className='sm:text-lg text-gray-700 text-base mb-4'>
      Unlock Your Potential, Connect with Customers Nationwide
      </p>
      <p className=' sm:text-xl text-base mb-8 text-justify'>
      Welcome to The Ekaiv Selling Page, where artisans thrive and creativity flourishes. Showcase your craft, connect with customers nationwide, and embark on a journey of growth and success with us.
      </p>
     <Link to ='/become-seller'> <button class="mb-3 sm:w-[180px]  sm:text-xl text-sm cursor-pointer text-white font-bold shadow-md hover:scale-[1.025] hover:shadow-xl rounded-full sm:px-6 sm:py-3 px-3 py-2 bg-purple-700">
    Start Selling
</button>

      </Link>

     <Link to ='/vendor-account-policy'> <button className=' sm:text-xs text-[8px] flex justify-start  items-center gap-0 font-bold px-4 py-2 cursor-pointer text-gray-600 tracking-widest rounded-md  duration-300 hover:gap-1 hover:translate-x-2'>
        *T&C Apply
      </button>
      </Link>
      
    </div>
    <div className=' w-full  sm:m-0 '>
      <img src={sell} alt="About" className=' ' /> 
    </div>
  </div>
  )
}

export default StartSelling
