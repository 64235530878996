import React from 'react'


function BillingForm({ formData, formErrors, handleInputChange, handleSubmit, closeForm }) {

    return (
            <form onSubmit={handleSubmit} className='border rounded-lg p-4 '>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Full Name
                    </label>
                    <input
                        type='text'
                        name='fullname'
                        value={formData.fullname}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.fullName ? 'border-red-500' : ''
                            }`}
                        placeholder='Full Name'
                    />
                    {formErrors.fullname && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.fullname}</p>
                    )}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Mobile Number
                    </label>
                    <input
                        type='text'
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        onClick={(e) => {
                            // Allow only numeric characters (0-9) and backspace
                            const isNumeric = /^[0-9\b]+$/;
                            if (!isNumeric.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        maxLength={10}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.mobileNumber ? 'border-red-500' : ''
                            }`}
                        placeholder='Mobile Number'
                    />
                    {formErrors.phoneNumber && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.phoneNumber}</p>
                    )}
                </div>

                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Flat/House No.
                    </label>
                    <input
                        type='text'
                        name='flat'
                        value={formData.flat}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.address ? 'border-red-500' : ''
                            }`}
                        placeholder='Flat'
                    />
                    {formErrors.flat && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.flat}</p>
                    )}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Area
                    </label>
                    <input
                        type='text'
                        name='area'
                        value={formData.area}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.area ? 'border-red-500' : ''
                            }`}
                        placeholder='Area'
                    />
                    {formErrors.area && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.area}</p>
                    )}
                </div>

                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Landmark
                    </label>
                    <input
                        type='text'
                        name='landmark'
                        value={formData.landmark}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.landmark ? 'border-red-500' : ''
                            }`}
                        placeholder='Landmark'
                    />
                    {formErrors.landmark && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.landmark}</p>
                    )}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Town/City
                    </label>
                    <input
                        type='text'
                        name='city'
                        value={formData.city}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.townCity ? 'border-red-500' : ''
                            }`}
                        placeholder='Town/City'
                    />
                    {formErrors.city && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.city}</p>
                    )}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        Pincode
                    </label>
                    <input
                        type='text'
                        name='pinCode'
                        value={formData.pinCode}
                        onChange={handleInputChange}
                        onClick={(e) => {
                            // Allow only numeric characters (0-9) and backspace
                            const isNumeric = /^[0-9\b]+$/;
                            if (!isNumeric.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        maxLength={6}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.pincode ? 'border-red-500' : ''
                            }`}
                        placeholder='Pincode'
                    />
                    {formErrors.pinCode && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.pinCode}</p>
                    )}
                </div>


                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2'>
                        State
                    </label>
                    <select
                        name='state'
                        value={formData.state}
                        onChange={handleInputChange}
                        className={`border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.state ? 'border-red-500' : ''
                            }`}
                    >
                        <option value="" disabled>
    Select State
  </option>
  {[
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Lakshadweep',
    'Delhi',
    'Puducherry',
  ].map((state) => (
    <option key={state} value={state}>
      {state}
    </option>
  ))}
</select>
                    {formErrors.state && (
                        <p className='text-red-500 text-sm mt-2'>{formErrors.state}</p>
                    )}
                </div>
                <div className='flex gap-4'>
                    <button type='submit' className='bg-[#4cd964] text-white  pl-2 pr-2 pt-1 pb-1 rounded-sm'>Save</button>
                    <button className='bg-[#ff2d55]  text-white pl-2 pr-2 pt-1 pb-1 rounded-sm' onClick={closeForm}>Cancel</button>
                </div>

            </form>
        // </form>
    );
}

export default BillingForm;