import React, { useState, useEffect } from "react";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFromCart, getDataFromCart, updateToCart } from "../Redux/features/auth/authSlice";
import { toast, Toaster } from 'react-hot-toast'
import { resetMessage } from "../Redux/features/auth/authSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Loader from "./common/Loader";

export default function CartCard({ setShowCartSideBar, showCartSideBar }) {

  const users = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([])
  const [subTotal, setSubTotal] = useState(0);
  const [loading, setLoading] = useState(null);

  const handleCheckout = () => {
    // Close the cart page
    setShowCartSideBar(false);
  };

  const handleQuantityChange = (e, productId, updatedAmount) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity < 1) {
      toast.error('Quantity cannot be less than 1');
      return;
    }
    const product = cartItems.find((item) => item.product._id === productId);

    if (product && newQuantity > product.product.stock) {
      toast.error(`Products have this limited stock: ${product.product.stock}`);
      return;
    }

    const updatedCartItems = cartItems.map((item) => {
      if (item.product._id === productId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    // Update the state with the new cart items
    setCartItems(updatedCartItems);

    let total = 0;
    updatedCartItems.forEach((item) => {
      total += item.product.theEkaivDiscountPrice * item.quantity;
    });

    setSubTotal(total);

    const newData = {
      quantity: newQuantity,
      pId: productId
    };
    dispatch(updateToCart(newData))
      .then((result) => {

        if (updateToCart.fulfilled.match(result)) {
          // Product added to the cart successfully, handle the success case

        } else {
          // Handle the error case
          console.error('Error adding product to the cart:', result.payload);
        }
      });

  };

  useEffect(() => {
    const fetchCartItems = async () => {
      if (users && users.user && users.user.cartsItems && users.user.cartsItems.length >= 0) {
        try {
          setLoading(true);

          // Fetch cart data
          await dispatch(getDataFromCart(users.user._id));

          // Set loading to false when data is fetched
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false)
          toast('Something went wrong')
        }
      }
    };

    fetchCartItems();
  }, [users.user]);
  // if (users.cartsData) {

  //   setCartItems(users.cartsData)


  //   // if (users.cartsData.data) {
  //   //   let total = 0
  //   //   users.cartsData.data.map((product) => {
  //   //     total = total + product.originalPrice
  //   //   })
  //   //   setSubTotal(total)

  //   // }
  // }
  useEffect(() => {
    if (users.cartsData && users.cartsData.length >= 0) {
      setCartItems(users.cartsData);
  
      let total = 0;
  
      users.cartsData.forEach((item) => {
        const price = item.customPay && item.customPay === true ? item.customPrice : item.product.theEkaivDiscountPrice;
        total += price * item.quantity;
      });
  
      setSubTotal(total);
    }
  }, [users.cartsData]);
  

  const removeCartItems = (productId) => {
    dispatch(deleteFromCart({ productId, id: users.user._id })).then((response) => {
      if (response.error) {
        toast(response.payload)
        dispatch(resetMessage())
      } else {
        toast(response.payload.message)
        dispatch(resetMessage())
      }
    })
  }

  return (
    <Transition.Root show={showCartSideBar} as={Fragment}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full">
      <Dialog as="div" className="relative z-30" onClose={() => setShowCartSideBar(false)}>
        <div >
          <div className="fixed inset-0 overflow-hidden bg-[#00000040]">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Shopping cart</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setShowCartSideBar(false)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>

                        <div className="mt-12">
                          {loading ? (
                            <div className="flex justify-center items-center h-32 mt-52">

                              {/* <FontAwesomeIcon icon={faCircleNotch} spin size="2x" /> */}
                              <Loader />

                            </div>
                          ) :

                            cartItems.length === 0 ? (
                              <div className="flex flex-col justify-center items-center gap-10">
                                <div className="w-80">
                                  <img src="https://img.freepik.com/free-vector/empty-cart-concept-illustration_114360-17069.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais" alt="" />
                                </div>
                                <div className="mt-8 text-center text-gray-500">
                                <p>Your cart is feeling a little empty.</p> 
                                <p>Why not fill it with some handmade goodness?</p> <Link
                                    to="/products"
                                    className="text-[#8A58DC] hover:text-[#F16080] animate-pulse"
                                    onClick={() => {
                                      setShowCartSideBar(false); // Close the cart
                                    }}
                                  >
                                    Shop now
                                  </Link>.
                                </div>
                              </div>
                            ) : (
                              <div className="flow-root">
                                <ul role="list" className="-my-6 divide-y divide-gray-200">
                                  {users.user && users.user.cartsItems && cartItems ? (
                                    cartItems.map((product) => (

                                      <>
                                        <li key={product.id} className="flex py-6">
                                          <div className=" h-16 min-[317px]:h-24 w-16 min-[317px]:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">


                                            <img
                                              src={product.product.images[0]}
                                              alt={product.product.imageAlt}
                                              className="h-full w-full object-cover object-center"
                                            />



                                          </div>

                                          <div className="ml-4 flex flex-1 flex-col">
                                            <div>
                                              <div className="flex justify-between text-base font-medium text-gray-900">
                                                <h3>
                                                  {/* {product.productName.length>20 ? `${product.productName.slice(0, 35)}...` : product.productName} */}
                                                  <Link to={`/SpecificProductsPage/${product.product._id}`} className="cursor-pointer">{product.product.productName.length > 20 ? `${product.product.productName.slice(0, 35)}...` : product.product.productName}</Link>
                                                </h3>
                                                <p className="ml-4 text-[red]">
  Price: {product.customPay && product.customPay === true ? product.customPrice : product.product.theEkaivDiscountPrice}
</p>

                                              </div>
                                              <p className="mt-1 text-sm text-gray-500">{product.product.category}</p>
                                            </div>
                                            <div className="flex flex-1 items-end justify-between text-sm">
                                              <div className="text-gray-500 flex flex-row items-center">
                                                <label htmlFor="quantity" className="inline mr-3">
                                                  Quantity :

                                                </label>
                                                <div className="flex items-center">
                                                  <button
                                                    className="px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded-l"
                                                    onClick={() =>
                                                      handleQuantityChange({ target: { value: product.quantity - 1 } }, product.product._id)}

                                                  >
                                                    -
                                                  </button>
                                                  <span className="px-2">{product.quantity}</span>
                                                  <button
                                                    className="px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded-r"
                                                    onClick={() => handleQuantityChange({ target: { value: product.quantity + 1 } }, product.product._id)}

                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>

                                              <div className="flex">
                                                <button
                                                  type="button"
                                                  className="font-medium text-[#8A58DC] hover:text-[#8b58dcd9]"
                                                  onClick={() => removeCartItems(product.product._id)}
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </li>

                                      </>
                                    ))
                                  ) : (
                                    null
                                  )}
                                </ul>
                              </div>
                            )
                          }
                        </div>
                      </div>

                      {cartItems.length > 0 && (
                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                          <div className="flex justify-between text-base font-medium text-[red]">
                            <p>Subtotal</p>
                            <p>₹{subTotal}</p>
                          </div>
                          <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                          <button className="mt-6" onClick={handleCheckout}>
                            <Link
                              to={{
                                pathname: '/checkout',
                              }}
                            >
                              <div
                                className="flex items-center justify-center rounded-md border border-transparent bg-[#8A58DC] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-[#8b58dcd9]"
                              >
                                Checkout
                              </div>
                            </Link>
                          </button>
                          <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                            <p>
                              or <Link to="/products">
                                <button
                                  type="button"
                                  className="font-medium text-[#8A58DC] hover:text-[#8b58dcd9]"
                                  onClick={() => setShowCartSideBar(false)}
                                >
                                  Continue Shopping
                                  <span aria-hidden="true"> &rarr;</span>
                                </button>
                              </Link>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </Dialog>
    </Transition.Root>
  );
}
