import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL , config} from '../../../config/config';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";





const Table = () => {

  const productsPerPage =1;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const vendor = useSelector((state) => state.vendor);
  const loggedInVendorId = vendor && vendor.vendor && vendor.vendor._id;
  const PRODUCT_API_URL = `${API_URL}/api/products/getProduct`;
  const noOrdersMessage = (
    <div className="text-center text-gray-500 mt-10">
      No orders in process available.
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/order/getAllOrders`, config);
        const ordersWithProductDetails = await Promise.all(
          response.data.map(async (order) => {
            const productDetailsWithAdditionalInfo = await Promise.all(
              order.productsDetails.map(async (product) => {
                try {
                  const productResponse = await axios.get(`${PRODUCT_API_URL}/${product.productId}`, config);

                  return { ...product, additionalInfo: productResponse.data };
                } catch (error) {
                  console.error('Error fetching product details:', error);
                  return { ...product, additionalInfo: null };
                }
              })
            );
            return { ...order, productsDetails: productDetailsWithAdditionalInfo };
          })
        );
        setOrders(ordersWithProductDetails);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      // Make a request to your backend to update the order status
      await axios.put(`${API_URL}/api/order/updateOrderStatus/${orderId}`, {
        orderStatus: newStatus,
      }, config);

      // Update the local state with the new order status
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-IN', options);
    return formattedDate;
  };


  const newOrders = orders.filter((order) => order.orderStatus === 'InProcess');

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
   // Calculate the total number of pages
 const totalPages = Math.ceil(newOrders.length / productsPerPage);

 // Calculate the index of the first and last product to display on the current page
 const startIndex = (currentPage - 1) * productsPerPage;
 const endIndex = Math.min(
   startIndex + productsPerPage,
   newOrders.length
 );

  return (
    <>
      <div className='hidden sm:block p-5 overflow-x-auto'>
        {loading ? (
          <div className='flex justify-center'><Loader /></div>
          ) : newOrders.length === 0 ? (
            noOrdersMessage
          ) : (
          <table className="w-full table-auto border-collapse border border-gray-100 ">
            <thead>
              <tr>
                <th className="bg-gray-200 font-bold px-4 py-2">Customer</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Order Details</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Order Status</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Date/Time</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {newOrders?.slice(startIndex, endIndex)?.map((item, index) => {
                // Filter productDetails for the logged-in vendor and create a set of unique product IDs
                const uniqueProductIds = new Set(
                  item.productsDetails
                    .filter((product) => product.vendorId === loggedInVendorId)
                    .map((product) => product.productId)
                );

                return Array.from(uniqueProductIds).map((productId, productIndex) => {
                  // Find the product details for the current product ID
                  const productDetail = item.productsDetails.find((product) => product.productId === productId);

                  if (productDetail) {
                    const image = productDetail.additionalInfo?.product?.images?.[0];

                    return (
                      <React.Fragment key={`${index}-${productIndex}`}>
                        <tr className='border-b border-gray-400'>
                          <td className='px-4 py-2'>{item?.shippingAddress?.fullname}</td>
                          <td className='px-4 py-2'>
                            <div className='flex flex-col lg:flex-row gap-2 items-center'>
                              <div key={productIndex}>
                                <Link
                                  to={{
                                    pathname: `/SpecificProductsPage/${productDetail.additionalInfo?.product?._id}`,
                                    state: { productId: productDetail.additionalInfo?.product?._id }
                                  }}
                                  target='_blank'
                                  onClick={scrollTo}
                                >
                                  {image && (
                                    <img src={image} alt="Product" className='w-16 h-16 md:w-24 md:h-24 object-fill' />
                                  )}
                                </Link>
                                <div className='flex flex-col'>
                                  <div>
                                    Quantity: {productDetail.quantity}
                                  </div>
                                  <div>
                                    Price: {productDetail.price * productDetail.quantity}
                                  </div>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='px-4 py-2'>
                            <div>
                              <button className='bg-orange-100 w-28 h-10 rounded-2xl text-orange-600'>{item.orderStatus}</button>
                            </div>
                          </td>
                          <td className='px-4 py-2'>{formatDate(item.createdAt)}</td>
                          <td className='px-4 py-2'>
                            <div className='flex flex-row gap-2'>
                              <button onClick={() => handleStatusChange(item._id, 'InTransit')} className='bg-orange-400 text-white w-24 h-10 rounded-[6px]'>
                                In Transit
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }

                  return null;
                });
              })}
            </tbody>
          </table>
        )}
      </div>
                          {/* Custom pagination */}
                          <div className="flex justify-center mt-4 mb-4">
          {/* Left button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button hover:bg-[#b788ca]  rounded-3xl"
          >
            <IoChevronBack />
          </button>

          {/* Page buttons */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`pagination-button  ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}

          {/* Right button */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button hover:bg-[#b788ca] rounded-3xl"
          >
            <IoChevronForward />
          </button>
        </div>

        {/* CSS for the custom pagination */}
        <style>
          {`
            .pagination-button {
              margin: 0 0.5rem;
              padding: 0.5rem 1rem;
              border: 1px solid #ccc;
              cursor: pointer;
            }

            .pagination-button.active {
              background-color: #ccc;
            }
          `}
        </style>

      {/* mobile view */}

      <div className='block sm:hidden p-5 mb-[100px]'>
        {newOrders?.map((order, orderIndex) => (
          <div key={orderIndex} className='w-full mb-4'>
            <div className='bg-white p-3 rounded-md'>
              <div className='flex flex-row gap-2'>
                <div className='text-xs font-bold w-[60%]'>Customer: {order?.shippingAddress?.fullname}</div>
                <div className='text-[10px] text-[#A9A9A9] w-[40%]'>{formatDate(order.createdAt)}</div>
              </div>
              {order.productsDetails.map((product, productIndex) => (
                <div key={`${orderIndex}-${productIndex}`} className='mt-2 border-t pt-2'>
                  <div className='grid grid-cols-2'>
                    <div>
                      <p className='text-xs font-bold'>Order Id:</p>
                      <p className='text-xs font-bold'>Name: <span className='text-xs text-[#A9A9A9]'>{order?.shippingAddress?.fullname}</span> </p>
                      <p className='text-xs font-bold'>Price: <span className='text-xs text-[#A9A9A9]'>{product.price * product.quantity}</span></p>
                      <p className='text-xs font-bold'>Quantity: <span className='text-xs text-[#A9A9A9]'>{product.quantity}</span></p>
                    </div>
                    <div className='flex justify-center items-center'>
                      <Link
                        to={{
                          pathname: `/SpecificProductsPage/${product.additionalInfo?.product?._id}`,
                          state: { productId: product.additionalInfo?.product?._id }
                        }}
                        target='_blank'
                        onClick={scrollTo}
                      >
                        {product.additionalInfo?.product?.images?.[0] && (
                          <img
                            src={product.additionalInfo.product.images[0]}
                            alt={`Product ${productIndex + 1}`}
                            className='w-16 h-16 md:w-24 md:h-24 object-fill'
                          />
                        )}
                      </Link>
                    </div>
                  </div>
                  <div className='flex justify-center items-center mt-2'>
                    <div className='w-full'>
                      <button
                        className='bg-orange-100 sm:w-28 sm:h-10 rounded-md sm:rounded-2xl text-xs font-bold w-20 h-6 text-orange-600'
                      >
                        {order.orderStatus}
                      </button>
                    </div>
                    <div className='w-full mt-2'>
                      <button
                        onClick={() => handleStatusChange(order._id, 'InProcess')}
                        className='bg-orange-400 text-white text-xs font-bold w-20 h-6 rounded-[6px]'
                      >
                        In Process
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>


    </>
  );
};

export default Table;

