import React from 'react'

import constant from '../constant'

const BLUE=constant.IMAGES_IMAGES.BECOME_A_SELLER.BADGES.BULE;
const ORANGE=constant.IMAGES_IMAGES.BECOME_A_SELLER.BADGES.ORANGE;
const PURPLE=constant.IMAGES_IMAGES.BECOME_A_SELLER.BADGES.PURPLE;
const QUESTION=constant.IMAGES_IMAGES.BECOME_A_SELLER.BADGES.QUESTION;
const TICK = constant.IMAGES_IMAGES.BECOME_A_SELLER.BADGES.TICK;

function Bages() {
    return (
        <div>
            <div className=' grid grid-cols-1  pt-20 pb-14'>
                <div className=' flex justify-center flex-wrap items-center gap-x-3 px-5'>
                    <h1 className=' text-3xl md:text-5xl font-bold text-center py-5'>The Ekaiv Badges</h1><img src={TICK} alt="tick"  className=' h-10 w-10'/>
                </div>
                <div>
                    <p className='pt-3 text-justify sm:text-center px-16 md:px-52 text-sm'>Unlock recognition for your efforts! The Ekaiv celebrates vendor excellence through our exclusive badge program. Showcase your achievements proudly on your catalogue page by earning these badges.</p>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center  py-20 gap-y-10'>
                {/* badge one start */}
                <div className=' py-10'>
                    <div className=' grid grid-cols-1 sm:grid-cols-2 gap-3'>
                        <div className=' flex justify-center items-center'>
                            <img src={ORANGE} alt="orange" className=' w-[60%]'  />
                        </div>
                        <div className='   flex justify-center items-center flex-col'>
                            <div className=' w-[70%]'>

                            
                            <div>
                                <h1 className=' text-center sm:text-left font-bold text-xl py-5'> Efficiency Expert (Warm Pink Badge)</h1>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>How to Earn:</h1>
                                <p className=' pl-2 text-sm'>
                                • Process orders promptly. <br />
                                • Ensure timely delivery. <br />
                                • Maintain high-quality products that meet or exceed customer expectations.<br />
                                </p>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>Benefits:</h1>
                                <p className=' pl-2 text-sm'>
                                • Increased visibility and prominence on our platform. <br />
                                • Build trust with customers, leading to repeat business. <br />
                                • Priority consideration for promotional features.<br />
                                </p>
                            </div>
                            </div>
                        </div>
                        

                    </div>
                    
                </div>
                {/* badge one end */}
                

                {/* badge 2 start */}
                
                <div className='py-10  '>
                    <div className=' grid grid-cols-1 sm:grid-cols-2 gap-3'>
                    <div className=' flex justify-center items-center'>
                            <img src={PURPLE} alt="Purple" className=' w-[60%]' />
                        </div>
                        
                        <div className=' flex justify-center items-center flex-col'>
                            <div className=' w-[70%]'>

                            
                            <div>
                                <h1 className=' font-bold text-center sm:text-left text-xl py-5'> Artisan Extraordinaire (Purple Flower Badge)</h1>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>How to Earn:</h1>
                                <p className=' pl-2 text-sm'>
                                • Showcase artistic and unique creations that reflect your distinct style. <br />
                                • Demonstrate creativity and innovation in your product offerings. <br />
                                • Consistently introduce new and captivating designs.<br />
                                </p>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>Benefits:</h1>
                                <p className=' pl-2 text-sm'>
                                • Stand out with unique products that capture the attention of art-loving customers.<br />
                                • Featured placement in our exclusive artisan spotlight promotions.<br />
                                • Access to special events and collaborations.<br />
                                </p>
                            </div>
                            </div>
                        </div>
                        

                    </div>
                    
                </div>
                
                {/* badge 2 end */}
                

                {/* badge 3 start */}
                <div className=' py-10'>
                    <div className=' grid grid-cols-1 sm:grid-cols-2 gap-3'>
                        <div className=' flex justify-center items-center'>
                            <img src={BLUE} alt="Blue" className=' w-[60%]' />
                        </div>
                        <div className=' flex justify-center items-center flex-col'>
                            <div className=' w-[70%]'>

                            
                            <div>
                                <h1 className=' font-bold text-xl py-5'> Customer Champion (Clear Blue Badge)</h1>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>How to Earn:</h1>
                                <p className=' pl-2 text-sm'>
                                • Provide excellent customer service, responding promptly to inquiries and concerns.<br />
                                • Garner positive feedback from satisfied customers.<br></br>
                                • Ensure overall customer satisfaction through personalized interactions.<br />
                                </p>
                            </div>
                            <div>
                                <h1 className=' font-bold text-md'>Benefits:</h1>
                                <p className=' pl-2 text-sm'>
                                • Build a loyal customer base with positive reviews and recommendations.<br />
                                • Enhanced visibility through the "Customer Favorites" section.<br />
                                • Participation in customer appreciation campaigns.<br />
                                </p>
                            </div>
                            </div>
                        </div>
                        

                    </div>
                    
                </div>
                {/* badge 3 end */}
            </div>



            <div className='flex flex-col justify-center items-center'>
                <div>
                <p className='text-center py-5 px-5'>
                        Enjoy the benefits of increased visibility, customer trust, and additional promotional opportunities.
                    </p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                <div className='text-justify flex flex-col justify-center items-center'>
                    <h1 className='font-bold text-xl px-4 py-5 text-center'>How It Works:</h1>
                    <p className='text-justify px-5 lg:px-20 text-sm w-[70%]'>
                    • Fulfill the specific criteria for each badge as outlined above.<br />
                    • Ekaiv reviews and awards badges based on your performance.<br />
                    • Display your badges proudly on your catalogue page to attract more customers.<br />
                    </p>
                   

                </div>
                <div>
                    <img src={QUESTION}
                    alt="question" className=' w-[80%]' />
                </div>


                </div>
                <div className=' flex flex-col justify-center items-center'>
                    
                    <p className='text-center py-16 px-10'>
                        Join the league of outstanding vendors, and let your badges tell your success story! <br/> Earning badges is a rewarding journey, and we're here to celebrate every milestone with you.
                    </p>
                </div>
                


            </div>

        </div>
    )
}

export default Bages
