import React, { useEffect, useState } from 'react';
import CaAssitance from '../component/Assitance/CaAssitance';
import Registrations from '../component/Assitance/Registrations';
import BusinessConsultants from '../component/Assitance/BusinessConsultants';
import DesignsNPhotography from '../component/Assitance/DesignsNPhotography';
import SAWdev from '../component/Assitance/SAWdev';
import { Helmet } from 'react-helmet';
import DmGd from '../component/Assitance/DmGd';
import Legal from '../component/Assitance/Legal';
import CommunityEngagement from '../component/Assitance/CommunityEngagement';
import Learning from '../component/Assitance/Learning';
import constant from '../constant';
import Loader from '../component/common/Loader'
// import '../component/common/AssistantButton.css'


const ROBOT=constant.IMAGES_IMAGES.ASSISTANCE.ROBOT;

const scrollToTarget = (targetId) => {
  const element = document.getElementById(targetId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const AssistancePage = () => {
  // State to track which component is currently open
  const [activeComponent, setActiveComponent] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const services = [
    { name: 'CaAssitance', text: 'CA Assistance' },
    { name: 'SAWdev', text: 'Web/App/Software Development' },
    { name: 'Registrations', text: 'Business Registrations' },
    { name: 'BusinessConsultants', text: 'Business Consultation' },
    { name: 'DesignsNPhotography', text: 'Photography' },
    { name: 'DmGd', text: 'Graphic Designing' },
    { name: 'Legal', text: 'Legal Advisory' },
    { name: 'CommunityEngagement', text: 'Community & Welfare' },
    { name: 'Learning', text: 'Training & Development' },
  ];

  // Function to toggle the visibility of a component
  const toggleComponent = (componentName) => {
    if (activeComponent === componentName) {
      
      setActiveComponent(null);
    } else {
      setActiveComponent(componentName);
    } 
  };
 

  // scroll behavior
  useEffect(() => {
    if (activeComponent) {
      
        scrollToTarget(`${activeComponent}`);
    }
}, [activeComponent]);



  return (
    <div className=" mt-5 max-w-[1300px] mx-auto flex flex-col">

      <Helmet><title>Assistance</title></Helmet>

      <div className='w-full grid grid-cols-1 md:grid-cols-2 px-5 sm:px-10'>
  <div className='flex flex-col justify-center items-center'>
    <h1 className="text-3xl font-bold mb-4 px-1 sm:px-5 md:px-20">
      Assistance
    </h1>
    <p className='px-1 sm:px-1 md:px-5 lg:px-20'>
      At The Ekaiv, we believe in empowering our vendors not just as sellers but as thriving entrepreneurs. Our Assistance Page is designed to provide a helping hand, fostering growth and success for our valued vendors. Whether you're a seasoned business owner or just starting, our range of support services ensures you have the guidance and expertise needed to navigate various aspects of your business journey.
    </p>
  </div>
  <div className='w-full h-full flex items-center justify-center'>
    <img
      src={ROBOT}
      alt="himage"
      className='h-full object-cover'
      onLoad={() => setImageLoaded(true)}
    />
    {!imageLoaded && (
      <div className='flex items-center justify-center md:h[710px] sm:h-[410px] h-[200px]'>
        <Loader />
      </div>
    )}
  </div>
</div>





     <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 mt-20'>
     {services.map(({ name, text }) => (
        <div key={name} className='flex justify-center items-center'>
          <div className={`mb-4 shadow-lg flex justify-center items-center rounded-xl h-16 w-[95%] lg:w-2/3`}>
            <div
              className={`cursor-pointer px-5 rounded-xl text-purple-800 hover:bg-purple-500 hover:text-white w-full flex justify-center items-center text-center h-full border-2 border-purple-800 text-base lg:text-xl font-semibold
                ${activeComponent === name ? 'text-violet-500' : 'text-gray-700'
              }`}
              onClick={() => toggleComponent(name)}
            >
              {text || name}
            </div>
          </div>
        </div>
      ))}
    </div>

      {/* Line Below Buttons */}
      <div className='w-full  my-4'></div>
      {/* Conditional Rendering of Cards */}
      {activeComponent && (
        <div className='w-full'>
          {/* Render Card based on the active component */}
          <div id="CaAssitance"> 
            {activeComponent === 'CaAssitance' && <CaAssitance />}
        </div>
        <div id='SAWdev'>
        {activeComponent === 'SAWdev' && <SAWdev />}
        </div>
         <div id='Registrations'>
         {activeComponent === 'Registrations' && <Registrations />}
         </div>
         <div id='BusinessConsultants'>
         {activeComponent === 'BusinessConsultants' && <BusinessConsultants id="abc" />}
         </div>
         <div id='DesignsNPhotography'>
         {activeComponent === 'DesignsNPhotography' && <DesignsNPhotography />}
         </div>
         <div id='DmGd'>
         {activeComponent === 'DmGd' && <DmGd />}
         </div>
         <div id='Legal'>
         {activeComponent === 'Legal' && <Legal />}
         </div>
         <div id='CommunityEngagement'>
         {activeComponent === 'CommunityEngagement' && <CommunityEngagement />}
         </div>
         <div id='Learning'>
         {activeComponent === 'Learning' && <Learning />}
         </div>
              
        </div>
      )}
    </div>
  );
};

export default AssistancePage;
