import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { API_URL, cofnfig } from '../../../config/config'
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
const noOrdersMessage = (
    <div className="text-center text-gray-500 mt-10">
      No new orders available.
    </div>
  );

function AllDeliveredOrders() {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [vendor, setVendor] = useState([])
   

    useEffect(() => {
        orderData();
    }, []);

    useEffect(() =>{
        vendorData();
    }, []);

    const orderData = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/order/getAllOrders`);
            setOrders(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const vendorData = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/vendor/vendor/getAllVendors`);
            setVendor(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };

    const getVendorName = (vendorId) => {
        // Ensure vendorIds is an array
        if (!Array.isArray(vendorId)) {
            return [];
        }
    
        const uniqueIds = Array.from(new Set(vendorId));
        // Map each vendorId to its respective name
        const names = uniqueIds.map((vendorId) => {
            const findVendor = vendor.find((vendor) => vendor._id === vendorId);
            return findVendor ? findVendor.BussinessDetails?.Businessname  : 'null';
        });
    

        return names;
    };
    const getVendorAddress = (vendorId) => {
      // Ensure vendorIds is an array
      if (!Array.isArray(vendorId)) {
          return [];
      }
     
      const uniqueIds = Array.from(new Set(vendorId));
      // Map each vendorId to its respective name
      const address = uniqueIds.map((vendorId) => {
          const findVendor = vendor.find((vendor) => vendor._id === vendorId);
          return findVendor ? findVendor.BussinessDetails?.BusinessAddress  : 'null';
      });


      return address;
  };
   

    const deliveredOrders = orders.filter((item) => item.orderStatus === 'Completed');

    

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-IN', options);
        return formattedDate;
      };
  return (
    <div>
{loading ? (
          <div className='flex justify-center'><Loader /></div>
        ) : deliveredOrders.length === 0 ? (
          noOrdersMessage
        ) : (
          <table className="w-full table-auto border-collapse border border-gray-100 ">
            <thead>
              <tr>
                <th className="bg-gray-200 font-bold px-4 py-2">Customer</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Order Details</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Business Details</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Order Status</th>
                <th className="bg-gray-200 font-bold px-4 py-2">Date/Time</th>
                
              </tr>
            </thead>
            <tbody>
              {deliveredOrders?.map((item, index) => {
                // Filter productDetails for the logged-in vendor and create a set of unique product IDs
                const uniqueProductIds = new Set(
                  item.productsDetails
                    .map((product) => product.productId)
                );
                const vendorId = item.productsDetails.map(product => product.vendorId);
                const uniqueVendorIds = new Set(vendorId);
                uniqueVendorIds.forEach(vendorId => {
                    const vendorName = getVendorName(vendorId);
                    const vendorAddress = getVendorAddress(vendorId);
                  });
                // Get the vendor name based on the vendorId
                const vendorName = getVendorName(vendorId);
                const vendorAddress = getVendorAddress(vendorId);

                return Array.from(uniqueProductIds).map((productId, productIndex) => {
                  // Find the product details for the current product ID
                  const productDetail = item.productsDetails.find((product) => product.productId === productId);

                  if (productDetail) {

                    // Access the first image for the current productDetail
                    const image = productDetail.additionalInfo?.product?.images?.[0];

                    return (
                      <React.Fragment key={`${index}-${productIndex}`}>
                        <tr className='border-b border-gray-400'>
                          <td className='px-4 py-2'>
                          <div>
                                <div>
                                {item?.shippingAddress?.fullname}
                                </div>
                                <div>
                                {item?.shippingAddress?.phoneNumber} 
                                </div>
                                <div>
                                <p><span className=' text-[#8A58DC]'>Flat:</span>{item?.shippingAddress?.flat}</p> 
                                <p><span className=' text-[#8A58DC]'>Area:</span>{item?.shippingAddress?.area}</p>
                                <p><span className=' text-[#8A58DC]'>Landmark:</span> {item?.shippingAddress?.landmark}</p>
                                </div>
                                <div>
                                {item?.shippingAddress?.city},{item?.shippingAddress?.state},{item?.shippingAddress?.pinCode}
                                </div>
                            </div>
                          </td>
                          <td className='px-4 py-2'>
                            <div className='flex flex-col lg:flex-row gap-2 items-center'>
                              <div key={productIndex}>
                                <Link
                                  to={{
                                    pathname: `/SpecificProductsPage/${productDetail.additionalInfo?.product?._id}`,
                                    state: { productId: productDetail.additionalInfo?.product?._id }
                                  }}
                                  target='_blank'
                                  
                                >
                                  {image && (
                                    <img src={image} alt="Product" className='w-16 h-16 md:w-24 md:h-24 object-fill' />
                                  )}
                                </Link>
                                <div className='flex flex-col'>
                                <div>
                                    <span className='text-[#8A58DC] '>Product Id: </span>{productDetail.productId}
                                    </div>
                                  <div>
                                  <span className='text-[#8A58DC] '>Quantity:</span> {productDetail.quantity}
                                  </div>
                                  <div>
                                  <span className='text-[#8A58DC] '>Price:</span> {productDetail.price * productDetail.quantity}
                                  </div>
                                  <div>
                                  {/* {productDetail.customization.isCustomized !== "false" &&
                                  (<> 
                                  <div> <span className='text-[#8A58DC] '>Customization:</span> {item?.productDetail?.customization?.isCustomized}</div> 
                                  <div> <span className='text-[#8A58DC] '>Customization Details:</span> {item?.productDetail?.customization?.customizationDetails}</div>
                                  </>)} */}
                                   
                                  </div>

                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                          <div>
                                <div>
                                {vendorName}
                                </div>
                                <div>
                                {vendorAddress.map((item, index) => (
                                    <div key={index}>
                                    <p> <span className='text-[#8A58DC] '>Address 1:</span> {item.addressLine1}</p>
                                    <p>  <span className='text-[#8A58DC] '>Address 2:</span> {item.addressLine2}</p>
                                    <p>  <span className='text-[#8A58DC] '>City:</span> {item.city}</p>
                                    <p> <span className='text-[#8A58DC] '>State :</span> {item.state}</p>
                                    </div>
                                ))}
                                </div>
                             

                            </div>
                          </td>
                          <td className='px-4 py-2'>
                            <div>
                              <button className='bg-orange-100 w-28 h-10 rounded-2xl text-orange-600'>{item.orderStatus}</button>
                            </div>
                          </td>
                          <td className='px-4 py-2'>{formatDate(item.createdAt)}</td>
                          
                        </tr>
                      </React.Fragment>
                    );
                  }

                  return null;
                });
              })}
            </tbody>
          </table>
        )}
      
    </div>
  )
}

export default AllDeliveredOrders
