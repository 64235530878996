import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  setRating,
  getProductsByRating,
} from "../../Redux/features/product/productsSlice";
import { IoIosArrowDown } from "react-icons/io";
import ProductCard from "../../component/ProductCard/ProductCard";
import "rc-slider/assets/index.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Helmet } from "react-helmet";
import Loader from "../../component/common/Loader";
import { IoIosClose } from "react-icons/io";
import { API_URL } from "../../config/config";
import { Search } from "react-feather";


const Products = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategoryFromURL = queryParams.get('category');
  const [searchTerm, setSearchTerm] = useState('');
  const [relatedProducts, setRelatedProducts] = useState([]);

  ///////////////search bar//////////////////
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTermFromUrl = queryParams.get('searchTerm');
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
    if (searchTerm === null) {
      setSearchTerm('');
    }
  }, [location]);

  ////////////////////////////////////////////
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".footer-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  const [categoryExpanded, setCategoryExpanded] = useState(false);
  const [sellerExpanded, setSellerExpanded] = useState(false);
  const [showKidsSubcategories, setShowKidsSubcategories] = useState(false);
  const [selectedSellers, setSelectedSellers] = useState('');

  const [priceRangeExpanded, setPriceRangeExpanded] = useState(false);
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedMinPriceRange, setSelectedMinPriceRange] = useState("")
  const [selectedMaxPriceRange, setSelectedMaxPriceRange] = useState("")

  const [currentPage, setCurrentPage] = useState(1);
  const [ratingExpanded, setRatingExpanded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredPages, setFilteredPages] = useState(1);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(1);
  const [showAllSellers, setShowAllSellers] = useState(false);
  const [showAllSellersBox, setShowAllSellersBox] = useState(false);
  const [allSellers, setAllSellers] = useState([]);

  const [loading, setLoading] = useState(true);

  const productsPerPage = 20;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const defaultPriceRange = [0, 10000];

  const [selectedRating, setSelectedRating] = useState('');
  // const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedCategory, setSelectedCategory] = useState('');

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const togglePriceRangeExpansion = () => {

    if (window.innerWidth < 640) {
      if (priceRangeExpanded) {
        setPriceRangeExpanded(false);
      } else {
        setPriceRangeExpanded(true);
        setRatingExpanded(false);
        setCategoryExpanded(false);
        setSellerExpanded(false);
      }
    } else {
      setPriceRangeExpanded(!priceRangeExpanded);
    }
  };


  const toggleRatingExpansion = () => {

    if (window.innerWidth < 640) {
      if (ratingExpanded) {
        setRatingExpanded(false);
      } else {
        setRatingExpanded(true);
        setPriceRangeExpanded(false);
        setCategoryExpanded(false);
        setSellerExpanded(false);
      }
    } else {
      setRatingExpanded(!ratingExpanded);
    }
  };

  const toggleCategoryExpansion = () => {

    if (window.innerWidth < 640) {
      if (categoryExpanded) {
        setCategoryExpanded(false);
      } else {
        setCategoryExpanded(true);
        setPriceRangeExpanded(false);
        setRatingExpanded(false);
        setSellerExpanded(false);
      }
    } else {
      setCategoryExpanded(!categoryExpanded)
    }
  };

  const toggleSellerExpansion = () => {

    if (window.innerWidth < 640) {
      if (sellerExpanded) {
        setSellerExpanded(false);
      } else {
        setSellerExpanded(true);
        setPriceRangeExpanded(false);
        setRatingExpanded(false);
        setCategoryExpanded(false);
      }
    } else {
      setSellerExpanded(!sellerExpanded)
    }
  };
  const toggleShowAllSellers = () => {
    setShowAllSellers(!showAllSellers);
  };
  const toggleShowAllSellersBox = () => {
    setShowAllSellersBox(!showAllSellersBox);
  };

  const toggleKidsSubcategories = () => {
    setShowKidsSubcategories(!showKidsSubcategories);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedCategory(subcategory);
    setCategoryExpanded(false);
  };

  const [vendorProducts, setVendorProducts] = useState([]);

  const getAllVendorProducts = async (vendorId) => {
    try {

      // Make a GET request to the API endpoint with the vendorId parameter
      const response = await fetch(
        `${API_URL}/api/vendor/${vendorId}/verifiedproducts`,
      );

      // Check if the response status is OK (200)
      if (!response.ok) {
        // If the response status is not OK, throw an error
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      // Parse the JSON data from the response
      const data = await response.json();

      // Return the data
      return data;
    } catch (error) {
      // Handle errors
      console.error("Error fetching vendor products:", error);
      throw error; // You may want to handle errors in a more sophisticated way
    }
  };

  const resetCategories = () => {
    setSelectedCategory("");
    setShowKidsSubcategories(false);

    // Dispatch the action to get all products without specifying a selected category
    dispatch(getAllProducts({ selectedCategory: "" })).then((res) => {

      // After the dispatch is completed, use navigate to redirect to the product page
      navigate("/products/");
    });
  };

  const [categoriesData, setCategoriesData] = useState([]);

  const [showVendorProducts, setShowVendorProducts] = useState(false);

  // feched api for the category filter/////////////////////////////

  const getAllListApiCall = () => {
    // Dispatch the action to get all products without specifying a selected category
    dispatch(getAllProducts({ selectedCategory: "" })).then((res) => {

      setFilteredProducts(res?.payload?.products)

      // After the dispatch is completed, use navigate to redirect to the product page
      navigate("/products/");
    });
  }

  ///////////////////navbar category select/////////////////
  const [urlSelectedCategory, setUrlSelectedCategory] = useState(selectedCategoryFromURL)
  useEffect(() => {
    if (selectedCategoryFromURL) {
      setUrlSelectedCategory(selectedCategoryFromURL);
     
    } if (urlSelectedCategory === null) {
      setUrlSelectedCategory('')
    }
  }, [selectedCategoryFromURL]);


////////////////search product , filtter api call /////////////////////
  useEffect(() => {

    if (searchTerm === null) {
      return;
    }

    if (urlSelectedCategory === null) {
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/api/products/searchproducts?&category=${selectedCategory || urlSelectedCategory}&maxPrice=${selectedMaxPriceRange}&ratings=${selectedRating}&minPrice=${selectedMinPriceRange}&vendorId=${(selectedSellers[0]?.id) || ''}&searchTerm=${searchTerm}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          category: selectedCategory && selectedCategory,
          maxPrice: selectedPriceRange && selectedPriceRange,

        }),
      })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        setFilteredProducts(data?.data)

      })
      .catch((error) => {
        // Handle errors
        setLoading(false)
        console.error("Error fetching data:", error);

      })
      .finally(() => {
        setLoading(false)
      })
  }, [urlSelectedCategory, selectedCategory, selectedMaxPriceRange, selectedMinPriceRange, selectedRating, selectedSellers, searchTerm])



  // useEffect(() => {
  //   getAllListApiCall()
  // }, [])

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    // Add any additional logic you have for filtering products
  };

  const handleCategorySelect = (subcategory) => {


    if (subcategory === selectedCategory) {
      setSelectedCategory("");
    } else {
      if (subcategory) {
        setSelectedCategory(subcategory);
      } else {
        setSelectedCategory('')
      }

    }
  };


  ////////////////fetching all categories/////////////////////////
  useEffect(() => {
    // Replace the API URL with your actual endpoint
    fetch(`${API_URL}/api/admin-profile/getCategories`)
      .then((response) => response.json())
      .then((data) => {

        setCategoriesData(data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching categories:", error);
      });
  }, []);


  ////////////////////Get all Vendor //////////////////////////////
  const [sellerName, setSellerName] = useState("");
  useEffect(() => {
    const fetchAllSellers = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/vendor/vendor/getAllVendors`,
        );
        const data = await response.json();

        setSellerName(data[0]?.BussinessDetails?.Businessname);
        setAllSellers(data);
      } catch (error) {
        console.error("Error fetching sellers:", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchAllSellers();
  }, []);
  const [selectedVendorName, setSelectedVendorName] = useState()






  /////////// selected vendor //////////////////
  const handleSellerSelect = async (selectedSeller) => {
    try {
      if (!selectedSeller || !selectedSeller._id) {
        console.error("Invalid selectedSeller object:", selectedSeller);
        return;
      }

      const selectedSellerId = {
        id: selectedSeller._id,
        name: selectedSeller.BussinessDetails.Businessname
      }


      // Fetch vendor products for the selected seller
      const products = await getAllVendorProducts(selectedSellerId.id);

      // Set the products and show the vendor products
      setVendorProducts(products);
      setShowVendorProducts(true);

      // Update the selected seller and close the seller expansion
      setSelectedSellers([selectedSellerId]);
      setSellerExpanded(false);
    } catch (error) {
      console.error("Error handling seller selection:", error);
      // Handle error state if necessary
    }
  };




  let count = 0;
  useEffect(() => {
    // When the page is loaded or currentPage changes, reset filters and navigate to the initial state
    if (location.pathname !== "/products") {
      resetFiltersAndNavigate();
    }
  }, [location.pathname]);

  const resetFiltersAndNavigate = () => {
    setSelectedCategory("");

    setSelectedPriceRange("");
    setSelectedMaxPriceRange("");
    setSelectedMinPriceRange("");
    setVendorProducts("");
    dispatch(setRating("")); // Reset the rating filter

    // Only navigate to another page if not already on the product page
    if (location.pathname !== "/products") {
      navigate("/products/");
    }
  };

  useEffect(() => {
    if (products.products) {
      const totalProducts = products.products.length;

      // Calculate the total number of pages based on products and productsPerPage
      const calculatedTotalPages = Math.ceil(totalProducts / productsPerPage);

      // Update the total pages state
      setTotalPages(calculatedTotalPages);
    }
  }, [products, productsPerPage]);


  //remove
  const handlePriceChange = async (range) => {

    if (range === "reset") {
      setSelectedPriceRange('');
      setSelectedMaxPriceRange('');
      setSelectedMinPriceRange('');
      setFilteredProducts([]);
      setFilteredPages(1);
      setFilteredCurrentPage(1);
    } else {
      setSelectedPriceRange(range);


      // const filteredProducts = products.products.filter((product) => {
      //   return product.originalPrice <= range;
      // });
      // Set the filtered products based on the price range

    }


  };

  const handleFilteredPageChange = (event, newPage) => {
    setFilteredCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  //remove
  const [filteredProductsByRating, setFilteredProductsByRating] = useState([]);

  const handleRatingSelect = (rating) => {
    let filteredProducts = [];


    if (rating === "reset") {
      // If 'reset' is selected, reset the rating filter
      setSelectedRating('');
      setFilteredProducts([]);
      setFilteredPages(1);
      setFilteredCurrentPage(1);
    } else {
      setSelectedRating(rating);
      // Filter products based on the selected rating


      // Set the filtered products based on the rating
      setFilteredProducts(filteredProducts);
      const calculatedTotalPages = Math.ceil(
        filteredProducts.length / productsPerPage
      );
      setFilteredPages(calculatedTotalPages);

      // Update the separate array for filtered products by rating



      setFilteredProducts(filteredProducts);
    }

    // Set the selected rating

    setRatingExpanded(false);


  };



  return (
    <>
      <div className="p-4 bg-[#f7fafc] ">
        <Helmet>
          <title>Product</title>
        </Helmet>
        <div className="flex  flex-col sm:flex-row gap-5">
          {/* left fiter */}

          <div className="w-full  relative max-[488px]:gap-1 gap-5 sm:gap-0 justify-center sm:justify-normal items-center sm:items-stretch 
          flex flex-row sm:flex-col md:w-1/4  sm:w-1/3 lg:w-1/5 p-0 min-[292px]:p-2 sm:p-6 cursor-pointer min-[292px]:shadow-lg
          ">
            {/* category dropdown */}
            <div>
              <div
                className={`flex py-2 px-1 sm:p-3 bg-[#995ABA] items-center ${categoryExpanded ? "rounded-t-lg" : "rounded-lg"
                  }`}
                onClick={toggleCategoryExpansion}
              >
                <h1 className="font-[300] text-xs sm:text-base text-white sm:flex-grow">
                  Category
                </h1>
                <IoIosArrowDown
                  className={`transform text-xs sm:text-base text-white ${categoryExpanded
                    ? "rotate-clockwise rotate"
                    : "rotate-counterclockwise rotate"
                    }`}
                />
              </div>
              {categoryExpanded && (
                <ul className="absolute   left-0 sm:-left-0 w-full h-[170px] sm:w-full sm:relative mt-[10px] sm:mt-0 p-3 rounded-b-lg bg-purple-100">
                  <li
                    className={`cursor-pointer mt-1 bg-purple-100 text-start p-2 hover:bg-[#995ABA] hover:text-white ${selectedCategory === "" ? "font-bold" : ""

                      }`}

                    onClick={() => {
                      handleCategorySelect(""); // Set the selected category to an empty string
                      toggleCategoryExpansion();
                    }}
                  >
                    Select None
                  </li>

                  {categoriesData?.map((category) => {
                    const formattedName = category.name
                      .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()) // Convert kebab case to camel case
                      .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
                      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter

                    return (
                      <li
                        key={category._id}
                        className={`cursor-pointer bg-purple-100 hover:bg-[#995ABA] hover:text-white text-start pl-2 ${selectedCategory === formattedName ? "font-bold" : ""}`}
                        onClick={() => {
                          handleCategorySelect(category.name, formattedName);
                          toggleCategoryExpansion();
                        }}
                      >
                        {formattedName}
                      </li>
                    );
                  })}
                </ul>
              )}

              {selectedCategory && (
                <div className=" hidden sm:block ml-2 mt-2">
                  {selectedCategory === ""
                    ? "All Products"
                    : selectedCategory
                      .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()) // Convert kebab case to camel case
                      .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
                      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
                      .replace("Deocr", "Decor") // Replace "Deocr" with "Decor"
                  }
                </div>
              )}
            </div>


            <div className="hidden sm:block w-full p-4">
              <hr className=" border-gray-500" />
            </div>

            {/* sellers dropdown */}
            <div>
              <div
                className={`flex py-[6px] px-1 sm:p-3  sm:mt-2 bg-[#995ABA]  items-center ${sellerExpanded ? "rounded-t-lg" : "rounded-lg"
                  }`}
                onClick={toggleSellerExpansion}
              >
                <h1 className="font-[300] text-sm sm:text-base text-white sm:flex-grow">
                  Sellers
                </h1>
                <IoIosArrowDown
                  className={`transform text-xs sm:text-base text-white  ${sellerExpanded
                    ? "rotate-clockwise rotate"
                    : "rotate-counterclockwise rotate"
                    }`}
                />
              </div>
              {sellerExpanded ? (

                <div className=" absolute    left-0 sm:-left-0 w-full h-[170px] sm:w-full sm:relative mt-[10px] sm:mt-0 p-3 rounded-b-lg bg-purple-100">
                  <div
                    className={`flex  items-center ${selectedSellers.length === 0 ? "font-bold" : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id="SelectNone"
                      checked={selectedSellers.length === 0}
                      onChange={() => {
                        if (selectedSellers.length > 0) {
                          setSelectedSellers([]); // Unselect all sellers when "Select None" is checked
                        }
                        toggleSellerExpansion(); // Close the expanded part
                      }}
                    />
                    <label
                      htmlFor="SelectNone"
                      className="ml-2 hover:bg-purple-200"
                    >
                      Select None
                    </label>
                  </div>

                  {allSellers.slice(0, 5).map((seller, index) => (
                    <div
                      key={seller?.BussinessDetails?.VendorID}
                      className="flex items-center"
                    >
                      <input
                        type="radio"
                        className="form-checkbox h-5 w-5"
                        id={`seller-${index}`}
                        checked={selectedSellers.includes(
                          seller?.BussinessDetails?.VendorID
                        )}
                        onChange={() => handleSellerSelect(seller)}
                      />
                      <label
                        htmlFor={`seller-${index}`}
                        className="ml-2 hover:bg-purple-200"
                      >
                        {seller?.BussinessDetails?.Businessname}
                      </label>
                    </div>
                  ))}



                </div>
              ) : null}

              {selectedSellers.length > 0 && (
                <div className="hidden sm:block ml-2 mt-2">
                  {selectedSellers[0]?.name === "" ? "All Products" : selectedSellers[0]?.name}
                  {/* .map((seller) => seller.BussinessDetails?.Businessname)} */}
                </div>
              )}
            </div>

            <div className="hidden sm:block w-full p-4">
              <hr className=" border-gray-500" />
            </div>



            <div>

              <div
                className={`flex py-2 px-1 sm:p-3 sm:mt-2 bg-[#995ABA] items-center ${priceRangeExpanded ? "rounded-t-lg" : "rounded-lg"
                  }`}

                onClick={togglePriceRangeExpansion}
              >
                <h1 className="hidden sm:block font-[300] text-white flex-grow">
                  Price Range
                </h1>
                <h1 className="block sm:hidden text-xs sm:text-base font-[300] text-white ">
                  Price
                </h1>
                <IoIosArrowDown
                  className={`transform sm:text-base text-white ${priceRangeExpanded
                    ? "rotate-clockwise rotate"
                    : "rotate-counterclockwise rotate"
                    }`}
                />
              </div>
              {priceRangeExpanded ? (
                <div className="absolute left-0 sm:-left-0 w-full sm:w-full sm:relative  mt-[10px]  sm:mt-0 p-2 rounded-b-lg bg-purple-100">
                  <div className="flex items-end justify-center w-full">
                    <button
                      className="mb-2 text-sm bg-white rounded-md text-purple-500 p-2 hover:scale-105 hover:text-white w-full hover:bg-purple-500 shadow-sm"
                      onClick={() => handlePriceChange("reset")}
                    >
                      Reset Price Range
                    </button>
                  </div>
                  <label className="font-bold">Select Price Range</label>

                  <button
                    className="w-full text-start hover:bg-[rgb(153,90,186)] hover:text-white"
                    onClick={() => { handlePriceChange(); setSelectedMaxPriceRange(100); setSelectedMinPriceRange(0); }}
                  >
                    Under 100
                  </button>
                  <button
                    className="w-full text-start hover:bg-[#995ABA] hover:text-white"
                    onClick={() => { handlePriceChange(); setSelectedMaxPriceRange(500); setSelectedMinPriceRange(0); }}

                  >
                    Under 500
                  </button>
                  <button
                    className="w-full text-start hover:bg-[#995ABA] hover:text-white"
                    onClick={() => { handlePriceChange(); setSelectedMaxPriceRange(999); setSelectedMinPriceRange(0); }}
                  >
                    Under 999
                  </button>
                  <button
                    className="w-full text-start hover:bg-[#995ABA] hover:text-white"
                    onClick={() => { handlePriceChange(); setSelectedMaxPriceRange(); setSelectedMinPriceRange(1000); }}
                  >
                    Above 1000
                  </button>
                </div>
              ) : null}
              {selectedPriceRange && (
                <div className="hidden sm:block ml-2 mt-2">{selectedPriceRange.min} - {selectedPriceRange.max}</div>
              )}

              <div className="hidden sm:block w-full p-4">
                <hr className=" border-gray-500" />
              </div>
            </div>

            {/* Rating dropdown */}
            <div className="flex-none">
              <div
                className={`flex py-2 px-1 sm:p-3 bg-[#995ABA] items-center ${ratingExpanded ? "rounded-t-lg" : "rounded-lg"
                  }`}
                onClick={toggleRatingExpansion}
              >
                <h1 className="font-[300] text-xs sm:text-base text-white flex-grow">
                  Rating
                </h1>
                <IoIosArrowDown
                  className={`transform text-xs sm:text-base  text-white ${ratingExpanded
                    ? "rotate-clockwise rotate"
                    : "rotate-counterclockwise rotate"
                    }`}
                />
              </div>
              {ratingExpanded ? (
                <div className="absolute  left-0 sm:-left-0 w-full mt-[10px] sm:w-full sm:relative sm:mt-0 p-2 rounded-b-lg bg-purple-100">
                  <div className=" p-2 rounded-b-lg bg-purple-100">
                    <div className="sm:flex w-full  justify-center">
                      <button
                        className="mb-2 text-sm bg-white rounded-md text-purple-500 p-2 w-full sm:hover:scale-105 hover:text-white hover:bg-purple-500 shadow-sm"
                        onClick={() => handleRatingSelect("reset")}
                      >
                        Reset Ratings
                      </button>
                    </div>
                  </div>

                  <div
                    className={`hover:text-yellow-500 hover:scale-105 text-start ml-2 ${selectedRating === "1" ? "font-bold" : ""
                      }`}
                    onClick={() => handleRatingSelect("1")}
                  >
                    <span style={{ color: "gold" }}>
                      &#9733;&#9734;&#9734;&#9734;&#9734;
                    </span>{" "}
                    (1 Star)
                  </div>
                  <div
                    className={`hover:text-yellow-500 hover:scale-105 text-start ml-2 ${selectedRating === "2" ? "font-bold" : ""
                      }`}
                    onClick={() => handleRatingSelect("2")}
                  >
                    <span style={{ color: "gold" }}>
                      &#9733;&#9733;&#9734;&#9734;&#9734;
                    </span>{" "}
                    (2 Stars)
                  </div>
                  <div
                    className={` hover:text-yellow-500 hover:scale-105 text-start ml-2 ${selectedRating === "3" ? "font-bold" : ""
                      }`}
                    onClick={() => handleRatingSelect("3")}
                  >
                    <span style={{ color: "gold" }}>
                      &#9733;&#9733;&#9733;&#9734;&#9734;
                    </span>{" "}
                    (3 Stars)
                  </div>
                  <div
                    className={`hover:text-yellow-500 hover:scale-105 text-start ml-2 ${selectedRating === "4" ? "font-bold" : ""
                      }`}
                    onClick={() => handleRatingSelect("4")}
                  >
                    <span style={{ color: "gold" }}>
                      &#9733;&#9733;&#9733;&#9733;&#9734;
                    </span>{" "}
                    (4 Stars)
                  </div>
                  <div
                    className={`hover:text-yellow-500 hover:scale-105 text-start ml-2 ${selectedRating === "5" ? "font-bold" : ""
                      }`}
                    onClick={() => handleRatingSelect("5")}
                  >
                    <span style={{ color: "gold" }}>
                      &#9733;&#9733;&#9733;&#9733;&#9733;
                    </span>{" "}
                    (5 Stars)
                  </div>
                </div>
              ) : null}
              {selectedRating && (
                <div className=" hidden sm:block p-2" style={{ color: "gold" }}>
                  Selected Rating:
                  {selectedRating === "1" ? "★" : ""}
                  {selectedRating === "2" ? "★ ★" : ""}
                  {selectedRating === "3" ? "★ ★ ★" : ""}
                  {selectedRating === "4" ? "★ ★ ★ ★" : ""}
                  {selectedRating === "5" ? "★ ★ ★ ★ ★" : ""}
                </div>
              )}
            </div>
          </div>

          {/* right side products */}
          <div className="scrollTop flex flex-grow flex-col min-w-0 ">
            <div className="flex justify-center items-center  min-w-0">
              {console.log(loading, filteredProducts)}

            {loading && <Loader  className='w-full h-full flex justify-center items-center' />}
{/* {console.log(filteredProducts)} */}
{!loading && filteredProducts?.length > 0 ?
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pr-5 gap-8  justify-center h-full ">
    {filteredProducts?.map((product) => {
      return <ProductCard key={product._id} data={product} />
    })}
  </div>
  : (!loading && <div className=' text-slate-900 flex w-full h-full  justify-center items-center'>No product available</div>)
}

              {/* {loading ? (
                // Show loader while loading
                <Loader />
              ) : (filteredProducts && filteredProducts.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pr-5 gap-8">
                  {filteredProducts.map((product) => (
                    <ProductCard key={product._id} data={product} />
                  ))}
                </div>
              ) : (

                <div className="flex flex-col justify-center items-center gap-5 p-5">
                  <img className="flex h-[400px] w-[400px] justify-center items-center" src="https://img.freepik.com/free-vector/purchase-ban-online-store-website-error-cancel-buying-order-placing-inability-buy-limit-budget-line-online-buyer-cartoon-character-vector-isolated-concept-metaphor-illustration_335657-2844.jpg?w=740&t=st=1706773442~exp=1706774042~hmac=cae5e4c8d587a74073f45b1426728cc5b906d4a5bac1556287989173e39f389a" />


                  <p className="font-semibold text-black">
                    {selectedPriceRange || selectedRating ? "No matching products found" : "No products available"}
                  </p>
                </div>
              )
              )} */}
            </div>

            <div className="flex justify-center items-center ">
              {((selectedPriceRange && filteredProducts && filteredProducts.length > 0) ||
                (!selectedPriceRange && products.products && products.products.length > 0)) && (
                  <div className="flex items-center justify-center mt-4">
                    {selectedPriceRange && filteredProducts && filteredProducts.length > 0 ? (
                      <div></div>
                      // <Pagination count={filteredPages} page={filteredCurrentPage} onChange={handleFilteredPageChange} />
                    ) : (
                      // <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                      <div></div>
                    )}
                  </div>
                )}
            </div>
          </div>




        </div>
      </div>



    </>
  );
};

export default Products;
