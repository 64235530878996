import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      category: 'General FAQs',
      questions: [
        { question: 'How can I place an order?', answer: "To place an order on ‘The Ekaiv’,  start by creating an account with your name and email. Browse through the diverse range of products using keywords or categories and click on your desired items to view details and reviews. Add selected products to your cart, review your order, and proceed to checkout. Provide accurate shipping details and enter your payment information or choose an alternative method. Confirm your order details, and you'll receive an order confirmation via email. You can track your order's status on the website, and once delivered, consider leaving a review or feedback about the product and your shopping experience. In summary, the process involves account creation, product selection, cart review, secure checkout, order confirmation, tracking, and, finally, receiving and potentially reviewing your order. " },
        { question: 'What payment methods do you accept?', answer: 'At The Ekaiv, we offer the convenience of flexible payment options. You can make secure and hassle-free transactions using any of the following methods: Credit Cards, Debit Cards, UPI (Unified Payments Interface). We believe in providing a seamless shopping experience, and our diverse payment options ensure that you can choose the method that suits you best. If you have any specific questions or encounter any issues during the payment process, feel free to reach out to our dedicated customer support team for assistance. Your satisfaction is our priority!' },
        { question: 'Do you ship internationally?', answer: 'We appreciate your interest in our products/services. Currently, we do not offer international shipping. However, we have exciting plans. We will notify our users through email, WhatsApp, and app notifications when we expand our shipping options to include international destinations. Thank you for your understanding, and we look forward to serving you on a global scale soon.' },
        { question: 'What is The Ekaiv return/exchange policy?', answer: 'At The Ekaiv, we take pride in offering fresh and handmade products crafted by our talented vendors. Since each item is made to order, we currently do not have a return or exchange policy. We encourage our customers to carefully review the size chart provided for each product before making a selection. This ensures that you choose the right size and are delighted with your purchase. For any questions or concerns about sizing or product details, our customer support team is always here to assist you. Your satisfaction is our priority, and we appreciate your understanding of our unique approach to delivering fresh, artisanal creations.' },
        { question: 'How do I track my order?', answer: "Tracking your order with The Ekaiv is a breeze! Once your order is confirmed and dispatched, you will receive a shipping confirmation WhatsApp message containing a unique tracking link. Simply click on the provided link to check the real-time status and location of your package. For a seamless experience, you can also log in to your the Ekaiv account and navigate to the 'Order History' section, where you'll find detailed information about your order processing and in transit details. If you have any further questions or need assistance, our dedicated customer support team is here to help. Happy shopping! " },
        { question: 'Are the images accurate representations of the products?', answer: 'Yes, the images provided on The Ekaiv are accurate representations of the products available. Our platform takes pride in showcasing genuine and high-quality visuals of each item. However, if you opt for customization, slight variations may occur. Additionally, please note that minimal color differences might be observed due to variations in product photography. Rest assured, we are committed to delivering products that meet your expectations. If you have any specific concerns or questions, feel free to reach out to our customer support team for further assistance. Happy shopping!' },
        { question: 'Is my personal information secure?', answer: 'Absolutely! At The Ekaiv, we prioritize the security and confidentiality of your personal information. We adhere to stringent security measures to protect your data from loss, misuse, or unauthorized access. Our secure servers, combined with advanced technological security protocols, ensure that your sensitive information is safeguarded in accordance with industry standards. To learn more about how we handle your data, please refer to our Privacy Policy. If you have any specific concerns or queries, our customer support team is here to assist you. Your trust is our utmost priority.' },
      ],
    },
    {
      category: 'Clothing FAQs',
      questions: [
        { question: 'How do I find my size?', answer: "Ensuring the perfect fit is crucial! Refer to our comprehensive Size Chart provided for each product. This detailed guide will help you identify the right size based on your measurements. If you have any specific concerns or queries about sizing, our customer support team is here to assist you. We want you to feel confident and comfortable in every purchase, so don't hesitate to reach out for personalized assistance. Happy shopping!" },
        { question: 'Can I return or exchange clothing if it does not fit?', answer: "Unfortunately, we don't offer returns or exchanges for sizing issues as our vendors create fresh products for each order. To ensure the perfect fit, we recommend carefully checking the Size Chart provided with each product. If you have specific concerns about sizing, feel free to send the message in the customization box. We're here to help you find the right fit and make your shopping experience delightful. Thank you for understanding our commitment to delivering freshly crafted products tailored to your preferences." },
        { question: 'What are the care instructions for the clothing items?', answer: 'If there are any specific care instructions for a clothing product, you can find them in the product details section. We provide detailed information to ensure your garments stay in excellent condition.' },

      ],
    },
    {
      category: 'Accessories FAQs',
      questions: [
        { question: 'Are the accessories hypoallergenic?', answer: 'We strive to provide information about the materials used in our accessories. If hypoallergenic properties are mentioned in the product details, you can trust that the accessory is designed with sensitivity in mind. Always check the product description for specific details.' },
        { question: 'Do you offer warranties on accessories?', answer: "Our accessories are crafted with precision and care. While we don't provide explicit warranties, your satisfaction is our priority. If you encounter any issues, contact our customer support, and we'll be happy to assist you. Check product details for specific care instructions to maintain the longevity of your accessories." },
        { question: 'Explain whether your accessories come with any warranties or guarantees against defects.', answer: "Our accessories are crafted with meticulous attention to detail and quality. While we don't offer explicit warranties or guarantees against defects, we take pride in the durability and craftsmanship of our products. If you encounter any issues or defects, please reach out to our customer support team. We are committed to ensuring your satisfaction and will work towards a resolution. Additionally, check the product details for specific care instructions to maintain the longevity of your accessories. Thank you for choosing The Ekaiv for your unique accessory needs. " },
      ],
    },
    {
      category: 'Homemade Food Items FAQs',
      questions: [
        { question: 'What is the shelf life of your homemade food products?', answer: 'The shelf life of our homemade food products varies depending on the specific item. Please refer to the individual product descriptions for accurate information on shelf life. We are committed to delivering fresh and high-quality homemade treats to your doorstep. If you have any concerns or questions about a particular product, feel free to reach out to our customer support team. Your satisfaction is our priority at The Ekaiv.' },
        { question: 'Do you accommodate special dietary requirements?', answer: 'Yes, we strive to accommodate special dietary requirements for our customers. Many of our homemade food products are available with various dietary options, such as gluten-free, vegan, or other specific preferences. Please check the product descriptions for details on dietary considerations and feel free to contact us if you have specific dietary needs. We aim to provide a diverse range of options to suit different preferences and ensure an enjoyable experience for all our customers at The Ekaiv.' },
        { question: 'How are the homemade food items packaged to ensure freshness during shipping?', answer: "To ensure the freshness of our homemade food items during shipping, we employ careful packaging methods. Each item is securely sealed and packaged using high-quality, food-grade materials that maintain the product's quality and prevent contamination. Our packaging is designed to withstand the shipping process and protect the contents from external factors. Additionally, we may use protective measures such as vacuum sealing, insulated packaging, or ice packs for specific perishable items. Our commitment is to deliver our homemade food products to you in the best possible condition, preserving their flavour and quality throughout the shipping journey." },
      ],
    },
    {
      category: 'Home Decor FAQs',
      questions: [
        { question: 'Do you provide installation instructions for your home decor items?', answer: "Yes, we strive to make your experience seamless by providing detailed installation instructions for our home decor items. Each product comes with a set of clear and easy-to-follow instructions to guide you through the installation process. Whether it's wall art, furniture, or other decor pieces, we want to ensure that you can effortlessly set up and enjoy your chosen items in your living space. If you ever have questions or need further assistance, our customer support team is ready to help. Your satisfaction with our products and your overall experience is our priority." },
        { question: 'Can I return home decor items if they do not match my interior? ', answer: "As much as we aim to provide a delightful shopping experience, we currently do not accept returns for home decor items based on a change of preference or if they don't match your interior. We understand the importance of choosing the right pieces for your space, and we encourage you to carefully consider your selections before making a purchase. We provide detailed product descriptions, images, and dimensions to help you make an informed decision. If you have any specific concerns about a product, feel free to reach out to our customer support team for assistance before completing your order. Your satisfaction is essential to us, and we're here to help you find the perfect additions to your home." },
        { question: 'Are the dimensions provided for home decor items accurate?', answer: "We strive to provide accurate dimensions for all our home decor items. However, as these items are handmade, slight variations may occur. To ensure the product fits your space perfectly, we recommend reviewing the provided dimensions and comparing them with your available space. If you have specific size requirements or concerns, feel free to reach out to our customer support team. We're here to assist you in making informed decisions and creating a harmonious environment in your home with our curated decor items. " },

      ],
    },
  ];

  const toggleQuestion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAnswerClick = (event) => {
    // Stop the click event propagation for the answer div
    event.stopPropagation();
  };

  return (
    <div className="flex w-full m-auto container flex-col mt-10 mb-10">
      <Helmet>
        <title>Faq</title>
      </Helmet>
      <div className="faq-content text-center space-y-10 px-6">
        {faqData.map((category, index) => (
          <div key={index}>
            <h2 className="text-2xl mt-5 mb-5 font-semibold">{category.category}</h2>
            <div className="m-3 space-y-6 md:w-2/3 lg:w-1/2 mx-auto">
              {category.questions.map((faq, faqIndex) => (
                <div key={faqIndex} className="text-center">
                  <div
                    className={`cursor-pointer p-4 bg-white rounded-lg shadow-md text-left relative ${activeIndex === index * 1000 + faqIndex ? 'rounded-b-none' : ''
                      }`}
                    onClick={() => toggleQuestion(index * 1000 + faqIndex)}
                  >
                    <div className="flex justify-between items-center">
                      <p>{faq.question}</p>
                      <span className="transform transition-transform">
                        {activeIndex === index * 1000 + faqIndex ? '-' : '+'}
                      </span>
                    </div>
                    {activeIndex === index * 1000 + faqIndex && (
                      <div
                        className={`mt-2 p-3 bg-gray-100 rounded-md`}
                        onClick={handleAnswerClick}
                      >
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqPage;