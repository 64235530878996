// import React, { useState, useEffect } from 'react';
// import Card from '@mui/material/Card';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
// import { BiSort, BiRupee } from 'react-icons/bi';
// import API_URL from '../../../config/config';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import { useTheme } from '@emotion/react';

// import {
// 	LineChart,
// 	ResponsiveContainer,
// 	Legend,
// 	Tooltip,
// 	Line,
// 	XAxis,
// 	YAxis,
// 	CartesianGrid,
// 	BarChart,
// 	Bar,
// 	RadialBarChart,
// 	RadialBar,
// } from 'recharts';



// const Report = () => {
// 	const [selectedMonthRange, setSelectedMonthRange] = useState('thisMonth');

// 	const [selectedMonthRangeBar, setSelectedMonthRangeBar] = useState('thisWeek');

// 	const currentDate = new Date();

// 	const profitData = [
// 		{
// 			week: 'week 1',
// 			sales: 500,
// 			production: 13000,
// 		},
// 		{
// 			week: 'week 2',
// 			sales: 1400,
// 			production: 14000,
// 		},
// 		{
// 			week: 'week 3',
// 			sales: 1550,
// 			production: 15000,
// 		},
// 		{
// 			week: 'week 4',
// 			sales: 2000,
// 			production: 15500,
// 		},
// 		{
// 			week: 'week 5',
// 			sales: 2550,
// 			production: 16000,
// 		},
// 		{
// 			week: 'week 6',
// 			sales: 2750,
// 			production: 16500,
// 		},
// 		{
// 			week: 'week 7',
// 			sales: 3000,
// 			production: 17000,
// 		},
// 		{
// 			week: 'week 8',
// 			sales: 3414,
// 			production: 17500,
// 		},
// 	];

// 	const productData = [
// 		{
// 			day: 'day 1',
// 			sales: 500,
// 			production: 1300,
// 		},
// 		{
// 			day: 'day 2',
// 			sales: 1400,
// 			production: 2000,
// 		},
// 		{
// 			day: 'day 3',
// 			sales: 1550,
// 			production: 8000,
// 		},
// 		{
// 			day: 'day 4',
// 			sales: 2000,
// 			production: 10000,
// 		},
// 		{
// 			day: 'day 5',
// 			sales: 2550,
// 			production: 11500,
// 		},
// 		{
// 			day: 'day 6',
// 			sales: 2750,
// 			production: 13752,
// 		},
// 		{
// 			day: 'day 7',
// 			sales: 3000,
// 			production: 15742,
// 		},
// 	];

// 	const customerActivityData = [
// 		{ dayOfWeek: 'Sunday', customerVisits: 80 },
// 		{ dayOfWeek: 'Monday', customerVisits: 20 },
// 		{ dayOfWeek: 'Tuesday', customerVisits: 30 },
// 		{ dayOfWeek: 'Wednesday', customerVisits: 25 },
// 		{ dayOfWeek: 'Thursday', customerVisits: 40 },
// 		{ dayOfWeek: 'Friday', customerVisits: 35 },
// 		{ dayOfWeek: 'Saturday', customerVisits: 15 },
// 	];

// 	useEffect(() => {
// 		calculateCustomerActivity();
// 	}, [selectedMonthRangeBar]);

// 	const calculateCustomerActivity = () => {
// 		// Get the current date

// 		// Create an array to represent the days of the week
// 		const daysOfWeek = [
// 			'Sunday',
// 			'Monday',
// 			'Tuesday',
// 			'Wednesday',
// 			'Thursday',
// 			'Friday',
// 			'Saturday',
// 		];

// 		// Calculate the start date based on the selected time range
// 		let startDate;
// 		switch (selectedMonthRange) {
// 			case 'thisWeek':
// 				startDate = new Date(currentDate);
// 				startDate.setDate(currentDate.getDate() - currentDate.getDay()); // Start of the week
// 				break;
// 			case 'thisMonth':
// 				startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
// 				break;
// 			case 'last3Months':
// 				startDate = new Date(currentDate);
// 				startDate.setMonth(currentDate.getMonth() - 2);
// 				startDate.setDate(1);
// 				break;
// 			case 'last6Months':
// 				startDate = new Date(currentDate);
// 				startDate.setMonth(currentDate.getMonth() - 5);
// 				startDate.setDate(1);
// 				break;
// 			default:
// 				startDate = new Date(currentDate);
// 				startDate.setDate(currentDate.getDate() - currentDate.getDay()); // Start of the week by default
// 		}

// 		// Reset customerActivityData
// 		customerActivityData.length = 0;

// 		// Generate data for each day
// 		for (let i = 0; i < 7; i++) {
// 			const date = new Date(startDate);
// 			date.setDate(startDate.getDate() + i);
// 			const dayOfWeek = daysOfWeek[i];

// 			// Replace this with logic to fetch customer visit data for the selected date
// 			const customerVisits = /* Fetch customer visit data for the date */ 0;

// 			customerActivityData.push({ dayOfWeek, customerVisits });
// 		}
// 	};


// 	const calculateProductSoldPercentage = () => {
// 		const currentDate = new Date();
// 		const lastMonthDate = new Date();
// 		lastMonthDate.setMonth(currentDate.getMonth() - 1);
// 		const last3MonthsDate = new Date();
// 		last3MonthsDate.setMonth(currentDate.getMonth() - 3);
// 		const last6MonthsDate = new Date();
// 		last6MonthsDate.setMonth(currentDate.getMonth() - 6);
// 		const lastYearDate = new Date();
// 		lastYearDate.setFullYear(currentDate.getFullYear() - 1);

// 		const filteredData = profitData.filter((dataPoint) => {
// 			const dataDate = new Date(dataPoint.month + ' 1, 2023'); // Assuming a common year (e.g., 2023)
// 			switch (selectedMonthRange) {
// 				case 'thisMonth':
// 					return dataDate.getMonth() === currentDate.getMonth();
// 				case 'last3Months':
// 					return dataDate >= last3MonthsDate && dataDate <= currentDate;
// 				case 'last6Months':
// 					return dataDate >= last6MonthsDate && dataDate <= currentDate;
// 				case 'lastYear':
// 					return dataDate >= lastYearDate && dataDate <= currentDate;
// 				default:
// 					return true; // Default to all data
// 			}
// 		});

// 		const totalSold = filteredData.reduce((acc, curr) => acc + curr.sales, 0);
// 		const totalForecast = filteredData.reduce((acc, curr) => acc + curr.forecast, 0);

// 		return (totalSold / totalForecast) * 50; // Half-filled circle
// 	};

// 	const calculateProductProducedPercentage = () => {
// 		const currentDate = new Date();
// 		const lastMonthDate = new Date();
// 		lastMonthDate.setMonth(currentDate.getMonth() - 1);
// 		const last3MonthsDate = new Date();
// 		last3MonthsDate.setMonth(currentDate.getMonth() - 3);
// 		const last6MonthsDate = new Date();
// 		last6MonthsDate.setMonth(currentDate.getMonth() - 6);
// 		const lastYearDate = new Date();
// 		lastYearDate.setFullYear(currentDate.getFullYear() - 1);

// 		const filteredData = productData.filter((dataPoint) => {
// 			const dataDate = new Date(dataPoint.month + ' 1, 2023'); // Assuming a common year (e.g., 2023)
// 			switch (selectedMonthRange) {
// 				case 'thisMonth':
// 					return dataDate.getMonth() === currentDate.getMonth();
// 				case 'last3Months':
// 					return dataDate >= last3MonthsDate && dataDate <= currentDate;
// 				case 'last6Months':
// 					return dataDate >= last6MonthsDate && dataDate <= currentDate;
// 				case 'lastYear':
// 					return dataDate >= lastYearDate && dataDate <= currentDate;
// 				default:
// 					return true; // Default to all data
// 			}
// 		});

// 		const totalProduced = filteredData.reduce((acc, curr) => acc + curr.production, 0);
// 		const totalForecast = filteredData.reduce((acc, curr) => acc + curr.forecast, 0);

// 		return (totalProduced / totalForecast) * 50; // Half-filled circle
// 	};


// 	// -----vendor sales-----
// 	const vendor = useSelector((state) => state.vendor);

// 	const vendorProductLength = vendor && vendor.vendor && vendor.vendor.products && vendor.vendor.products.length
// 	const [productSoldCount, setProductSoldCount] = useState(0);
// 	const [totalOrderCount, setTotalOrderCount] = useState(0);
// 	const [totalEarnings, setTotalEarnings] = useState(0);
// 	const [newOrdersCount, setNewOrdersCount] = useState(0);
// 	const [incompleteOrdersCount, setIncompleteOrdersCount] = useState(0);
// 	const [totalGstCharges , setTotalGstCharges] =  useState(0);
// 	const [serviceCharge, setServiceCharge] = useState(0);
// 	const [finalEarnings, setFinalEarnings] = useState(0);
// 	const [totalVisitors , setTotalVisitors] = useState(0);
// 	const [totalProductAdded , setTotalProductAdded] = useState(vendorProductLength);

// 	useEffect(() => {
// 		const fetchNewOrdersCount = async () => {
// 			try {
// 				const response = await axios.get(`${API_URL}/api/order/getAllOrders`);
// 				const orders = response.data;

// 				setTotalOrderCount(orders.length);

// 				// Calculate product sold count (assuming status 'Completed')
// 				const soldOrders = orders.filter(order => order.orderStatus === 'Completed');
// 				setProductSoldCount(soldOrders.length);

// 				// Calculate new orders count including 'Completed', 'InTransit', and 'InProcess'
// 				const relevantStatuses = ['NewOrders', 'Completed', 'InTransit', 'InProcess'];
// 				const newOrders = orders.filter(order => relevantStatuses.includes(order.orderStatus));
// 				setNewOrdersCount(newOrders.length);

// 				// Calculate total earnings for orders with status 'Completed'
// 				const completedOrders = orders.filter(order => order.orderStatus === 'Completed');
// 				const totalEarnings = completedOrders.reduce((total, order) => total + order.totalAmount, 0);
// 				setTotalEarnings(totalEarnings);

// 				const incompleteOrders =  orders.filter(order => order.orderStatus === 'InProcess');
// 				setIncompleteOrdersCount(incompleteOrders.length);

				

// 			} catch (error) {
// 				console.error('Error fetching new orders:', error);
// 			}
// 		};

// 		fetchNewOrdersCount();
// 	}, []);

// 	// ----------------------

// 	const fillPercentage = [
// 		{ value: 100, fill: "#1a88fe" },
// 		{ value: 75, fill: "#6e64e5" },
// 		{ value: 80, fill: "#d64ab0" },
// 		{ value: 99, fill: "#ff6b67" },
// 	];

// 	const categoryData = [
// 		{ category: 'Women', sales: 25, color: 'bg-pink-500' },
// 		{ category: 'Men', sales: 100, color: 'bg-blue-500' },
// 		{ category: 'Kids', sales: 80, color: 'bg-green-500' },
// 		{ category: 'Accessories', sales: 37, color: 'bg-yellow-500' },
// 		{ category: 'Home Food', sales: 45, color: 'bg-red-500' },
// 		{ category: 'Home Decor', sales: 55, color: 'bg-purple-500' },
// 	];

// 	return (
// 		<div className='p-5'>
// 			{/* sales card */}
// 			<div className=''>
// 				<h1 className="text-3xl font-bold mb-4">Sales</h1>
// 				<div className="w-full grid grid-col-3 sm:grid-row gap-y-5 gap-x-2 sm:p-5 py-5">

// 					{/* first row */}
// 					<div className='flex flex-row gap-4 cursor-pointer'>
// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{productSoldCount}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{productSoldCount} </p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Product Sold</p>
// 								</div>
// 							</div>
// 						</Card>



// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2  sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>

// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><BiSort className="text-xl sm:text-2xl m-1  sm:m-2" /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{newOrdersCount}</p>

// 								</div>
// 								<span className='hidden sm:block'><BiSort className="text-xl sm:text-2xl m-1  sm:m-2" /></span>
// 								<div className="flex justify-center items-center flex-col">
// 									<div className=" hidden sm:block sm:p-4 ">
// 										<p className="text-2xl flex justify-center items-center font-bold text-[#737587]">{newOrdersCount}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl py-[2px] sm:py-0">Total Orders</p>
// 								</div>
// 							</div>
// 						</Card>

// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2  sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><PersonOutlineRoundedIcon className='m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-10 mt-2 '>{totalEarnings}</p>

// 								</div>
// 								<span className='hidden sm:block'><PersonOutlineRoundedIcon className='m-2' /></span>
// 								<div className="flex justify-center items-center flex-col">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className="text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">Price: <BiRupee /> {totalEarnings}</p>
// 									</div>
// 									<p className="font-bold text-xl sm:text-2xl">Total Earnings</p>
// 								</div>
// 							</div>
// 						</Card>
// 					</div>


// 					{/* second row */}
// 					<div className='flex flex-row gap-4 cursor-pointer'>
// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{incompleteOrdersCount}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{incompleteOrdersCount}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Incomplete Orders</p>
// 								</div>
// 							</div>
// 						</Card>


// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{totalGstCharges}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{totalGstCharges}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">GST charges Total</p>
// 								</div>
// 							</div>
// 						</Card>


// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{serviceCharge}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{serviceCharge} </p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Service Charges</p>
// 								</div>
// 							</div>
// 						</Card>
// 					</div>

// 					{/* third row */}
// 					<div className='flex flex-row gap-4 cursor-pointer'>
// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{finalEarnings}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{finalEarnings}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Final Earnings</p>
// 								</div>
// 							</div>
// 						</Card>
// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{totalVisitors}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{totalVisitors}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Total Visitors</p>
// 								</div>
// 							</div>
// 						</Card>
// 						<Card
// 							variant="outlined"
// 							orientation="horizontal"
// 							className="w-1/2 sm:w-1/2 h-1/2 sm:h-full rounded-md sm:rounded-xl"
// 							sx={{
// 								boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
// 								borderRadius: '1rem', // Rounded corners
// 								'@media screen and (max-width: 640px)': {
// 									borderRadius: '0.5rem', // Change borderRadius for screens below 640px width (sm breakpoint)
// 								},
// 								'&:hover': {
// 									boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Shadow on hover
// 									borderColor: 'neutral.outlinedHoverBorder',
// 								},
// 							}}
// 						>
// 							<div>
// 								<div className='flex sm:hidden'>
// 									<span><ManageAccountsIcon className='m-1 sm:m-2' /></span>
// 									<p className='text-xl w-full text-center sm:text-2xl font-bold text-[#737587] px-5 '>{totalProductAdded}</p>

// 								</div>
// 								<span className='hidden sm:block'><ManageAccountsIcon className='m-2 ' /></span>

// 								<div className="flex justify-center items-center flex-col sm:mb-4">
// 									<div className="hidden sm:block sm:p-4">
// 										<p className=" text-xl sm:text-2xl flex justify-center items-center font-bold text-[#737587]">{totalProductAdded}</p>
// 									</div>
// 									<p className="text-center font-bold text-base sm:text-2xl">Total Product Added</p>
// 								</div>
// 							</div>
// 						</Card>
// 					</div>


// 				</div>
// 			</div>

// 			{/* weekly profit chart */}
// 			<div className='flex flex-col md:flex-row justify-between items-center w-full gap-5 shadow-md border rounded-md bg-white p-2 mb-4'>
// 				<div className="w-full md:w-1/2">
// 					<h1 className='text-3xl font-bold mb-4 p-2'>Weekly Profit Chart</h1>
// 					<div>
// 						<ResponsiveContainer height={400} width="100%">
// 							<LineChart data={profitData}>
// 								<CartesianGrid />
// 								<XAxis dataKey="week" interval={'preserveStartEnd'} />
// 								<YAxis label={{ value: 'Rupees', angle: -90, position: 'insideLeft', offset: 1 }} />
// 								<Legend />
// 								<Tooltip />
// 								<Line dataKey="sales" stroke="#F15080" activeDot={{ r: 8 }} />
// 							</LineChart>
// 						</ResponsiveContainer>
// 					</div>
// 				</div>

// 				<div className='w-full md:w-1/2 flex flex-row'>
// 					<RadialBarChart width={400} height={350} innerRadius="40%" outerRadius="100%" data={fillPercentage} startAngle={90} endAngle={-270}>
// 						<RadialBar dataKey="value" background='transparent' fill="#8884d8" />
// 						<Tooltip
// 							dataKey="value"
// 							position="insideBottom"
// 							content={({ payload }) => {
// 								if (payload && payload.length > 0) {
// 									const fillPercentage = payload[0].payload.value;
// 									return `${fillPercentage}%`;
// 								}
// 								return null;
// 							}}
// 						/>
// 					</RadialBarChart>


// 					<div className='flex flex-col justify-start p-2'>
// 						<span><div className="w-5 h-5 rounded-full bg-[#1a88fe] inline-block mr-2"></div>Incomplete Products</span>
// 						<span><div className="w-5 h-5 rounded-full bg-[#6e64e5] inline-block mr-2"></div>Complete Products</span>
// 						<span><div className="w-5 h-5 rounded-full bg-[#d64ab0] inline-block mr-2"></div>Inprocess Products</span>
// 						<span><div className="w-5 h-5 rounded-full bg-[#ff6b67] inline-block mr-2"></div>Newly Added Products</span>
// 					</div>

// 				</div>
// 			</div>


// 			{/* Product data line Chart */}
// 			<div className="container mx-auto flex flex-col shadow-md border rounded-md bg-white p-2 mb-4">
// 				<h1 className="text-3xl font-bold mb-4">Product Completion Chart</h1>

// 				<div className="mb-8">
// 					<ResponsiveContainer width="100%" height={400}>
// 						<LineChart data={productData}>
// 							<CartesianGrid />
// 							<XAxis dataKey="day" interval={'preserveStartEnd'} />
// 							<YAxis label={{ value: 'Product Count', angle: -90, position: 'insideLeft', offset: 1 }} />
// 							<Legend />
// 							<Tooltip />
// 							<Line
// 								dataKey="production"
// 								name="Product Completion Over Time"
// 								stroke="#F15080"
// 								activeDot={{ r: 8 }}
// 							/>
// 						</LineChart>
// 					</ResponsiveContainer>
// 				</div>
// 			</div>

// 			{/* Customer Activity Bar Chart */}
// 			<div className="flex flex-row gap-5 shadow-md border rounded-md bg-white p-2 mb-4">
// 				<div className='w-[800px] h-full'>
// 					<h1 className="text-3xl font-bold mb-4">Customer Activity Bar Chart</h1>
// 					<ResponsiveContainer height={400}>
// 						<BarChart data={customerActivityData}>
// 							<CartesianGrid vertical={false} strokeDashoffset={3} />
// 							<XAxis dataKey="dayOfWeek" />
// 							<YAxis label={{ value: 'Customer Visits', angle: -90, position: 'insideLeft', offset: 1 }} />
// 							<Tooltip />
// 							<Legend />
// 							<Bar dataKey="customerVisits" fill="#8884d8" />
// 						</BarChart>
// 					</ResponsiveContainer>
// 				</div>

// 				<div className='flex flex-col'>
// 					<h1 className="text-3xl font-bold mb-4">Category Horizontal Progress Bars</h1>
// 					<div className='w-[400px] h-max shadow-md border rounded-md bg-white p-5 mb-4'>
// 						{categoryData.map((category, index) => (
// 							<div key={index}>
// 								<div className='grid grid-cols-3 items-center'>
// 									<p className="mb-2 font-bold">{category.category}</p>

// 									<div className={`h-5 rounded-full ${category.color} ${category.sales === 0 ? 'invisible' : ''}`} style={{ width: `${category.sales}%` }}></div>


// 									<span className="text-xs text-right font-semibold inline-block py-1 px-2 uppercase rounded-full" style={{ backgroundColor: category.color }}>
// 										{category.sales}%
// 									</span>


// 								</div>
// 							</div>

// 						))}
// 					</div>
// 				</div>
// 			</div>

// 		</div >


// 	);
// }

// export default Report;


import React from 'react'
import ComingSoon from '../../../pages/ComingSoon'

function Report() {
  return (
	<div className='h-full'>
	  <ComingSoon/>
	</div>
  )
}

export default Report
