import React from "react";
import { Link } from "react-router-dom";
import constant from "../../constant";
import InstaCards from "./InstaCards";
import { BsArrowBarRight, BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FiChevronsRight } from "react-icons/fi";


const first= constant.IMAGES_IMAGES.INSTAGRAM.FIRST;
const second= constant.IMAGES_IMAGES.INSTAGRAM.SECOND;
const third= constant.IMAGES_IMAGES.INSTAGRAM.THIRD;
const fourth= constant.IMAGES_IMAGES.INSTAGRAM.FOURTH;
const fifth= constant.IMAGES_IMAGES.INSTAGRAM.FIFTH;
const sixth= constant.IMAGES_IMAGES.INSTAGRAM.SIXTH;
const seventh= constant.IMAGES_IMAGES.INSTAGRAM.Seventh;

const instagramData = [
  {
    link: "https://www.instagram.com/p/C3STNc3NQY9/",
    alt: "",
    photo: first,
    content: "This is first content"
  },
  {
    link: "https://www.instagram.com/p/C3PkhOctoDC/",
    alt: "",
    photo: second,
    content: "This is second content"
  },
  {
    link: "https://www.instagram.com/p/C3CcyFDtvWb/",
    alt: "",
    photo: third,
    content: "This is third content"
  },
  {
    link: "https://www.instagram.com/p/C2jRQvtNhkI/",
    alt: "",
    photo: fourth,
    content: "This is fourth content"
  },
  {
    link: "https://www.instagram.com/p/C2jPNMfySoV/",
    alt: "",
    photo: fifth,
    content: "This is fifth content"
  },
  {
    link: "https://www.instagram.com/p/C2jOpIHyWFR/",
    alt: "",
    photo: sixth,
    content: "This is sixth content"
  },
  {
    link: "https://www.instagram.com/p/C2jlpXQSlAp/",
    alt: "",
    photo: seventh,
    content: "This is seventh content"
  },
 
];




const Instagram = () => {

  const slideLeft = () => {
    var slider = document.getElementById('instaCards');
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById('instaCards');
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  return (
    <div>
      <div className={`sm:block pt-10 mb-12 cursor-pointer rounded-xl p-5`}>

        <div className="w-full flex justify-center text-[27px] font-600 text-[#343246] mt-2 text-center p-2">THE EKAIV ON INSTAGRAM</div>


        <span className="flex justify-center">
          <a
            href="https://www.instagram.com/the_ekaiv/"
            target="_blank"
            className="text-center text-base sm:text-lg lg:text-xl"
            style={{
              background: "-webkit-linear-gradient(left, #F16080, #5156E9)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {"@the_ekaiv"}
          </a>
        </span>



      </div>

      <div className="relative">
        {/* Left arrow */}
        <div onClick={slideLeft}  className="absolute left-2 sm:left-5 top-1/2 transform -translate-y-1/2 cursor-pointer z-10  shadow-2xl md:shadow-2xl lg:shadow-2xl xl:shadow-2xl opacity-80">
          <BsArrowLeftShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}size={35} />
        </div>

        {/* Main container for cards */}
        <div id="instaCards"   className="flex gap-5 overflow-x-auto no-scrollbar scroll-smooth  pt-4 pb-4  sm:pl-5 pr-5 space-x-4 ml-10 mr-10">
          {instagramData.map((item) => (
            <div key={item.id} className="flex-shrink-0 hover:scale-105 " style={{ width: '250px', height: '400px' }}>
              <InstaCards link={item.link} alt={item.alt} content={item.content} photo={item.photo} />
            </div>
          ))}
        </div>

        {/* Right arrow */}
        <div onClick={slideRight} className="absolute right-2 sm:right-5 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer  opacity-80">
          <BsArrowRightShort className="rounded-full border border-spacing-4 text-white bg-[#880081]" style={{boxShadow: "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}} size={35} />
        </div>
      </div>


      {/* See more button */}
      <div className="flex justify-center p-10 mt-10 mb-10">
        <Link
          to="https://www.instagram.com/the_ekaiv/"
          target="blank"
          className="inline-flex"
        >
          <button class="rounded px-3 py-2 m-1 border-b-4 border-l-2 shadow-lg bg-[#8A58DC] border-[#8A58DC] text-white  flex gap-2 justify-center items-center transition duration-500 ease-in-out hover:scale-105">

            <span>SEE MORE</span> <span><FiChevronsRight size={25} /></span>

          </button>
        </Link>
      </div>
    </div>
  );
};

export default Instagram;