import axios from 'axios';
import {API_URL } from '../../../config/config';

const getAllProducts = async (searchCriteria) => {
  const { category, ratings, searchTerm  } = searchCriteria;
  const queryParams = {
    status: 'Verified'
  };

  
  if (category) {
    queryParams.category = category;
  }

  if (ratings) {
    queryParams.rating = ratings;
  }

  if (searchTerm) {
    queryParams.searchTerm = searchTerm;
  }

  try {
    const response = await axios.post(`${API_URL}/api/products/searchproducts?searchTerm=`, {
      params: queryParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPopularProducts = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/products/popularProduct`);
    return response.data;
  } catch (error) {
    throw error; // Handle errors as needed
  }
};
const getFeaturedProducts = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/products/featuredProducts`);
    return response.data;
  } catch (error) {
    throw error; // Handle errors as needed
  }
};

const productsService = {
  getAllProducts,
  getPopularProducts,
  getFeaturedProducts, // Add the new function for fetching featured products
};

export default productsService;
