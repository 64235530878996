import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSwapRight } from 'react-icons/ai';
import { getUser, login } from '../Redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { resetMessage } from '../Redux/features/auth/authSlice';
import { toast, Toaster } from 'react-hot-toast'
import constant from "../constant";

const images = constant.IMAGES_IMAGES.HOME.LOGIN

const LoginForm = () => {
  
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(null);


  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    if (newPassword.length <= 16) {
      setPassword(newPassword);
    }
  };

  const handleCreateAccountLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default form submission
      handleLogin();
    }
  };

  const [clicked, setClicked] = useState(false);

  const handleLogin = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(emailOrPhone).toLowerCase().trim())) {
      dispatch(login({ emailOrPhone, password })).then((response) => {
        if (response.error) {
          setSuccessMessage('')
          setErrorMessage(response.payload)
          toast.error(response.payload)
          dispatch(resetMessage())
        } else {
          dispatch(getUser());
          toast.success(response.payload.message)
          setErrorMessage('')
          setSuccessMessage(response.payload.message)
          dispatch(resetMessage())
          window.location.href = '/'
        }
      });
      return;
    }
    if (emailOrPhone.match(/^\d+$/)) {
      dispatch(login({ emailOrPhone, password })).then((response) => {
        if (response.error) {
          setSuccessMessage('')
          setErrorMessage(response.payload)
          toast.error(response.payload)
          dispatch(resetMessage())
        } else {
          setErrorMessage('')
          dispatch(getUser());
          toast.success(response.payload.message)
          setSuccessMessage(response.payload.message)
          window.location.href = '/'
          dispatch(resetMessage())
        }
      })
    } else {
      toast.error("Please enter valid email or password.")
    }

    if (!clicked) {
      
      setClicked(true);

      const timeout = setTimeout(() => {
        setClicked(false);
        clearTimeout(timeout);
      }, 2000); 

      setTimer(timeout);
    }

  }

  // const handleLogin = () => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const phoneRegex = /^\d{10}$/; // Allow only 10 digits

  //   if (!emailOrPhone.trim()) {
  //     toast.error('Email or Phone is required.');
  //     return;
  //   }

  //   if (!password.trim()) {
  //     toast.error('Password is required.');
  //     return;
  //   }

  //   const lowercasedEmailOrPhone = emailOrPhone.toLowerCase(); // Convert to lowercase

  //   if (!emailRegex.test(lowercasedEmailOrPhone) && !phoneRegex.test(emailOrPhone)) {
  //     toast.error('Please enter a valid email or 10-digit phone number.');
  //     return;
  //   }

  //   dispatch(login({ email: lowercasedEmailOrPhone, password })).then((response) => {
  //     if (response.error) {
  //       setSuccessMessage('');
  //       setErrorMessage(response.payload);

  //       // Check for specific error messages and show toasts accordingly
  //       if (response.payload === 'User not found') {
  //         toast.error('User not found. Please sign up to continue.');
  //       } else if (response.payload === 'Incorrect password') {
  //         toast.error('Incorrect password. Please enter the correct password.');
  //       } else if (response.payload === 'Incorrect email') {
  //         toast.error('Incorrect email. Please enter the correct email.');
  //       } else {
  //         toast.error(response.payload);
  //       }

  //       dispatch(resetMessage());
  //     } else {
  //       setErrorMessage('');
  //       setSuccessMessage(response.payload.message);
  //       dispatch(resetMessage());
  //       window.location.href = '/';
  //     }
  //   });
  // };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scroll-link");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {
      // Remove event listeners when the component unmounts
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <div className='h-full w-auto bg-white' >
      <div className="flex items-center justify-center flex-col w-full p-5 pb-10 max-sm:p-0">        <div className="bg-white rounded-lg p-8 shadow-lg max-w-4xl w-full">
        <div className="flex flex-col md:flex-row gap-5">
          <div className="md:w-1/2">
            <img
              src={images}
              alt="Image"
              className="h-auto w-full mx-auto md:ml-0 md:mr-8 mb-4 md:mb-0"
            />
          </div>
          <div className="md:w-1/2">
            <h2 className="text-md mt-4 font-lighter text-[#222] text-opacity-50">Welcome back!</h2>
            <h2 className="text-3xl font-bold mb-4">Start Shopping</h2>
            <form className="space-y-4">
              <div className='mt-10'>
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  Phone or Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={emailOrPhone}
                  maxLength={320}
                  onChange={(e) => setEmailOrPhone(e.target.value.toLowerCase())}
                  className="w-full px-4 py-2 border-none outline-none rounded-md bg-[#FFEDF1]"
                  placeholder="test1@gmail.com"
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-gray-700 mb-2">
                  Password
                </label>
                <div className="relative">

                  <input
                    className={`w-full px-4 py-2 pr-10 border-none outline-none rounded-md bg-[#FFEDF1] ${password ? '' : ''
                      }`}
                    type={visible ? 'text' : 'password'}
                    value={password}
                    placeholder='Password'
                    onChange={handlePasswordChange}
                    onKeyDown={handleEnterKey}
                  />
                  {/* eye open/close */}
                  <span
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {visible ? (
                      <AiOutlineEye className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                    ) : (
                      <AiOutlineEyeInvisible className="h-6 w-6 text-gray-500 hover:text-[#8A58DC] transition-colors duration-300" />
                    )}
                  </span>
                </div>
              </div>
              <div className="text-[#222] text-opacity-50 text-sm hover:underline">
                <Link to="/forgot-password" className=' scroll-link'>Forgot Password?</Link>
              </div>


              <div className="flex items-center justify-center">
                <div className="relative overflow-hidden">
                  <button
                    type="button"
                    onClick={handleLogin}
                    className={`flex gap-2 text-white font-medium rounded-full text-sm px-5 py-2.5  text-center justify-center items-center mb-2 ${
                      clicked ? 'bg-[#F16080] hover:bg-[#FF6FAA]' : 'bg-[#8A58DC] hover:bg-[#965df0] shadow-md '
                    }`}
                    disabled={clicked}
                  >
                    LOGIN <AiOutlineSwapRight className='text-xl flex justify-start items-start' />
                  </button>
                </div>
              </div>
              <p className="mt-5 text-center text-[#222] text-opacity-80 text-sm">
                New to The Ekaiv?{' '}
                <Link to="/signup" className="text-[#8A58DC]" onClick={handleCreateAccountLinkClick}>
                  Create an account
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default LoginForm;
