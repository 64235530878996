import React, { useState, useEffect } from 'react'
import ProductDesc from './ProductDesc';
// import ProductPrice from './ProductPrice';
import RecentlyViewedProduct from './RecentlyViewedProduct';
import ProductImage from './ProductImage';
import Loader from '../common/Loader';

const ParticularProduct = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        };
        fetchData();
    }, []);
    return (
        <>
            {/* main div */}
            {loading ? (
                <div className='flex flex-col gap-2 justify-center items-center h-screen w-full'>
                    <Loader />
                    <span className="text-[#8A58DC] text-lg font-bold">Loading...</span>
                </div>
            ) : (
                <div className='flex flex-col bg-[#F7F7F7] mb-4 justify-between lg:flex-row gap-10 lg:items-start p-4'>
                    {/* left div */}
                    <ProductImage />
                    {/* right div */}
                    <div className='flex flex-col justify-between items-center w-full border border-none'>
                        {/* <ProductPrice /> */}
                        <ProductDesc />
                    </div>
                </div>
            )}
            <RecentlyViewedProduct />

        </>
    )
}

export default ParticularProduct 