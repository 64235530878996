import React, { useState, useEffect } from 'react'
import constant from '../../constant';
import { API_URL, config } from '../../config/config';
import { useNavigate } from 'react-router-dom';

function FestiveCards() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const links = document.querySelectorAll(".scrollTop");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    return () => {

      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const [bannerData, setBannerData] = useState([]);


  useEffect(() => {
    fetch(`${API_URL}/api/admin-profile/bannerall`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {

        setBannerData(data);
      })
      .catch(error => console.error('Error fetching banner data:', error));
  }, []);

  const handleSearchSubmit = (searchTerm) => {
    navigate(`/products/?searchTerm=${encodeURIComponent(searchTerm)}`);
    window.scrollTo(0, 0);
  };



  return (
    <div>
      <div className='p-5 sm:p-10 grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8 justify-center items-center'>

        <div className=' w-full h-full'>

          {bannerData.festBannerData && (
            <div className='relative  w-full h-full rounded-2xl'>
              <img src={bannerData.festBannerData.mainBanner} alt={bannerData.festBannerData._id} className='object-cover w-full h-full rounded-2xl' />
            </div>
          )}


        </div>

        <div className=' grid grid-cols-2    gap-x-5 min[350px]:gap-x-10 gap-y-5 min-[350px]:gap-y-5'>


          {bannerData.festBannerData && Object.keys(bannerData.festBannerData.bannerCards).map((key, index) => (
            <div key={index} className='flex justify-center items-center relative rounded-2xl'>
              <img src={bannerData.festBannerData.bannerCards[key]} alt={bannerData.festBannerData.titles[key]} className='object-cover w-full h-full rounded-2xl' />
              <button onClick={() => handleSearchSubmit(bannerData.festBannerData.titles[key])} className='scrollTop bg-[#EF5E84] bottom-2 lg:bottom-4 left-0 ml-2 lg:ml-6 w-[60px] min[350px]:w-[70px] h-[18px] min[350px]:h-[23px] lg:w-[80px] lg:h-[30px] absolute text-[10px] lg:text-[12px] text-white rounded-xl '>Shop Now</button>
            </div>
          ))}
        </div>



      </div>
    </div>
  )
}

export default FestiveCards




