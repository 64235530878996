import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './AddAddressForm.css'
import InputBox from '../InputBox'
import { addAddress, editAddress, resetMessage } from '../../Redux/features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import {toast} from 'react-hot-toast'


const inputFields = [
  {
    id: "country",
    label: "Country/Region",
    name: "country",
    autofocus: true,
    autocomplete: "country",
    defaultValue: "India",
  },
  {
    id: "fullname",
    label: "Full Name",
    name: "fullname",
    autocomplete: "fullname",
    placeholder: "Full Name",
  },
  {
    id: "mobile",
    label: "Mobile Number",
    name: "phoneNumber",
    autocomplete: "mobile",
    placeholder: 'Mobile Number',

  },
  {
    id: "pincode",
    label: "Pin Code",
    name: "pinCode",
    autocomplete: "pincode",
    placeholder: 'Pincode',

  },
  {
    id: "flats",
    label: "Flat, House No., Building, Company, Apartment",
    name: "flat",
    autocomplete: "flats",
    placeholder: 'Flat/House Number, Building, Company, Apartment',
  },
  {
    id: "area",
    label: "Area, Street, Sector, Village",
    name: "area",
    autocomplete: "area",
    placeholder: 'Area, Street, Sector, Village',
  },
  {
    id: "landmark",
    label: "Landmark",
    name: "landmark",
    autocomplete: "landmark",
    placeholder: 'Landmark',
  },
  {
    id: "town",
    label: "Town/City",
    name: "city",
    autocomplete: "city",
    placeholder: 'Town/City',
  },

]
const AddAddressForm = ({ openAddAddressForm, addressId, setAddressId }) => {
  const { user, isSuccess, message, isError } = useSelector((state) => state.auth)
  const dispatch = useDispatch();


  useEffect(() => {
    if ((isSuccess || isError) && message) {
      setTimeout(() => {
        dispatch(resetMessage());
      }, 2000);
    }

    if (isSuccess && message === 'Address added successfully') {
      openAddAddressForm(false);
    }
    if (isSuccess && message === 'Address Editted successfully') {
      openAddAddressForm(false);
    }

  }, [isSuccess, isError, message])

  const [prevData, setPrevData] = useState(null)

  const [values, setValues] = useState({
    country: 'India',
    fullname: '',
    phoneNumber: '',
    pinCode: '',
    flat: '',
    area: '',
    landmark: '',
    city: '',
    state: ''
  })

  useEffect(() => {

    user && addressId && setPrevData(user.address.find((e) => e._id === addressId))
    prevData && setValues({
      country: 'India',
      fullname: prevData.fullname,
      phoneNumber: prevData.phoneNumber,
      pinCode: prevData.pinCode,
      flat: prevData.flat,
      area: prevData.area,
      landmark: prevData.landmark,
      city: prevData.city,
      state: prevData.state
    })

  }, [addressId, user, prevData])


  const handleEditSubmit = (event) => {
    event.preventDefault();
    const phoneNumberWithoutSpaces = values.phoneNumber.replace(/\s/g, "");
  const phoneNumberLength = phoneNumberWithoutSpaces.length;

  if (!((phoneNumberLength === 10 && !phoneNumberWithoutSpaces.startsWith('91')) || (phoneNumberLength === 12 && (phoneNumberWithoutSpaces.startsWith('91') || phoneNumberWithoutSpaces.startsWith('+91'))))) {
    // Display an error message or handle the validation accordingly
    toast.error('Please enter a valid phone number.');
    return;
  }
    if (values.pinCode.length !== 6) {
      toast.error('Please enter a valid Pin Code.');
      return;
    }
    const address = {
      country: 'India',
      fullname: values.fullname,
      phoneNumber: `+91${phoneNumberWithoutSpaces.replace(/^91/, '')}`,
      pinCode: values.pinCode,
      flat: values.flat,
      area: values.area,
      landmark: values.landmark,
      city: values.city,
      state: values.state
    };
    dispatch(editAddress({ addressData: { ...address }, id: user ? user._id : null, addressId }))
    // openEditAddressForm(false);
    // setAddressId(null)
  };
  const handleAddSubmit = (event) => {
    event.preventDefault();
    const phoneNumberWithoutSpaces = values.phoneNumber.replace(/\s/g, "");
  const phoneNumberLength = phoneNumberWithoutSpaces.length;

  if (!((phoneNumberLength === 10 && !phoneNumberWithoutSpaces.startsWith('91')) || (phoneNumberLength === 12 && (phoneNumberWithoutSpaces.startsWith('91') || phoneNumberWithoutSpaces.startsWith('+91'))))) {
    // Display an error message or handle the validation accordingly
    toast.error('Please enter a valid phone number.');
    return;
  }
    if (values.pinCode.length !== 6) {
      toast.error('Please enter a valid pin code.');
      return;
    }
    const data = new FormData(event.currentTarget);
    const address = {
      country: 'India',
      fullname: data.get('fullname'),
      phoneNumber: `+91${phoneNumberWithoutSpaces.replace(/^91/, '')}`,
      pinCode: data.get('pinCode'),
      flat: data.get('flat'),
      area: data.get('area'),
      landmark: data.get('landmark'),
      city: data.get('city'),
      state: data.get('state')
    };
    dispatch(addAddress({ ...address, id: user ? user._id : null }))
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      // Allow only digits and limit to a maximum length of 10
      const formattedValue = value.replace(/\D/g, '').slice(0, 12);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    } else if (name === 'pinCode') {
      // Allow only numeric characters and limit to a maximum length of 6
      const formattedValue = value.replace(/\D/g, '').slice(0, 6);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (['flat', 'landmark', 'area'].includes(name)) {
      // Limit to a maximum length of 40 for specific fields
      const formattedValue = value.slice(0, 80);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (name === 'fullname') {
      // Limit to a maximum length of 40 for specific fields
      const formattedValue = value.slice(0, 50);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else if (name === 'city') {
      // Limit to a maximum length of 40 for specific fields
      const formattedValue = value.slice(0, 20);
      setValues({
        ...values,
        [name]: formattedValue,
      });
    }
    else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '29px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          lineHeight: '25px',
          color: "#404040"
        }}>{addressId ? "Edit" : "Add"} a Delivery Address</Typography>

      <Box component="form" noValidate onSubmit={addressId ? handleEditSubmit : handleAddSubmit} sx={{ mt: 3 }}>
        {
          inputFields.map((element, index) => {
            return <InputBox {...element} key={index} value={values[element.name]} changeInput={changeInput} />
          })
        }
        <div>

          <label className='block text-gray-700 text-sm font-bold text-left mb-2'>
            State
          </label>
          <select
            name='state'
            value={values.state} 
            onChange={changeInput}
            className='border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
          >
            <option value="" disabled>
    Select State
  </option>
  {[
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Lakshadweep',
    'Delhi',
    'Puducherry',
  ].map((state) => (
    <option key={state} value={state}>
      {state}
    </option>
  ))}
</select>
        </div>


        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 2,
            color: "#ffffff",
            borderRadius: "6px",
            borderColor: "#8A58DC",
            backgroundColor: "#8A58DC",
            marginTop: "15px",
            height: '36px',
            "&:hover": {
              borderColor: "#8A58DC !important",
              backgroundColor: "#8A58DC !important"
            },
            "&:active": {
              borderColor: "#8A58DC !important",
              backgroundColor: "#8A58DC !important"
            },
          }}

        >
          Submit
        </Button>
      </Box>
      {message && <div className='text-sm text-[#8A58DC]'>{message}</div>}
    </Box>
  )
}

export default AddAddressForm


