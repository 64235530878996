import axios from 'axios'
import { config, API_URL } from '../../../config/config';


const login = async (userData) => {

  const response = await axios.post(`${API_URL}/api/auth/signin/`, userData)
  if (response.data) {
    localStorage.setItem('token', JSON.stringify(response.data.token));
  }
  return response.data
}





const logout = async () =>{ 
    const response = await axios.post(`${API_URL}/api/auth/signout`);
    if(response.status===200){
        localStorage.clear();
    }
    return response.data;
}

const signup = async (userData) => {
  const response = await axios.post(`${API_URL}/api/auth/signup/`, userData)
  return response.data
}

const createVendor = async (vendorData) => {

  const response = await axios.post(`${API_URL}/api/vendor/createVendor`, vendorData, config)

  if (response.data) {
    localStorage.setItem('token', JSON.stringify(response.data.token));
  }
  return response.data
}
const getUser = async () => {

  const response = await axios.get(`${API_URL}/api/user/getUser`, config);
  if (response.data) {
    localStorage.setItem('token', JSON.stringify(response.data.token));
  }
  return response.data
}
const updateUser = async (newUserData) => {

  const response = await axios.put(`${API_URL}/api/user/updateUser`, newUserData, config)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response.data
}
const addAddress = async (address) => {

  const response = await axios.put(`${API_URL}/api/user/addAddress`, address, config)
  return response.data
}

const deleteAddress = async (data) => {

  const response = await axios.delete(`${API_URL}/api/user/deleteAddress/${data.addressId}`, config)
  return response.data
}

const editAddress = async (data) => {

  const response = await axios.put(`${API_URL}/api/user/editAddress/${data.addressId}`, data.addressData, config)
  return response.data
}
const changePassword = async (data) => {

  const response = await axios.put(`${API_URL}/api/user/changePassword/${data.id}`, data, config)
  return response.data
}
const addToCart = async (newData) => {
  try {
    const { id, pId, quantity , size ,customizationMessage, customPay,customPrice,customizationId } = newData;
    const response = await axios.post(`${API_URL}/api/user/addToCart/${id}/${pId}`, { quantity, size,customizationMessage, customPay,customPrice,customizationId }, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};
const updateCart = async (newData) => {
  try {
    const { pId, quantity } = newData;
    const response = await axios.put(`${API_URL}/api/user/updateCart/${pId}`, { quantity }, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFromCart = async (newData) => {
  try {
    const response = await axios.delete(`${API_URL}/api/user/removeCartItem/${newData.id}/${newData.productId}`, config)
    return response.data
  } catch (error) {
    throw error;
  }
};
export const getCart = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/getCart/${userId}`, config);
    return response.data.cart;
  } catch (error) {
    throw error;
  }
};


export const getDataFromCart = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/user/getCart/${userId}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllOrders = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/user/getAllOrders`,config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getWishlist = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/getWishlist/${userId}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addToWishlist = async (newData) => {

  const response = await axios.post(`${API_URL}/api/user/addToWishlist/${newData.id}/${newData.pId}`, newData, config)

  return response.data
}
export const deleteFromWishlist = async (newData) => {
  try {

    const response = await axios.delete(`${API_URL}/api/user/removeWishlistItem/${newData.id}/${newData.pId}`, config);
    return response.data
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordWithEmail = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/forgotPasswordWithEmail`, data);

    return response.data
  } catch (error) {
    throw error;
  }
};
export const forgotPasswordWithPhone = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/forgotPasswordWithPhone`, data);
    return response.data
  } catch (error) {
    throw error;
  }
};

const authService = {
  login,
  logout,
  signup,
  getUser,
  updateUser,
  addAddress,
  deleteAddress,
  editAddress,
  changePassword,
  createVendor,
  addToCart,
  updateCart,
  deleteFromCart,
  getCart,
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
  getDataFromCart,
  getAllOrders,
  forgotPasswordWithEmail,
  forgotPasswordWithPhone
}

export default authService