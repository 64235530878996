import React, { useState, useEffect } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { IoMdCart } from 'react-icons/io';
import { Rating } from '@mui/material';
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, addToWishlist, deleteFromWishlist } from '../../Redux/features/auth/authSlice';
import { toast } from 'react-hot-toast';
import { API_URL } from '../../config/config';

const ProductCard = ({ data, showWishlistAndCartButtons = true, showStatus = false }) => {

  const dispatch = useDispatch();
  const users = useSelector((state) => state.auth);

  const [isWishlist, setIsWishlist] = useState(false);
  const [localWishlist, setLocalWishlist] = useState([]);
  const navigate = useNavigate();

  const addProductToRecentlyViewed = (product) => {
    const storedRecentlyViewed = localStorage.getItem('recentlyViewed');
    const existingRecentlyViewed = storedRecentlyViewed ? JSON.parse(storedRecentlyViewed) : [];
    const newRecentlyViewed = [product, ...existingRecentlyViewed.slice(0, 7)];
    localStorage.setItem('recentlyViewed', JSON.stringify(newRecentlyViewed));
  };


  useEffect(() => {
    const localWishlistData = localStorage.getItem('wishlist');
    if (localWishlistData) {
      setLocalWishlist(JSON.parse(localWishlistData));
    }
  }, []);

  useEffect(() => {
    setIsWishlist(localWishlist.some(item => item.id === data._id));
  }, [localWishlist, data]);

  const handleAddToCart = () => {
    if (!users || !users.user || !users.user._id) {
      toast.error('Please log in first to add items to your cart');
      return;
    }

    const loggedInVendorId = users?.user?.vendorDetails;

    if (loggedInVendorId === data.vendorId) {
      toast.error('You cannot add your own products to the cart');
      return;
    }

    if (data.stock === 0) {
      toast.error('This product is currently out of the stock');
      return;
    }
    if (data.selectedCategory === 'clothing' ||
      data.selectedCategory === 'Men' ||
      data.selectedCategory === 'kids' ||
      data.selectedCategory === 'Boy' ||
      data.selectedCategory === 'Girl' ||
      data.selectedCategory === 'Women') {
      toast.error('Please select a size before adding to cart');
      return;
    }

    const newData = {
      id: users.user._id,
      pId: data._id,
      quantity: 1,
      size: null,
    }

    dispatch(addToCart(newData))
      .then((result) => {
        if (addToCart.fulfilled.match(result)) {
          toast.success('Item added to cart successfully');
        } else {
          console.error('Error adding product to the cart:', result.payload);
        }
      });
  };

  const handleAddToWishlist = () => {
    const loggedInVendorId = users?.user?.vendorDetails;
    if (loggedInVendorId === data.vendorId) {
      toast.error('You cannot add your own products to the wishlist');
      return;
    }
    const updatedLocalWishlist = [...localWishlist, { id: data._id }];
    setLocalWishlist(updatedLocalWishlist);



    // Save the local wishlist to local storage
    localStorage.setItem('wishlist', JSON.stringify(updatedLocalWishlist));

    const newData = {
      id: users && users.user && users.user._id,
      pId: data._id,
    };

    dispatch(addToWishlist(newData))
      .then((result) => {
        if (addToWishlist.fulfilled.match(result)) {
          setIsWishlist(true);
          toast.success('Item added to your wishlist');
        } else {
          console.error('Error adding product to the wishlist:', result.payload);
        }
      });
  };

  const handleRemoveFromWishlist = () => {
    const updatedLocalWishlist = localWishlist.filter(item => item.id !== data._id);
    setLocalWishlist(updatedLocalWishlist);

    // Save the updated local wishlist to local storage
    localStorage.setItem('wishlist', JSON.stringify(updatedLocalWishlist));

    const newData = {
      id: users && users.user && users.user._id,
      pId: data._id,
    };

    dispatch(deleteFromWishlist(newData))
      .then((result) => {
        if (deleteFromWishlist.fulfilled.match(result)) {
          setIsWishlist(false);
          toast.success('Item removed from your wishlist');
        } else {
          console.error('Error removing product from the wishlist:', result.payload);
        }
      });
  };

  const handleToggleWishlist = () => {
    if (!users || !users.user || !users.user._id) {
      toast.error('Please log in first to add items to your wishlist');
      return;
    }

    if (isWishlist) {
      handleRemoveFromWishlist();
      setIsWishlist(false);
    } else {
      handleAddToWishlist();
      setIsWishlist(true);
    }
  };

  const handleProductClick = () => {
    // Make a POST request to increment the click count
    fetch(`${API_URL}/api/products/incrementClickCount/${data._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })

    // Add the product to recently viewed items
    addProductToRecentlyViewed(data);
  };

  const [msg, setMsg] = useState(null);
  const [ratings, setRatings] = useState(0);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  const product_name = data && data.productName ? data.productName.replace(/\s+/g, '-') : '';

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };

  let statusColor = ''; // Define a variable to store the color based on status

  // Determine the color based on the status
  switch (data.status) {
    case 'Verified':
      statusColor = 'text-green-500'; // Green for Verified
      break;
    case 'Pending':
      statusColor = 'text-yellow-500'; // Yellow for Pending
      break;
    case 'Rejected':
      statusColor = 'text-red-500'; // Red for Rejected
      break;
    default:
      statusColor = 'text-gray-500'; // Default color (gray) for other cases
      break;
  }



  return (
    data && (
      <div className="w-[250px] h-[350px] border rounded-sm p-2 flex flex-col gap-1 hover:scale-105 bg-white cursor-pointer mx-auto">
        <Link
          target='_blank'
          to={{
            pathname: `/SpecificProductsPage/${data._id}`,
            state: { productId: data._id },
          }}
          onClick={handleProductClick}
        >
          <div className="w-full p-3 hover:scale-110">
            <img
              src={data.images[0]}
              alt="productImages"
              className="w-full h-[170px] object-contain"
            ></img>
          </div>
        </Link>

        <div className="w-full flex flex-row justify-between gap-4 px-2">
          <Link
            target='_blank'
            to={{
              pathname: `/SpecificProductsPage/${data._id}`,
              state: { productId: data._id }
            }}
            onClick={scrollTo}
          >
            <div className="flex flex-col gap-1 flex-1">
              <div className="flex flex-row gap-1 items-baseline">
                {data.status === 'Pending' ? (
                  <div className="text-sm text-yellow-500 font-bold">Pending</div>
                ) : data.status === 'Rejected' ? (
                  <div className="text-sm text-red-500 font-bold">Rejected</div>
                ) : (
                  <>
                    <div className="text-base font-semibold text-[#343246]">₹{data.theEkaivDiscountPrice}</div>
                    <div className="text-[#9CA3AF] text-sm font-normal">
                      <strike>₹{data.theEkaivPrice} </strike>
                    </div>
                  </>
                )}
              </div>
              <div className="flex gap-0 ">
                <Rating
                  name="simple-controlled"
                  readOnly
                  value={data.ratings}
                  onChange={(event, newRating) => {
                    setRatings(newRating);
                  }}
                />
              </div>
              <div className="flex text-base text-[#343246] font-normal">
                {data.productName && data.productName.length > 25 ? data.productName.slice(0, 25) + '...' : data.productName}
              </div>
              {showStatus && <p className={`text-sm ${statusColor}`}>{data.status}</p>}

            </div>
          </Link>
          {showWishlistAndCartButtons && (
            <div className="flex flex-col gap-14 p-2">
              <div className="w-1/2" >
                {isWishlist ? (
                  <AiFillHeart
                    size={22}
                    className="cursor-pointer"
                    style={{ color: 'red' }}
                    title="Remove from wishlist"
                    onClick={handleToggleWishlist}
                  />
                ) : (
                  <AiOutlineHeart
                    size={22}
                    className="cursor-pointer"
                    style={{ color: 'red' }}
                    title="Add to wishlist"
                    onClick={handleToggleWishlist}
                  />
                )}
              </div>
              <div className="w-1/2 text-right">
                <IoMdCart
                  size={25}
                  className="cursor-pointer text-[#8A58DC] hover:text-[#F16080]"
                  onClick={handleAddToCart}
                  title="Add to cart" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default ProductCard;