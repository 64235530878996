import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com'
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {API_URL} from '../../config/config';

const CaAssistance = () => {
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: '',
    info: '',
  });

  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'John Doe', // Placeholder text
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'example@example.com', // Placeholder text
    },
    {
      name: 'query',
      label: 'query',
      type: 'text',
      placeholder: 'Your Query', // Placeholder text
    },
    {
      name: 'message',
      label: 'message',
      type: 'text',
      placeholder: 'Your Requirements', // Placeholder text
    },
    // Add more form fields as needed
  ];

  //email.js

  const formca = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phoneRegex.test(formData.phone)) {
        toast.error('Please enter a valid 10-digit phone number');
        return;
    } else if (!emailRegex.test(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
    } else {
        setPhoneError('');
        setEmailError('');
        // Proceed with sending the email
    }


    try {
      
      const response = await axios.post(`${API_URL}/api/email/sendCAAssistanceEmail`, formData);
      
      if (response.data.success) {
        toast.success('Form submitted successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          query: '',
          info: '',
        });
      } else {
        toast.error(response.data.message || 'Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Internal Server Error');
    }

    setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
  }
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    
  };


  return (
    <>
      <div className=' bg-gray-100 p-2 sm:p-10'>

        <div className=' w-full flex justify-center items-center'>
          <h2 className="text-xl min-[510px]:text-2xl font-bold mb-4 text-gray-800">CA Assistance</h2>
        </div>

        <div className=' grid md:grid-cols-2 grid-cols-1 gap-5 p-2'>

        <div className=' flex flex-col justify-center items-center' >
          <div className="bg-white rounded-2xl p-5 w-[100%] md:w-[70%]">

            
            <form ref={formca} onSubmit={sendEmail} className="mt-4 flex flex-col">
              <div className="mb-4">
                
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                  required
                  placeholder="Name"
                />
              </div>
              <div className="mb-4">
                
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                  required
                  placeholder="Email"
                 
                />
              </div>
              <div className="mb-4">
                
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[0-9]*"
                  maxLength={10}
                  value={formData.phone}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericInput = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
                    setFormData({ ...formData, phone: numericInput });
                    setPhoneError(numericInput.length === 10 ? '' : 'Please enter a 10-digit phone number');
                  }}
                  className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-full focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                  required
                  placeholder="Phone"
                />
              </div>
              <div className="mb-4">
                
                <textarea
                  id="query"
                  name="query"
                  value={formData.query}
                  onChange={(e) => setFormData({ ...formData, query: e.target.value })}
                  className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-2xl focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                  rows="2"
                  placeholder="Query and Concern"
                ></textarea>
              </div>
              <div className="mb-4">
                
                <textarea
                  id="info"
                  name="info"
                  value={formData.info}
                  onChange={(e) => setFormData({ ...formData, info: e.target.value })}
                  className={`form-input bg-gray-100 px-4 py-2 w-full  shadow-lg mt-1 rounded-2xl focus:ring-2 focus:ring-blue-500 hover:border-violet-500`}
                  rows="2"
                  placeholder="Any information before connecting"
                ></textarea>
              </div>
              <button
                type="submit"
                className="px-4 py-2 bg-violet-500 text-white rounded-full hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 transition duration-300"
                disabled={buttonDisabled}
              >
                Book an Appointment
              </button>
            </form>
            {/* Add any other UI elements and functionality here */}
          </div></div>

          <div className='  '>
            
            <div className=' rounded-2xl px-5 pt-5 '>
                      
            <div className=' flex flex-col'>
              {/* <div className='flex'>

              <p className=' pt-14 px-5 text-2xl font-bold'>Ranjana Erankar</p>
              </div>
              <div className=' flex flex-col p-5'>
              <p className='text-sm text-justify'>Ranjana Erankar, with an extensive 18-year history in Business Registration, exemplifies a company that has stood the test of time. Demonstrating unwavering commitment, our company continuously endeavors to enhance and purify its services. We specialize in facilitating various types of loans, including Personal, Home, Business, Car, Mortgage, OD, BT, and TOP UP. Through partnerships with 50 banks and financial institutions, we streamline documentation processes and also offer comprehensive Insurance policies. For all your loan requirements, Ranjana Erankar is your trusted point of contact, ensuring seamless and efficient service.</p>
              </div> */}
              <h1 className=' font-bold text-2xl text-violet-500 sm:indent-2 pt-12'>Contact Us to Get Started ...</h1>
              <p className="text-gray-600">
              At The Ekaiv, we believe in empowering our vendors not just as sellers but as thriving entrepreneurs. Our Assistance Page is designed to provide a helping hand, fostering growth and success for our valued vendors. Whether you're a seasoned business owner or just starting, our range of support services ensures you have the guidance and expertise needed to navigate various aspects of your business journey.
            </p>
              
            </div>
          
            </div>
            </div>

        </div>
      </div>

    </>
  );
};

export default CaAssistance;
