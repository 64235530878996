import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { toast, Toaster } from 'react-hot-toast'
import {API_URL} from '../config/config';
import axios from 'axios';

const ContactUs = () => {
  
  const subjects = ["General Inquiry", "Technical Support", "Billing Issue", "Other"];

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!clicked) {
      
      setClicked(true);

      const timeout = setTimeout(() => {
        setClicked(false);
        clearTimeout(timeout);
      }, 3000); 

      setTimer(timeout);
    }

    // Check if all fields are filled
    const formFields = ['name', 'email', 'phone', 'subject', 'message'];
    const allFieldsFilled = formFields.every(field => e.target[field].value.trim() !== '');

    if (!allFieldsFilled) {
      toast.error('Please fill in all fields before submitting.');
      return;
    }

    const phone = e.target['phone'].value.trim();
  if(phone.length !== 10 || !/^\d+$/.test(phone)) {
    toast.error('Please enter a valid 10-digit phone number.');
    return;
  }

  const email = e.target['email'].value.trim();
  if (!validateEmail(email)) {
    toast.error('Please enter a valid email address.');
    return;
  }

    try {
      const response = await axios.post(`${API_URL}/api/ContactUs/mail`, {
        name: e.target['name'].value.trim(),
        email: e.target['email'].value.trim(),
        phone: e.target['phone'].value.trim(),
        subject: e.target['subject'].value.trim(),
        message: e.target['message'].value.trim(),
      });

      if (response.status === 200) {
        toast.success('Mail sent successfully!');
        e.target.reset();
      } else {
        toast.error('Failed to send the mail. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('Failed to send the mail. Please try again later.');
    }
  };

  
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [clicked, setClicked] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleClick = () => {
   
    
    // Perform any other actions needed after the button is clicked
  };


  return (

    <div className="min-h-screen mx-auto max-w-7xl flex items-center justify-center pb-10 mt-10">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-6xl mx-auto md:flex flex-row items-center">


        <div className="md:w-[50%] sm:p-8 p-4">
          <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
          <div className="mb-6">
            <p className="text-gray-700">
              If you have any questions or feedback, please feel free to contact us using the form below.
            </p>
          </div>

          <form className="w-full max-w-5xl mx-auto" onSubmit={sendEmail} id='form' >
            {/* Form fields */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC]"
                id="name"
                name="name"
                maxLength="255"
                // value={formData.name}
                // onChange={handleInputChange}
                type="text"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
             className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC] '
              
                id="email"
                name="email"
                type="email"
                placeholder="Your Email"
                
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC]"
                id="phone"
                type="tel"
                pattern="[0-9]*"
                maxLength={10}
                name="phone"
                placeholder="Your Phone Number"
                onKeyDown={(e) => {
                  if (!/\d/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                      e.preventDefault();
                  }}}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                Subject
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC]"
                id="subject"
                name='subject'
              >
                <option value="" disabled selected>Select a subject</option>
                {subjects.map((subject, index) => (
                  <option key={index} value={subject}>{subject}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-[#8A58DC]"
                id="message"
                name='message'
                rows="5"
                placeholder="Your Message"
              ></textarea>
            </div>
            {/* Button */}
            <div className="flex items-center justify-center">
              <button 
              className={`${clicked ? 'bg-[#F16080] hover:bg-[#FF6FAA]' : 'bg-[#8A58DC] hover:bg-[#965df0] shadow-md '} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105`}
                type="submit" value="send"
                disabled={clicked}

              >
                Submit
              </button>
            </div>
          </form>

          <div className="mt-10">

            <h1 className="text-lg font-semibold text-[#8A58DC] mb-4">The Ekaiv Team is here to help. Feel free to reach out with any questions or concerns.</h1>
            <div className="mt-10 flex flex-col space-y-2 ">
              <div className="flex items-center">
                <FaEnvelope className="h-6 w-6 text-[#F16080] mr-2" />
                <p className="text-gray-700">Email: <a href="mailto:support@percerptionstechnologies.com" className="text-[#8A58DC] hover:underline">support@theekaiv.in</a></p>
              </div>

            </div>
          </div>
        </div>

        <div className="sm:w-[50%] mx-auto relative overflow-hidden">
          <img
            src="https://img.freepik.com/free-vector/contact-us-concept-illustration_114360-3147.jpg?size=626&ext=jpg&ga=GA1.1.584503204.1684751112&semt=ais"
            alt="Contact Us"
            className="w-full border-slate-950 h-auto rounded-lg transform hover:scale-105 transition-transform duration-300"
          />
        </div>

      </div>
      <Toaster />
    </div>
  );
};

export default ContactUs;